import React, { useEffect, useState } from 'react';
import { Box, ToolbarArea } from 'components/Layout';
import { Tab, TabPanel, Tabs } from 'components/Navigation/Tabs';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import TextField from 'components/Inputs/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from 'components/Inputs/Checkbox';
import { Radio, RadioGroup } from 'components/Inputs/RadioButton';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import SafeEvlRslt from './SafeEvlRslt';
import DropzoneImage from '../../components/Inputs/DropzoneImage';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

let opinionEtc = `· 위험시설 지정 여부 : 지정 (OOOO년)/ 지정해제 (OOOO년) / 미지정
· 시점부 : 산지초입/ 저수지 하류/ 보호시설 직하류/ 하폭 1m 이하 / 기타 중 택1
· 종점부 : 국가하천/ 지방하천/ 소하천/ 농수로/ 우수관로 / 세천 / 저수지
· 피해이력 여부: 있음/ 없음
· 세천 제원: 연장 598m, 평균 하폭 1.3m, 제방고 0.8m
· 호안 및 옹벽 (설치/미설치) 이며, (정비구간 없음/정비 필요)
· 하폭협소 및 급축소로 인한 정비 필요 여부 : 있음 / 없음
· 특이사항 : 주택 및 마을과는 (인접/이격)되어 있으며 피해시 영향범위는 (많은/작은) 편임
· 주민의견 : 없음/ 있으면 상세내용
· 공사시급성 : 양호 / 보통 / 시급`;

const validationColumns = [
  {
    part: 'start',
    partName: '시점부',
    columns: [
      { id: 'riverWidth', name: '하천단면 - 하폭' },
      { id: 'leveeHeight', name: '하천단면 - 제방고' },
      { id: 'leveeLeftLen', name: '둑마루폭 - 좌안', flagColumn: 'leveeLeftFlag' },
      { id: 'leveeRightLen', name: '둑마루폭 - 우안', flagColumn: 'leveeRightFlag' },
      { id: 'innerLeft', name: '호안 - 좌안' },
      { id: 'innerRight', name: '호안 - 우안' },
      { id: 'val1_1', name: '인명·재산피해 - 인명피해' },
      { id: 'val1_2', name: '인명·재산피해 - 재산피해' },
      { id: 'val2', name: '상습침수피해 - 침수피해' },
      { id: 'val3_1', name: '제 방 - 좌안' },
      { id: 'val3_2', name: '제 방 - 우안' },
      { id: 'val4_1', name: '호 안 - 좌안' },
      { id: 'val4_2', name: '호 안 - 우안' },
      { id: 'val5', name: '통관·문비' },
      { id: 'val6', name: '하폭 변화' },
      { id: 'val7', name: '하상침식·퇴적' },
      { id: 'val8', name: '식생·유송잡물' },
      { id: 'val9_1', name: '입지여건' },
      { id: 'val10', name: '훼손·불법점용' }
    ]
  },
  {
    part: 'start-mid',
    partName: '시점-중앙',
    columns: [{ id: 'riverWidth', name: '하천단면' }]
  },
  {
    part: 'mid',
    partName: '중앙부',
    columns: [
      { id: 'riverWidth', name: '하천단면-하폭' },
      { id: 'leveeHeight', name: '하천단면-제방고' },
      { id: 'leveeLeftLen', name: '둑마루폭-좌안', flagColumn: 'leveeLeftFlag' },
      { id: 'leveeRightLen', name: '둑마루폭-우안', flagColumn: 'leveeRightFlag' },
      { id: 'innerLeft', name: '호안-좌안' },
      { id: 'innerRight', name: '호안-우안' },
      { id: 'val1_1', name: '인명·재산피해 - 인명피해' },
      { id: 'val1_2', name: '인명·재산피해 - 재산피해' },
      { id: 'val2', name: '상습침수피해 - 침수피해' },
      { id: 'val3_1', name: '제 방 - 좌안' },
      { id: 'val3_2', name: '제 방 - 우안' },
      { id: 'val4_1', name: '호 안 - 좌안' },
      { id: 'val4_2', name: '호 안 - 우안' },
      { id: 'val5', name: '통관·문비' },
      { id: 'val6', name: '하폭 변화' },
      { id: 'val7', name: '하상침식·퇴적' },
      { id: 'val8', name: '식생·유송잡물' },
      { id: 'val9_1', name: '입지여건' },
      { id: 'val10', name: '훼손·불법점용' }
    ]
  },
  {
    part: 'mid-end',
    partName: '중앙-종점',
    columns: [{ id: 'riverWidth', name: '하천단면' }]
  },
  {
    part: 'end',
    partName: '종점부',
    columns: [
      { id: 'riverWidth', name: '하천단면-하폭' },
      { id: 'leveeHeight', name: '하천단면-제방고' },
      { id: 'leveeLeftLen', name: '둑마루폭-좌안', flagColumn: 'leveeLeftFlag' },
      { id: 'leveeRightLen', name: '둑마루폭-우안', flagColumn: 'leveeRightFlag' },
      { id: 'innerLeft', name: '호안-좌안' },
      { id: 'innerRight', name: '호안-우안' },
      { id: 'val1_1', name: '인명·재산피해 - 인명피해' },
      { id: 'val1_2', name: '인명·재산피해 - 재산피해' },
      { id: 'val2', name: '상습침수피해 - 침수피해' },
      { id: 'val3_1', name: '제 방 - 좌안' },
      { id: 'val3_2', name: '제 방 - 우안' },
      { id: 'val4_1', name: '호 안 - 좌안' },
      { id: 'val4_2', name: '호 안 - 우안' },
      { id: 'val5', name: '통관·문비' },
      { id: 'val6', name: '하폭 변화' },
      { id: 'val7', name: '하상침식·퇴적' },
      { id: 'val8', name: '식생·유송잡물' },
      { id: 'val9_1', name: '입지여건' },
      { id: 'val10', name: '훼손·불법점용' }
    ]
  }
];
export default function SafeEvlA(props) {
  const { pData, pRows, insertRows, setInsertRows, setVisibleAction, setIsEdit } = props;

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
    setVisibleAction(newValue === 5 ? false : true);
  };

  const handleChange = (part, data) => {
    const isPart = insertRows.find((t) => t.part === part);
    let result = [...insertRows];
    if (isPart) {
      result = insertRows.map((row) => (row.part === part ? { ...row, ...data } : row));
    } else {
      const { fcCode, fcType, prjId } = pData;
      const params = { ...data, part, fcCode, fcType, prjId };
      result.push(params);
    }
    setInsertRows(result);
    setIsEdit(true);
  };

  const start = insertRows.find((t) => t.part === 'start');
  const startMid = insertRows.find((t) => t.part === 'start-mid');
  const mid = insertRows.find((t) => t.part === 'mid');
  const midEnd = insertRows.find((t) => t.part === 'mid-end');
  const end = insertRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box style={{ m: 0, borderBottom: '1px solid #CCA63D' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
          <Tab label="시점부" index={0} />
          <Tab label="시점-중앙" index={1} />
          <Tab label="중앙부" index={2} />
          <Tab label="중앙-종점" index={3} />
          <Tab label="종점부" index={4} />
          <Tab label="평가결과 미리보기" index={5} />
        </Tabs>
      </Box>
      <Box sx={{ display: 'block', height: '550px', overflow: 'auto', overflowX: 'hidden' }}>
        <TabPanel value={valueTab} index={0}>
          <LinePointInfo label="시점부" part="start" datas={start} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <LineConnectInfo label="시점-중앙" part="start-mid" datas={startMid} onChange={handleChange} />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <LinePointInfo label="중점부" part="mid" datas={mid} onChange={handleChange} prjId={pData.prjId} />
        </TabPanel>
        <TabPanel value={valueTab} index={3}>
          <LineConnectInfo label="중앙-종점" part="mid-end" datas={midEnd} onChange={handleChange} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={4}>
          <LinePointInfo label="종점부" part="end" datas={end} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={5}>
          <SafeEvlRslt label="평가결과 미리보기" pData={pData} pRows={pRows} validationColumns={validationColumns} selectedMenu="safetyEvl" />
        </TabPanel>
      </Box>
    </Box>
  );
}

const LinePointInfo = (props) => {
  const { datas, prjId, fcCode, part, label, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleChangeRadio = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeScore = (data) => {
    if (!onChange) return;
    if (data.scoreId.indexOf('score9') > -1) {
      onChange(part, { [data.valId]: data.valValue, score9: data.scoreValue });
    } else {
      onChange(part, { [data.valId]: data.valValue, [data.scoreId]: data.scoreValue });
    }
  };

  const handleClickRadioScore = (e) => {
    const valId = e.target.name;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const handleChangeChecked = (e) => {
    if (!onChange) return;
    const id = e.target.id;
    let initInfo = {};
    if (e.target.checked) {
      if (id === 'leveeLeftFlag') {
        initInfo = { leveeLeftLen: null };
      } else if (id === 'leveeRightFlag') {
        initInfo = { leveeRightLen: null };
      }
    }
    onChange(part, { ...initInfo, [e.target.id]: e.target.checked });
  };

  const handleChangeCheckedVal9 = (e) => {
    if (!onChange) return;
    const valId = e.target.id;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
    onChange(part, { [valId]: e.target.checked ? '1' : '0' });
  };

  const handleChange = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.id]: e.target.value });
  };

  const handleDropImg = (acceptedFiles, imageType) => {
    if (!!acceptedFiles && acceptedFiles.length > 0) {
      setBackdropOpen(true);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      return new Promise((resolve) => {
        reader.onload = () => {
          eval(`onChange(part, { ${imageType + 'Pre'}: reader.result, ${imageType}: acceptedFiles[0] })`);
          resolve();
          setBackdropOpen(false);
        };
      });
    }
  };

  const open = Boolean(anchorEl);
  const checkVaild = (id) => (!datas?.[id] ? { required: true } : {});

  return (
    <Box sx={{ m: 2, display: 'block' }}>
      <ToolbarArea title="제원측정"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                하천단면
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.riverWidth && { required: true })}>
                하폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="riverWidth"
                  type="number"
                  value={datas?.riverWidth || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th">
                제방고
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="leveeHeight"
                  type="number"
                  value={datas?.leveeHeight || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                둑마루폭
              </TableCell>
              <TableCell align="center" component="th">
                좌안
              </TableCell>
              <TableCell align="center">
                <Box sx={{ m: 0 }}>
                  <TextField
                    id="leveeLeftLen"
                    type="number"
                    value={datas?.leveeLeftLen || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    style={{ width: 180 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    disabled={datas?.leveeLeftFlag}
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                  <Box sx={{ flex: '1 1 auto' }}></Box>
                  <Checkbox id="leveeLeftFlag" label="미설치" checked={datas?.leveeLeftFlag} onChange={handleChangeChecked} />
                </Box>
              </TableCell>
              <TableCell align="center" component="th">
                우안
              </TableCell>
              <TableCell align="center">
                <Box sx={{ m: 0 }}>
                  <TextField
                    id="leveeRightLen"
                    type="number"
                    value={datas?.leveeRightLen || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    style={{ width: 180 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    disabled={datas?.leveeRightFlag}
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                  <Box sx={{ flex: '1 1 auto' }}></Box>
                  <Checkbox id="leveeRightFlag" label="미설치" checked={datas?.leveeRightFlag} onChange={handleChangeChecked} />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="2">
                호안
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.innerLeft && { required: true })}>
                좌안
              </TableCell>
              <TableCell align="center" colSpan="3">
                <RadioGroup onChange={handleChangeRadio} name="innerLeft" value={datas?.innerLeft || ''} color="secondary">
                  <Radio value="0" label="미설치" />
                  <Radio value="1" label="석축" />
                  <Radio value="2" label="돌쌓기" />
                  <Radio value="3" label="콘크리트 수로 및 옹벽" />
                  <Radio value="4" label="콘크리트 블럭" />
                  <Radio value="5" label="기타" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...(!datas?.innerRight && { required: true })}>
                우안
              </TableCell>
              <TableCell align="center" colSpan="3">
                <RadioGroup onChange={handleChangeRadio} name="innerRight" value={datas?.innerRight || ''}>
                  <Radio value="0" label="미설치" />
                  <Radio value="1" label="석축" />
                  <Radio value="2" label="돌쌓기" />
                  <Radio value="3" label="콘크리트 수로 및 옹벽" />
                  <Radio value="4" label="콘크리트 블럭" />
                  <Radio value="5" label="기타" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="피해이력"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="2">
                ① 인명·재산피해
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.val1_1 && { required: true })}>
                {`인명피해 [ ${datas?.score1_1 || '- '} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val1_1" value={datas?.val1_1 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="없음" />
                  <Radio value="2" label="있음" />
                </RadioGroup>
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.val1_2 && { required: true })}>
                {`재산피해 [ ${datas?.score1_2 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val1_2" value={datas?.val1_2 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="없음" />
                  <Radio value="2" label="있음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                피해내용
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <TextField id="text1" value={datas?.text1 || ''} placeholder={'피해 내용 서술'} onChange={handleChange} fullWidth inputProps={{ maxLength: 200 }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="2">
                ② 상습침수피해
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.val2 && { required: true })}>
                {`침수피해 [ ${datas?.score2 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup name="val2" value={datas?.val2 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="없음" />
                  <Radio value="1" label="장비 후 추가피해 없음" />
                  <Radio value="2" label="있음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                피해내용
              </TableCell>
              <TableCell align="center" colSpan="3">
                <TextField id="text2" value={datas?.text2 || ''} placeholder={'피해 내용 서술'} onChange={handleChange} fullWidth inputProps={{ maxLength: 200 }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="시설물 현황"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ③ 제 방
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.val3_1 && { required: true })}>
                {`좌안 [ ${datas?.score3_1 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup onClick={handleClickRadioScore} name="val3_1" value={datas?.val3_1 || ''}>
                  <Radio value="0" label="파손없음" />
                  <Radio value="2" label="파손" />
                </RadioGroup>
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.val3_2 && { required: true })}>
                {`우안 [ ${datas?.score3_2 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup onClick={handleClickRadioScore} name="val3_2" value={datas?.val3_2 || ''}>
                  <Radio value="0" label="파손없음" />
                  <Radio value="2" label="파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ④ 호 안
              </TableCell>
              <TableCell align="center" component="th" {...checkVaild('score4_1')}>
                {`좌안 [ ${datas?.score4_1 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup onClick={handleClickRadioScore} name="val4_1" value={datas?.val4_1 || ''}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="노후화" />
                  <Radio value="2" label="호안없음·파손" />
                </RadioGroup>
              </TableCell>
              <TableCell align="center" component="th" {...checkVaild('score4_2')}>
                {`우안 [ ${datas?.score4_2 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup onClick={handleClickRadioScore} name="val4_2" value={datas?.val4_2 || ''}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="노후화" />
                  <Radio value="2" label="호안없음·파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score5')}>
                {`⑤ 통관·문비 [ ${datas?.score5 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val5" value={datas?.val5 || ''}>
                  <Radio value="0" label="시설물양호" />
                  <Radio value="1" label="노후화 심함" />
                  <Radio value="2" label="시설물 없거나 파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="하도 현황"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score6')}>
                {`⑥ 하폭 변화 [ ${datas?.score6 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val6" value={datas?.val6 || ''}>
                  <Radio value="0" label="급축소 없음" />
                  <Radio value="1" label="급축소" />
                  <Radio value="2" label="하폭 매우 협소" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score7')}>
                {`⑦ 하상침식·퇴적 [ ${datas?.score7 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val7" value={datas?.val7 || ''}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="일부퇴적" />
                  <Radio value="2" label="심한퇴적" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score8')}>
                {`⑧ 식생·유송잡물 [ ${datas?.score8 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val8" value={datas?.val8 || ''}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="하도내식생" />
                  <Radio value="2" label="교량내 유송잡물" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score9')}>
                {`⑨ 입지여건 [ ${datas?.score9 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val9_1" value={datas?.val9_1 || ''}>
                  <Radio value="0" label="산지·나대지" />
                  <Radio value="1" label="일부 농경지·취락지" />
                  <Radio value="2" label="다수 취락지" />
                </RadioGroup>
                {/* <Box sx={{ my: 0 }}>
                  <Checkbox id="val9_1" label="산지·나대지" checked={datas?.val9_1 === '0' ? false : true} value="0" onChange={handleChangeCheckedVal9} />
                  <Checkbox id="val9_2" label="일부 농경지·취락지" checked={datas?.val9_2 === '0' ? false : true} value="1" onChange={handleChangeCheckedVal9} />
                  <Checkbox id="val9_3" label="다수 취락지" checked={datas?.val9_3 === '0' ? false : true} value="2" onChange={handleChangeCheckedVal9} />
                </Box> */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score10')}>
                {`⑩ 훼손·불법점용 [ ${datas?.score10 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onClick={handleClickRadioScore} name="val10" value={datas?.val10 || ''}>
                  <Radio value="0" label="없음" />
                  <Radio value="1" label="일부" />
                  <Radio value="2" label="심함" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="기타의견"></ToolbarArea>
      <TextField id="opinionEtc" multiline rows={10} fullWidth value={datas?.opinionEtc || opinionEtc} onChange={handleChange} inputProps={{ maxLength: 3000 }} />

      <ToolbarArea title="사진 첨부"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="50%" />
            <col width="50%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                전경 사진
              </TableCell>
              <TableCell align="center" component="th">
                {`${label} 현황`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="panorama" maxFiles={1} maxFilesize={5242880} />
                {datas?.panoramaPre && <img src={datas?.panoramaPre} alt="panoramaPre" width="80%" height="200px" />}
                {!datas?.panoramaPre && (
                  <img
                    src={!!datas?.panoramaPre ? datas?.panoramaPre : `/api/safe/img/${prjId}/${fcCode}/${part}Panorama`}
                    alt={`${part}Panorama`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="point" maxFiles={1} maxFilesize={5242880} />
                {datas?.pointPre && <img src={datas?.pointPre} alt="pointPre" width="80%" height="200px" />}
                {!datas?.pointPre && (
                  <img
                    src={!!datas?.pointPre ? datas?.pointPre : `/api/safe/img/${prjId}/${fcCode}/${part}Point`}
                    alt={`${part}Point`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <PopoverSpfRadio
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          selected={selectedScore}
          onChangeScore={handleChangeScore}
        />
      )}
      <Backdrop open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const LineConnectInfo = (props) => {
  const { datas, part, label, onChange } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(part, { [e.target.id]: e.target.value });
    }
  };
  return (
    <Box sx={{ display: 'block' }}>
      <ToolbarArea title="제원측정"></ToolbarArea>
      <TableContainer component={'div'} sx={{ height: '510px', width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="17%" />
            <col width="13%" />
            <col width="70%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                하천단면
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.riverWidth && { required: true })}>
                하폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="riverWidth"
                  value={datas?.riverWidth || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
