import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Button from './Button';
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default function StyledDropzone(props) {
  const { accept, onAcceptedFiles, onUploadFiles, id, maxFiles = 0, maxSize = 10485760 } = props;
  const [shpFileFlag, setShpFileFlag] = useState(false);
  const [shxFileFlag, setShxFileFlag] = useState(false);
  const [dbfFileFlag, setDbfFileFlag] = useState(false);
  const [rejections, setRejections] = useState([]);
  const [open, setOpen] = useState(false);

  const checkShps = (file) => {
    if (file.name?.indexOf('.shp') > 0) {
      setShpFileFlag(true);
    } else if (file.name?.indexOf('.shx') > 0) {
      setShxFileFlag(true);
    } else if (file.name?.indexOf('.dbf') > 0) {
      setDbfFileFlag(true);
    }
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    // isDragActive,
    isDragAccept,
    isDragReject,
    inputRef
  } = useDropzone({ accept: accept, maxSize: maxSize, maxFiles: maxFiles, validator: checkShps });
  const [isDragActive, setIsDragActive] = useState(false);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleUploadFiles = async () => {
    try {
      setOpen(true);
      if (acceptedFiles.length > 0) {
        await onUploadFiles(acceptedFiles)
          .then((response) => {
            acceptedFiles.length = 0;
            acceptedFiles.splice(0, acceptedFiles.length);
            inputRef.current.value = '';
            setRejections([]);
            if (response) {
              alert(response.data.message);
            }
            setOpen(false);
          })
          .catch((error) => {
            alert(error.response?.data?.message);
          });
      }
    } catch (e) {
      console.log(e);
      setOpen(false);
      alert('파일 업로드를 실패하였습니다.');
    }
  };

  const handleMouseHover = (e) => {
    setIsDragActive(!isDragActive);
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (!(dbfFileFlag && shxFileFlag && shpFileFlag)) {
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
        inputRef.current.value = '';
        setShpFileFlag(false);
        setShxFileFlag(false);
        setDbfFileFlag(false);
        alert('.shp, .shx, .dbf 파일을 함께 업로드하세요.');
        return;
      }

      setShpFileFlag(false);
      setShxFileFlag(false);
      setDbfFileFlag(false);

      onAcceptedFiles(acceptedFiles, id);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections) {
      let fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
          <li key={file.path} style={{ color: 'red' }}>
            {file.path} - {file.size} bytes
            <ul>
              {errors.map((e) => {
                let message = null;
                if (e.code === 'file-invalid-type') {
                  message = `[${e.message.replace('File type must be ', '')}] 파일만 업로드 가능합니다.`;
                } else if (e.code === 'file-too-large') {
                  message = '10M 이하 파일만 업로드 가능합니다.';
                } else if (e.code === 'too-many-files') {
                  message = '.shp, .shx, .dbf 파일을 하나씩만 올려주세요.';
                }
                return <li key={e.code}>{message || e.message}</li>;
              })}
            </ul>
            <Divider />
          </li>
        );
      });
      setRejections(fileRejectionItems);
    }
  }, [fileRejections]);

  return (
    <div>
      <div></div>
      <Container onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>여기에 파일을 드래그 앤 드롭하거나 클릭하여 파일을 선택하십시오.</p>
      </Container>
      <aside>
        <ul>{files}</ul>
      </aside>
      <aside>
        <ul>{rejections}</ul>
      </aside>
      {onUploadFiles && (
        <Button fullWidth variant="contained" onClick={handleUploadFiles} disabled={files.length <= 0}>
          <SaveIcon sx={{ mr: 0.5 }} />
          파일 업로드
        </Button>
      )}
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
