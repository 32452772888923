import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'utils/api.js';
import moment from 'moment';

import { Box, ContentsBox, SearchBox } from 'components/Layout';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import MapIcon from '@mui/icons-material/Map';
import { InputBase, IconButton, Paper, Select, MenuItem } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { MobileTitle } from 'components/mobiles';

export default function SafeMng() {
  const navigate = useNavigate();

  const [srchDatas, setSrchDatas] = useState({});
  const [rows, setRows] = useState([]);

  /**
   * 프로젝트 관리 - 목록 조회
   * @param {*} e
   */
  const getDataList = async () => {
    let params = { status: 'ing' };
    if (srchDatas?.projName) {
      params = { ...params, projName: srchDatas?.projName };
    }
    const response = await axios.get(`/api/prj`, { params: params });
    if (response.data) {
      setRows([...response.data]);
    }
  };

  const handleClickMovePage = (params) => (e) => {
    navigate(`/m/map`, { state: { data: params } });
  };

  const handleClickSrchBtn = (e) => {
    if (srchDatas?.projName?.length >= 100) {
      alert('프로젝트명은 100자까지 가능합니다.');
      return;
    }
    getDataList();
  };

  const handleSrchChange = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <div>
      <MobileTitle title="프로젝트 선택" />
      <Box sx={{ p: 1, display: 'block' }}>
        <Paper sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
          <TextField id="projName" value={srchDatas?.projName} onChange={handleSrchChange} placeholder="프로젝트명" sx={{ flex: 1 }} />
          <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleClickSrchBtn}>
            <SearchIcon />
          </IconButton>
        </Paper>
        <Paper sx={{ flexGrow: 1, overflow: 'auto', my: 1 }} elevation={3}>
          <List>
            {rows.map((row, index) => {
              return (
                <ListItem
                  key={index}
                  divider
                  secondaryAction={
                    <IconButton edge="end" aria-label="map" onClick={handleClickMovePage(row)} sx={{ mr: 1 }}>
                      <MapIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={row?.projName} secondary={`${row?.startDate} ~ ${row?.endDate}`} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Box>
    </div>
  );
}
