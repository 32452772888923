import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import axios from 'utils/api.js';
import ViewListIcon from '@mui/icons-material/ViewList';
import ReportIcon from '@mui/icons-material/Report';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'components/Inputs/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import SafeEvl from './SafeEvl';
import SafeEvlResult from './SafeEvlResult';
import SafeEvlReport from './SafeEvlReport';
import SafeEvlPicture from './SafeEvlPicture';

import MapContext from 'map/MapContext';
import { mapUtil } from 'map/MapUtils.js';

export default function SafeMngClass(props) {
  const { map } = useContext(MapContext);

  const { selectedFeature, setSelectedFeature } = props;

  const [facInfo, setFacInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const hanldeClickDelete = (feature) => async (e) => {
    try {
      if (window.confirm('삭제시 모든 데이터가 사라집니다. 삭제하시겠습니까?')) {
        const response = await axios.delete(`/api/safe/spf-geom`, { data: { fcType: feature.get('fc_type'), dataList: [feature.get('id')] } });
        const spfSource = mapUtil.getLayerSourceType(map, 'spf');
        spfSource.forEach((source) => {
          source.refresh();
        });
        setSelectedFeature(null);
      }
    } catch (e) {
      alert('시설물을 삭제할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  const handleClickOpen = (e) => {
    const type = e.target.id;
    setOpenModal({ open: true, type: type });
  };

  const handleClose = (value) => {
    if (value) {
      const { type, datas } = value;
      if (type === 'evlResult') {
        const editSource = mapUtil.getLayerSourceId(map, `editLayer`);
        editSource.clear();
        const source = mapUtil.getLayerSourceId(map, `fc_${facInfo?.fcType.toLowerCase()}`);
        source.getFeatures().forEach((ft) => {
          if (ft.get('id') === facInfo.id) {
            ft.setProperties({ ...ft.getProperties(), flag_evl: '1' });
            editSource.addFeature(ft);
            setSelectedFeature(ft);
          }
        });
      }
    }
    setOpenModal({ open: false, type: null });
  };

  const getGeoFeatureInfo = (feature) => {
    const spfInfo = {
      id: feature.get('id'),
      fcCode: feature.get('fc_code'),
      fcType: feature.get('fc_type').toLowerCase(),
      prjId: feature.get('prj_id')
    };
    return spfInfo;
  };

  /**
   * 선택된 시설물 정보 상세 조회
   * @param {*} data
   */
  const getFacilityInfo = async () => {
    try {
      const featureInfo = getGeoFeatureInfo(selectedFeature);
      const response = await axios.get(`/api/safe/insp/${featureInfo.id}`, {
        params: featureInfo
      });
      if (response.data) {
        setFacInfo(response.data);
      }
    } catch (e) {
      alert('시설 정보를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  useEffect(() => {
    if (selectedFeature) getFacilityInfo();
  }, [selectedFeature]);

  return (
    <Box
      component="div"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        m: 1,
        p: 0,
        zIndex: 3
      }}
    >
      <Stack direction="row-reverse" spacing={1}>
        <Button startIcon={<DeleteIcon />} color="error" onClick={hanldeClickDelete(selectedFeature)}>
          삭제
        </Button>
      </Stack>
      <Paper sx={{ mt: 1, p: 2 }}>
        <Stack spacing={1} direction="column">
          <div>{selectedFeature.get('name')}</div>
          <div>{`연장 : ${selectedFeature.get('length')?.toFixed(2)}m`}</div>
          <Button startIcon={<ReportIcon />} id="evaluation" color="secondary" onClick={handleClickOpen}>
            현장조사 (안전점검평가)
          </Button>
          <Button startIcon={<ViewListIcon />} id="result" color="secondary" onClick={handleClickOpen}>
            현장조사 결과
          </Button>
          {/* <Button startIcon={<SummarizeIcon />} id="safetyReport" color="secondary" onClick={handleClickOpen}>
                안전점검보고서
              </Button> */}
          <Button startIcon={<PhotoLibraryIcon />} id="picture" color="secondary" onClick={handleClickOpen}>
            사진관리
          </Button>
        </Stack>
        {openModal?.type === 'evaluation' && <SafeEvl open={openModal.open} title="현장조사 (안전점검평가)" facInfo={facInfo} onClose={handleClose} getFacilityInfo={getFacilityInfo} />}
        {openModal?.type === 'result' && <SafeEvlResult open={openModal.open} title="현장조사 결과" facInfo={facInfo} onClose={handleClose} />}
        {openModal?.type === 'report' && <SafeEvlReport open={openModal.open} title="안전점검보고서" facInfo={facInfo} onClose={handleClose} />}
        {openModal?.type === 'picture' && <SafeEvlPicture open={openModal.open} title="사진관리" facInfo={facInfo} onClose={handleClose} />}
      </Paper>
    </Box>
  );
}
