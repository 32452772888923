import React, { useState, useEffect } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from 'components/Inputs/TextField';

import moment from 'moment';
import ko from 'moment/locale/ko';
moment.locale('ko');

export default function CustomDatePicker(props) {
  const { id, format, onChangeDate, date } = props;

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value) {
      onChangeDate(value, id);
    }
  }, [value]);

  useEffect(() => {
    if (date) setValue(date);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {format === 'year' && (
        <DatePicker
          views={['year']}
          // label="Year only"
          value={value}
          onChange={(newValue) => {
            setValue(newValue.format('YYYY'));
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
          disableMaskedInput={true}
        />
      )}
      {format === 'yyyy-MM' && (
        <DatePicker
          inputFormat={'YYYY-MM'}
          views={['year', 'month']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onError={(value) => {
            console.log(value);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
          disableMaskedInput={true}
        />
      )}
      {format === 'yyyy-MM-DD' && (
        <DatePicker
          inputFormat={'YYYY-MM-DD'}
          // label="Invert the order of views"
          value={value}
          onChange={(newValue) => {
            setValue(newValue.format('YYYY-MM-DD'));
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
          disableMaskedInput={true}
        />
      )}
    </LocalizationProvider>
  );
}
