import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';

export default function CustomizedRadioGroup(props) {
  const { children, value, name, ...rest } = props;
  return (
    <RadioGroup row aria-labelledby={name || 'buttons-group-label'} name={name} sx={{ ml: 1 }} value={value} {...rest}>
      {children}
    </RadioGroup>
  );
}
