import React from 'react';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`
}));

export default function CustomTableContainer(props) {
  const { children, sx, ...rest } = props;

  return (
    <StyledTableContainer component={'div'} sx={sx} {...rest}>
      {children}
    </StyledTableContainer>
  );
}
