import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import axios from 'utils/api.js';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Tabs, Tab, TabPanel } from 'components/Navigation/Tabs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Button from 'components/Inputs/Button';
import Stack from '@mui/material/Stack';
import TextField from 'components/Inputs/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from 'components/Inputs/Checkbox';
import { RadioGroup, Radio } from 'components/Inputs/RadioButton';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import MapContext from 'map/MapContext';

const lastTableCellStyle = { borderRight: '1px solid #e0e0e0' };

const checkList = {
  A: [
    { checkIdx: 1, text: '1.과거 인명피해 및 재산피해 이력' },
    { checkIdx: 2, text: '2.제내지 상습침수피해 이력' },
    { checkIdx: 3, text: '3.제방단면 부족 및 파손여부' },
    { checkIdx: 4, text: '4.호안 유.무, 노후화 및 파손상태' },
    { checkIdx: 5, text: '5.기타시설물(통관,문비 등)노후화' },
    { checkIdx: 6, text: '6.하폭 부족 또는 급축소등' },
    { checkIdx: 7, text: '7.하도 침식/퇴적에 따른 위험성' },
    { checkIdx: 8, text: '8.유송잡물,식생에 따른 통수능 부족' },
    { checkIdx: 9, text: '9.입지여건(취락지 등)에 따른 위험성' },
    { checkIdx: 10, text: '10.인위적인 훼손 및 불법점용' }
  ],
  B: [
    { checkIdx: 1, text: '1.교량 부분파손, 콘크리트 균열' },
    { checkIdx: 2, text: '2.철근노출 및 부식 여부' },
    { checkIdx: 3, text: '3.교각, 교대 등의 세굴피해' },
    { checkIdx: 4, text: '4.노후교량, 구조물의 안정성 등' },
    { checkIdx: 5, text: '5.제방 접합부 침식에 의한 피해' },
    { checkIdx: 6, text: '6.여유고 부족, 월류로 인한 피해여부' },
    { checkIdx: 7, text: '7.하폭에 비해 작은 규모의 교량 (자동산정)' },
    { checkIdx: 8, text: '8.경간장 부족에 의한 피해 여부 (자동산정)' },
    { checkIdx: 9, text: '9.입지여건(통행량)에 따른 위험' },
    { checkIdx: 10, text: '10.홍수시 통행자 위험성(세월교 등)' }
  ],
  C: [
    { checkIdx: 1, text: '1.본체 노후 및 파손여부' },
    { checkIdx: 2, text: '2.옹벽(본체 양쪽) 파손여부' },
    { checkIdx: 3, text: '3.바닥보호공 파손여부' },
    { checkIdx: 4, text: '4.본체와 제방 접합부 세굴여부' },
    { checkIdx: 5, text: '5.본체 높이에 따른 제방 월류' },
    { checkIdx: 6, text: '6.보 : 취수기능 상실여부 / 낙차공 : 낙차기능 상실여부' },
    { checkIdx: 7, text: '7.기계장치(조작실, 권양기 등) 파손여부' },
    { checkIdx: 8, text: '8.상·하류부 침식/퇴적에 따른 피해' },
    { checkIdx: 9, text: '9.철거민원 등 사회, 환경적 영향' },
    { checkIdx: 10, text: '10.어도 유무 및 파손 여부' }
  ],
  E: [
    { checkIdx: 1, text: '1.도로폭 협소(교행불가 등)' },
    { checkIdx: 2, text: '2.선형불량(심한 곡선, 시야 미확보 등)' },
    { checkIdx: 3, text: '3.비탈면의 안전시설(난간 등) 유∙무' },
    { checkIdx: 4, text: '4.지반침하 여부' },
    { checkIdx: 5, text: '5.포장상태 불량 및 파손 여부' },
    { checkIdx: 6, text: '6.비포장으로 인한 피해여부' },
    { checkIdx: 7, text: '7.노면의 요철 여부' },
    { checkIdx: 8, text: '8.구조물 파손으로 인한 피해여부' },
    { checkIdx: 9, text: '9.배수로 통수능 부족 여부' },
    { checkIdx: 10, text: '10.도로의 입지에 따른 위험성' }
  ],
  F: [
    { checkIdx: 1, text: '1.도로폭 협소(교행불가 등)' },
    { checkIdx: 2, text: '2.선형불량(심한 곡선, 시야 미확보 등)' },
    { checkIdx: 3, text: '3.비탈면의 안전시설(난간 등) 유∙무' },
    { checkIdx: 4, text: '4.지반침하 여부' },
    { checkIdx: 5, text: '5.포장상태 불량 및 파손 여부' },
    { checkIdx: 6, text: '6.비포장으로 인한 피해여부' },
    { checkIdx: 7, text: '7.노면의 요철 여부' },
    { checkIdx: 8, text: '8.구조물 파손으로 인한 피해여부' },
    { checkIdx: 9, text: '9.배수로 통수능 부족 여부' },
    { checkIdx: 10, text: '10.도로의 입지에 따른 위험성' }
  ]
};
export default function ResultView(props) {
  const { map } = useContext(MapContext);

  const { pData, pRows, selectedMenu, validationColumns } = props;

  const [evlRslt, setEvlRslt] = useState({});

  /**
   * 시설물의 후보지 선정하기
   * @param {*} e
   */
  const handleClickCandidate = async (e) => {
    const { prjId, fcCode, id } = pData;
    try {
      const flagCand = e.target.value;
      const params = { prjId, fcCode, flagCand, id };
      const response = await axios.patch(`/api/safe/evl-rslt-cand`, params);
      if (response.data) {
        getSafeEvlRsltInfo();
      }
    } catch (e) {
      // alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  const checkSubColumn = async (column, nullColumn, validationColumn, row) => {
    if (column.subColumns) {
      const subColumn = column.subColumns.find((subColumn) => subColumn.type === eval(`row.${column.id}`));
      if (!subColumn) {
        nullColumn.partName = validationColumn.partName;
        nullColumn.name = column.name;
        return true;
      } else {
        await subColumn.columns.map((sColumn) => {
          checkSubColumn(sColumn, nullColumn, validationColumn, row).then((result) => {
            if (!eval(`row.${sColumn.id}`) && Object.keys(nullColumn).length === 0 && !result) {
              nullColumn.partName = validationColumn.partName;
              nullColumn.name = `${column.name} - ${sColumn.name}`;
              return true;
            }
          });
        });
      }
    }
    return false;
  };

  const checkValidation = async (nullColumn, validationColumn) => {
    if (!!!nullColumn) return;
    const row = pRows.find((row) => row.part === validationColumn.part);
    if (row) {
      await validationColumn.columns.map(async (column) => {
        await checkSubColumn(column, nullColumn, validationColumn, row).then((result) => {
          if (column.flagColumn) {
            if (!eval(`row.${column.flagColumn}`) && Object.keys(nullColumn).length === 0 && !result) {
              if (!eval(`row.${column.id}`)) {
                nullColumn.partName = validationColumn.partName;
                nullColumn.name = column.name;
                return;
              }
            }
          } else if (!eval(`row.${column.id}`) && Object.keys(nullColumn).length === 0 && !result) {
            nullColumn.partName = validationColumn.partName;
            nullColumn.name = column.name;
            return;
          }
        });
      });
      return;
    } else if (!row && Object.keys(nullColumn).length === 0) {
      nullColumn.partName = validationColumn.partName;
      return;
    }
  };

  /**
   * 안전점검평가 최종 완료
   * @param {*} end
   */
  const handleClickCompleteSafe = async (e) => {
    try {
      let nullColumn = {};
      await validationColumns.map(async (validationColumn) => {
        await checkValidation(nullColumn, validationColumn);
      });

      if (Object.keys(nullColumn).length !== 0) {
        let message = `누락된 데이터가 있습니다.\r\n`;
        message += `${nullColumn.partName}${nullColumn.name != null ? '의 ' + nullColumn.name : ''}을/를 확인해주세요.`;
        alert(message);
        return;
      }

      if (!evlRslt?.id) {
        alert('누락된 데이터가 있습니다.\r\n현장조사 결과표 확인 후 조사를 완료하세요.');
        return;
      }
      if (window.confirm('안전점검 평가를 완료하면 이후 내용 수정이 불가능합니다. 완료하시겠습니까?')) {
        const str = localStorage.getItem('user');
        const json = JSON.parse(str);
        const response = await axios.patch(`/api/safe/evl-rslt/${evlRslt.id}`, { flagEvl: '1', cmpltId: json.userid });
        if (response.data) {
          setEvlRslt(response.data);
        }
      }
    } catch (e) {
      console.log(e);
      alert('평가를 완료할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  /**
   * 안전점검결과 결과보기 상세조회
   * @returns
   */
  const getSafeEvlRsltInfo = async () => {
    try {
      const { evlRsltId, prjId, fcCode, fcType } = pData;
      const params = { id: evlRsltId, prjId, fcCode, fcType };
      const response = await axios.get(`/api/safe/evl-rslt/${evlRsltId}`, { params });
      if (response.data) {
        setEvlRslt(response.data);
      }
    } catch (e) {
      // alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  useEffect(() => {
    if (pData?.evlRsltId) getSafeEvlRsltInfo();
  }, []);

  return (
    <Box sx={{ m: 0 }}>
      <Box sx={{ display: 'block', m: 2, p: 2 }}>
        {(pData?.fcType === 'A' || pData?.fcType === 'E' || pData?.fcType === 'F') && <LineTypeRslt fcType={pData?.fcType} evlRslt={evlRslt} pData={pData} pRows={pRows} />}
        {(pData?.fcType === 'B' || pData?.fcType === 'C') && <PolygonTypeRslt fcType={pData?.fcType} evlRslt={evlRslt} pData={pData} pRows={pRows} />}
        {selectedMenu === 'safetyEvl' && (
          <>
            <ToolbarArea title="후보지 선정 여부"></ToolbarArea>
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 2 }}>
              <Button fullWidth color={evlRslt?.flagCand === 'N' ? 'error' : 'default'} startIcon={<ClearIcon />} value="N" onClick={handleClickCandidate}>
                후보지 선정 제외하기
              </Button>
              <Button fullWidth color={evlRslt?.flagCand === 'Y' ? 'success' : 'default'} startIcon={<CheckIcon />} value="Y" onClick={handleClickCandidate}>
                후보지 선정하기
              </Button>
            </Stack>

            <ToolbarArea title="안전점검평가 완료 여부"></ToolbarArea>
            <Button fullWidth sx={{ mt: 2, height: 60 }} onClick={handleClickCompleteSafe}>
              {evlRslt?.flagEvl === '0' ? '안전점검평가 완료하기' : '안전점검평가 완료 (수정불가)'}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

const LineTypeRslt = (props) => {
  const { fcType, evlRslt, pData, pRows } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <>
      {fcType === 'A' && <FcTypeA evlRslt={evlRslt} pData={pData} pRows={pRows} />}
      {fcType === 'E' && <FcTypeE evlRslt={evlRslt} pData={pData} pRows={pRows} />}
      {fcType === 'F' && <FcTypeF evlRslt={evlRslt} pData={pData} pRows={pRows} />}

      <ToolbarArea title="현장조사 결과"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="*" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="center" rowSpan="2">
                점검항목
              </TableCell>
              <TableCell align="center" colSpan="3">
                상세평가
              </TableCell>
              <TableCell align="center" colSpan="2">
                평가결과
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">시점부</TableCell>
              <TableCell align="center">중앙부</TableCell>
              <TableCell align="center">종점부</TableCell>
              <TableCell align="center">상태등급</TableCell>
              <TableCell align="center">점수</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkList?.[fcType].map((row, idx) => {
              const checkIdx = row.checkIdx;
              return (
                <>
                  <TableRow key={`row-${idx}`}>
                    <TableCell rowSpan="2">{row.text}</TableCell>
                    <TableCell align="center">{start?.[`grade${checkIdx}`] || '-'}</TableCell>
                    <TableCell align="center">{mid?.[`grade${checkIdx}`] || '-'} </TableCell>
                    <TableCell align="center">{end?.[`grade${checkIdx}`] || '-'} </TableCell>
                    <TableCell align="center" rowSpan="2">
                      {evlRslt?.[`grade${checkIdx}`] || '-'}
                    </TableCell>
                    <TableCell align="center" rowSpan="2">
                      {evlRslt?.[`score${checkIdx}`] || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{start?.[`score${checkIdx}`] || '-'}</TableCell>
                    <TableCell align="center">{mid?.[`score${checkIdx}`] || '-'}</TableCell>
                    <TableCell align="center" style={lastTableCellStyle}>
                      {end?.[`score${checkIdx}`] || '-'}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            <TableRow>
              <TableCell align="center" component="th">
                평가점수
              </TableCell>
              <TableCell align="center" component="th" colSpan="3">
                {evlRslt?.totResult}
              </TableCell>
              <TableCell align="center" component="th">
                합계
              </TableCell>
              <TableCell align="center" component="th">
                {evlRslt?.totScore}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PolygonTypeRslt = (props) => {
  const { fcType, evlRslt, pData, pRows } = props;

  const point = pRows.find((t) => t.part === 'point');

  return (
    <>
      {fcType === 'B' && <FcTypeB evlRslt={evlRslt} pData={pData} pRows={pRows} />}
      {fcType === 'C' && <FcTypeC evlRslt={evlRslt} pData={pData} pRows={pRows} />}

      <ToolbarArea title="현장조사 결과"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="center" rowSpan="2" colSpan="2">
                점검항목
              </TableCell>
              <TableCell align="center" colSpan="2">
                평가결과
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">상태등급</TableCell>
              <TableCell align="center">점수</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkList?.[fcType].map((row, idx) => {
              const checkIdx = row.checkIdx;
              return (
                <TableRow key={`row-${idx}`}>
                  <TableCell colSpan="2">{row.text}</TableCell>
                  <TableCell align="center">{evlRslt?.[`grade${checkIdx}`] || '-'}</TableCell>
                  <TableCell align="center">{evlRslt?.[`score${checkIdx}`] || '-'}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align="center" component="th">
                평가점수
              </TableCell>
              <TableCell align="center" component="th">
                {evlRslt?.totResult}
              </TableCell>
              <TableCell align="center" component="th">
                합계
              </TableCell>
              <TableCell align="center" component="th">
                {evlRslt?.totScore}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const FcTypeA = (props) => {
  const { evlRslt, pData, pRows } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block' }}>
      <ToolbarArea title="제원측정 결과"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" colSpan="3">
                연장 (m)
              </TableCell>
              <TableCell align="center" colSpan="2">
                {evlRslt?.len}
              </TableCell>
              <TableCell align="center">{evlRslt?.len < 50 ? '불만족' : '만족'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="5">
                하천단면
              </TableCell>
              <TableCell align="center" component="th" rowSpan="5">
                하폭 (m)
              </TableCell>
              <TableCell align="center" component="th">
                시점부
              </TableCell>
              <TableCell align="center">{start?.riverWidth || '-'}</TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg || '-'}
              </TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg > 1 ? '만족' : '불만족'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                시점-중앙
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {startMid?.riverWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {mid?.riverWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙-종점
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {midEnd?.riverWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                종점부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {end?.riverWidth || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" component="h2" align="right">
        세천은 가늘고 긴 개울로서 '하천법','소하천 정비법'에 따라 관리되지 않은 시설물 폭 1미터 이상이고 연장 50미터 이상인 것을 말한다.
      </Typography>
    </Box>
  );
};

const FcTypeB = (props) => {
  const { evlRslt, pData, pRows } = props;

  const point = pRows.find((t) => t.part === 'point');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <ToolbarArea title="제원측정 결과"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="16%" />
            <col width="13%" />
            <col width="15%" />
            <col width="13%" />
            <col width="15%" />
            <col width="13%" />
            <col width="15%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                교량제원
              </TableCell>
              <TableCell align="center" component="th">
                연장
              </TableCell>
              <TableCell align="center">{point?.bLength}</TableCell>
              <TableCell align="center" component="th">
                폭
              </TableCell>
              <TableCell align="center">{point?.bWidth}</TableCell>
              <TableCell align="center" component="th">
                높이
              </TableCell>
              <TableCell align="center">{point?.bHeight}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                상하류 하폭
              </TableCell>
              <TableCell align="center" component="th">
                상류하폭
              </TableCell>
              <TableCell align="center">{point?.riverWidthTop}</TableCell>
              <TableCell align="center" component="th">
                교량위치하폭
              </TableCell>
              <TableCell align="center">{point?.riverWidthPos}</TableCell>
              <TableCell align="center" component="th">
                하류하폭
              </TableCell>
              <TableCell align="center">{point?.riverWidthBottom}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" rowSpan={2}>
                교량형식별 <br />
                통수단면 제원
              </TableCell>
              <TableCell align="center" component="th">
                교량유형
              </TableCell>
              <TableCell align="center" colSpan={5}>
                {point?.pierType === '0' ? '슬래브교' : point?.pierType === '1' ? '라멘교' : point?.pierType === '2' ? '암거교' : point?.pierType === '3' ? '흄관교' : point?.pierType === '4' ? '세월교' : '-'}
              </TableCell>
            </TableRow>
            {(point?.pierType === '0' || point?.pierType === '1') && (
              <TableRow>
                <TableCell align="center" component="th">
                  교각수
                </TableCell>
                <TableCell align="center">{point?.pierCnt || '-'}</TableCell>
                <TableCell align="center" component="th">
                  경간장
                </TableCell>
                <TableCell align="center">{point?.pierLength || '-'}</TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {point?.pierType === '2' && (
              <TableRow>
                <TableCell align="center" component="th">
                  암거련수
                </TableCell>
                <TableCell align="center">{point?.pierCnt || '-'}</TableCell>
                <TableCell align="center" component="th">
                  암거직경
                </TableCell>
                <TableCell align="center">{point?.pierLength || '-'} </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {point?.pierType === '3' && (
              <TableRow>
                <TableCell align="center" component="th">
                  흄관련수
                </TableCell>
                <TableCell align="center">{point?.pierCnt || '-'} </TableCell>
                <TableCell align="center" component="th">
                  흄관직경
                </TableCell>
                <TableCell align="center">{point?.pierLength || '-'} </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {point?.pierType === '4' && (
              <TableRow>
                <TableCell align="center" component="th">
                  교량형식
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  {point?.pierForm === '0' ? '하상바닥' : point?.pierForm === '1' ? 'BOX형' : point?.pierForm === '2' ? '흄관형' : '-'}
                </TableCell>
              </TableRow>
            )}
            {point?.pierType === '4' && point?.pierForm === '1' && (
              <TableRow>
                <TableCell align="center" component="th">
                  폭
                </TableCell>
                <TableCell align="center">{point?.pierFormW || '-'}</TableCell>
                <TableCell align="center" component="th">
                  높이
                </TableCell>
                <TableCell align="center">{point?.pierFormH || '-'} </TableCell>
                <TableCell align="center" component="th">
                  련수
                </TableCell>
                <TableCell align="center">{point?.pierFormCnt || '-'}</TableCell>
              </TableRow>
            )}
            {point?.pierType === '4' && point?.pierForm === '2' && (
              <TableRow>
                <TableCell align="center" component="th">
                  직경
                </TableCell>
                <TableCell align="center">{point?.pierFormL || '-'}</TableCell>
                <TableCell align="center" component="th">
                  련수
                </TableCell>
                <TableCell align="center">{point?.pierFormCnt || '-'}</TableCell>
                <TableCell align="center" colSpan={2}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" component="h2" align="right"></Typography>
    </Box>
  );
};

const FcTypeC = (props) => {
  const { evlRslt, pData, pRows } = props;

  const point = pRows.find((t) => t.part === 'point');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <ToolbarArea title="제원측정 결과"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="16%" />
            <col width="13%" />
            <col width="15%" />
            <col width="13%" />
            <col width="15%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                시설구분
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {point?.cFcType === 'C' ? '취입보' : '낙차공'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                제원측정
              </TableCell>
              <TableCell align="center" component="th">
                연장
              </TableCell>
              <TableCell align="center">{point?.cLength}</TableCell>
              <TableCell align="center" component="th">
                높이
              </TableCell>
              <TableCell align="center">{point?.cHeight}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" component="h2" align="right"></Typography>
    </Box>
  );
};

const FcTypeE = (props) => {
  const { evlRslt, pData, pRows } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block' }}>
      <ToolbarArea title="도로구조 결과"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="15%" />
            <col width="25%" />
            <col width="15%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="5">
                도로폭 (m)
              </TableCell>
              <TableCell align="center" component="th">
                시점부
              </TableCell>
              <TableCell align="center">{start?.roadWidth || '-'}</TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg || '-'}
              </TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg > 1 ? '만족' : '불만족'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                시점-중앙
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {startMid?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {mid?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙-종점
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {midEnd?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                종점부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {end?.roadWidth || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" component="h2" align="right">
        농로는 경작지 등과 연결되어 농어민의 생산 활동에 직접 공용되는 도로로 평균폭 2.5m 이상인 것을 말한다.
      </Typography>
    </Box>
  );
};

const FcTypeF = (props) => {
  const { evlRslt, pData, pRows } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block' }}>
      <ToolbarArea title="도로구조 결과"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="15%" />
            <col width="25%" />
            <col width="15%" />
            <col width="25%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" rowSpan="5">
                도로폭 (m)
              </TableCell>
              <TableCell align="center" component="th">
                시점부
              </TableCell>
              <TableCell align="center">{start?.roadWidth || '-'}</TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg || '-'}
              </TableCell>
              <TableCell align="center" rowSpan="5">
                {evlRslt?.widthAvg > 1 ? '만족' : '불만족'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                시점-중앙
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {startMid?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {mid?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                중앙-종점
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {midEnd?.roadWidth || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                종점부
              </TableCell>
              <TableCell align="center" style={lastTableCellStyle}>
                {end?.roadWidth || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" component="h2" align="right">
        마을진입로는 자연·인위적으로 생긴 마을을 연결하거나 마을안의 공공용 도로로 평균폭 3.0m 이상인 것을 말한다.
      </Typography>
    </Box>
  );
};
