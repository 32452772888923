import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authAtom } from "_state";

import axios from "utils/api.js";
import Cookies from "js-cookie";

import AuthContext from "./AuthContext";
import { authUtils } from "../utils/authUtils";

export default function AuthProvider({ children, isMobile }) {
  let navigate = useNavigate();

  const rootUrl = isMobile ? "/m/" : "/";

  const [auth, setAuth] = useRecoilState(authAtom);

  const removeLocalStorage = () => {
    localStorage.removeItem("user");
    setAuth(null);
  };

  const setLocalStorage = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
  };

  let signin = async (userInfo, callback) => {
    const encryptData = authUtils.useEncryptByPublicKey(userInfo);
    try {
      const { data } = await axios.get(`/api/login`, { params: encryptData });
      if (data?.success) {
        if (data.userInfo.passwordInit === "Y") {
          alert("비밀번호가 초기화 되었습니다.\r\n비밀번호를 변경 해주세요.");
          navigate(`/changePassword`, { state: { userInfo: data.userInfo } });
        } else {
          setLocalStorage(data.userInfo);
          setAuth(data.userInfo);
          // if (data.userInfo.scale === 'N') {
          //   alert('아직 승인이 되지 않아 일부메뉴만 이용 가능합니다. ');
          // }
          navigate(rootUrl);
        }
      }
    } catch (err) {
      if (callback) callback({ status: err.response.status, message: "로그인 실패" });
    }
  };

  let signout = (callback) => {
    removeLocalStorage();
  };

  let checkAuth = async () => {
    const accessToken = Cookies.get("access_token");

    try {
      if (accessToken) {
        const { data } = await axios.post(
          `/api/auth/verify`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!data.success) {
          throw new Error("access_token 만료");
        }
        setLocalStorage(data.userInfo);
      } else {
        throw new Error("access_token 없음");
      }
    } catch (error) {
      // console.log(error);
      removeLocalStorage();
    }
  };

  let value = { user: auth, signin, signout, checkAuth };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
