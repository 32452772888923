import Chart from 'react-apexcharts';
import React from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CustomBarChart(props) {
  const { name, width, height, type, categories, data } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        width: width
      }}
    >
      <CardHeader title={name} action={<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}></Typography>} />
      <CardContent sx={{ pb: 0, pt: 2 }}>
        <Chart
          options={{
            chart: {
              height: height || 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: categories,
              labels: {
                style: {
                  fontSize: '12px'
                }
              }
            }
          }}
          series={[
            {
              data: data
            }
          ]}
          type={type}
        />
      </CardContent>
    </Card>
  );
}
