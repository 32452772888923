import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import axios from 'utils/api.js';
import { Box, ToolbarArea } from 'components/Layout';
import Select from 'components/Inputs/Select';
import LayersIcon from '@mui/icons-material/Layers';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import StraightenIcon from '@mui/icons-material/Straighten';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, useTheme } from '@mui/material/styles';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import ViewListIcon from '@mui/icons-material/ViewList';
import ReportIcon from '@mui/icons-material/Report';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DeleteIcon from '@mui/icons-material/Delete';
import SummarizeIcon from '@mui/icons-material/Summarize';

import Button from 'components/Inputs/Button';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';

import MapContext from 'map/MapContext';
import { initMap, mapUtil, mapEdit } from 'map/MapUtils.js';

import MapLayerSetting from './MapLayerSetting';
import MapFacilitySearch from './MapFacilitySearch';
import MapFacilityEdit from './MapFacilityEdit';
import SafeEvlSpf from './SafeEvlSpf';
import SafeEvlPicture from './SafeEvlPicture';
import ResultView from './SafeEvlRslt';

const drawerHeight = 64;
const drawerWidth = 800;
const CustomBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  m: 0,
  p: 0,
  width: `100%`,
  height: `calc(100vh - ${drawerHeight}px)`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  })
}));

let editSource = null;

export default function AssetMap(props) {
  const { map } = useContext(MapContext);
  const location = useLocation();
  const mapSettingRef = useRef();
  const pData = location?.state?.data;

  if (!location?.state?.data) navigator('/safe');

  const [open, setOpen] = useState(false);
  const [openSafeEvl, setOpenSafeEvl] = useState(false);
  const [openSafePicture, setOpenSafePicture] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [visibleSetting, setVisibleSetting] = useState('layer');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [editFeature, setEditFeature] = useState(null);

  const handleChangeSelect = (e) => {};

  function handleClickOutside(e) {
    e.stopPropagation();
    if (mapSettingRef.current && !mapSettingRef.current.contains(e.target)) {
      setVisibleSetting(null);
    }
  }

  const handleChangeSetting = (event, newValue) => {
    setVisibleSetting(newValue);
  };

  /**
   * 편집모드 저장 or 종료
   * @param {*} params
   */
  const handleCloseEditMode = (params) => {
    if (params?.editedFeature) {
      editSource.clear();
      editSource.addFeature(params?.editedFeature);
      setSelectedFeature(params?.editedFeature);
    }
    setEditFeature(null);
    setVisibleSetting(null);
  };

  const handleChangeSearch = (event) => {
    setOpen(event.target.checked);
  };

  const hanldeClickDelete = (feature) => async (e) => {
    try {
      if (window.confirm('삭제시 모든 데이터가 사라집니다. 삭제하시겠습니까?')) {
        const response = await axios.delete(`/api/safe/spf-geom`, { data: { fcType: feature.get('fc_type'), dataList: [feature.get('id')] } });
        editSource.clear();
        const spfSource = mapUtil.getLayerSourceType(map, 'spf');
        spfSource.forEach((source) => {
          source.refresh();
        });
        setEditFeature(null);
        setSelectedFeature(null);
      }
    } catch (e) {
      alert('시설물을 삭제할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  const handleClickOpen = (e) => {
    const type = e.target.id;
    if (type === 'safetyEvl') {
      setOpenSafeEvl(true);
    } else if (type === 'safetyRslt') {
      setOpenSafeEvl(true);
    } else if (type === 'safetyReport') {
      setOpenSafeEvl(true);
    } else if (type === 'safetyPicture') {
      setOpenSafePicture(true);
    }
    setSelectedMenu(type);
  };

  const getFitLayerExtent = async () => {
    try {
      const response = await axios.get(`/api/prj/layer-fit`, { params: { prjId: pData.id, layerNm: 'sgg' } });
      if (response) {
        const { xMin, yMin, xMax, yMax } = response.data;
        mapUtil.fitExtent(map, 'sgg', [xMin, yMin, xMax, yMax]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const mapClickEvt = useCallback(
    async (evt) => {
      const fcLayer = ['fc_a', 'fc_b', 'fc_c', 'fc_e', 'fc_f'];
      const result = map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          let values = feature.getProperties();
          if (values) {
            map.getView().fit(feature.getGeometry().getExtent(), { duration: 500 });
            feature.setProperties({ ...feature.getProperties(), length: mapUtil.getFeatureLength(feature) || 0 });
            setSelectedFeature(feature);
            return feature;
          }
        },
        {
          // hitTolerance: 2,
          layerFilter: function (layer) {
            return fcLayer.find((t) => t === layer.get('id'));
          }
        }
      );
      if (!result) setSelectedFeature(null);
    },
    [map]
  );

  useEffect(() => {
    if (map && pData.id) {
      initMap(map, {
        prjId: pData.id
      });
      map.on('singleclick', mapClickEvt);
      editSource = mapUtil.getLayerSourceId(map, 'editLayer');
      getFitLayerExtent();
      mapUtil.isVisibleLayerId(map, 'insp', true);
    }
  }, [map, pData]);

  useEffect(() => {
    if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') {
      map.un('singleclick', mapClickEvt);
      document.removeEventListener('mousedown', handleClickOutside);
      setEditFeature(selectedFeature);
    } else if (visibleSetting === 'measure') {
      mapEdit.distanceMeasureStart(map);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (visibleSetting === 'measure') {
        mapEdit.distanceMeasureStop(map);
      } else if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') {
        map.on('singleclick', mapClickEvt);
      }
    };
  }, [visibleSetting]);

  useEffect(() => {
    if (!map) return;
    if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') return;
    editSource.clear();
    if (selectedFeature) editSource.addFeature(selectedFeature);
  }, [selectedFeature]);

  useEffect(() => {
    if (map) {
      setTimeout(function () {
        map.updateSize();
      }, 300);
    }
  }, [open]);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const selectedView = selectedFeature || editFeature;

  return (
    <Box sx={{ m: 0, display: 'flex' }}>
      <CustomBox id="map" open={open} sx={{ m: 0 }}>
        {/* <Box sx={{ position: 'absolute', zIndex: 1, ml: 5 }}>
          <Select id="sggCd" value={47900} onChange={handleChangeSelect} width={120}>
            <option value={47900}>예천군</option>
          </Select>

          <Select id="offCd" value={5230103} onChange={handleChangeSelect} width={150}>
            <option value="all">전체</option>
            <option value={5230103}>맑은물사업소</option>
          </Select>
        </Box> */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: drawerHeight,
            right: open ? drawerWidth + 10 : 50,
            zIndex: 1,
            display: 'block'
          }}
        >
          <ToggleButtonGroup
            value={visibleSetting}
            exclusive
            color="primary"
            onChange={handleChangeSetting}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              // height: 40,
              zIndex: 3,
              backgroundColor: 'white'
            }}
          >
            <ToggleButton value="layer" aria-label="layer">
              <LayersIcon />
            </ToggleButton>
            <ToggleButton value="measure" aria-label="measure">
              <StraightenIcon />
            </ToggleButton>
            {selectedFeature || editFeature ? (
              <ToggleButton value="editFeature" aria-label="editFeature">
                <EditIcon />
              </ToggleButton>
            ) : (
              <ToggleButton value="addFeature" aria-label="addFeature">
                <AddIcon />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {map && <MapLayerSetting value={visibleSetting} index={'layer'} mapRef={mapSettingRef} map={map} />}
          {map && (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') && (
            <MapFacilityEdit
              visibleSetting={visibleSetting}
              setVisibleSetting={setVisibleSetting}
              editFeature={editFeature}
              setEditFeature={setEditFeature}
              setSelectedFeature={setSelectedFeature}
              pData={pData}
              onCloseEdit={handleCloseEditMode}
            />
          )}
        </Box>
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: drawerHeight,
            right: 0,
            zIndex: 3
          }}
        >
          <Switch {...label} checked={open} onChange={handleChangeSearch} />
        </Box>

        {selectedView && (
          <Box
            component="div"
            sx={{
              display: 'block',
              position: 'absolute',
              bottom: 0,
              left: 240,
              right: open ? drawerWidth + 10 : 10,
              backgroundColor: 'white',
              m: 1,
              p: 2,
              zIndex: 3
            }}
          >
            <SafeEvlSpf selectedMenu={selectedMenu} open={openSafeEvl} setOpenSafeEvl={setOpenSafeEvl} geoFeature={selectedView} />
            <SafeEvlPicture open={openSafePicture} setOpenSafeEvl={setOpenSafePicture} geoFeature={selectedView} />
            <Grid container spacing={2}>
              <Grid item md={10} xs={10}>
                {`시설명 : ${selectedView.get('name')}`}
                {/* {`[${selectedView.get('fc_type_nm')}] ${selectedView.get('region_name')}_${selectedView.get('fc_type')}_${selectedView.get('fc_subnum').toString().padStart(9, '0')}`} */}
              </Grid>
              <Grid item md={2} xs={2}>
                <Button startIcon={<DeleteIcon />} color="error" fullWidth onClick={hanldeClickDelete(selectedView)}>
                  삭제
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                {`연장 : ${selectedView.get('length')?.toFixed(2)}m`}
              </Grid>
              <Grid item md={3} xs={3}>
                <Button startIcon={<ReportIcon />} fullWidth id="safetyEvl" color="secondary" onClick={handleClickOpen} sx={{ height: 50 }}>
                  현장조사 <br />
                  (안전점검평가)
                </Button>
              </Grid>
              <Grid item md={3} xs={3}>
                <Button startIcon={<ViewListIcon />} fullWidth id="safetyRslt" color="secondary" onClick={handleClickOpen} sx={{ height: 50 }}>
                  현장조사 결과
                </Button>
              </Grid>
              <Grid item md={3} xs={3}>
                <Button startIcon={<SummarizeIcon />} fullWidth id="safetyReport" color="secondary" onClick={handleClickOpen} sx={{ height: 50 }}>
                  안전점검보고서
                </Button>
              </Grid>
              <Grid item md={3} xs={3}>
                <Button startIcon={<PhotoLibraryIcon />} fullWidth id="safetyPicture" color="secondary" onClick={handleClickOpen} sx={{ height: 50 }}>
                  사진관리
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </CustomBox>
      <Drawer
        sx={{
          // width: -drawerWidth,
          flexShrink: 0,
          zIndex: 2,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{ mt: 8, mb: 0, mx: 2 }}>
          <ToolbarArea title="시설물 검색"></ToolbarArea>
        </Box>
        <MapFacilitySearch map={map} pData={pData} setSelectedFeature={setSelectedFeature} />
      </Drawer>
    </Box>
  );
}
