// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerTop = 82;

const drawerWidth = 260;

const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto'
};

const defaultFont = {
  fontFamily: ['Roboto', 'Nanum Gothic'].join(','),
  fontWeight: 'default',
  fontSize: '12px'
};

const titleFont = {
  fontFamily: ['Roboto', 'Nanum Gothic'].join(','),
  fontWeight: 'bold',
  fontSize: '16px'
};

const middleFont = {
  fontFamily: ['Roboto', 'Nanum Gothic'].join(','),
  fontWeight: 'normal',
  fontSize: '14px'
};

const button = {
  colors: {
    primary: '#004442',
    secondary: '#CCA63D',
    success: '#003399',
    warning: '#ff9800',
    error: '#f44336',
    default: '#5D5D5D'
  }
};

const colors = {
  blue: ['#2196f3'],
  gray: ['#eeeeee', '#e0e0e0', '#9e9e9e', '#757575'],
  primary: '#004442',
  secondary: '#CCA63D'
};

const warningColor = ['#ff9800', '#ffa726', '#fb8c00', '#ffa21a'];
const dangerColor = ['#f44336', '#ef5350', '#e53935', '#f55a4e'];
const successColor = ['#4caf50', '#66bb6a', '#43a047', '#5cb860'];
const infoColor = ['#00acc1', '#26c6da', '#00acc1', '#00d3ee'];
const roseColor = ['#e91e63', '#ec407a', '#d81b60', '#eb3573'];
const grayColor = ['#e5e5e5', '#757575', '#EFEFEF'];
const greenColor = '#2F9D27';
const blueColor = '#003399';
const blackColor = '#000';
const whiteColor = '#FFF';

export { drawerWidth, container, defaultFont, titleFont, middleFont, drawerTop, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor, greenColor, blackColor, whiteColor, blueColor, button, colors };
