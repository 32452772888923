import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';

export default function MbRadioGroup(props) {
  const { children, value, name, onChange } = props;
  return (
    <RadioGroup row name={name || 'radio-group'} aria-labelledby={`${name || 'radio-group'}-label`} value={value || ''} onChange={onChange} sx={{ m: 0 }}>
      {children}
    </RadioGroup>
  );
}
