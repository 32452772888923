import React, { useRef, useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from 'assets/img/dohwa.png';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/api.js';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://didas.dohwa.co.kr/nxui">
        DOHWA ENGINEERING
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Join() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'all'
  });

  const [userid, setUserid] = useState();
  const [dgCds, setDgCds] = useState([]);
  const [sgguCds, setSgguCds] = useState([]);
  const [privateTermOpen, setPrivateTermOpen] = useState(false);
  const [joinTermOpen, setJoinTermOpen] = useState(false);
  const [privateTermCheck, setPrivateTermCheck] = useState(false);
  const [joinTermCheck, setJoinTermCheck] = useState(false);

  const password = useRef();
  password.current = watch('password');

  const navigate = useNavigate();

  const signUp = async (data) => {
    data.role = 'ROLE_MANAGER';
    return await axios.post(`/api/signup`, data);
  };

  const onSubmit = (data) => {
    if (!privateTermCheck || !joinTermCheck) {
      alert('약관에 동의해주세요.');
      return;
    }

    signUp(data)
      .then((response) => {
        alert(response.data.message + '\n심사 후 프로그램 사용 가능합니다.');
        navigate('/login');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const handleBack = () => {
    navigate('/login');
  };

  const handleChange = (e) => {
    setUserid(e.target.value);
  };

  const handleCloseJoinTerm = () => {
    setJoinTermOpen(false);
  };

  const handleClosePrivateTerm = () => {
    setPrivateTermOpen(false);
  };

  const handleChangeDgCdSelect = (e) => {
    getSgguCodeList(e.target.value);
  };

  const handleChangeJoinTerm = (e) => {
    setJoinTermCheck(e.target.checked);
  };

  const handleChangePrivateTerm = (e) => {
    setPrivateTermCheck(e.target.checked);
  };

  const getDgCodeList = async () => {
    await axios
      .get(`/api/code/dg`)
      .then((response) => {
        if (!!response.data) {
          setDgCds(response.data);
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const getSgguCodeList = async (dgCd) => {
    await axios
      .get(`/api/code/${dgCd}`)
      .then((response) => {
        if (!!response.data) {
          setSgguCds(response.data);
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const isDuplicationUserid = async (e) => {
    await axios
      .get(`/api/user/isduplicationuserid/${userid}`)
      .then((response) => {
        alert(response.data.message);
        if (response.data.Code) {
          setUserid('');
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {
    getDgCodeList();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box component="img" src={logo} sx={{ m: 2, p: 2, witdh: 120, height: 120 }} />
          <Typography component="h1" variant="h5">
            회 원 가 입
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="dense"
              required
              id="id"
              label="ID"
              name="id"
              autoFocus
              sx={{ width: 2 / 3 }}
              value={userid || ''}
              error={!!errors.userid}
              helperText={errors.userid && errors.userid.message}
              {...register('userid', {
                required: 'ID를 입력해주세요.',
                minLength: { value: 6, message: 'ID는 6자 이상입니다.' },
                maxLength: { value: 20, message: 'ID는 20자 이하입니다.' }
              })}
              onChange={handleChange}
            />
            <Button variant="contained" onClick={isDuplicationUserid} size="large" sx={{ width: 0.9 / 3, ml: 1, mt: 1, fontSize: 13, height: 55 }}>
              중복 체크
            </Button>
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              label="비밀번호"
              type="password"
              id="password"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...register('password', {
                required: '비밀번호를 입력해주세요.',
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,20}$/,
                  message: '비밀번호는 8자 이상, 최소 하나의 문자 및 숫자를 입력해주세요.'
                }
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="passwordCheck"
              label="비밀번호 확인"
              type="password"
              id="passwordCheck"
              error={!!errors.passwordCheck}
              helperText={errors.passwordCheck && errors.passwordCheck.message}
              {...register('passwordCheck', {
                required: '비밀번호 확인을 입력해주세요.',
                validate: (value) => {
                  if (value !== password.current) {
                    return '비밀번호가 일치하지 않습니다.';
                  }
                }
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="companyName"
              label="회사명"
              id="companyName"
              error={!!errors.companyName}
              helperText={errors.companyName && errors.companyName.message}
              {...register('companyName', {
                required: '회사명을 입력해주세요.'
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="companyNo"
              label="사업자등록번호"
              id="companyNo"
              error={!!errors.companyNo}
              helperText={errors.companyNo && errors.companyNo.message}
              {...register('companyNo', {
                required: '사업자등록번호를 입력해주세요.',
                pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
              })}
            />
            <FormControl sx={{ minWidth: 1 / 2 }}>
              <InputLabel id="dgCd-label" required>
                시도
              </InputLabel>
              <Select
                labelId="dgCd-label"
                required
                id="dgCd"
                name="dgCd"
                label="시도"
                error={!!errors.dgCd}
                {...register('dgCd', {
                  required: '시도를 입력해주세요.'
                })}
                onChange={handleChangeDgCdSelect}
              >
                {dgCds.map((item, idx) => {
                  return (
                    <MenuItem key={`dgCd-${idx}`} value={item.cdVal}>
                      <ListItemText primary={item.cdNm} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 1 / 2 }}>
              <InputLabel id="sgguCd-label" required>
                시군구
              </InputLabel>
              <Select
                labelId="sgguCd-label"
                required
                id="sgguCd"
                name="sgguCd"
                label="시군구"
                error={!!errors.sgguCd}
                {...register('sgguCd', {
                  required: '시군구를 입력해주세요.'
                })}
              >
                {sgguCds.map((item, idx) => {
                  return (
                    <MenuItem key={`sgguCd-${idx}`} value={item.cdVal}>
                      <ListItemText primary={item.cdNm} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              required
              fullWidth
              name="username"
              label="담당자 이름"
              id="username"
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              {...register('username', {
                required: '담당자 이름을 입력해주세요.'
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="eMail"
              label="E-mail"
              type="text"
              id="eMail"
              error={!!errors.eMail}
              helperText={errors.eMail && errors.eMail.message}
              {...register('eMail', {
                required: '이메일을 입력해주세요.',
                pattern: { value: /^\S+@\S+$/i, message: '이메일 형식을 확인해주세요' }
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="phoneNumber"
              label="전화번호"
              id="phoneNumber"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber && errors.phoneNumber.message}
              {...register('phoneNumber', {
                required: '전화번호를 입력해주세요.',
                pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="cellPhoneNumber"
              label="핸드폰 번호"
              id="cellPhoneNumber"
              error={!!errors.cellPhoneNumber}
              helperText={errors.cellPhoneNumber && errors.cellPhoneNumber.message}
              {...register('cellPhoneNumber', {
                required: '핸드폰번호를 입력해주세요.',
                pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
              })}
            />
            <Box>
              <FormControlLabel control={<Checkbox />} id="joinTerms" name="joinTerms" label="서비스 이용약관 동의 (필수)" onChange={handleChangeJoinTerm} />
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  variant="outlined"
                  sx={{ ml: 1 }}
                  size="small"
                  onClick={() => {
                    setJoinTermOpen(true);
                  }}
                >
                  내용 보기
                </Button>
              </ButtonGroup>
            </Box>
            <Box>
              <FormControlLabel control={<Checkbox />} id="privateTerms" name="privateTerms" label="개인정보 수집에 대한 동의 (필수)" onChange={handleChangePrivateTerm} />
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  variant="outlined"
                  sx={{ ml: 1 }}
                  size="small"
                  onClick={() => {
                    setPrivateTermOpen(true);
                  }}
                >
                  내용 보기
                </Button>
              </ButtonGroup>
            </Box>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              회원가입
            </Button>
            <Button fullWidth variant="contained" color={'warning'} onClick={handleBack}>
              취소
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
