import React, { useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Divider from '@mui/material/Divider';

import Headerbar from './Headerbar';
import Sidebar from './Sidebar';
import Navigationbar from './Navigationbar';
import { useRecoilState } from 'recoil';
import { authAtom } from '../_state';

const mdTheme = createTheme();

export default function Default() {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const [auth, setAuth] = useRecoilState(authAtom);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Headerbar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar pathname={location.pathname} />
        <Box
          component="main"
          sx={{
            // backgroundColor: (theme) =>
            //     theme.palette.mode === 'light'
            //         ? theme.palette.grey[100]
            //         : theme.palette.grey[900],
            backgroundColor: 'white',
            flexGrow: 1,
            // height: "100vh",
            overflow: 'auto',
            m: 0,
            display: 'block'
          }}
        >
          {location.pathname.indexOf('/map') === -1 && <Navigationbar />}
          <Outlet context={{ openSidebar: open }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
