import React, { cloneElement } from 'react';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

const StyledTableBody = styled(TableBody)(({ theme }) => ({
}));

export default function CustomTableBody(props) {

    const { children, hover, ...rest } = props;

    return (
        <StyledTableBody
            {...rest}
        >
            {
                hover
                    ?
                    children.length > 0
                        ?
                        children.map((item, idx) => {
                            return cloneElement(item, {
                                hover
                            });
                        })
                        :
                        children
                    :
                    children
            }
        </StyledTableBody>
    );
}
