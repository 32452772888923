// src/components/ResponsiveTable.js
import React from 'react';
import { Paper, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { ThemeProvider, useTheme, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';

const GridContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  // gridTemplateColumns: isSmallScreen  ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  fontSize: theme.typography.pxToRem(14), // 기본 폰트 크기
  [theme.breakpoints.up('md')]: {
    // sm 이상일 때
    fontSize: theme.typography.pxToRem(16) // 폰트 크기 증가
  }
}));

const GridItem = styled(Grid)(({ theme }) => ({
  // borderTop: `0.5px solid ${theme.palette.divider}`,
  borderBottom: `0.5px solid ${theme.palette.divider}`,
  // "&:first-child": {
  //   borderTop: "none",
  // },
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const ChildItem = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  // borderLeft: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const ResponsiveGridRow = (props) => {
  const { data } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <GridContainer>
        {data &&
          data.map(({ id, label, component, isScore, score, components }, idx) => (
            <GridItem item xs={12} sm={12} key={idx}>
              <Box
                sx={{
                  display: 'flex',
                  height: '100%'
                }}
              >
                <Box sx={{ width: '30%', backgroundColor: (theme) => theme.palette.grey[200], display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{label}</Box>
                <Box sx={{ width: '70%', borderLeft: (theme) => `1px solid ${theme.palette.divider}` }}>
                  {!components ? (
                    <Box sx={{ p: 1, display: 'flex', flexShrink: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                      {component}
                      {isScore && <Box sx={{ minWidth: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>[ {score || '-'} ]</Box>}
                    </Box>
                  ) : (
                    components.map(({ id, label, component }, idx) => {
                      return (
                        <CustomBox label={label} key={`custom-box-${idx}`}>
                          {component}
                        </CustomBox>
                      );
                    })
                  )}
                </Box>
              </Box>
            </GridItem>
          ))}
      </GridContainer>
    </ThemeProvider>
  );
};

const CustomBox = ({ label, children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const direction = isSmallScreen ? 'column' : 'row';

  return (
    <ChildItem
      sx={{
        display: 'flex',
        flexDirection: direction,
        width: '100%'
        // alignItems: `stretch`,
      }}
      textAlign="center"
    >
      {direction === 'column' && (
        <Box
          sx={{
            width: '100%'
          }}
        >
          <Box sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}>{label}</Box>
          <Box sx={{ p: 1 }}>{children}</Box>
        </Box>
      )}
      {direction === 'row' && (
        <Box
          sx={{
            display: 'flex',
            width: '100%'
          }}
        >
          <Box sx={{ width: '20%', backgroundColor: (theme) => theme.palette.grey[200], display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{label}</Box>
          <Box sx={{ width: '80%', p: 1, borderLeft: (theme) => `1px solid ${theme.palette.divider}` }}>{children}</Box>
        </Box>
      )}
    </ChildItem>
  );
};

export default ResponsiveGridRow;
