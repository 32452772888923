import { Circle, RegularShape, Fill, Stroke, Text, Style } from 'ol/style';
import { Point } from 'ol/geom';
import { GeoJSON } from 'ol/format';
import * as turf from '@turf/turf';

/**
 * LineString 에 대해서 측점 포인트를 그리고, {arr_pointName:[], arr_pos:[]} 정보를 반환한다.
 * @param {*} p_feature
 * @returns
 */
const show_sidePoints_of_lineString = function (p_feature) {
  let arr_pointName = [];
  let arr_pos = [];

  const tmp_coord = p_feature.getGeometry().getCoordinates();
  if (tmp_coord.length > 1) {
    // 측점 포인트 찍기
    const standardDistance = 450; // 450 meter
    const pointDistance = 100; // 100 meter

    p_feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');

    const format = new GeoJSON();
    const turfLine = format.writeFeatureObject(p_feature);
    const length = turf.length(turfLine, { units: 'meters' });

    if (length >= standardDistance) {
      // 거리가 450m 이상인 경우 100m 단위로 점 찍기 - 마지막 거리가 100m 이내인 경우 마지막 점은 삭제
      const cntPoint = length / pointDistance - 1;
      for (let i = 1; i <= cntPoint; i++) {
        // marker 포인트 생성 및 Vector에 추가
        const turfPoint = turf.along(turfLine, (i * pointDistance) / 1000);
        const tmp_marker = format.readFeature(turfPoint);
        tmp_marker.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        // source_tmp_fc_point_startEnd.addFeature(tmp_marker);

        // marker에 Style 적용
        const tmp_lenName = 'P' + pointDistance * i;
        const tmp_point = tmp_marker.getGeometry().getCoordinates();

        // 정보 저장
        arr_pointName.push(pointDistance * i);
        arr_pos.push(tmp_point);
      }
    } else {
      // 거리가 450m 미만인 경우 4등분해서 가운데 3개 점 찍기
      const cntPoint = 4;
      const tmp_pointDistance = length / cntPoint;

      for (let i = 1; i < cntPoint; i++) {
        if (i == 2) {
          // 중점부 위치는 Skip
          continue;
        }
        // marker 포인트 생성 및 Vector에 추가
        const turfPoint = turf.along(turfLine, (i * tmp_pointDistance) / 1000);
        let tmp_marker = format.readFeature(turfPoint);
        tmp_marker.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        // source_tmp_fc_point_startEnd.addFeature(tmp_marker);

        // marker에 Style 적용
        const tmp_lenName = 'P' + Math.round(tmp_pointDistance * i);
        const tmp_point = tmp_marker.getGeometry().getCoordinates();

        // 정보 저장
        arr_pointName.push(Math.round(tmp_pointDistance * i));
        arr_pos.push(tmp_point);
      }
    }
  }

  let jsonData_output = {
    arr_pointName,
    arr_pos
  };

  return jsonData_output;
};

/**
 * LineString 에 대해, Selected 상태 -- 시점부, 중앙부, 종점부 및 측점 포인트를 추가함
 * @param {*} p_feature
 * @returns
 */
const show_subPoints_of_lineString = function (p_feature) {
  const tmp_coord = p_feature.getGeometry().getCoordinates();

  if (tmp_coord.length > 1) {
    // 시점부 포인트
    const pointStart = tmp_coord[0];

    // 종점부 포인트
    const pointEnd = tmp_coord[tmp_coord.length - 1];

    // 중앙부 포인트 - 계산 using turfLine
    p_feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const format = new GeoJSON();
    const turfLine = format.writeFeatureObject(p_feature);
    const length = turf.length(turfLine, { units: 'meters' });
    const turfPoint = turf.along(turfLine, length / 2 / 1000); // 중앙부
    const marker_mid = format.readFeature(turfPoint);
    marker_mid.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    const point_mid = marker_mid.getGeometry().getCoordinates();

    return [
      { name: '시점부', coord: pointStart },
      { name: '중앙부', coord: point_mid },
      { name: '종점부', coord: pointEnd }
    ];
  }
  return;
};

class ENUM_FC_TYPE {
  static Unknown = '';
  static type_A = 'A';
  static type_B = 'B';
  static type_C = 'C';
  static type_E = 'E';
  static type_F = 'F';
  static type_DL = 'DL';
  static type_DP = 'DP';

  static isLineString = function (fc_type) {
    const tmp_arr = [ENUM_FC_TYPE.type_A, ENUM_FC_TYPE.type_E, ENUM_FC_TYPE.type_F, ENUM_FC_TYPE.type_DL];
    return tmp_arr.includes(fc_type);
  };

  static isPolygon = function (fc_type) {
    const tmp_arr = [ENUM_FC_TYPE.type_C, ENUM_FC_TYPE.type_B, ENUM_FC_TYPE.type_DP];
    return tmp_arr.includes(fc_type);
  };
}

/**
 * @class OpenLayer Style 관련
 */
class MAP_STYLE {
  /**
   * 행정구역 스타일
   * @param {*} tmp_ft
   * @returns
   */
  static style_regionArea_func(tmp_ft) {
    const area_name = tmp_ft.get('region_name');
    return new Style({
      stroke: new Stroke({
        width: 3,
        color: [189, 189, 189, 1],
        lineJoin: 'round',
        lineCap: 'round'
      }),
      text: new Text({
        font: 'bold 11pt NanumGothic',
        fill: new Fill({ color: '#ffffff' }),
        stroke: new Stroke({
          color: '#000000',
          width: 4
        }),
        text: area_name
      })
    });
  }

  /**
   * 점검구역 스타일
   * @param {*} tmp_ft
   * @returns
   */
  static style_regionArea_insp_func(tmp_ft) {
    return new Style({
      fill: new Fill({
        color: [255, 255, 255, 0.5]
      })
    });
  }

  //----------------------------------------------------------------------------

  // 배경지도 관련 스타일
  static style_bgMap_river = new Style({
    stroke: new Stroke({
      width: 2,
      color: [60, 180, 230, 0.6],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });

  static style_bgMap_road = new Style({
    stroke: new Stroke({
      width: 3,
      color: [188, 100, 29, 0.6],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });

  static style_bgMap_continue = new Style({
    stroke: new Stroke({
      width: 2,
      color: [104, 230, 0, 0.6],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });

  static style_bgMap_rain = new Style({
    stroke: new Stroke({
      width: 2,
      color: [228, 82, 167, 0.6],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });

  //----------------------------------------------------------------------------

  // memo marker feature style 지정
  // static iconStyle = new Style({
  //   image: new Icon({
  //     anchor: [0.5, 28],
  //     anchorXUnits: 'fraction',
  //     anchorYUnits: 'pixels',
  //     src: ConfigMap.src_img_memo
  //   })
  // });

  // static iconGuideStyle = new Style({
  //   image: new Icon({
  //     anchor: [0.5, 28],
  //     opacity: 0.6,
  //     anchorXUnits: 'fraction',
  //     anchorYUnits: 'pixels',
  //     src: ConfigMap.src_img_memo
  //   })
  // });

  //----------------------------------------------------------------------------

  static lineJoin_fc = 'round';
  static lineCap_fc = 'round';

  // 정보가 있는. (실선)
  static line_fc_normal___lineString = 7;
  static line_fc_normal_bgLine___lineString = 8;

  // eval 하나도 없는. (점선)
  static line_fc_no_eval = 5.5;
  static line_fc_no_eval_bgLine = 8;
  static lineDashed_fc_no_eval = [4, 7];

  static line_basic = 7;
  static line_basic_bg = 9;

  static radius_basic = 5;

  static line_selected = 7;
  static line_selected_bg = 9;
  static line_selected_outer = 10;

  // 시설물에 대한 기본 Color 값
  static color_fc_type_a = [68, 158, 231, 1]; // 세천 (파랑) -  #449EE7

  static color_fc_type_b = [230, 51, 50, 1]; // 소교량 (빨강) - #E63332
  static color_fc_type_b_fill = [230, 51, 50, 0.3]; // 소교량 (빨강) - #E63332

  static color_fc_type_c = [236, 208, 48, 1]; // 취입보 (노랑) - #ECD030
  static color_fc_type_c_fill = [236, 208, 48, 0.3]; // 취입보 (노랑) - #ECD030

  static color_fc_type_e = [236, 208, 48, 1]; // 농로 (노랑) - #ECD030
  static color_fc_type_f = [230, 51, 50, 1]; // 마을진입로 (빨강) - #E63332

  static color_fc_type_del = [93, 93, 93, 1]; // 삭제시설 (검정) -  #5D5D5D
  static color_fc_type_del_fill = [93, 93, 93, 0.3]; // 취입보 (노랑) - #ECD030

  static color_fc_bg = [255, 255, 255, 0.8]; // 라인 바깥의 옅은 회색 배경

  // 정비계획 - 유형 순서대로
  static color_plan_type_1 = [184, 60, 55, 1]; // (1) : B83C37 붉은계통
  static color_plan_type_2 = [46, 47, 166, 1]; // (a) : 2E2FA6 파랑계통
  static color_plan_type_3 = [44, 127, 58, 1]; // (가) : 2C7F3A 녹색계통

  // 단독, 부속시설 텍스트 영역 배경
  static colorStr_bg_flag_sub_yes = 'rgba(255, 200, 200, 0.6)';
  static colorStr_bg_flag_sub_no = 'rgba(255, 255, 255, 0.6)';

  //----------------------------------------------------------------------------

  // zoom 에 따라서 normal 스타일에 해당하는 Stroke 정보를 반환 (lineString - A, E, F 타입)
  static pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom) {
    let main_lineWidth = 7;
    let bg_lineWidth = 8;

    if (curr_zoom > 13) {
      // do nothing
    }
    // if(curr_zoom > 15){
    //     // do nothing
    // }
    // else if(curr_zoom <= 15 && curr_zoom > 13){
    //     main_lineWidth = 5;
    //     bg_lineWidth = 6;
    // }
    else if (curr_zoom <= 13 && curr_zoom > 11) {
      main_lineWidth = 3;
      bg_lineWidth = 4;
    } else if (curr_zoom <= 11 && curr_zoom > 9) {
      main_lineWidth = 2;
      bg_lineWidth = 3;
    } else {
      main_lineWidth = 1;
      bg_lineWidth = 1.5;
    }

    return { main_lineWidth, bg_lineWidth };
  }

  // zoom 에 따라서 no_eval 스타일에 해당하는 Stroke 정보를 반환 (lineString - A, E, F 타입)
  static pv_get_strokeInfo_lineString_basedOnZoom___no_eval(curr_zoom) {
    let main_lineWidth = 5.5;
    let bg_lineWidth = 8;
    let bg_lineDash = [4, 7];

    if (curr_zoom > 13) {
      // do nothing
    }
    // if(curr_zoom > 15){
    //     // do nothing
    // }
    // else if(curr_zoom <= 15 && curr_zoom > 13){
    //     main_lineWidth = 4;
    //     bg_lineWidth = 5;
    //     bg_lineDash = [3, 6];
    // }
    else if (curr_zoom <= 13 && curr_zoom > 11) {
      main_lineWidth = 3;
      bg_lineWidth = 4;
      bg_lineDash = [3, 5];
    } else if (curr_zoom <= 11 && curr_zoom > 9) {
      main_lineWidth = 2;
      bg_lineWidth = 3;
      bg_lineDash = [2, 3];
    } else {
      main_lineWidth = 1;
      bg_lineWidth = 1.3;
      bg_lineDash = [1, 2];
    }

    return { main_lineWidth, bg_lineWidth, bg_lineDash };
  }

  // zoom 에 따라서 normal 스타일에 해당하는 Stroke 정보를 반환 (polygon - B, C 타입)
  static pv_get_strokeInfo_polygon_basedOnZoom___normal(curr_zoom) {
    let main_lineWidth = 2;

    // if(curr_zoom > 15){
    //     // do nothing
    // }
    // else if(curr_zoom <= 15 && curr_zoom > 11){
    //     main_lineWidth = 1;
    // }
    // else{
    //     main_lineWidth = 1;
    // }

    return { main_lineWidth };
  }

  // zoom 에 따라서 no_eval 스타일에 해당하는 Stroke 정보를 반환 (polygon - B, C 타입)
  static pv_get_strokeInfo_polygon_basedOnZoom___no_eval(curr_zoom) {
    let main_lineWidth = 2;
    let bg_lineWidth = 2.5;
    let bg_lineDash = [2, 4];

    // if(curr_zoom > 15){
    //     // do nothing
    // }
    // else if(curr_zoom <= 15 && curr_zoom > 11){
    //     main_lineWidth = 1;
    //     bg_lineWidth = 1.5
    // }
    // else{
    //     main_lineWidth = 1;
    //     bg_lineWidth = 1.5;
    // }

    return { main_lineWidth, bg_lineWidth, bg_lineDash };
  }

  // LineString 시설물의 normal 상태 스타일 반환
  static getStyle_fc_lineString_normal = function (lineColor, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);
    return [
      new Style({
        stroke: new Stroke({
          width: data_info.bg_lineWidth,
          color: MAP_STYLE.color_fc_bg,
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: data_info.main_lineWidth,
          color: lineColor,
          lineJoin: 'round',
          lineCap: 'round'
        })
      })
    ];
  };

  // LineString 시설물의 no_eval 상태 스타일 반환
  static getStyle_fc_lineString_no_eval = function (lineColor, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___no_eval(curr_zoom);

    return [
      new Style({
        stroke: new Stroke({
          width: data_info.bg_lineWidth,
          color: MAP_STYLE.color_fc_bg,
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: data_info.main_lineWidth,
          color: lineColor,
          lineJoin: 'round',
          lineCap: 'round',
          lineDash: data_info.bg_lineDash
        })
      })
    ];
  };

  // Polygon 시설물의 normal 상태 스타일 반환
  static getStyle_fc_polygon_normal = function (lineColor, fillColor, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_polygon_basedOnZoom___normal(curr_zoom);
    return [
      new Style({
        stroke: new Stroke({
          width: data_info.main_lineWidth,
          color: lineColor,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        fill: new Fill({
          color: fillColor
        })
      })
    ];
  };

  static getStyle_fc_polygon_no_eval = function (lineColor, fillColor, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_polygon_basedOnZoom___no_eval(curr_zoom);
    return [
      new Style({
        stroke: new Stroke({
          width: data_info.bg_lineWidth,
          color: MAP_STYLE.color_fc_bg,
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: data_info.main_lineWidth,
          color: lineColor,
          lineJoin: 'round',
          lineCap: 'round',
          lineDash: data_info.bg_lineDash
        }),
        fill: new Fill({
          color: fillColor
        })
      })
    ];
  };

  //----------------------------------------------------------------------------

  // 시설물 Selected 아랫부분에 배경으로 깔 Style - 타입별 공용사용
  static style_selected_bg_outerLine_black = new Style({
    stroke: new Stroke({
      width: MAP_STYLE.line_selected_outer,
      color: [60, 60, 60, 1],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });
  static style_selected_bg_outerLine_white = new Style({
    stroke: new Stroke({
      width: MAP_STYLE.line_selected_outer,
      color: [220, 220, 220, 1],
      lineJoin: 'round',
      lineCap: 'round'
    })
  });

  //----------------------------------------------------------------------------

  static color_draing_fc_lineString_end = 'rgba(255, 0, 0, 1.0)';
  static color_draing_fc_lineString_ing = 'rgba(255, 0, 0, 0.7)';

  static color_draing_fc_lineString_point_startEnd = 'rgb(15, 153, 15)';

  // static color_draing_fc_lineString_ing = "rgba(255, 0, 0, 0.7)";

  // 시설물 그리는 중의 Style
  static style_drawing_fc = [
    // new Style({
    //     stroke: new Stroke({
    //         width: MAP_STYLE.line_selected_bg
    //         , color: [220, 220, 220, 1]
    //         , lineDash: [10, 10]
    //         , lineJoin: 'round'
    //         , lineCap: 'round'
    //     })
    // }),
    new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: MAP_STYLE.color_draing_fc_lineString_ing,
        // , lineDash: [10, 10]
        width: 3
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: MAP_STYLE.color_draing_fc_lineString_ing
        }),
        fill: new Fill({
          color: MAP_STYLE.color_draing_fc_lineString_ing
        })
      })
    })
  ];

  static style_drawing_plan = function (feature) {
    console.log('-----  MAP_STYLE.style_drawing_plan  -----');
    return [
      new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: MAP_STYLE.color_draing_fc_lineString_ing,
          // , lineDash: [10, 10]
          width: 3
        }),
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: MAP_STYLE.color_draing_fc_lineString_ing
          }),
          fill: new Fill({
            color: MAP_STYLE.color_draing_fc_lineString_ing
          })
        })
      })
    ];
  };

  // 시설물 Drawing 끝나고, 저장하기 전의 확정 전 스타일
  static style_drawing_fc_end = [
    new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: MAP_STYLE.color_draing_fc_lineString_end,
        width: 3
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: MAP_STYLE.color_draing_fc_lineString_end
        }),
        fill: new Fill({
          color: MAP_STYLE.color_draing_fc_lineString_end
        })
      })
    })
  ];

  // LineString 에 대하여 시점부 포인트 스타일을 반환함
  static getStyle_startPoint_selected = function (pos_curr, pos_next, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    let style = [
      new Style({
        image: new Circle({
          radius: (data_info.main_lineWidth / 3) * 2 + 1,
          // fill : new Fill({color: MAP_STYLE.color_draing_fc_lineString_point_startEnd}),
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: data_info.main_lineWidth / 2
          })
        })
      }),
      new Style({
        geometry: new Point(pos_curr),
        text: new Text({
          font: 'bold 11pt NanumGothic',
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: 4
          }),
          // offsetY : MAP_STYLE.getOffset(pos_curr[1], pos_next[1]),
          offsetX: 30,
          text: '시작부'
        })
      })
    ];

    return style;
  };

  static getStyle_endPoint_selected = function (pos_curr, pos_prev, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    let style = [
      new Style({
        image: new Circle({
          radius: (data_info.main_lineWidth / 3) * 2 + 1,
          // fill : new Fill({color: MAP_STYLE.color_draing_fc_lineString_point_startEnd}),
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: data_info.main_lineWidth / 2
          })
        })
      }),
      new Style({
        geometry: new Point(pos_curr),
        text: new Text({
          font: 'bold 11pt NanumGothic',
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: 4
          }),
          // offsetY : MAP_STYLE.getOffset(pos_curr[1], pos_prev[1]),
          offsetX: 30,
          text: '종점부'
        })
      })
    ];

    return style;
  };

  // 시점부, 중앙부, 종점부 포인트 스타일
  static getStyle_startMidEndPoint_selected = function (pos_curr, text, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    let style = new Style({
      geometry: new Point(pos_curr),
      image: new Circle({
        radius: (data_info.main_lineWidth / 3) * 2 + 1,
        fill: new Fill({ color: '#ffffff' }),
        stroke: new Stroke({
          color: '#000000',
          width: data_info.main_lineWidth / 2
        })
      }),
      text: new Text({
        font: 'bold 10pt NanumGothic',
        fill: new Fill({ color: '#ffffff' }),
        stroke: new Stroke({
          color: '#000000',
          width: 4
        }),
        offsetX: data_info.main_lineWidth + 4,
        text: text,
        textAlign: 'left'
      })
    });

    return style;
  };

  // 측점 - 포인트 스타일
  static getStyle_sidePoint_selected = function (pos_curr, letName, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    let style = [
      new Style({
        image: new Circle({
          radius: (data_info.main_lineWidth / 5) * 3,
          fill: new Fill({ color: '#000000' })
        })
      }),
      new Style({
        geometry: new Point(pos_curr),
        text: new Text({
          font: 'bold 9pt NanumGothic',
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: 3
          }),
          offsetX: 8,
          text: letName,
          textAlign: 'left'
        })
      })
    ];
    return style;
  };

  static getStyle_sidePoint_selected_new = function (pos_curr, letName, curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    let style = new Style({
      geometry: new Point(pos_curr),
      image: new Circle({
        radius: (data_info.main_lineWidth / 5) * 3,
        fill: new Fill({ color: '#000000' })
      }),
      text: new Text({
        font: 'bold 9pt NanumGothic',
        fill: new Fill({ color: '#ffffff' }),
        stroke: new Stroke({
          color: '#000000',
          width: 3
        }),
        offsetX: 8,
        text: letName,
        textAlign: 'left'
      })
    });

    return style;
  };

  /**
   * 라인스트링 시작부 포인트 스타일
   */
  static getStartPointStyle = function (point_start, point_next) {
    const dx = point_start[0] - point_next[0];
    const dy = point_start[1] - point_next[1];
    const rotation = Math.atan2(dy, dx);

    let style = [
      // new Style({
      //     image : new Circle({
      //         radius: 5,
      //         // fill : new Fill({color: MAP_STYLE.color_draing_fc_lineString_point_startEnd}),
      //         fill : new Fill({color: '#ff0000'}),
      //         stroke : new Stroke({
      //             color: '#ffffff',
      //             width: 1
      //         }),
      //     })
      // }),

      new Style({
        image: new RegularShape({
          fill: new Fill({ color: '#ff0000' }),
          stroke: new Stroke({
            color: '#ffffff',
            width: 1
          }),
          points: 4,
          radius: 7,
          angle: Math.PI / 4, // 차후 각도 조절
          rotation: -rotation
        })
      }),

      new Style({
        geometry: new Point(point_start),
        text: new Text({
          font: 'bold 10pt NanumGothic',
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: 4
          }),
          //offsetX : getOffset(startPoint[0], endPoint[0]),
          offsetY: MAP_STYLE.getOffset(point_start[1], point_next[1]),
          text: '시점부'
        })
      })
    ];

    return style;
  };

  /**
   * 라인스트링 종점부 포인트 스타일
   * @param {Array} start 좌표
   * @param {Array} end 좌표
   */
  static getEndPointStyle = function (point_prev, point_end) {
    const dx = point_prev[0] - point_end[0];
    const dy = point_prev[1] - point_end[1];
    const rotation = Math.atan2(dy, dx);

    let style = [
      new Style({
        image: new RegularShape({
          // fill : new Fill({color: MAP_STYLE.color_draing_fc_lineString_point_startEnd}),
          fill: new Fill({ color: '#ff0000' }),
          stroke: new Stroke({
            color: '#ffffff',
            width: 1
          }),
          points: 3,
          radius: 7,
          angle: Math.PI / 6,
          rotation: -rotation
        })
      }),
      new Style({
        geometry: new Point(point_end),
        text: new Text({
          font: 'bold 10pt NanumGothic',
          fill: new Fill({ color: '#ffffff' }),
          stroke: new Stroke({
            color: '#000000',
            width: 4
          }),
          //offsetX : getOffset(startPoint[0], endPoint[0]),
          offsetY: MAP_STYLE.getOffset(point_end[1], point_prev[1]),
          text: '종점부'
        })
      })
    ];

    return style;
  };

  static getOffset = function (x1, x2) {
    const flagOffsetAdd = x1 - x2 >= 0 ? true : false;

    if (flagOffsetAdd) return -22;

    return 22;
  };

  // 병합에서 s1 ~ e2 텍스트 배치를 위한 함수
  static getOffsetY_forMergeGuildText = function (x1, x2) {
    const flagOffsetAdd = x1 - x2 >= 0 ? true : false;

    if (flagOffsetAdd) return -15;

    return 15;
  };

  //----------------------------------------------------------------------

  static color_mergeOption = 'rgba(255, 255, 255, 1.0)';

  static style_mergeOption = function (curr_zoom) {
    const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___normal(curr_zoom);

    return [
      new Style({
        stroke: new Stroke({
          width: data_info.bg_lineWidth,
          color: '#ffffff',
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: data_info.main_lineWidth,
          color: 'rgb(0, 173, 0)',
          lineJoin: 'round',
          lineCap: 'round'
        })
      })
    ];
  };

  // 병합 - 결과물 선택을 위한 가이드 라인 스타일
  static style_mergeGuideLine = function (curr_zoom) {
    // console.log("-----  MAP_STYLE.style_mergeGuideLine  -----");
    // const data_info = MAP_STYLE.pv_get_strokeInfo_lineString_basedOnZoom___no_eval(curr_zoom);
    // console.log(data_info);
    // return [
    //     new Style({
    //         stroke: new Stroke({
    //             color : "rgb(0, 173, 0)"
    //             , lineDash: data_info.bg_lineDash
    //             , width: data_info.bg_lineWidth
    //         })
    //     })
    // ];

    let main_lineWidth = 5.5;
    let bg_lineWidth = 8;
    let bg_lineDash = [4, 7];

    return [
      new Style({
        stroke: new Stroke({
          width: bg_lineWidth,
          color: '#ffffff',
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: main_lineWidth,
          color: 'rgb(0, 173, 0)',
          lineJoin: 'round',
          lineCap: 'round',
          lineDash: bg_lineDash
        })
      })
    ];
  };

  // 자르기 - 결과물 선택을 위한 가이드 라인 스타일
  static style_cutLine_partGuideLine = function (curr_zoom) {
    let main_lineWidth = 5;
    let bg_lineWidth = 6;
    let bg_lineDash = [7, 10];

    return [
      new Style({
        stroke: new Stroke({
          width: bg_lineWidth,
          // , color : "#ffffff"
          color: '#666666',
          lineJoin: 'round',
          lineCap: 'round'
        })
      }),
      new Style({
        stroke: new Stroke({
          width: main_lineWidth,
          color: 'rgb(165, 237, 33)',
          lineJoin: 'round',
          lineCap: 'round',
          lineDash: bg_lineDash
        })
      })
    ];
  };

  // 자르기 - 사용자가 자르기 Drawing 라인의 스타일
  static style_cutLine = [
    new Style({
      stroke: new Stroke({
        // color: '#333333',
        color: '#ffffff',
        width: 5,
        lineJoin: 'round',
        lineCap: 'round'
      })
    }),
    new Style({
      stroke: new Stroke({
        // color: '#ffffff',
        color: '#333333',
        width: 3,
        // lineDash : [5, 5],
        lineJoin: 'round',
        lineCap: 'round'
      })
    })
  ];

  // 병합에서 사용함. 텍스트 반환
  static get_pointText_forMerge(index, flagStartPoint) {
    if (index == 1) {
      return flagStartPoint ? 's1' : 'e1';
    } else {
      return flagStartPoint ? 's2' : 'e2';
    }
  }

  // 임시로 우선 버튼 스타일 함수 만들어둠. 나중에 name refact 할 것!  sunnyfish
  static apply_btn_style___active = function (p_el_btn, flagActive) {
    p_el_btn.classList.remove('d-none');
    if (flagActive) {
      p_el_btn.classList.remove('text-gray-700');
      p_el_btn.classList.add('text-primary');
    } else {
      p_el_btn.classList.add('text-gray-700');
      p_el_btn.classList.remove('text-primary');
    }
  };

  // Drawing(FC, Plan), Merge, Cut 에서 현재 사용중
  // 임시로 우선 버튼 스타일 함수 만들어둠. 나중에 name refact 할 것!  sunnyfish
  static apply_btn_style___hide = function (p_el_btn) {
    p_el_btn.classList.add('d-none');
  };

  static apply_btn_style___show = function (p_el_btn) {
    p_el_btn.classList.remove('d-none');
  };

  // 병합, 자르기 가이드 옵션선택 버튼 스타일 적용
  static applyStyle_checked_optionBtn = function (id_btn, flagChecked) {
    const tmp_el_btn = document.querySelector(id_btn);
    tmp_el_btn.classList.remove('d-none'); // 항상 show
    tmp_el_btn.classList.remove('text-gray-700'); // 혹시 이전에 apply_btn_style___active 함수 호출 했을까봐~
    tmp_el_btn.classList.remove('text-primary'); // 혹시 이전에 apply_btn_style___active 함수 호출 했을까봐~

    const tmp_el_icon = document.querySelector(id_btn + ' .icon i');
    if (flagChecked) {
      // 선택 상태
      tmp_el_btn.classList.remove('btn-light');
      tmp_el_btn.classList.add('btn-primary');

      tmp_el_icon.classList.remove('fa-square');
      tmp_el_icon.classList.add('fa-check-square');
    } else {
      // 해제 상태
      tmp_el_btn.classList.add('btn-light');
      tmp_el_btn.classList.remove('btn-primary');

      tmp_el_icon.classList.add('fa-square');
      tmp_el_icon.classList.remove('fa-check-square');
    }
  };

  static styleFunc_selected_fc_basic = (feature, map) => {
    // source_tmp_fc_point_startEnd.clear();
    const flag_del = feature.get('flag_del');
    const flag_evl = feature.get('flag_evl');
    let fc_type = flag_del == '1' || flag_del == '3' ? 'D' : feature.get('fc_type');
    if (ENUM_FC_TYPE.isLineString(fc_type)) {
      let arr_style = [];
      if (flag_evl == '1') {
        switch (fc_type) {
          case ENUM_FC_TYPE.type_A:
            arr_style = MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_a, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_E:
            arr_style = MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_e, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_F:
            arr_style = MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_f, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_DL:
            arr_style = MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_del, map.getView().getZoom());
            break;
        }
      } else {
        switch (fc_type) {
          case ENUM_FC_TYPE.type_A:
            arr_style = MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_a, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_E:
            arr_style = MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_e, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_F:
            arr_style = MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_f, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_DL:
            arr_style = MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_del, map.getView().getZoom());
            break;
        }
      }

      if (arr_style) {
        const tmp_feature_1 = feature.clone();
        const tmp_feature_2 = feature.clone();
        const data_sidePoint = show_sidePoints_of_lineString(tmp_feature_1); // 측점 포인트 찍기
        data_sidePoint.arr_pos.forEach((point, idx) => {
          arr_style.push(MAP_STYLE.getStyle_sidePoint_selected_new(point, `P${data_sidePoint.arr_pointName[idx]}`, map.getView().getZoom()));
        });
        const data_mainPoint = show_subPoints_of_lineString(tmp_feature_2); // 시점부, 종점부, 중앙부
        data_mainPoint.forEach((row, idx) => {
          arr_style.push(MAP_STYLE.getStyle_startMidEndPoint_selected(row.coord, row.name, map.getView().getZoom()));
        });

        // // 시점, 중앙, 종점, 측점에 대해서 속성 추가함
        // feature.setProperties({ point_start: JSON.stringify(data_mainPoint[0]) });
        // feature.setProperties({ point_mid: JSON.stringify(data_mainPoint[1]) });
        // feature.setProperties({ point_end: JSON.stringify(data_mainPoint[2]) });
        // feature.setProperties({ point_side: JSON.stringify(data_sidePoint.arr_pointName) });
        // // tmp_feature.getGeometry().transform('EPSG:3857','EPSG:4326');
        // let data_mainPoint_latLon = [];
        // for (k = 0; k < data_mainPoint.length; k++) {
        //   /// console.log(data_mainPoint[k]);
        //   const f_geom = new ol.geom.Point(data_mainPoint[k]);
        //   data_mainPoint_latLon.push(f_geom.transform('EPSG:3857', 'EPSG:4326').getCoordinates());
        // }
        // /// console.log(data_mainPoint);
        // /// console.log(data_mainPoint_latLon);
        // // 측점 텍스트 정보 업데이트
        // update_lineString_mainAndSidePoint(feature.get('fc_code'), data_mainPoint, data_mainPoint_latLon, data_sidePoint);
      }

      return arr_style;
    } else if (ENUM_FC_TYPE.isPolygon(fc_type)) {
      // else if(arr_polygon.includes(fc_type)){
      let arr_style = [];
      if (flag_evl == '1') {
        switch (fc_type) {
          case ENUM_FC_TYPE.type_B:
            arr_style = MAP_STYLE.getStyle_fc_polygon_normal(MAP_STYLE.color_fc_type_b, MAP_STYLE.color_fc_type_b_fill, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_C:
            arr_style = MAP_STYLE.getStyle_fc_polygon_normal(MAP_STYLE.color_fc_type_c, MAP_STYLE.color_fc_type_c_fill, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_DP:
            arr_style = MAP_STYLE.getStyle_fc_polygon_normal(MAP_STYLE.color_fc_type_del, MAP_STYLE.color_fc_type_del_fill, map.getView().getZoom());
            break;
        }
      } else {
        switch (fc_type) {
          case ENUM_FC_TYPE.type_B:
            arr_style = MAP_STYLE.getStyle_fc_polygon_no_eval(MAP_STYLE.color_fc_type_b, MAP_STYLE.color_fc_type_b_fill, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_C:
            arr_style = MAP_STYLE.getStyle_fc_polygon_no_eval(MAP_STYLE.color_fc_type_c, MAP_STYLE.color_fc_type_c_fill, map.getView().getZoom());
            break;
          case ENUM_FC_TYPE.type_DP:
            arr_style = MAP_STYLE.getStyle_fc_polygon_no_eval(MAP_STYLE.color_fc_type_del, MAP_STYLE.color_fc_type_del_fill, map.getView().getZoom());
            break;
        }
      }

      // 중심점 좌표를 경위도 값으로 체크해야함
      // const centerPoint = get_centerPoint_of_polygon(feature);
      // 경위도로 좌표계 변경
      // const f_geom = new ol.geom.Point(centerPoint);
      // const centerPoint_latLon = f_geom.transform('EPSG:3857', 'EPSG:4326').getCoordinates();
      /// console.log(centerPoint);
      /// console.log(centerPoint_latLon);

      // 중심부 정보 업데이트
      // update_centerPoint(feature.get('fc_code'), centerPoint, centerPoint_latLon);

      return arr_style;
    }
    return new Style();
  };
}

export { MAP_STYLE, ENUM_FC_TYPE };
