import * as React from 'react';
import Box from '@mui/material/Box';

const style = {
  display: 'block',
  mx: 2,
  mt: 4,
  minWidth: 350
};

export default function CustomBox(props) {
  const { children, sx, flex, ...rest } = props;

  const customSx = { ...style, ...sx };
  return (
    <Box component="div" sx={customSx} {...rest}>
      {children}
    </Box>
  );
}
