import Chart from 'react-apexcharts';
import React from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CustomPieChart(props) {
  const { name, width, type, labels, series } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        width: width
      }}
    >
      <CardHeader title={name} action={<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}></Typography>} />
      <CardContent sx={{ pb: 0, pt: 2 }}>
        <Chart
          options={{
            chart: {
              type: 'pie'
            },
            labels: labels
          }}
          series={series}
          type={type}
        />
      </CardContent>
    </Card>
  );
}
