import * as React from 'react';
import Box from '@mui/material/Box';

const style = {
  display: 'flex',
  m: 1
  // border: 1,
  // color: 'white',
  // background: 'gray'
};

export default function CustomBox(props) {
  const { children, sx, ...rest } = props;

  return (
    <Box component="div" sx={{ ...style, ...sx }} {...rest}>
      {children}
    </Box>
  );
}
