import React from 'react';
import { Box } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';

const ResponsiveGrid = (props) => {
  const { children } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={1} useFlexGap flexWrap="wrap">
        {children}
      </Stack>
    </Box>
  );
};

export default ResponsiveGrid;
