import React, { useEffect, useState } from 'react';

import axios from 'utils/api.js';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import 'moment/locale/ko';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Pagination from '@mui/material/Pagination';
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function UserMng(props) {
  const { role } = props;
  const [srchData, setSrchData] = useState({});
  const [datas, setDatas] = useState({ Role: { role: role }, isActive: false });
  const [rows, setRows] = useState([]);
  const [pageCnt, setPageCnt] = useState(1);
  const [page, setPage] = useState(1);
  const [offset] = useState(10);
  const [isDuplication, setIsDuplication] = useState(false);

  const [roles, setRoles] = useState([]);

  const columns = [
    { id: 'rowNumber', label: 'No', minWidth: 40, align: 'center' },
    { id: 'userid', label: 'ID', minWidth: 140, align: 'center' },
    { id: 'username', label: '이름', minWidth: 100, align: 'center' },
    { id: 'companyName', label: '소속', minWidth: 100, align: 'center' },
    { id: 'createdAt', label: '등록일', minWidth: 100, align: 'center' },
    { id: 'lastLoginAt', label: '최근 로그인 일시', minWidth: 100, align: 'center' },
    { id: 'isActiveNm', label: '사용여부', minWidth: 100, align: 'center' },
    { id: 'failCount', label: '로그인 실패', minWidth: 100, align: 'center' }
  ];

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'all'
  });

  const handleChangeSrch = (e) => {
    let id = e.target.id.replace('Srch', '');
    setSrchData({ ...srchData, [id]: e.target.value });
  };

  const handleChange = (e) => {
    setDatas({ ...datas, [e.target.id]: e.target.value });
  };

  const handleChangeRole = (e) => {
    setDatas({ ...datas, Role: { role: e.target.value } });
  };

  const handleChangeIsActive = (e) => {
    setDatas({ ...datas, isActive: e.target.value });
  };

  const handleRowClick = (data) => (evt) => {
    initForm();
    setDatas(data);
    setIsDuplication(true);
    setValue('datas', data);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const getDataList = async (e) => {
    if (validationName() || validationId()) {
      return;
    }
    const { userid, username, companyName, companyNo } = srchData;
    let params = {
      offset,
      page,
      limit: offset,
      userid,
      username,
      companyName,
      companyNo,
      role
    };

    const response = await axios.get(`/api/user`, { params });
    if (response.data) {
      const result = response.data;
      setPageCnt(Math.ceil(result.count / offset));
      setRows(result.rows);
      if (e?.target) {
        setPage(1);
      }
    }
  };

  const onClickInit = (evt) => {
    initForm();
  };

  const initForm = () => {
    setDatas({ Role: { role: role } });
    setIsDuplication(false);
    clearErrors();
    reset();
  };

  const userInfoCreate = async (data) => {
    return await axios.post(`/api/user`, data);
  };

  const userInfoUpdate = async (data) => {
    return await axios.patch(`/api/user/${data.id}`, data);
  };

  const onClickDelete = async (evt) => {
    try {
      if (datas.id) {
        if (window.confirm('삭제하시겠습니까?')) {
          await axios
            .delete(`/api/user/${datas.id}`)
            .then((response) => {
              getDataList();
              initForm();
              alert(response.data.message);
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        }
      }
    } catch (e) {
      console.log(`insert failed`);
    }
  };

  const onClickInitPassword = async (evt) => {
    try {
      if (datas.id) {
        await axios
          .patch(`/api/user/initpassword/${datas.id}`)
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    } catch (e) {
      console.log(`insert failed`);
    }
  };

  const createOrUpdate = async (datas) => {
    if (!!datas.id) {
      return await userInfoUpdate(datas);
    } else {
      return await userInfoCreate(datas);
    }
  };

  const isReservedWord = async (userid) => {
    const reservedWords = ['admin', 'administrator', 'root', 'system'];
    let flag = false;
    reservedWords.map((reservedWord) => {
      if (reservedWord.toUpperCase() === userid.toUpperCase()) {
        flag = true;
      }
    });

    return flag;
  };

  const isDuplicationUserid = async (e) => {
    isReservedWord(datas.userid).then(async (result) => {
      if (result) {
        alert(`${datas.userid} 아이디는 사용할수없습니다.`);
      } else {
        await axios
          .get(`/api/user/isduplicationuserid/${datas.userid}`)
          .then((response) => {
            alert(response.data.message);
            setIsDuplication(true);
            if (response.data.Code) {
              setDatas({ ...datas, userid: '' });
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    });
  };

  const onSubmit = () => {
    if (isDirty) {
      createOrUpdate(datas)
        .then((response) => {
          getDataList();
          initForm();
          alert(response.data.message);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const getRoleCodeList = async () => {
    await axios
      .get(`/api/code/role`)
      .then((response) => {
        if (!!response.data) {
          setRoles(response.data);
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const resetFailCount = async (id) => {
    await axios
      .patch(`/api/auth/reset/fail/${id}`)
      .then((response) => {
        getDataList();
        initForm();
        alert(response.data.message);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const validationName = () => {
    let check = /^[ㄱ-ㅎ가-힣a-zA-Z|]{0,20}$/;
    return !check.test(srchData?.username);
  };

  const validationId = () => {
    let check = /^[A-Za-z0-9]{0,20}$/;
    return !check.test(srchData?.userid);
  };

  useEffect(() => {
    getDataList(page);
  }, [page]);

  useEffect(() => {
    getRoleCodeList();
  }, []);

  useEffect(() => {
    initForm();
    setPage(1);
    getDataList(1);
  }, [role]);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {role === 'ROLE_MANAGER' ? '관리자 관리' : '점검원 관리'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Stack direction="row" spacing={2}>
            <TextField
              id="usernameSrch"
              variant="outlined"
              size="small"
              label="이름"
              value={srchData?.username || ''}
              onChange={handleChangeSrch}
              error={validationName()}
              helperText={validationName() ? '이름은 한글 또는 영문만 20자까지 가능합니다.' : ''}
            />
            <TextField
              id="useridSrch"
              variant="outlined"
              size="small"
              label="아이디"
              value={srchData?.userid || ''}
              onChange={handleChangeSrch}
              error={validationId()}
              helperText={validationId() ? '아이디는 영문, 숫자만 20자까지 가능합니다.' : ''}
            />
            <Button variant="contained" onClick={getDataList}>
              검색
            </Button>
          </Stack>
          <TableContainer component={'div'}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} sx={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIdx) => (
                  <TableRow hover key={row.id} onClick={handleRowClick(row)}>
                    {columns.map((col, colIdx) => {
                      const value = row[col.id];
                      if (col.id === 'failCount' && value >= 5) {
                        return (
                          <TableCell key={`row${rowIdx}-col${colIdx}`} align={col.align}>
                            {value}
                            <Button variant="contained" color="success" onClick={(e) => resetFailCount(row['id'])}>
                              초기화
                            </Button>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={`row${rowIdx}-col${colIdx}`} align={col.align}>
                          {row[col.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Container maxWidth="sm" sx={{ mt: 2 }}>
              <Pagination count={pageCnt} page={page} onChange={handleChangePage} showFirstButton showLastButton />
            </Container>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="contained" onClick={onClickInit}>
              초기화
            </Button>
            <Button variant="contained" type="submit" form="userInfoForm" disabled={!isDuplication}>
              {!!datas.id ? '수정' : '등록'}
            </Button>
            <Button variant="contained" onClick={onClickDelete} disabled={datas.id ? false : true}>
              삭제
            </Button>
          </Stack>
          <Box id="userInfoForm" component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, minWidth: 300, maxWidth: 500 }}>
            <TextField
              margin="dense"
              required
              sx={{ width: 2 / 3 }}
              id="userid"
              label="ID"
              name="userid"
              value={datas?.userid || ''}
              disabled={datas?.id && true}
              error={!!errors.datas?.userid}
              helperText={errors.datas?.userid && errors.datas?.userid.message}
              {...register('datas.userid', {
                required: 'ID를 입력해주세요.',
                pattern: {
                  value: /^[a-z|A-Z|0-9|d-|d_]+$/,
                  message: '아이디는 영문, 숫자, 특수문자(-, _)만 가능합니다.'
                },
                minLength: { value: 5, message: 'ID는 5자 이상입니다.' },
                maxLength: { value: 20, message: 'ID는 20자 이하입니다.' }
              })}
              onChange={handleChange}
            />
            <Button variant="contained" onClick={isDuplicationUserid} size="large" sx={{ width: 0.8 / 3, ml: 2, mt: 1, fontSize: 12, height: 55 }} disabled={datas?.userid?.length >= 5 && !!!datas.id ? false : true}>
              중복체크
            </Button>
            <TextField
              margin="dense"
              required={!datas?.id && true}
              name="password"
              label="비밀번호"
              type="password"
              id="password"
              value={datas?.password || ''}
              sx={{ width: 2 / 3 }}
              error={!!errors.datas?.password}
              helperText={errors.datas?.password && errors.datas?.password.message}
              {...register('datas.password', {
                required: !datas?.id && '비밀번호를 입력해주세요.',
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  message: '비밀번호는 8자 이상, 최소 하나 이상의 문자, 숫자, 특수문자를 입력해주세요.'
                }
              })}
              onChange={handleChange}
            />
            <Button variant="contained" onClick={onClickInitPassword} sx={{ width: 0.8 / 3, ml: 2, mt: 1, fontSize: 12 }} disabled={!!datas.id ? false : true}>
              비밀번호 <br />
              초기화
            </Button>
            <TextField
              margin="dense"
              required
              fullWidth
              name="username"
              label="이름"
              id="username"
              value={datas?.username || ''}
              error={!!errors.datas?.username}
              helperText={errors.datas?.username && errors.datas?.username.message}
              {...register('datas.username', {
                required: '이름을 입력해주세요.',
                minLength: { value: 1, message: '이름은 1자 이상입니다.' },
                maxLength: { value: 20, message: '이름은 20자 이하입니다.' },
                pattern: {
                  value: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|]+$/,
                  message: '이름은 한글 또는 영문만 가능합니다.'
                }
              })}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="companyName"
              label="소속"
              id="companyName"
              value={datas?.companyName || ''}
              error={!!errors.datas?.companyName}
              helperText={errors.datas?.companyName && errors.datas?.companyName.message}
              {...register('datas.companyName', {
                required: '소속을 입력해주세요.',
                minLength: { value: 1, message: '소속은 1자 이상입니다.' },
                maxLength: { value: 20, message: '소속은 20자 이하입니다.' }
              })}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="eMail"
              label="E-mail"
              type="text"
              id="eMail"
              value={datas?.eMail || ''}
              error={!!errors.datas?.eMail}
              helperText={errors.datas?.eMail && errors.datas?.eMail.message}
              {...register('datas.eMail', {
                required: '이메일을 입력해주세요.',
                minLength: { value: 3, message: '이메일은 3자 이상입니다.' },
                maxLength: { value: 50, message: '이메일은 50자 이하입니다.' },
                pattern: { value: /^\S+@\S+$/i, message: '이메일 형식을 확인해주세요' }
              })}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="phoneNumber"
              label="전화번호"
              id="phoneNumber"
              value={datas?.phoneNumber || ''}
              error={!!errors.datas?.phoneNumber}
              helperText={errors.datas?.phoneNumber && errors.datas?.phoneNumber.message}
              {...register('datas.phoneNumber', {
                required: '전화번호를 입력해주세요.',
                minLength: { value: 9, message: '전화번호는 9자 이상입니다.' },
                maxLength: { value: 20, message: '전화번호는 20자 이하입니다.' },
                pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
              })}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="cellPhoneNumber"
              label="핸드폰 번호"
              id="cellPhoneNumber"
              value={datas?.cellPhoneNumber || ''}
              error={!!errors.datas?.cellPhoneNumber}
              helperText={errors.datas?.cellPhoneNumber && errors.datas?.cellPhoneNumber.message}
              {...register('datas.cellPhoneNumber', {
                required: '핸드폰번호를 입력해주세요.',
                minLength: { value: 9, message: '핸드폰번호는 9자 이상입니다.' },
                maxLength: { value: 20, message: '핸드폰번호는 20자 이하입니다.' },
                pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
              })}
              onChange={handleChange}
            />
            <FormControl>
              <FormLabel required>권한</FormLabel>
              <RadioGroup name="role" id="role" value={datas.Role?.role || 'ROLE_USER'} onChange={handleChangeRole}>
                {roles.map((item, idx) => {
                  if (role === 'ROLE_MANAGER' && (item.cdVal === 'ROLE_USER' || item.cdVal === 'ROLE_ADMIN')) {
                    return;
                  } else if (role === 'ROLE_USER' && item.cdVal !== 'ROLE_USER') {
                    return;
                  }
                  return <FormControlLabel key={item.cdVal} value={item.cdVal} control={<Radio {...register('datas.Role.role')} />} label={item.cdNm} />;
                })}
              </RadioGroup>
              <FormLabel required>사용여부</FormLabel>
              <RadioGroup name="isActive" id="isActive" value={datas.isActive || false} onChange={handleChangeIsActive}>
                <FormControlLabel key="true" value={true} control={<Radio {...register('datas.isActive')} />} label="사용중" />
                <FormControlLabel key="false" value={false} control={<Radio {...register('datas.isActive')} />} label="미사용" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
