import React from 'react';
import { Paper, Typography, Divider, Box } from '@mui/material';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';

const MobileSubTitle = (props) => {
  const { title, children, ...rest } = props;

  return (
    <Box
      textAlign="start"
      sx={{
        display: 'flex',
        my: 1
      }}
    >
      <HdrWeakIcon sx={{ mr: 1, fontSize: 18 }} color="success" />
      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{title}</span>
      {children}
    </Box>
  );
};

export default MobileSubTitle;
