import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'utils/api.js';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment';
import 'dayjs/locale/ko';

moment.locale('ko');

export default function ProjectRegister(props) {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all'
  });

  const { open, handleClose, handleGetList, pData } = props;
  const [backOpen, setBackOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const insertOrModifyOrCopy = async (data) => {
    data.startDate = moment(startDate.toLocaleString()).format('YYYY-MM-DD');
    data.endDate = moment(endDate.toLocaleString()).format('YYYY-MM-DD');
    if (pData.status === 'I') {
      return await axios.post(`/api/prj`, data);
    } else if (pData.status === 'U') {
      return await axios.patch(`/api/prj/${pData.id}`, data);
    } else if (pData.status === 'C') {
      data.refProjId = pData.id;
      return await axios.post(`/api/prj/copy`, data);
    }
  };

  const onSubmit = async (data) => {
    if (endDate < startDate) {
      setError('endDate', { type: 'nonPast', message: '날짜를 확인해주세요' });
      return;
    }
    setBackOpen(true);
    insertOrModifyOrCopy(data)
      .then((response) => {
        alert(response.data.message);
        handleGetList();
        handleClose();
      })
      .catch((error) => {
        alert(error.response.data.message);
      })
      .finally(() => {
        setBackOpen(false);
      });
  };

  useEffect(async () => {
    if (open) {
      reset();
      setStartDate(!!pData.startDate ? pData.startDate : moment().format('YYYY-MM-DD'));
      setEndDate(!!pData.endDate ? pData.endDate : moment().format('YYYY-MM-DD'));
      if (!!pData.projName) {
        setValue('projName', pData.projName + (pData.status === 'C' ? ' 복사본' : ''));
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="draggable-dialog-title">{`프로젝트 ${pData.status === 'I' ? '생성' : pData.status === 'U' ? '수정' : '복사'}`}</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <DialogContent dividers>
          <TableContainer component={'div'}>
            <Table size="small">
              <TableRow>
                <TableCell>프로젝트명</TableCell>
                <TableCell>
                  <TextField
                    margin="dense"
                    size="small"
                    required
                    fullWidth
                    name="projName"
                    id="projName"
                    error={!!errors.projName}
                    helperText={errors.projName && errors.projName.message}
                    {...register('projName', {
                      required: '프로젝트명을 입력해주세요.',
                      maxLength: { value: 100, message: '프로젝트명은 100자까지 가능합니다.' }
                    })}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>프로젝트 시작일</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                    <DatePicker
                      inputFormat={'YYYY-MM-DD'}
                      value={startDate}
                      onChange={(newValue) => {
                        if (newValue.format('YYYY-MM-DD') > endDate) {
                          setStartDate(endDate);
                          setError('startDate', { type: 'nonPast', message: '시작일이 종료일보다 늦을수 없습니다.' });
                          return;
                        }
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          error={!!errors.startDate}
                          helperText={errors.startDate && errors.startDate.message}
                          {...register('startDate', {
                            required: '시작일을 입력해주세요.',
                            pattern: {
                              value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                              message: '시작일은 날짜형식으로만 입력 가능합니다.'
                            }
                          })}
                        />
                      )}
                      disableMaskedInput={true}
                      // disablePast={true}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>프로젝트 종료일</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat={'YYYY-MM-DD'}
                      value={endDate}
                      onChange={(newValue) => {
                        if (newValue.format('YYYY-MM-DD') < startDate) {
                          setEndDate(startDate);
                          setError('endDate', { type: 'nonPast', message: '종료일이 시작일보다 빠를수 없습니다.' });
                          return;
                        }
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          error={!!errors.endDate}
                          helperText={errors.endDate && errors.endDate.message}
                          {...register('endDate', {
                            required: '종료일을 입력해주세요.',
                            pattern: {
                              value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                              message: '종료일은 날짜형식으로만 입력 가능합니다.'
                            }
                          })}
                        />
                      )}
                      disableMaskedInput={true}
                      // disablePast={true}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {pData.status === 'I' ? '생성' : pData.status === 'U' ? '수정' : '복사'}
          </Button>
          <Button variant="outlined" color="success" onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
        <Backdrop open={backOpen}>
          <CircularProgress />
        </Backdrop>
      </Box>
    </Dialog>
  );
}
