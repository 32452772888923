import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MapIcon from "@mui/icons-material/Map";

import Headerbar from "./Headerbar";
import { useEffect } from "react";

export default function DefaultMobile() {
  let navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    const uri = `/m/${newValue}`;
    setValue(newValue);
    navigate(`${uri}`);
  };

  const isMap = location.pathname.indexOf("/map") > -1;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Headerbar />
      {isMap ? (
        <Box component="main" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden", height: "calc(100vh - 112px)", m: 0 }}>
          <Outlet />
        </Box>
      ) : (
        <Box component="main" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "auto", height: "calc(100vh - 112px)", mx: 1, mb: 1 }}>
          <Outlet />
        </Box>
      )}
      {/* {!isMap && (
        <Paper elevation={3}>
          <BottomNavigation value={value} onChange={handleChange} showLabels>
            <BottomNavigationAction label="프로젝트 선택" value="safe" icon={<ListAltIcon />} />
            <BottomNavigationAction label="안전관리" value="map" icon={<MapIcon />} />
          </BottomNavigation>
        </Paper>
      )} */}
    </Box>
  );
}
