import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Checkbox from 'components/Inputs/Checkbox';
import Stack from '@mui/material/Stack';

const itemsVal1 = [
  { label: '콘크리트', value: '0' },
  { label: '비포장', value: '1' },
  { label: '아스콘', value: '2' }
];
const itemsVal2 = [
  { label: '선형양호', value: '0' },
  { label: '선형불량·안전시설 대처가능', value: '1' },
  { label: '선형불량·사고위험', value: '2' }
];
const itemsVal3 = [
  { label: '적합설치', value: '0' },
  { label: '노후화·부분파손', value: '1' },
  { label: '없음 또는 심한파손', value: '2' }
];
const itemsVal4 = [
  { label: '없음', value: '0' },
  { label: '일부진행', value: '1' },
  { label: '있음', value: '2' }
];
const itemsVal5 = [
  { label: '양호', value: '0' },
  { label: '균열·포트홀·통행양호', value: '1' },
  { label: '파손·통행지장', value: '2' }
];
const itemsVal6 = [
  { label: '없음·양호·통행적음', value: '0' },
  { label: '차량통행 많음', value: '1' },
  { label: '차량통행 많음·민원 많음', value: '2' }
];
const itemsVal7 = [
  { label: '없음', value: '0' },
  { label: '소성변형·통행지장 없음', value: '1' },
  { label: '소성변형·통행지장', value: '2' }
];
const itemsVal8 = [
  { label: '양호', value: '0' },
  { label: '일부파손·안전지장 없음', value: '1' },
  { label: '심한파손', value: '2' }
];
const itemsVal9 = [
  { label: '양호', value: '0' },
  { label: '일부파손·막힘', value: '1' },
  { label: '없음 또는 심한파손', value: '2' }
];
const itemsVal10 = [
  { label: '안전지역 입지', value: '0' },
  { label: '절벽·민가입지·통행량 적음', value: '1' },
  { label: '위험지역 입지·통행량 많음', value: '2' }
];

export default function SafeEvlReportF(props) {
  const { pData, pRows, evlRslt } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box sx={{ m: 2, display: 'block' }}>
        <ToolbarArea title="현장조사"></ToolbarArea>
        <TableContainer component={'div'} sx={{ width: '1200px' }}>
          <Table>
            <colgroup>
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="6%" />
              <col width="5%" />
              <col width="6%" />
              <col width="5%" />
              <col width="6%" />
              <col width="20%" />
              <col width="5%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2}>
                  구분
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  조사항목
                </TableCell>
                <TableCell align="center" rowSpan={2} colSpan={7}>
                  항목별 평가
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  점수
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">개별</TableCell>
                <TableCell align="center">평균</TableCell>
              </TableRow>
            </TableHead>
            {/* 도로구조문제 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={14} component="th">
                  도로구조문제
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  ① 도로폭·포장종류
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  도로폭
                </TableCell>
                <TableCell align="center">{start?.roadWidth || '-'}</TableCell>
                <TableCell rowSpan={5} component="th">
                  평균도로폭
                </TableCell>
                <TableCell align="center" rowSpan={5}>
                  {evlRslt?.widthAvg}
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  포장종류
                </TableCell>
                <TableCell>
                  <CheckItems items={itemsVal1} value={mid?.val1_2} />
                </TableCell>
                <TableCell align="center"> {start?.score1}</TableCell>
                <TableCell align="center" rowSpan={5}>
                  {evlRslt?.score1}
                </TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  시점중앙
                </TableCell>
                <TableCell align="center">{startMid?.roadWidth || '-'}</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  중앙부
                </TableCell>
                <TableCell align="center">{mid?.roadWidth || '-'}</TableCell>
                <TableCell>
                  <CheckItems items={itemsVal1} value={mid?.val1_2} />
                </TableCell>
                <TableCell align="center"> {mid?.score1}</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  중앙종점
                </TableCell>
                <TableCell align="center">{midEnd?.roadWidth || '-'}</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center">{end?.roadWidth || '-'}</TableCell>
                <TableCell>
                  <CheckItems items={itemsVal1} value={end?.val1_2} />
                </TableCell>
                <TableCell align="center"> {end?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ② 선형불량
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal2} value={start?.val2} />
                </TableCell>
                <TableCell align="center">{start?.score2}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score2}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal2} value={start?.val2} />
                </TableCell>
                <TableCell align="center">{mid?.score2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal2} value={start?.val2} />
                </TableCell>
                <TableCell align="center">{end?.score2}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ③ 안전시설(난간)
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal3} value={start?.val3} />
                </TableCell>
                <TableCell align="center">{start?.score3}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score3}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal3} value={mid?.val3} />
                </TableCell>
                <TableCell align="center">{mid?.score3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal3} value={end?.val3} />
                </TableCell>
                <TableCell align="center">{end?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ④ 지반침하
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal4} value={start?.val4} />
                </TableCell>
                <TableCell align="center">{start?.score4}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal4} value={mid?.val4} />
                </TableCell>
                <TableCell align="center">{mid?.score4}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal4} value={end?.val4} />
                </TableCell>
                <TableCell align="center">{end?.score4}</TableCell>
              </TableRow>
            </TableBody>

            {/* 기능성 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={9} component="th">
                  기능성
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ⑤ 포장상태
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal5} value={start?.val5} />
                </TableCell>
                <TableCell align="center">{start?.score5}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score5}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal5} value={mid?.val5} />
                </TableCell>
                <TableCell align="center">{mid?.score5}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal5} value={end?.val5} />
                </TableCell>
                <TableCell align="center">{end?.score5}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑥ 비포장 구간
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal6} value={start?.val6} />
                </TableCell>
                <TableCell align="center">{start?.score6}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score6}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal6} value={mid?.val6} />
                </TableCell>
                <TableCell align="center">{mid?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal6} value={end?.val6} />
                </TableCell>
                <TableCell align="center">{end?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑦ 노면요철
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal7} value={mid?.val7} />
                </TableCell>
                <TableCell align="center">{start?.score7}</TableCell>
                <TableCell align="center" rowSpan={6}>
                  {evlRslt?.score7}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal7} value={mid?.val7} />
                </TableCell>
                <TableCell align="center">{mid?.score7}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal7} value={end?.val7} />
                </TableCell>
                <TableCell align="center">{end?.score7}</TableCell>
              </TableRow>
            </TableBody>
            {/* 시설물 파손 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={6} component="th">
                  시설물 파손
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ⑧ 도로·부대시설
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal8} value={start?.val8} />
                </TableCell>
                <TableCell align="center">{start?.score8}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score8}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal8} value={mid?.val8} />
                </TableCell>
                <TableCell align="center">{mid?.score8}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal8} value={end?.val8} />
                </TableCell>
                <TableCell align="center">{end?.score8}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑨ 배수로
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal9} value={start?.val9} />
                </TableCell>
                <TableCell align="center">{start?.score9}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score9}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal9} value={mid?.val9} />
                </TableCell>
                <TableCell align="center">{mid?.score9}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal9} value={end?.val9} />
                </TableCell>
                <TableCell align="center">{end?.score9}</TableCell>
              </TableRow>
            </TableBody>

            {/* 기타 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  기타
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ⑩ 농로입지
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal10} value={start?.val10} />
                </TableCell>
                <TableCell align="center">{start?.score10}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score10}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal10} value={mid?.val10} />
                </TableCell>
                <TableCell align="center">{mid?.score10}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <CheckItems items={itemsVal10} value={end?.val10} />
                </TableCell>
                <TableCell align="center">{end?.score10}</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell align="center" component="th" colSPan={3}>
                  계
                </TableCell>
                <TableCell align="center" colSPan={6}>
                  <span style={evlRslt?.totResult === '양호' ? totResultStyle : {}}>양호</span> / <span style={evlRslt?.totResult === '보통' ? totResultStyle : {}}>보통</span> /{' '}
                  <span style={evlRslt?.totResult === '불량' ? totResultStyle : {}}>나쁨</span>
                </TableCell>
                <TableCell align="center" colSPan={2}>
                  {evlRslt?.totScore}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
const totResultStyle = { width: '100px', height: '85px', borderRadius: '50%', border: '1px solid' };

const CheckItems = (props) => {
  const { value, items } = props;

  return (
    <Stack direction="row" sx={{ my: 0 }} spacing={1} justifyContent="center">
      {items.map((item) => {
        return <Checkbox label={item?.label} checked={item.value === value} disabled />;
      })}
    </Stack>
  );
};
