import React, { useCallback, useEffect, useState } from 'react';
import axios from 'utils/api.js';
import moment from 'moment';

import { Box, ContentsBox, SearchBox } from 'components/Layout';
import { DataGrid, DataGridToolbar, GridActionsCellItem, GridRowModes, useGridApiContext } from 'components/DataDisplay/DataGrid';
import TextField from 'components/Inputs/TextField';
import Button from 'components/Inputs/Button';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import DatePicker from 'components/Date/DatePicker';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import ProjectMngDetail from './ProjectMngDetail';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ProjectRegister from './ProjectRegister';

const TextFieldEditInput = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.updateRows([{ id, [field]: event.target.value }]);
  };

  useEffect(() => {
    apiRef.current.updateRows([{ id, [field]: value }]);
  }, []);

  return <TextField value={value || ''} onChange={handleChange} fullWidth />;
};
const renderTextFieldEditInput = (params) => {
  return <TextFieldEditInput {...params} />;
};

const DateEditInput = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (date) => {
    await apiRef.current.setEditCellValue({ id, field, value: date });
    apiRef.current.updateRows([{ id, [field]: date }]);
  };

  return <DatePicker id={id} format="yyyy-MM-DD" onChangeDate={handleChange} date={value || ''} placeholder="입력해주세요" fullWidth />;
};
const runderDateEditInput = (params) => {
  return <DateEditInput {...params} />;
};

export default function ProjectMng() {
  const [anchor, setAnchor] = useState(false);
  const [srchDatas, setSrchDatas] = useState({ status: '' });
  const [rows, setRows] = useState([]);
  const [selectedRowParams, setSelectedRowParams] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState({});

  const handleClose = (e) => {
    getDataList();
    setAnchor(false);
  };

  const handleClickOpenCopy = (data) => {
    data.status = 'C';
    setDatas(data);
    setOpen(true);
  };

  const handleCloseCopy = (e) => {
    setOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setAnchor(open);
  };

  /**
   * 프로젝트 관리 - 목록 조회
   * @param {*} e
   */
  const getDataList = async () => {
    const response = await axios.get(`/api/prj`, { params: { ...srchDatas } });
    if (response.data) {
      setRows([...response.data]);
    }
  };

  const handleSrchChange = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  const handleSrchChangeStatus = (e) => {
    setSrchDatas({ ...srchDatas, status: e.target.value });
  };

  const handleRowClick = (params) => (e) => {
    setSelectedRow({ ...params.row });
    setAnchor(true);
  };

  const handleClickSrchBtn = (e) => {
    if (srchDatas?.projName?.length >= 100) {
      alert('프로젝트명은 100자까지 가능합니다.');
      return;
    }
    getDataList();
  };

  const handleRowFocus = useCallback((event) => {
    const id = event.currentTarget.dataset.id;
    setSelectedRowParams(id);
  }, []);

  const handleEditClick = (row) => () => {
    row.status = 'U';
    setDatas(row);
    setOpen(true);
  };

  const handleSaveClick = (row) => () => {
    if (!!!row.projName) {
      alert('프로젝트명을 입력해주세요.');
      return;
    }

    if (row.projName.length >= 200) {
      alert('프로젝트명은 200자까지 가능합니다.');
      return;
    }

    if (!!!row.startDate) {
      alert('시작일을 입력해주세요.');
      return;
    }

    const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!regex.test(row.startDate)) {
      alert('시작일은 날짜형식으로만 입력 가능합니다.');
      return;
    }

    if (!!!row.endDate) {
      alert('종료일을 입력해주세요.');
      return;
    }

    if (!regex.test(row.endDate)) {
      alert('종료일은 날짜형식으로만 입력 가능합니다.');
      return;
    }
  };

  const handleDeleteClick = (id) => () => {
    if (window.confirm(`프로젝트를 삭제 하시겠습니까?`)) {
      axios
        .delete(`/api/prj`, { data: { dataList: [id] } })
        .then(async (response) => {
          alert(response.data.message);
          await getDataList();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const projectEnd = (params) => () => {
    if (window.confirm(`프로젝트를 마감으로 처리 하시겠습니까?`)) {
      axios
        .patch(`/api/prj/end/${params.row.id}`)
        .then(async (response) => {
          alert('마감 완료');
          await getDataList();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: false, headerAlign: 'center', align: 'center', hide: true },
    { field: 'rowNumber', headerName: 'No', width: 100, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: 'select',
      headerName: '상세보기',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 200,
      renderCell: (params) => (
        <>
          <div>
            {params.row.status && (
              <Button variant="text" onClick={handleRowClick(params)}>
                설정하기
              </Button>
            )}
          </div>
          <div>
            {params.row.status && params.row.status !== 'end' && (
              <Button variant="text" disabled={params.row.status !== 'ing'} onClick={projectEnd(params)}>
                마감하기
              </Button>
            )}
            {params.row.status === 'end' && (
              <Button variant="text" disabled={params.row.status !== 'end'} onClick={() => handleClickOpenCopy(params.row)}>
                복사하기
              </Button>
            )}
          </div>
        </>
      )
    },
    { field: 'statusNm', headerName: '상태', width: 100, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: 'projName',
      headerName: '프로젝트명',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value,
      renderEditCell: renderTextFieldEditInput
    },
    {
      field: 'startDate',
      headerName: '시작일',
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value,
      renderEditCell: runderDateEditInput
    },
    {
      field: 'endDate',
      headerName: '종료일',
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value,
      renderEditCell: runderDateEditInput
    },
    {
      field: 'registerId',
      headerName: '등록자',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value
    },
    {
      field: 'createdAt',
      headerName: '등록일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => moment(params.value).format('LLL')
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(row)} />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />
          ];
        }

        return [
          <>{row.status !== 'end' && <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(row)} color="inherit" />}</>,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <Box sx={{ display: 'block' }}>
      <SearchBox component="form" noValidate autoComplete="off">
        <TextField margin="dense" name="projName" label="프로젝트명" id="projName" onChange={handleSrchChange} />
        <ToggleButtonGroup color={'primary'} value={srchDatas.status} exclusive onChange={handleSrchChangeStatus} size="small">
          <ToggleButton value="">전체</ToggleButton>
          <ToggleButton value="ready">준비</ToggleButton>
          <ToggleButton value="ing">진행중</ToggleButton>
          <ToggleButton value="end">마감</ToggleButton>
        </ToggleButtonGroup>
        <Button align="right" variant="contained" color="primary" onClick={handleClickSrchBtn}>
          검색
        </Button>
      </SearchBox>
      <Box sx={{ mt: 4 }}></Box>
      <ContentsBox>
        <DataGrid
          rowHeight={40}
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          components={{
            Toolbar: CustomToolbar
          }}
          editMode="row"
          isCellEditable={(params) => params.row.status !== 'end'}
          componentsProps={{
            toolbar: {
              rows,
              setRows,
              rowModesModel,
              setRowModesModel,
              selectedRowId,
              setSelectedRowId,
              getDataList,
              selectionModel,
              setOpen,
              setDatas
            },
            row: {
              onFocus: handleRowFocus
            }
          }}
          experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
        />
      </ContentsBox>
      <Drawer anchor="right" open={anchor} onClose={toggleDrawer(false)}>
        <ProjectMngDetail onClose={handleClose} pData={selectedRow} />
      </Drawer>
      <ProjectRegister open={open} handleClose={handleCloseCopy} handleGetList={getDataList} pData={datas} />
    </Box>
  );
}

const CustomToolbar = (props) => {
  const apiRef = useGridApiContext();
  const { rows, setRows, rowModesModel, setRowModesModel, getDataList, selectionModel, setDatas, setOpen } = props;

  /**
   * 프로젝트 관리 - 등록
   * @param {*} e
   */
  const handleAdd = async (evt) => {
    let data = {};
    data.status = 'I';
    setDatas(data);
    setOpen(true);
  };

  /**
   * 프로젝트 관리  - 선택삭제
   * @param {*} e
   */
  const handleDelete = async (evt) => {
    try {
      const result = selectionModel.filter((t) => !isNaN(t));
      if (result.length > 0) {
        if (window.confirm(`프로젝트를 삭제 하시겠습니까?`)) {
          await axios
            .delete(`/api/prj`, { data: { dataList: result } })
            .then(async (response) => {
              alert(response.data.message);
              await getDataList();
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        }
      }
    } catch (e) {
      console.log(`delete failed`);
    }
  };

  return (
    <DataGridToolbar title="프로젝트 목록" count={rows?.length}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button variant="contained" onClick={handleAdd}>
          프로젝트 생성
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          삭제
        </Button>
      </Stack>
    </DataGridToolbar>
  );
};
