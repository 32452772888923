import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding: `0.5em`
  },
  [`&.${tableCellClasses.head}`]: {
    // borderTop: `1px solid ${theme.palette.grey[600]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    padding: `0.5em`,
    fontWeight: theme.typography.fontWeightBold
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: 20
  }
  // '&:nth-last-of-type(1)': {
  //     borderRight: 0,
  // },
  // '&:last-child': {
  //     borderRight: 0,
  // }
}));

const requiredStyle = {
  borderLeft: `3px solid #FF0000`,
  marginRight: `10px`
};

const CustomTableCell = React.memo((props) => {
  const { children, sx, th, required, ...rest } = props;

  const theme = useTheme();

  return (
    <StyledTableCell
      sx={{
        ...sx,
        ...(th && {
          borderRight: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: (theme) => theme.palette.grey[200],
          color: (theme) => theme.palette.common.black,
          padding: `0.5em`
        })
      }}
      {...rest}
    >
      {required && <span style={requiredStyle}></span>}
      {children}
    </StyledTableCell>
  );
});

export default CustomTableCell;
