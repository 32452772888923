import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import { button } from 'assets/jss/spf-react';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Some CSS
          fontSize: '0.8rem'
          // margin: 8,
          // fontWeight: 'bold'
        }
      }
    }
  },
  palette: {
    primary: {
      main: button.colors.primary
    },
    secondary: {
      main: button.colors.secondary,
      contrastText: '#FFFFFF'
    },
    success: {
      main: button.colors.success,
      contrastText: '#FFFFFF'
    },
    error: {
      main: button.colors.error,
      contrastText: '#FFFFFF'
    },
    default: {
      main: button.colors.default,
      contrastText: '#FFFFFF'
    }
  }
});

export default function CustomTabs(props) {
  const { children, ...rest } = props;

  return (
    <ThemeProvider theme={theme}>
      <Tabs {...rest}>{children}</Tabs>
    </ThemeProvider>
  );
}
