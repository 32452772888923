import React, { useState } from 'react';
import { Box, ToolbarArea } from 'components/Layout';
import { Tab, TabPanel, Tabs } from 'components/Navigation/Tabs';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import TextField from 'components/Inputs/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Radio, RadioGroup } from 'components/Inputs/RadioButton';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import SafeEvlRslt from './SafeEvlRslt';
import DropzoneImage from '../../components/Inputs/DropzoneImage';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

let opinionEtc = `· 위험시설 지정 여부 : 지정 (OOOO년)/ 지정해제 (OOOO년) / 미지정
· 취입보 위치 : 국가하천, 지방하천, 소하천, 세천, 구거
· 취입보 기능 : 양호/ 보통/ 불량
· 취입보 제원 : 연장 3.5m, 높이 1.5m
· 특이사항 : 주변환경은 주로 (농경지/산지 등) 이며 피해발생 시 영향범위는 (많은/작은) 편임
· 주민의견 : 없음/ 있으면 상세내용
· 공사시급성 : 양호 / 보통 / 시급`;

const validationColumns = [
  {
    part: 'point',
    partName: '평가하기',
    columns: [
      { id: 'cFcType', name: '제원측정 - 시설구분' },
      { id: 'cLength', name: '제원측정 - 연장' },
      { id: 'cHeight', name: '제원측정 - 높이' },
      { id: 'val1', name: '구조적 안정성 - 본체 노후·파손' },
      { id: 'val2', name: '구조적 안정성 - 제방연결 옹벽 파손' },
      { id: 'val3', name: '구조적 안정성 - 바닥보호공 파손' },
      { id: 'val4', name: '구조적 안정성 - 제방 접합부 세굴' },
      { id: 'val5', name: '구조적 안정성 - 본체 높이로 제방월류' },
      { id: 'val7', name: '기능성 - 기계장치파손' },
      { id: 'val8', name: '침식·퇴적 - 상하류부 침식·퇴적' },
      { id: 'val9', name: '기타 - 철거민원·사회,환경영향' },
      { id: 'val10', name: '기타 - 어도 유무·파손' }
    ]
  }
];

export default function SafeEvlC(props) {
  const { pData, pRows, insertRows, setInsertRows, setVisibleAction, setIsEdit } = props;

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
    setVisibleAction(newValue === 5 ? false : true);
  };

  const handleChange = (part, data) => {
    const isPart = insertRows.find((t) => t.part === part);
    let result = [...insertRows];

    if (isPart) {
      result = insertRows.map((row) => (row.part === part ? { ...row, ...data } : row));
    } else {
      const { fcCode, fcType, prjId } = pData;
      const params = { ...data, part, fcCode, fcType, prjId };
      result.push(params);
    }
    setInsertRows(result);
    setIsEdit(true);
  };

  const point = insertRows.find((t) => t.part === 'point');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box style={{ m: 0, borderBottom: '1px solid #CCA63D' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
          <Tab label="평가하기" index={0} />
          <Tab label="평가결과 미리보기" index={1} />
        </Tabs>
      </Box>
      <Box sx={{ display: 'block', height: '550px', overflow: 'auto', overflowX: 'hidden' }}>
        <TabPanel value={valueTab} index={0}>
          <EvlInfo label="평가하기" part="point" datas={point} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <SafeEvlRslt label="평가결과 미리보기" pData={pData} pRows={pRows} validationColumns={validationColumns} selectedMenu="safetyEvl" />
        </TabPanel>
      </Box>
    </Box>
  );
}

const EvlInfo = (props) => {
  const { datas, prjId, fcCode, part, label, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleChangePier = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeRadio = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeScore = (data) => {
    if (!onChange) return;
    onChange(part, { [data.valId]: data.valValue, [data.scoreId]: data.scoreValue });
  };

  const handleClickRadioScore = (e) => {
    const valId = e.target.name;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.id]: e.target.value });
  };

  const handleDropImg = (acceptedFiles, imageType) => {
    if (!!acceptedFiles && acceptedFiles.length > 0) {
      setBackdropOpen(true);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      return new Promise((resolve) => {
        reader.onload = () => {
          eval(`onChange(part, { ${imageType + 'Pre'}: reader.result, ${imageType}: acceptedFiles[0] })`);
          resolve();
          setBackdropOpen(false);
        };
      });
    }
  };

  const open = Boolean(anchorEl);
  const checkVaild = (id) => (!datas?.[id] ? { required: true } : {});

  return (
    <Box sx={{ m: 2, display: 'block' }}>
      <ToolbarArea title="제원측정"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="30%" />
            <col width="20%" />
            <col width="30%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('cFcType')}>
                시설구분
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="cFcType" value={datas?.cFcType || ''} onClick={handleChangePier}>
                  <Radio value="C" label="취입보" />
                  <Radio value="D" label="낙차공" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('bLength')}>
                연장
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="cLength"
                  value={datas?.cLength || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th" {...checkVaild('bHeight')}>
                높이
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="cHeight"
                  value={datas?.cHeight || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="구조적 안정성"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score1')}>
                ①본체 노후·파손 {` [ ${datas?.score1 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val1" value={datas?.val1 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="노후·일부파손" />
                  <Radio value="2" label="파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score2')}>
                ②제방연결 옹벽 파손 {` [ ${datas?.score2 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val2" value={datas?.val2 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="노후" />
                  <Radio value="2" label="없음·파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score3')}>
                ③바닥보호공 파손 {` [ ${datas?.score3 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val3" value={datas?.val3 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="없음·일부파손" />
                  <Radio value="2" label="파손·세굴" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score4')}>
                ④제방 접합부 세굴 {` [ ${datas?.score4 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val4" value={datas?.val4 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="옹벽 없으나 양호" />
                  <Radio value="2" label="파손·세굴" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score5')}>
                ⑤본체 높이로 제방월류 {` [ ${datas?.score5 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val5" value={datas?.val5 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호·과거 월류피해 없음" />
                  <Radio value="2" label="과거 월류피해·보 높음·제방 낮음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="기능성"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            {datas?.pierType === 'C' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('score6')}>
                  ⑥ 취수기능상실 {` [ ${datas?.score6 || '-'} ]`}
                </TableCell>
                <TableCell align="center">
                  <RadioGroup name="val6" value={datas?.val6 || ''} onClick={handleClickRadioScore}>
                    <Radio value="0" label="양호" />
                    <Radio value="1" label="일부퇴적·노후·취수가능" />
                    <Radio value="2" label="심한퇴적·취수문 파손·취수불가" />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            )}
            {datas?.pierType === 'D' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('score6')}>
                  ⑥ 낙차기능상실 {` [ ${datas?.score6 || '-'} ]`}
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <RadioGroup name="val6" value={datas?.val6 || ''} onClick={handleClickRadioScore}>
                    <Radio value="0" label="양호" />
                    <Radio value="1" label="비교적 양호" />
                    <Radio value="2" label="본체파괴·퇴적·기능상실" />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score7')}>
                ⑦ 기계장치파손 {` [ ${datas?.score7 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val7" value={datas?.val7 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="작동가능·일부노출" />
                  <Radio value="2" label="작동불가" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="침식·퇴적"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score8')}>
                ⑧ 상하류부 침식·퇴적 {` [ ${datas?.score8 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val8" value={datas?.val8 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="거의 없음" />
                  <Radio value="1" label="일부 침식·퇴적" />
                  <Radio value="2" label="침식·퇴적·취수영향" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ToolbarArea title="기타"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score9')}>
                ⑨ 철거민원·사회,환경영향 {` [ ${datas?.score9 || '-'} ]`}
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val9" value={datas?.val9 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="기능 확실" />
                  <Radio value="1" label="기능 상실·영향 없음" />
                  <Radio value="2" label="영향 있음·개선필요" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" {...checkVaild('score10')}>
                ⑩ 어도 유무·파손 {` [ ${datas?.score10 || '-'} ]`}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val10" value={datas?.val10 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="어도기능 양호" />
                  <Radio value="1" label="어도없음·역할미미" />
                  <Radio value="2" label="어도파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="기타의견"></ToolbarArea>
      <TextField id="opinionEtc" multiline rows={10} fullWidth value={datas?.opinionEtc || opinionEtc} onChange={handleChange} inputProps={{ maxLength: 3000 }} />

      <ToolbarArea title="사진 첨부"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="50%" />
            <col width="50%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                전경 사진
              </TableCell>
              <TableCell align="center" component="th">
                주변부 사진
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="panorama" maxFiles={1} maxFilesize={5242880} />
                {datas?.panoramaPre && <img src={datas?.panoramaPre} alt="panoramaPre" width="80%" height="200px" />}
                {!datas?.panoramaPre && (
                  <img
                    src={!!datas?.panoramaPre ? datas?.panoramaPre : `/api/safe/img/${prjId}/${fcCode}/${part}Panorama`}
                    alt={`${part}Panorama`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="side" maxFiles={1} maxFilesize={5242880} />
                {datas?.sidePre && <img src={datas?.sidePre} alt="sidePre" width="80%" height="200px" />}
                {!datas?.sidePre && (
                  <img
                    src={!!datas?.sidePre ? datas?.sidePre : `/api/safe/img/${prjId}/${fcCode}/${part}Side`}
                    alt={`${part}Side`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <PopoverSpfRadio
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          selected={selectedScore}
          onChangeScore={handleChangeScore}
        />
      )}
      <Backdrop open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
