import React, { cloneElement } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from './TableHead';
import TableBody from './TableBody';

const StyledTable = styled(Table)(({ theme }) => ({
}));

export default function CustomTable(props) {

    const { children, hover, ...rest } = props;

    return (
        <StyledTable
            {...rest}
        >
            {
                children.length > 1
                    ?
                    children.map((item, idx) => {
                        const typeName = item.type?.name;
                        if (typeName === TableHead.name) {
                            return cloneElement(item, {
                                key: idx
                            });
                        } else if (typeName === TableBody.name) {
                            return cloneElement(item, {
                                key: idx,
                                hover
                            });
                        }
                        if (item?.type === 'colgroup') {
                            return item;
                        }
                        return item;
                    })
                    :
                    children.type.name === TableBody.name
                        ?
                        cloneElement(children, { hover })
                        :
                        children
            }

        </StyledTable>
    );
}
