import React, { useContext, useState, useEffect } from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OpacityIcon from '@mui/icons-material/Opacity';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MergeIcon from '@mui/icons-material/Merge';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';

import { mapUtil } from 'map/MapUtils.js';

const fcLayer = [
  { label: '세천', id: 'fc_a', isVisible: (isVisible) => <LooksOneIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '소교량', id: 'fc_b', isVisible: (isVisible) => <LooksTwoIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '보/낙차공', id: 'fc_c', isVisible: (isVisible) => <Looks3Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '농로', id: 'fc_e', isVisible: (isVisible) => <Looks4Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '마을진입로', id: 'fc_f', isVisible: (isVisible) => <Looks5Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> }
];

const backgroundLayer = [
  { label: '하천망도', id: 'river', isVisible: (isVisible) => <LooksOneIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '도로망도', id: 'road', isVisible: (isVisible) => <LooksTwoIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '연속주제도', id: 'continue', isVisible: (isVisible) => <Looks4Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '우수망도', id: 'rain', isVisible: (isVisible) => <Looks5Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> }
];

const logLayer = [
  { label: '원본', id: 'original', isVisible: (isVisible) => <LooksOneIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '신규', id: 'new', isVisible: (isVisible) => <LooksTwoIcon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> },
  { label: '삭제', id: 'delete', isVisible: (isVisible) => <Looks3Icon fontSize="large" color={isVisible ? 'primary' : 'cancel'} /> }
];

export default function MapLayerSetting(props) {
  const { index, value, map, mapRef } = props;

  const [checked, setChecked] = useState(['fc_a', 'fc_b', 'fc_c', 'fc_e', 'fc_f']);
  const [visible, setVisible] = useState(false);

  const handleClickLayers = (id) => (e) => {
    let data = [...checked];
    let isVisible = false;

    if (data.indexOf(id) > -1) {
      data.splice(data.indexOf(id), 1);
    } else {
      data.push(id);
      isVisible = true;
    }

    mapUtil.isVisibleLayerId(map, id, isVisible);

    setChecked(data);
  };

  useEffect(() => {
    setVisible(value === index ? true : false);
  }, [value]);

  return (
    <div id="layerMenu" className="layer layerMenu" style={{ display: visible ? 'block' : 'none' }} ref={mapRef}>
      <strong>소규모 공공 시설</strong>
      <ul className="list_map_setting">
        {fcLayer.map((row, index) => {
          return (
            <li key={`li-spf-${index}`}>
              <a onClick={handleClickLayers(row.id)}>
                <i>{row.isVisible(checked.indexOf(row.id) > -1 ? true : false)}</i>
                {row.label}
              </a>
            </li>
          );
        })}
      </ul>
      <strong>배경지도</strong>
      <ul className="list_map_setting">
        {backgroundLayer.map((row, index) => {
          return (
            <li key={`li-map-${index}`}>
              <a onClick={handleClickLayers(row.id)}>
                <i>{row.isVisible(checked.indexOf(row.id) > -1 ? true : false)}</i>
                {row.label}
              </a>
            </li>
          );
        })}
      </ul>
      {/*<strong>시설이력</strong>*/}
      {/*<ul className="list_map_setting">*/}
      {/*  {logLayer.map((row, index) => {*/}
      {/*    return (*/}
      {/*      <li key={`li-log-${index}`}>*/}
      {/*        <a onClick={handleClickLayers(row.id)}>*/}
      {/*          <i>{row.isVisible(checked.indexOf(row.id) > -1 ? true : false)}</i>*/}
      {/*          {row.label}*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</ul>*/}
    </div>
  );
}
