import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '14px'
  },
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: 'rgba(0, 0, 0, 1)'
  }
}));

export default function CustomCheckbox(props) {
  const { checked, label, onChange, color, ...rest } = props;

  const [check, setCheck] = useState(checked);

  useEffect(() => {}, [checked]);

  return (
    <>
      {label ? (
        <StyledFormControlLabel checked={checked} onChange={onChange} control={<Checkbox sx={{ p: '2px' }} color={color ? color : 'default'} {...rest} />} label={label ? label : ''} labelPlacement="left" {...rest} />
      ) : (
        <Checkbox sx={{ p: '2px' }} color={color} onChange={onChange} checked={checked} {...rest} />
      )}
    </>
  );
}
