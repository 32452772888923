import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

export default function DataGridToolbar(props) {
  const { children, title, count, sx, flex, ...rest } = props;

  return (
    <div style={{ backgroundColor: 'white', height: 45, position: 'absolute', inset: '-45px -1px 1px', borderColor: 'rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <Grid container>
        <Grid item xs={4} sx={{ mt: 2 }}>
          <span style={{ marginLeft: '8px' }}>
            {title} <span style={{ fontSize: 'small' }}> (전체 {count || 0} 건)</span>
          </span>
        </Grid>
        <Grid item xs={8}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
