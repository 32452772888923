import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from 'assets/img/dohwa.png';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/api';
import { useLocation } from 'react-router';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://didas.dohwa.co.kr/nxui">
        DOHWA ENGINEERING
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'all'
  });
  const location = useLocation();
  const userInfo = location?.state.userInfo;

  const password = useRef();
  password.current = watch('password');

  const navigate = useNavigate();

  const changePassword = async (data) => {
    data.id = userInfo.id;
    return await axios.patch(`/api/changePassword`, data);
  };

  const onSubmit = (data) => {
    changePassword(data)
      .then((response) => {
        alert(response.data.message);
        navigate('/login');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const handleBack = () => {
    navigate('/login');
  };

  useEffect(() => {
    console.log(userInfo);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box component="img" src={logo} sx={{ m: 2, p: 2, witdh: 120, height: 120 }} />
          <Typography component="h1" variant="h5">
            비밀번호 변경
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="dense"
              required
              fullWidth
              name="userpw"
              label="기존 비밀번호"
              type="password"
              id="userpw"
              error={!!errors.userpw}
              helperText={errors.userpw && errors.userpw.message}
              {...register('userpw', {
                required: '현재 비밀번호를 입력해주세요.'
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              label="변경 비밀번호"
              type="password"
              id="password"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...register('password', {
                required: '변경 비밀번호를 입력해주세요.',
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  message: '비밀번호는 8자 이상, 최소 하나 이상의 문자, 숫자, 특수문자를 입력해주세요.'
                }
              })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="passwordCheck"
              label="비밀번호 확인"
              type="password"
              id="passwordCheck"
              error={!!errors.passwordCheck}
              helperText={errors.passwordCheck && errors.passwordCheck.message}
              {...register('passwordCheck', {
                required: '비밀번호 확인을 입력해주세요.',
                validate: (value) => {
                  if (value !== password.current) {
                    return '비밀번호가 일치하지 않습니다.';
                  }
                }
              })}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              비밀번호 변경
            </Button>
            <Button fullWidth variant="contained" color={'warning'} onClick={handleBack}>
              취소
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
