import 'ol/ol.css';
// import { DEVICE_PIXEL_RATIO } from 'ol/has';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Text, Style } from 'ol/style';
import { GeoJSON } from 'ol/format';
import axios from 'utils/api.js';
import jsonp from 'simple-jsonp-promise';
import { bbox as bboxStrategy, all as allStrategy } from 'ol/loadingstrategy';

const vworldConfig = {
  vworldUrl: 'http://api.vworld.kr/req/data',
  key: '3FAAE206-9DA2-32E7-8CCB-6A9CC067E90D',
  sigKorNm: '예천',
  crs: 'EPSG:3857'
};

/**
 * 단일 VectorImage Layer 추가
 * @param {*} map
 */
async function mapAddVectorImageLayer(map, params) {
  const { id, name, layerType, uri } = params;

  let vSource = new VectorSource({
    format: new GeoJSON(),
    loader: async (extent, resolution, projection) => {
      vSource.clear(true);

      const result = await axios.get(uri, { params: { extent } });
      const geojson = result.data.geojson;

      if (geojson?.features) {
        vSource.addFeatures(new GeoJSON().readFeatures(geojson));
      }
    },
    strategy: bboxStrategy
  });

  let vLayer = new VectorImageLayer({
    ...params,
    source: vSource
  });

  map.addLayer(vLayer);
}

/**
 * 단일 VectorImage Layer 추가
 * @param {*} map
 */
async function mapAddVectorLayerLoader(map, params) {
  const { id, name, layerType, uri } = params;

  let vSource = new VectorSource({
    format: new GeoJSON(),
    loader: async (extent, resolution, projection) => {
      vSource.clear(true);

      const result = await axios.get(uri, { params: { extent } });
      const geojson = result.data.geojson;

      if (geojson?.features) {
        vSource.addFeatures(new GeoJSON().readFeatures(geojson));
      }
    },
    strategy: bboxStrategy
  });
  let vLayer = new VectorLayer({
    ...params,
    source: vSource
  });

  map.addLayer(vLayer);
}

/**
 * 단일 VectorImage Layer 추가
 * @param {*} map
 */
async function mapAddVectorLayer(map, params) {
  const { id, name, layerType, uri } = params;
  let vSource = new VectorSource();
  let vLayer = new VectorLayer({
    ...params,
    source: vSource
  });

  map.addLayer(vLayer);
}

const MapLayers = {
  /**
   * 편집 레이어 추가
   * @param {*} map
   */
  addEditLayers: (map) => {
    let source = new VectorSource();

    let drawLayer = new VectorLayer({
      id: 'editLayer',
      source: source,
      zIndex: 100,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255,255,255, 0.2)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: 'rgba(255,204,51, 0.5)'
          })
        })
      })
    });

    map.addLayer(drawLayer);

    let markerSource = new VectorSource();
    let markerLayer = new VectorLayer({
      id: 'markerLayer',
      source: markerSource,
      zIndex: 100,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255,255,255, 0.2)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: '#FF5E00'
          })
        })
      })
    });
    map.addLayer(markerLayer);
  }
};

export { mapAddVectorImageLayer, mapAddVectorLayerLoader, mapAddVectorLayer };
