import React, { useRef, useState, useContext } from 'react';
import { Box, Paper, Button } from '@mui/material';
import { MbDropzoneImage } from 'components/mobiles';
import { ResponsiveGrid, ResponsiveGridRow, ResponsiveGridColumn, MobileSubTitle, ControlledAccordion, ControlledAccordionsContainer } from 'components/mobiles';

import TEST_IMAGE from 'assets/img/dohwa.png';
import TEST_IMAGE2 from 'assets/img/dohwa_icon3.png';

export default function Components() {
  const [files, setFiles] = useState({});

  const handleClick = (e) => {
    // console.log(files);
    // console.log(files?.point?.file);
    // console.log(files?.point1?.file);
    setFiles({});
  };

  const handleDropImg = ({ file, id }) => {
    setFiles({ ...files, [id]: file });
  };

  return (
    <Box sx={{ p: 2 }}>
      <MobileSubTitle title="사진 첨부" />
      <Button onClick={handleClick}>저장</Button>
      <Paper elevation={3} sx={{ p: 2 }}>
        <ResponsiveGrid direction="column">
          {/* <MbDropzoneImage id="point" url={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/${partOptions}Point`} onAcceptedImage={handleDropImg} /> */}
          <ResponsiveGridColumn id="point" label="전경사진" component={<MbDropzoneImage id="point" isUpdate={files?.point} imageUrl={TEST_IMAGE} onAcceptedImage={handleDropImg} />} />
          <ResponsiveGridColumn id="point1" label="시점부" component={<MbDropzoneImage id="point1" isUpdate={files?.point1} imageUrl={TEST_IMAGE2} onAcceptedImage={handleDropImg} />} />
        </ResponsiveGrid>
      </Paper>
    </Box>
  );
}
