import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AuthProvider from 'router/AuthProvider';
import AuthContext from 'router/AuthContext';

import Map from 'map';
import Default from '../layout/Default';
import DefaultMobile from '../layout/DefaultMobile';
import LogIn from '../views/LogIn';
import Join from '../views/Join';
import ChangePassword from '../views/ChangePassword';

import { authUtils } from 'utils/authUtils.js';
import ProjectMng from '../views/prj/ProjectMng';
import UserMng from '../views/user/UserMng';
import InfoMng from '../views/user/InfoMng';
import SafeMng from '../views/safe/SafeMng';
import SafeMap from '../views/safe/SafeMap';
import StatMng from '../views/stat/StatMng';

import AdminRoute from '../components/Router/AdminRoute';
import ManagerRoute from '../components/Router/ManagerRoute';
import UserRoute from '../components/Router/UserRoute';
import IndexRoute from '../components/Router/IndexRoute';
import MobileSafeMng from '../views/mobile/safe/SafeMng';
import MobileSafeMap from '../views/mobile/map/SafeMap';

import Components from 'views/Components';

import { useTheme, useMediaQuery, Container, Box } from '@mui/material';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthProvider isMobile={isMobile}>
      <Routes>
        <Route path="/components" element={<Components />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/join" element={<Join />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route
          path="/m"
          element={
            <RequireAuth>
              <DefaultMobile />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <UserRoute>
                <IndexRoute isMobile={isMobile} />
              </UserRoute>
            }
          />
          <Route
            path="safe"
            element={
              <UserRoute>
                <MobileSafeMng />
              </UserRoute>
            }
          />
          <Route
            path="map"
            element={
              <RequireAuth>
                <Map>
                  <MobileSafeMap />
                </Map>
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Default />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <UserRoute>
                <IndexRoute isMobile={isMobile} />
              </UserRoute>
            }
          />
          <Route
            path="prj"
            element={
              <AdminRoute>
                <ProjectMng />
              </AdminRoute>
            }
          />
          <Route path="user">
            <Route
              path="admin"
              element={
                <AdminRoute>
                  <UserMng role="ROLE_MANAGER" />
                </AdminRoute>
              }
            />
            <Route
              path="insp"
              element={
                <ManagerRoute>
                  <UserMng role="ROLE_USER" />
                </ManagerRoute>
              }
            />
          </Route>
          <Route
            path="safe"
            element={
              <UserRoute>
                <SafeMng />
              </UserRoute>
            }
          />
          <Route
            path="safe/map"
            element={
              <RequireAuth>
                <Map>
                  <SafeMap />
                </Map>
              </RequireAuth>
            }
          />
          <Route
            path="stat"
            element={
              <UserRoute>
                <StatMng />
              </UserRoute>
            }
          />
          <Route
            path="info"
            element={
              <UserRoute>
                <InfoMng />
              </UserRoute>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;

const RequireAuth = ({ children }) => {
  let location = useLocation();
  let authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.checkAuth();
  }, [location.pathname]);

  return <AuthRoute>{children}</AuthRoute>;
};

const AuthRoute = ({ children }) => {
  const location = useLocation();

  if (!authUtils.useIsLogin()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
