import * as React from 'react';
import TextField from '@mui/material/TextField';
import { validatorType } from 'utils/validator';

const style = {
  '& label': {
    height: '15px',
    fontSize: 13
  },
  '& div': {
    backgroundColor: 'white'
    // '& .Mui-disabled': {
    //     backgroundColor: '#EAEAEA'
    // }
  },
  '& input': {
    height: '1.1rem',
    fontSize: 13
  },
  '& input:disabled': {
    backgroundColor: '#EAEAEA'
  }
};

export default function CustomTextField(props) {
  const { sx, type, validCheckType, value, error, required, defaultValue, ...rest } = props;
  let newProps = { error: false };

  if (type && value !== '') {
    const result = validatorType(value, type);

    if (result) {
      const isStat = result?.stat;
      if (isStat) {
        if (error) {
          newProps = { helperText: result.errors?.msg, value: result.errors?.text, error: error ? result?.stat : false };
        } else {
          newProps = { value: result.errors?.text };
        }
      } else {
        newProps = { value };
      }
    } else {
      newProps = { value };
    }
  } else {
    newProps = { ...newProps, ...(value ? { value } : { defaultValue }) };
  }

  return <TextField size="small" variant="outlined" sx={{ ...style, ...sx }} {...newProps} {...rest} />;
}
