import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { button } from 'assets/jss/spf-react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '0.8rem'
          // margin: 8,
          // fontWeight: 'bold'
        }
      }
    }
  },
  palette: {
    primary: {
      main: button.colors.primary
    },
    secondary: {
      main: button.colors.secondary
    },
    success: {
      main: button.colors.success
    },
    warning: {
      main: button.colors.warning
    },
    default: {
      main: button.colors.default,
      contrastText: '#FFFFFF'
    }
  }
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '14px'
  }
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '& .MuiRadio-root': {}
}));

export default function CustomizedRadio(props) {
  const { value, label, color, ...rest } = props;
  return (
    <ThemeProvider theme={theme}>
      {label ? <StyledFormControlLabel control={<StyledRadio color={color ? color : 'default'} size="small" sx={{ p: '5px' }} />} label={label} value={value} {...rest} /> : <Radio value={value} {...rest} />}
    </ThemeProvider>
  );
}
