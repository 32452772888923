import React, { cloneElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';

import { colors } from 'assets/jss/spf-react';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  '&.MuiDialogTitle-root': {
    padding: `11px`,
    cursor: 'move',
    fontSize: 18,
    color: 'white',
    border: `1px solid ${colors.primary}`,
    backgroundColor: colors.primary
  }
}));

export default function CustomDialogTitle(props) {
  const { children, ...rest } = props;

  return <StyledDialogTitle {...rest}>{children}</StyledDialogTitle>;
}
