import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from 'utils/api.js';
import LayersIcon from '@mui/icons-material/Layers';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import StraightenIcon from '@mui/icons-material/Straighten';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { fromLonLat } from 'ol/proj';
import MapContext from 'map/MapContext';
import { initMap, mapUtil, mapEdit } from 'map/MapUtils.js';
import { MobileTitle } from 'components/mobiles';
import MapFacilitySearch from './MapFacilitySearch';
import MapLayerSetting from './MapLayerSetting';
import MapFacilityEdit from './MapFacilityEdit';
import SafeMngClass from 'views/mobile/safe/SafeMngClass';

const drawerHeight = 56;
const drawerWidth = 800;

let editSource = null;
let gpsSource = null;
let watchId;

function getLocation() {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (geolocationPosition) {
          resolve(geolocationPosition);
        },
        function (error) {
          let message = '';
          if (error.code === error.PERMISSION_DENIED) {
            message = 'Permission denied by user.';
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            message = 'Location information is unavailable.';
          } else if (error.code === error.TIMEOUT) {
            message = 'The request to get user location timed out.';
          } else if (error.code === error.UNKNOWN_ERROR) {
            message = 'An unknown error occurred.';
          }
          reject({ code: error.code, message, error });
        },
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 27000
        }
      );
    } else {
      reject({ code: 'UNKNOWN_ERROR', message: 'Geolocation is not supported by this browser.' });
    }
  });
}

export default function AssetMap(props) {
  const { map } = useContext(MapContext);
  const navigate = useNavigate();
  const location = useLocation();
  const mapSettingRef = useRef();
  const pData = location?.state?.data;

  const [open, setOpen] = useState(false);
  const [visibleSetting, setVisibleSetting] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [editFeature, setEditFeature] = useState(null);
  const [isGPS, setIsGPS] = useState(false);

  function handleClickOutside(e) {
    e.stopPropagation();
    if (mapSettingRef.current && !mapSettingRef.current.contains(e.target)) {
      setVisibleSetting(null);
    }
  }

  const handleMoveHome = (e) => {
    navigate('/m/safe');
  };

  const handleClickSearch = (e) => {
    setOpen(!open);
  };

  const handleChangeSetting = (event, newValue) => {
    setVisibleSetting(newValue);
  };

  const drawGPSMarker = (gpsSource, location) => {
    try {
      mapUtil.drawGPSPosition(gpsSource, location);
      map.getView().setCenter(location);
    } catch (e) {
      alert(`ERROR ${e.code}: ${e.message}`);
      if (watchId) navigator.geolocation.clearWatch(watchId);
      setIsGPS(false);
    }
  };

  const watchPosition = () => {
    if ('geolocation' in navigator) {
      return navigator.geolocation.watchPosition(
        function (geolocationPosition) {
          const gpsPosition = [geolocationPosition.coords.longitude, geolocationPosition.coords.latitude];
          const newPosition = fromLonLat(gpsPosition);
          if (newPosition) {
            drawGPSMarker(gpsSource, newPosition);
          }
          console.log(watchId);
        },
        function (error) {
          let message = '';
          if (error.code === error.PERMISSION_DENIED) {
            message = 'Permission denied by user.';
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            message = 'Location information is unavailable.';
          } else if (error.code === error.TIMEOUT) {
            message = 'The request to get user location timed out.';
          } else if (error.code === error.UNKNOWN_ERROR) {
            message = 'An unknown error occurred.';
          }
          return { code: error.code, message, error };
        },
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 27000
        }
      );
    } else {
      return;
    }
  };

  const handleChangeGPS = async (e) => {
    if (isGPS) {
      mapUtil.isVisibleLayerId(map, 'gps', false);
      if (watchId) navigator.geolocation.clearWatch(watchId);
    } else {
      mapUtil.isVisibleLayerId(map, 'gps', true);
      mapUtil.drawGPSPosition(gpsSource, map.getView().getCenter());
      const position = await getLocation();
      watchId = watchPosition();
    }
    setIsGPS(!isGPS);
  };

  /**
   * 편집모드 저장 or 종료
   * @param {*} params
   */
  const handleCloseEditMode = (params) => {
    if (params?.editedFeature) {
      editSource.clear();
      editSource.addFeature(params?.editedFeature);
      setSelectedFeature(params?.editedFeature);
    }
    setEditFeature(null);
    setVisibleSetting(null);
  };

  const getFitLayerExtent = async () => {
    try {
      const response = await axios.get(`/api/prj/layer-fit`, { params: { prjId: pData.id, layerNm: 'sgg' } });
      if (response) {
        const { xMin, yMin, xMax, yMax } = response.data;
        mapUtil.fitExtent(map, 'sgg', [xMin, yMin, xMax, yMax]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const mapClickEvt = useCallback(
    async (evt) => {
      const fcLayer = ['fc_a', 'fc_b', 'fc_c', 'fc_e', 'fc_f'];
      const result = map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          let values = feature.getProperties();
          if (values) {
            map.getView().fit(feature.getGeometry().getExtent(), { duration: 500 });
            feature.setProperties({ ...feature.getProperties(), length: mapUtil.getFeatureLength(feature) || 0 });
            setSelectedFeature(feature);
            return feature;
          }
        },
        {
          // hitTolerance: 2,
          layerFilter: function (layer) {
            return fcLayer.find((t) => t === layer.get('id'));
          }
        }
      );
      if (!result) setSelectedFeature(null);
    },
    [map]
  );

  useEffect(() => {
    if (map && pData?.id) {
      initMap(map, {
        prjId: pData.id
      });
      map.on('singleclick', mapClickEvt);
      editSource = mapUtil.getLayerSourceId(map, 'editLayer');
      gpsSource = mapUtil.initGPSPosition(map, { layerId: 'gps', layerName: 'GPS' });
      getFitLayerExtent();
      mapUtil.isVisibleLayerId(map, 'insp', true);
    }
  }, [map, pData]);

  useEffect(() => {
    if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') {
      map.un('singleclick', mapClickEvt);
      document.removeEventListener('mousedown', handleClickOutside);
      setEditFeature(selectedFeature);
    } else if (visibleSetting === 'measure') {
      mapEdit.distanceMeasureStart(map);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (visibleSetting === 'measure') {
        mapEdit.distanceMeasureStop(map);
      } else if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') {
        map.on('singleclick', mapClickEvt);
      }
    };
  }, [visibleSetting]);

  useEffect(() => {
    if (!map) return;
    if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') return;
    editSource.clear();
    if (selectedFeature) editSource.addFeature(selectedFeature);
  }, [selectedFeature]);

  useEffect(() => {
    if (map) {
      setTimeout(function () {
        map.updateSize();
      }, 300);
    }
  }, [open]);

  const selectedView = selectedFeature || editFeature;

  return (
    <Box sx={{ m: 0, flexDirection: 'column' }}>
      <MobileTitle title="현장조사">
        <Box sx={{ position: 'fixed', left: 0, right: 0, m: 0, zIndex: 5, display: 'flex' }}>
          <IconButton aria-label="home" size="small" onClick={handleMoveHome}>
            <HomeIcon />
          </IconButton>
          <Box sx={{ flex: '1 1 auto' }}></Box>
          <ToggleButton
            color="primary"
            sx={{
              border: '0px',
              '&.MuiToggleButton-root.Mui-selected, .MuiToggleButton-root.Mui-selected:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0)'
              }
            }}
            value="search"
            selected={open}
            size="small"
            onChange={handleClickSearch}
          >
            <SearchIcon />
          </ToggleButton>
        </Box>
      </MobileTitle>
      <ToggleButtonGroup
        value={visibleSetting}
        exclusive
        color="primary"
        orientation="vertical"
        onChange={handleChangeSetting}
        sx={{
          position: 'fixed',
          top: drawerHeight + 50,
          right: 8,
          zIndex: 2,
          backgroundColor: 'white'
        }}
        size="small"
      >
        <ToggleButton value="layer" aria-label="layer">
          <LayersIcon />
        </ToggleButton>
        <ToggleButton value="measure" aria-label="measure">
          <StraightenIcon />
        </ToggleButton>
        {selectedFeature || editFeature ? (
          <ToggleButton value="editFeature" aria-label="editFeature">
            <EditIcon />
          </ToggleButton>
        ) : (
          <ToggleButton value="addFeature" aria-label="addFeature">
            <AddIcon />
          </ToggleButton>
        )}
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 8, right: 0, zIndex: 2, backgroundColor: 'white' }}>
            <ToggleButton value="gps" aria-label="gps" size="small" color="primary" selected={isGPS} onChange={handleChangeGPS}>
              <LocationSearchingIcon />
            </ToggleButton>
          </div>
        </div>
        {map && <MapLayerSetting value={visibleSetting} index={'layer'} mapRef={mapSettingRef} map={map} />}
        {map && (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') && (
          <MapFacilityEdit
            visibleSetting={visibleSetting}
            setVisibleSetting={setVisibleSetting}
            editFeature={editFeature}
            setEditFeature={setEditFeature}
            setSelectedFeature={setSelectedFeature}
            pData={pData}
            onCloseEdit={handleCloseEditMode}
          />
        )}
      </ToggleButtonGroup>

      {/* 추후 카테고리별로 클래스 추가 후 관리 */}
      {selectedView && <SafeMngClass selectedFeature={selectedView} setSelectedFeature={setSelectedFeature} />}

      <Box id="map" sx={{ m: 0, height: '100vh', width: '100%' }}></Box>
      <Drawer
        sx={{
          flexShrink: 0,
          zIndex: 3
        }}
        // variant="persistent"
        anchor="right"
        open={open}
      >
        <Toolbar />
        <MobileTitle title="시설물검색" />
        <MapFacilitySearch map={map} pData={pData} setSelectedFeature={setSelectedFeature} />
      </Drawer>
    </Box>
  );
}
