import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import * as turf from '@turf/turf';
import Feature from 'ol/Feature';
import { Point, LineString, Polygon } from 'ol/geom';
import * as interaction from 'ol/interaction';
import { GeoJSON, WKT } from 'ol/format';

import { Style, Circle, Stroke, Fill, RegularShape, Text, Icon } from 'ol/style';
import * as olSphere from 'ol/sphere';
import Observable from 'ol/Observable';
import Overlay from 'ol/Overlay';
import { singleClick } from 'ol/events/condition';
import pinIcon from 'assets/img/pin_rd.png';
import { fromLonLat } from 'ol/proj';

// import {booleanWithin, helpers} from '@turf/turf';
// import bbox from "@turf/bbox";
// import {feature, geometry, polygon} from '@turf/helpers';

import { multiPolygon, multiLineString, centroid } from '@turf/turf';

import { mapAddVectorImageLayer, mapAddVectorLayerLoader, mapAddVectorLayer } from './MapLayers';
import { MAP_STYLE, ENUM_FC_TYPE } from './mapStyle';

const dataProjection = 'EPSG:3857';
const featureProjection = 'EPSG:3857';

function initMap(map, params) {
  const { prjId } = params;

  // 행정구역 Layers
  mapAddVectorImageLayer(map, {
    id: 'sgg',
    name: 'sgg',
    layerType: 'sgg',
    uri: `/api/safe/geo/sgg/${prjId}`,
    style: (feature) => MAP_STYLE.style_regionArea_func(feature)
  });

  // 행정구역 점검구역 Layers
  mapAddVectorImageLayer(map, {
    id: 'insp',
    name: 'insp',
    layerType: 'sgg',
    uri: `/api/safe/geo/insp/${prjId}`,
    style: (feature) => MAP_STYLE.style_regionArea_insp_func(feature),
    visible: false
  });

  // 배경지도 Layers
  mapAddVectorImageLayer(map, {
    id: 'river',
    name: '하천망도',
    layerType: 'background',
    uri: `/api/safe/geo/bg/river/${prjId}`,
    style: MAP_STYLE.style_bgMap_river,
    visible: false,
    minZoom: 12
  });
  mapAddVectorImageLayer(map, {
    id: 'road',
    name: '도로망도',
    layerType: 'background',
    uri: `/api/safe/geo/bg/road/${prjId}`,
    style: MAP_STYLE.style_bgMap_road,
    visible: false,
    minZoom: 12
  });
  mapAddVectorImageLayer(map, {
    id: 'land',
    name: '주제도',
    layerType: 'background',
    uri: `/api/safe/geo/bg/land/${prjId}`,
    visible: false,
    minZoom: 12
  });
  mapAddVectorImageLayer(map, {
    id: 'continue',
    name: '연속주제도',
    layerType: 'background',
    uri: `/api/safe/geo/bg/continue/${prjId}`,
    style: MAP_STYLE.style_bgMap_continue,
    visible: false,
    minZoom: 12
  });
  mapAddVectorImageLayer(map, {
    id: 'rain',
    name: '우수망도',
    layerType: 'background',
    uri: `/api/safe/geo/bg/rain/${prjId}`,
    style: MAP_STYLE.style_bgMap_rain,
    visible: false,
    minZoom: 12
  });

  //시설물 Layers
  mapAddVectorLayerLoader(map, {
    id: 'fc_a',
    name: '세천',
    layerType: 'spf',
    uri: `/api/safe/geo/spf/a/${prjId}`,
    style: (feature) =>
      feature.get('flag_evl') === '1' ? MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_a, map.getView().getZoom()) : MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_a, map.getView().getZoom())
  });
  mapAddVectorLayerLoader(map, {
    id: 'fc_b',
    name: '소교량',
    layerType: 'spf',
    uri: `/api/safe/geo/spf/b/${prjId}`,
    style: (feature) =>
      feature.get('flag_evl') == '1'
        ? MAP_STYLE.getStyle_fc_polygon_normal(MAP_STYLE.color_fc_type_b, MAP_STYLE.color_fc_type_b_fill, map.getView().getZoom())
        : MAP_STYLE.getStyle_fc_polygon_no_eval(MAP_STYLE.color_fc_type_b, MAP_STYLE.color_fc_type_b_fill, map.getView().getZoom())
  });
  mapAddVectorLayerLoader(map, {
    id: 'fc_c',
    name: '보 및 낙차공',
    layerType: 'spf',
    uri: `/api/safe/geo/spf/c/${prjId}`,
    style: (feature) =>
      feature.get('flag_evl') == '1'
        ? MAP_STYLE.getStyle_fc_polygon_normal(MAP_STYLE.color_fc_type_c, MAP_STYLE.color_fc_type_c_fill, map.getView().getZoom())
        : MAP_STYLE.getStyle_fc_polygon_no_eval(MAP_STYLE.color_fc_type_c, MAP_STYLE.color_fc_type_c_fill, map.getView().getZoom())
  });
  mapAddVectorLayerLoader(map, {
    id: 'fc_e',
    name: '농로',
    layerType: 'spf',
    uri: `/api/safe/geo/spf/e/${prjId}`,
    style: (feature) =>
      feature.get('flag_evl') == '1' ? MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_e, map.getView().getZoom()) : MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_e, map.getView().getZoom())
  });
  mapAddVectorLayerLoader(map, {
    id: 'fc_f',
    name: '마을진입로',
    layerType: 'spf',
    uri: `/api/safe/geo/spf/f/${prjId}`,
    style: (feature) =>
      feature.get('flag_evl') == '1' ? MAP_STYLE.getStyle_fc_lineString_normal(MAP_STYLE.color_fc_type_f, map.getView().getZoom()) : MAP_STYLE.getStyle_fc_lineString_no_eval(MAP_STYLE.color_fc_type_f, map.getView().getZoom())
  });
  mapAddVectorLayer(map, {
    id: 'measure',
    name: '거리측정'
  });
  mapAddVectorLayer(map, {
    id: 'editLayer',
    name: '시설편집',
    layerType: 'edit',
    style: (feature) => {
      if (feature.get('edit')) {
        return [...MAP_STYLE.styleFunc_selected_fc_basic(feature, map), ...MAP_STYLE.style_drawing_fc];
      } else if (feature.get('fc_type')) {
        return MAP_STYLE.styleFunc_selected_fc_basic(feature, map);
      } else {
        return MAP_STYLE.style_drawing_fc;
      }
    }
  });
  mapAddVectorLayer(map, {
    id: 'mergeLayer',
    name: '시설물병합',
    layerType: 'edit',
    style: (feature) => {
      let style = MAP_STYLE.style_mergeOption(map.getView().getZoom());
      const coordinates = feature.getGeometry().getCoordinates();
      const startPoint = coordinates[0];
      const endPoint = coordinates[coordinates.length - 1];
      const pointStd = feature.get('target');
      if (!pointStd) {
        return MAP_STYLE.style_mergeGuideLine(map.getView().getZoom());
      } else {
        style.push(
          new Style({
            geometry: new Point(startPoint),
            text: new Text({
              font: 'bold 12pt NanumGothic',
              fill: new Fill({ color: '#ffffff' }),
              stroke: new Stroke({
                color: '#000000',
                width: 4
              }),
              offsetY: MAP_STYLE.getOffsetY_forMergeGuildText(startPoint[1], coordinates[1][1]),
              text: MAP_STYLE.get_pointText_forMerge(pointStd, true)
            })
          })
        );
        style.push(
          new Style({
            geometry: new Point(endPoint),
            text: new Text({
              font: 'bold 12pt NanumGothic',
              fill: new Fill({ color: '#ffffff' }),
              stroke: new Stroke({
                color: '#000000',
                width: 4
              }),
              offsetY: MAP_STYLE.getOffsetY_forMergeGuildText(endPoint[1], coordinates[1][1]),
              text: MAP_STYLE.get_pointText_forMerge(pointStd, false)
            })
          })
        );
        return style;
      }
    }
  });
}

let draw, snap, modify;
let evtFeatureDarw; //편집모드 시작 시 feature 개수 제한을 위한 기능
let evtDistanceMeasureDrawStart;
let evtDistanceMeasureDrawEnd;
let listener = null;
let measureTooltipElement; //draw 이벤트가 진행 중일 때 담을 거리 값 element
let measureTooltip; //툴팁 위치

function createMeasureTooltip(map) {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center'
  });
  map.addOverlay(measureTooltip);
}

function formatLength(line) {
  var length = olSphere.getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
  } else {
    output = Math.round(length * 100) / 100 + ' ' + 'm';
  }

  return output;
}

const mapUtil = {
  /**
   * ID 값으로 Layer 가져오기
   * @param {*} map
   * @param {*} id
   * @returns
   */
  getLayerId: (map, layerId) => {
    return map
      .getLayers()
      .getArray()
      .find((layer) => layer.get('id') == layerId);
  },

  /**
   * Type 값으로 Layer 가져오기
   * @param {*} map
   * @param {*} id
   * @returns
   */
  getLayersByType: (map, layerType) => {
    return map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('layerType') == layerType);
  },

  /**
   * ID 값으로 Layer Source 가져오기
   * @param {*} map
   * @param {*} id
   * @returns
   */
  getLayerSourceId: (map, id) => {
    let source = new VectorSource({ wrapX: false });

    map
      .getLayers()
      .getArray()
      .map((item) => {
        if (item.getProperties()?.id === id) {
          source = item.getSource();
        }
      });

    return source;
  },

  /**
   * Layer ID 값으로 Layer Extent 가져오기
   * @param {*} map
   * @param {*} id
   * @returns
   */
  getLayerExtentById: (map, id) => {
    const layer = map
      .getLayers()
      .getArray()
      .find((item) => {
        if (item.getProperties()?.id === id) {
          return item;
        }
      });

    if (layer) {
      const extent = layer.getSource().getExtent();
      if (isFinite(extent[0]) && isFinite(extent[1]) && isFinite(extent[2]) && isFinite(extent[3])) {
        map.getView().fit(layer.getSource().getExtent());
      }
    }
  },

  /**
   * 주어진 지도 및 extent 값으로 범위 맞춤
   * @param {*} map
   * @param {*} id
   * @returns
   */
  fitExtent: (map, layerId, extent) => {
    if (!extent[0] || !extent[1] || !extent[2] || !extent[3]) return;
    const layer = map
      .getLayers()
      .getArray()
      .find((item) => {
        if (item.getProperties()?.id === layerId) {
          return item;
        }
      });

    if (layer) {
      if (isFinite(extent[0]) && isFinite(extent[1]) && isFinite(extent[2]) && isFinite(extent[3])) {
        map.getView().fit(extent);
      }
    }
  },

  /**
   * LayerType 으로 Layer Source 가져오기
   * @param {*} map
   * @param {*} type
   * @returns
   */
  getLayerSourceType: (map, type) => {
    let sources = [];

    map
      .getLayers()
      .getArray()
      .map((item) => {
        if (item.getProperties()?.layerType === type) {
          const source = item.getSource();
          sources.push(source);
        }
      });

    return sources;
  },

  /**
   * Layer Id로 레이어 on/off
   * @param {*} map
   * @param {id , isVisible} params
   */
  isVisibleLayerId: (map, id, isVisible) => {
    if (!map.getLayers()) return;

    map
      .getLayers()
      .getArray()
      .map((item) => {
        if (item.get('id') === id) {
          item.setVisible(isVisible);
        }
      });
  },

  /**
   * LayerType 으로 레이어 on/off
   * @param {*} map
   * @param {id , isVisible} params
   */
  isVisibleLayerType: (map, type, isVisible) => {
    if (!map.getLayers()) return;

    map
      .getLayers()
      .getArray()
      .map((item) => {
        if (item.get('layerType') === type) {
          item.setVisible(isVisible);
        }
      });
  },

  /**
   * Layer에 타입 별 피처 추가
   * @param { olMap } map
   * @param { layerId, type, wkt } params
   */
  addFeatureWKT: (map, params) => {
    const format = new WKT();

    if (!params?.wkt) return;

    const feature = format.readFeature(params.wkt, {
      dataProjection: dataProjection,
      featureProjection: featureProjection
    });

    map
      .getLayers()
      .getArray()
      .map((item) => {
        if (item.getProperties()?.id === params.layerId) {
          item.getSource().addFeature(feature);
        }
      });
  },

  /**
   * feature Style 가져오기
   * @param { olMap } map
   * @param { styleName } params
   */
  getFeatureStyle: (styleName) => {
    let styles;

    if (styleName === 'selectedFeature') {
      styles = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)'
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 3
        })
      });
    }

    return styles;
  },

  /**
   * Overlay 생성 후 map 추가
   * @param { olMap } map
   * @param { styleName } params
   */
  initOverlay: (map, elemId) => {
    let overlay = new Overlay({
      element: document.getElementById(elemId)
    });

    map.addOverlay(overlay);
    overlay.setPosition(undefined);

    return overlay;
  },

  getCenterCoord: (map, data) => {
    let coord;

    if (data?.geom?.coordinates) {
      let type = data.geom.type;
      let ftType;

      if (type === 'MultiLineString') {
        ftType = multiLineString(data.geom.coordinates);
      } else if (type === 'MultiPolygon') {
        ftType = multiPolygon(data.geom.coordinates);
      }

      let ftCentroid = centroid(ftType);
      coord = ftCentroid.geometry.coordinates;
    }

    return coord;
  },
  /**
   * GPS - 기본 설정
   * @param {*} map
   * @returns
   */
  initGPSPosition: (map, { layerId, layerName }) => {
    try {
      const marker = new Feature({
        geometry: new Point(map.getView().getCenter())
      });
      marker.setStyle(
        new Style({
          image: new Icon({
            src: pinIcon,
            scale: 0.5
          })
        })
      );
      const vectorSource = new VectorSource({
        features: [marker]
      });
      const vectorLayer = new VectorLayer({
        id: layerId,
        name: layerName,
        source: vectorSource,
        visible: false
      });
      map.addLayer(vectorLayer);
      return vectorSource;
    } catch (error) {
      throw error;
    }
  },

  drawGPSPosition: (gpsSource, location) => {
    try {
      if (!gpsSource) return;
      const marker = gpsSource.getFeatures()[0];
      const textStyle = new Text({
        font: 'bold 8pt NanumGothic',
        stroke: new Stroke({
          color: '#000000'
        }),
        text: `${location[0].toFixed(2)}, ${location[1].toFixed(2)}`
      });
      let currentStyle = marker.getStyle();
      currentStyle.setText(textStyle);
      marker.setStyle(currentStyle);
      marker.getGeometry().setCoordinates(location);
    } catch (error) {
      throw error;
    }
  },

  /**
   * GPS - GPS 비활성화
   * @param {*} map
   * @returns
   */
  removeGPSPosition: (map, vectorLayer) => {
    if (!map && !vectorLayer) return;
    map.removeLayer(vectorLayer);
  },

  /**
   * Features 목록을 GeoJSON으로 추출
   * @param {olMap} map
   * @param {*} features
   */
  writeFeaturesToGeoJSON: (features) => {
    if (features.length == 0) return;
    return new GeoJSON().writeFeaturesObject(features);
  },

  /**
   * Feature를 GeoJSON으로 추출
   * @param {olMap} map
   * @param {*} feature
   */
  writeFeatureToGeoJSON: (feature) => {
    if (!feature) return;
    return new GeoJSON().writeFeatureObject(feature);
  },

  /**
   * GeoJSON을  feature로 변환
   * @param {olMap} map
   * @param {*} features
   */
  readGeoJSONToFeature: (geojson) => {
    if (!geojson) return;
    return new GeoJSON().readFeature(geojson);
  },

  /**
   * Geometry GeoJSON으로 추출
   * @param {olMap} map
   * @param {*} feature
   */
  writeGeometryToGeoJSON: (geometry) => {
    if (!geometry) return;
    return new GeoJSON().writeGeometryObject(geometry, {
      dataProjection: dataProjection,
      featureProjection: featureProjection
    });
  },

  /**
   * Geometry Feature를 WKT로 추출
   * @param {olMap} map
   * @param {*} feature
   */
  writeFeatureToWKT: (feature) => {
    if (!feature) return;
    return new WKT().writeFeature(feature, {
      dataProjection: dataProjection,
      featureProjection: featureProjection
    });
  },

  reverseFeaturePoint: (feature) => {
    const featureGeom = feature.getGeometry();
    let coords = featureGeom.getCoordinates();
    coords.reverse();
    featureGeom.setCoordinates(coords);
    return featureGeom;
  },

  /**
   * Layer refresh
   * @param {*} map
   * @param {*} id
   */
  clear: (map) => {
    if (!map) return;
    map
      .getLayers()
      .getArray()
      .forEach((item) => {
        const layerId = item.get('id');
        if (layerId === 'editLayer') {
          item.getSource().clear();
        } else {
          item.getSource().refresh();
        }
      });
  },

  /**
   * Layer refresh
   * @param {*} map
   * @param {*} id
   */
  refreshByLayerId: (map, id) => {
    if (!map) return;
    map
      .getLayers()
      .getArray()
      .forEach((item) => {
        if (item.get('id') === id) {
          item.getSource().refresh();
        }
      });
  },

  /**
   * 배열로된 좌표값을 가져와 새로운 geometry 생성
   * @param {*} geometryType
   * @param {*} coordinates
   * @returns
   */
  createGeometry: (geometryType, coordinates) => {
    if (!geometryType || !coordinates) return;
    let feature = null;
    if (geometryType === 'LineString') {
      feature = new LineString(coordinates);
    } else if (geometryType === 'Polygon') {
      feature = new Polygon(coordinates);
    }
    return feature;
  },

  /**
   * Geometry를 가져와 새로운 Feature 생성
   * @param {*} geometry
   * @param {*} options
   * @returns
   */
  createFeature: (geometry, options) => {
    if (!geometry) return;
    let cloneGeom = geometry.clone();
    const geometryType = cloneGeom.getType();
    const coordinates = cloneGeom.getCoordinates();
    let feature = null;

    if (geometryType === 'LineString') {
      feature = new Feature(new LineString(coordinates));
    } else if (geometryType === 'Polygon') {
      feature = new Feature(new Polygon(coordinates));
    }
    if (options?.properties) {
      let tmProps = { ...options.properties };
      delete tmProps.geometry;
      feature.setProperties(tmProps);
    }
    return feature;
  },
  /**
   * GeoJSON을 가져와 지정된 단위로 길이 측정
   * @param {*} geometry
   * @param {*} options
   * @returns
   */
  getFeatureLength: (feature) => {
    if (!feature) return;
    feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const format = new GeoJSON();
    const turfLine = format.writeFeatureObject(feature);
    const length = turf.length(turfLine, { units: 'meters' });
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    return length;
  },

  /**
   * feature의 처음, 중간, 끝 좌표 가져오기
   * @param {*} feature
   */
  getPointAddress: (feature) => {
    if (!feature) return;

    if (feature.getGeometry().getType() === 'Polygon') {
      feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      const polygon = turf.polygon(feature.getGeometry().getCoordinates());
      const center = turf.centerOfMass(polygon);
      const coord = center.geometry.coordinates;
      feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
      return { midCoord: coord };
    } else if (feature.getGeometry().getType() === 'LineString') {
      feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      const coord = feature.getGeometry().getCoordinates();
      const startCoord = feature.getGeometry().getFirstCoordinate();
      const endCoord = feature.getGeometry().getLastCoordinate();

      //중간지점 좌표 가져오기
      const format = new GeoJSON();
      const turfLine = format.writeFeatureObject(feature);
      const length = turf.length(turfLine, { units: 'meters' });
      const turfPoint = turf.along(turfLine, length / 2 / 1000); // 중앙부
      const marker_mid = format.readFeature(turfPoint);
      const midCoord = marker_mid.getGeometry().getCoordinates();
      feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
      return { startCoord, midCoord, endCoord };
    }
  },

  /**
   * 기준 레이어에 완전히 포함된 경우 true를 반환
   * @param {*} map
   * @param {*} layerId
   * @param {*} feature
   */
  isWithin: (map, layerId, feature) => {
    if (!map || !layerId || !feature) return;
    const cFt = feature.clone();
    let flagWithin = false;
    let points = [];

    try {
      if (!cFt.getGeometry()) return;
      cFt.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      if (cFt.getGeometry().getType() === 'Polygon') {
        points = turf.points(cFt.getGeometry().getCoordinates()[0]);
      } else if (cFt.getGeometry().getType() === 'LineString') {
        points = turf.points(cFt.getGeometry().getCoordinates());
      }

      let fts = [];
      map
        .getLayers()
        .getArray()
        .forEach((item) => {
          if (item.get('id') === layerId) {
            fts = [...item.getSource().getFeatures()];
          }
        });
      if (fts.length > 0) {
        fts.forEach((item) => {
          const cInspFt = item.clone();
          cInspFt.getGeometry().transform('EPSG:3857', 'EPSG:4326');
          var polygon = turf.multiPolygon(cInspFt.getGeometry().getCoordinates());
          const ptsWithin = turf.pointsWithinPolygon(points, polygon);
          console.log(ptsWithin);
          if (ptsWithin.features.length === points.features.length) {
            flagWithin = true;
          }

          //폴리곤타입 안되고있음.
        });
      }
      return flagWithin;
    } catch (e) {
      console.log(e);
    }
  }
};

const mapEdit = {
  /**
   * 지정 Layer에 interaction 추가하기
   * @param {ol.Map} map
   * @param {{type: string, maxPoints: integer}} options { type: 'LineString', maxPoints: 2 } Geometry 타입, 최대Point수
   * @param {ol.VectorSource} source vector layer source
   * @param {draw: boolean, modify: boolean, snap: boolean} disabledOption vector layer source
   * @returns Draw
   */
  addInteractionToLayer: (map, type, source, options) => {
    let newDraw = null;
    let selected = null;

    if (source) {
      if (type === 'draw') {
        newDraw = new interaction.Draw({ ...options, source });
        map.addInteraction(newDraw);
        return newDraw;
      }

      if (type === 'modify') {
        modify = new interaction.Modify({
          source: source
        });
        map.addInteraction(modify);
      }

      if (type === 'snap') {
        snap = new interaction.Snap({
          source: source
        });
        map.addInteraction(snap);
      }
    } else {
      if (type === 'draw') {
        newDraw = new interaction.Draw({ ...options });
        map.addInteraction(newDraw);
        return newDraw;
      }
    }
  },

  /**
   * Layer 편집 모드 종료
   * @param {*} map
   */
  removeInteraction: (map) => {
    if (map) {
      if (evtFeatureDarw) draw.un('drawstart', evtFeatureDarw);
      if (modify) map.removeInteraction(modify);
      if (snap) map.removeInteraction(snap);
      if (draw) map.removeInteraction(draw);
    }
  },
  /**
   * 시설물 편집 - 점추가
   * @param {*} map
   */
  addInteractionFeaturePoint: (map) => {
    const source = mapUtil.getLayerSourceId(map, 'editLayer');
    let pointDraw = new interaction.Draw({
      // source: source,
      type: 'Point'
    });
    pointDraw.on('drawend', (e) => {
      //새로 추가한 점
      let evtFeature = e.feature.clone();
      evtFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      const clickPointCoord = evtFeature.getGeometry().getCoordinates();
      const targetPoint = turf.point(clickPointCoord);

      //기존에 그려진 feature
      let drawFeature = source.getFeatures()[0].clone();
      drawFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      const firstCood = drawFeature.getGeometry().getFirstCoordinate();
      const lastCood = drawFeature.getGeometry().getLastCoordinate();

      //시종점 중 가까운 좌표를 반환하여 비교하고 선 연결
      const points = turf.featureCollection([turf.point(firstCood), turf.point(lastCood)]);
      const nearest = turf.nearestPoint(targetPoint, points);
      const nearCood = nearest.geometry.coordinates;

      let resultFeature = source.getFeatures()[0];
      let resultCood = resultFeature.getGeometry().getCoordinates();
      evtFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
      if (JSON.stringify(firstCood) == JSON.stringify(nearCood)) {
        resultCood.unshift(evtFeature.getGeometry().getCoordinates());
      } else if (JSON.stringify(lastCood) == JSON.stringify(nearCood)) {
        resultCood.push(evtFeature.getGeometry().getCoordinates());
      }
      resultFeature.getGeometry().setCoordinates(resultCood);
    });
    map.addInteraction(pointDraw);
    return pointDraw;
  },

  /**
   *
   * @param {*} lineFeature
   */
  addInteractionSplitFeature: (fLine, fSplitter) => {
    //분할할 Line
    let lineFeature = fLine.clone();
    lineFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const lineCoords = lineFeature.getGeometry().getCoordinates();
    const line = turf.lineString(lineCoords);

    //분할하는데 사용되는 Line
    let splitterFeature = fSplitter.clone();
    splitterFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const splitterCoords = splitterFeature.getGeometry().getCoordinates();
    const splitter = turf.lineString(splitterCoords);

    const split = turf.lineSplit(line, splitter);
    const result = split.features.map((f) => {
      let feature = new Feature(new LineString(f.geometry.coordinates));
      feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
      return feature;
    });
    return result;
  },

  /**
   * 시설물 편집 > Feature Merge
   * @param {*} map
   * @param {*} layer
   * @param {*} options
   * @returns
   */
  addInteractionMerge: (map, layer, options) => {
    // let arrMergeFeature = []; // 병합할 라인 Feature Array
    // let mergeLayer = null; // 병합 레이어(최초 선택 라인의 레이어)
    // let mergeLineFeature = null; // 연결 라인 Feature

    // // 임시라인용 Vector
    // // let mergeSourceLine = new ol.source.Vector();
    // let mergeVectorLine = new VectorLayer({
    //   source: new VectorSource(),
    //   style: MAP_STYLE.style_mergeGuideLine(map.getView().getZoom())
    //   // zIndex: 42
    // });
    // map.addLayer(mergeVectorLine);

    let selected = new interaction.Select({
      layers: layer, // lineString 만 선택 가능
      condition: singleClick,
      toggleCondition: singleClick,
      style: function (feature) {
        // ######################################################
        //  기본 Select 스타일 slice() 후 push()
        //  slice 하지 않고 push() 하는 경우 기존 텍스트 정보 보임
        // ######################################################
        let style = MAP_STYLE.style_mergeOption(map.getView().getZoom());
        const coordinates = feature.getGeometry().getCoordinates();
        const startPoint = coordinates[0];
        const endPoint = coordinates[coordinates.length - 1];
        const pointStd = feature.get('target');

        style.push(
          new Style({
            geometry: new Point(startPoint),
            text: new Text({
              font: 'bold 12pt NanumGothic',
              fill: new Fill({ color: '#ffffff' }),
              stroke: new Stroke({
                color: '#000000',
                width: 4
              }),
              offsetY: MAP_STYLE.getOffsetY_forMergeGuildText(startPoint[1], coordinates[1][1]),
              text: MAP_STYLE.get_pointText_forMerge(pointStd, true)
            })
          })
        );
        style.push(
          new Style({
            geometry: new Point(endPoint),
            text: new Text({
              font: 'bold 12pt NanumGothic',
              fill: new Fill({ color: '#ffffff' }),
              stroke: new Stroke({
                color: '#000000',
                width: 4
              }),
              offsetY: MAP_STYLE.getOffsetY_forMergeGuildText(endPoint[1], coordinates[1][1]),
              text: MAP_STYLE.get_pointText_forMerge(pointStd, false)
            })
          })
        );
        return style;
      },
      filter: options?.filter ? options.filter : (feature, layer) => true
    });
    map.addInteraction(selected);
    return selected;
  },

  /**
   * 편집된 피처 목록 조회
   * @param {*} map
   * @returns
   */
  getFeatures: (map) => {
    const source = mapUtil.getLayerSourceId(map, 'editLayer');

    if (source.getFeatures().length > 0) {
      return source.getFeatures();
    }
  },

  /**
   * 거리측정 시작
   * @param {*} map
   */
  distanceMeasureStart: (map) => {
    draw = new interaction.Draw({
      source: mapUtil.getLayerSourceId(map, 'measure'),
      type: 'LineString',
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2
        }),
        image: new Circle({
          radius: 5
        })
      })
    });
    map.addInteraction(draw);
    createMeasureTooltip(map);

    evtDistanceMeasureDrawStart = (evt) => {
      listener = evt.feature.getGeometry().on('change', function (evt) {
        var geom = evt.target;
        var output = formatLength(geom);

        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(geom.getLastCoordinate());
      });
    };

    evtDistanceMeasureDrawEnd = () => {
      measureTooltipElement.className = 'ol-tooptip ol-tooltip-static';
      measureTooltip.setOffset([0, -7]);
      measureTooltipElement = null;
      createMeasureTooltip(map);
      new Observable(listener);
    };

    draw.on('drawstart', evtDistanceMeasureDrawStart);
    draw.on('drawend', evtDistanceMeasureDrawEnd);
  },

  /**
   *  거리측정 종료
   * @param {*} map
   */
  distanceMeasureStop: (map) => {
    let eleMeasureTooltip = document.getElementsByClassName('ol-tooptip ol-tooltip-static');
    for (let elem of eleMeasureTooltip) {
      elem.parentNode.removeChild(elem);
    }
    measureTooltip.setPosition(undefined);
    if (evtDistanceMeasureDrawStart) draw.un('drawstart', evtDistanceMeasureDrawStart);
    if (evtDistanceMeasureDrawEnd) draw.un('drawstart', evtDistanceMeasureDrawEnd);
    map.removeInteraction(draw);
    map.removeOverlay(measureTooltip);
    const source = mapUtil.getLayerSourceId(map, 'measure');
    source.clear();
  }
};

export { initMap };
export { mapUtil, mapEdit };
