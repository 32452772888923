import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'utils/api.js';
import moment from 'moment';

import { Box, ContentsBox, SearchBox } from 'components/Layout';
import { DataGrid } from 'components/DataDisplay/DataGrid';
import TextField from 'components/Inputs/TextField';
import Button from 'components/Inputs/Button';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { authUtils } from 'utils/authUtils.js';

export default function SafeMng() {
  const navigate = useNavigate();
  const location = useLocation();
  const [srchDatas, setSrchDatas] = useState({ status: '' });
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  /**
   * 프로젝트 관리 - 목록 조회
   * @param {*} e
   */
  const getDataList = async () => {
    const response = await axios.get(`/api/prj`, { params: { ...srchDatas } });
    if (response.data) {
      setRows([...response.data]);
    }
  };

  const handleChangeDate = (date, id) => {
    setSrchDatas({ ...srchDatas, [id]: date });
  };

  const handleClickMovePage = (params) => (e) => {
    // setSelectedRow({ ...params.row });
    navigate(`/safe/map`, { state: { data: params.row } });
  };

  const handleClickSrchBtn = (e) => {
    if (srchDatas?.projName?.length >= 100) {
      alert('프로젝트명은 100자까지 가능합니다.');
      return;
    }
    getDataList();
  };

  const handleSrchChange = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  const handleSrchChangeStatus = (e) => {
    setSrchDatas({ ...srchDatas, status: e.target.value });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: false, headerAlign: 'center', align: 'center', hide: true },
    { field: 'rowNumber', headerName: 'No', width: 100, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: 'select',
      headerName: '안전점검',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => (
        <button style={{ color: 'blue' }} onClick={handleClickMovePage(params)}>
          안전점검
        </button>
      )
    },
    {
      field: 'projName',
      headerName: '프로젝트명',
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true
    },
    {
      field: 'startDate',
      headerName: '시작일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value
    },
    {
      field: 'endDate',
      headerName: '종료일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value
    },
    {
      field: 'registerId',
      headerName: '등록자',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value
    },
    {
      field: 'createdAt',
      headerName: '등록일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => moment(params.value).format('LLL')
    }
  ];

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <Box sx={{ display: 'block' }}>
      {authUtils.useIsManager() && (
        <SearchBox component="form" noValidate autoComplete="off">
          <TextField margin="dense" name="projName" label="프로젝트명" id="projName" onChange={handleSrchChange} />
          <ToggleButtonGroup color={'primary'} value={srchDatas.status} exclusive onChange={handleSrchChangeStatus} size="small">
            <ToggleButton value="">전체</ToggleButton>
            <ToggleButton value="ready">준비</ToggleButton>
            <ToggleButton value="ing">진행중</ToggleButton>
            <ToggleButton value="end">마감</ToggleButton>
          </ToggleButtonGroup>
          <Button align="right" variant="contained" color="primary" onClick={handleClickSrchBtn}>
            검색
          </Button>
        </SearchBox>
      )}
      <ContentsBox>
        <DataGrid height={300} rowHeight={40} rows={rows} columns={columns} />
      </ContentsBox>
    </Box>
  );
}
