import React from 'react';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)(({ theme }) => ({
}));

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function CustomTab(props) {

    const { children, label, index, ...rest } = props;
    return (
        <StyledTab
            label={label}
            {...a11yProps(index)}
            {...rest}
        >
            {children}
        </StyledTab>
    );
}
