import React, { useEffect, useRef, useState } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { authAtom } from '_state';
import axios from 'utils/api.js';

export default function InfoMng() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'all'
  });

  const auth = useRecoilValue(authAtom);
  const [userInfo, setUserInfo] = useState([]);

  const password = useRef();
  password.current = watch('userInfo.password');

  const userInfoUpdate = async (data) => {
    return await axios.patch(`/api/user/${auth.id}`, data);
  };

  const onSubmit = (data) => {
    if (isDirty) {
      userInfoUpdate(data.userInfo)
        .then((response) => {
          alert(response.data.message);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const getUser = async () => {
    const response = await axios.get(`/api/user/${auth.id}`);
    setUserInfo(response.data);
    setValue('userInfo', response.data);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.id]: e.target.value });
  };

  useEffect(async () => {
    await getUser();
  }, []);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        정보 관리
      </Typography>
      <Box id="userInfoForm" component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, minWidth: 300, maxWidth: 500 }}>
        <TextField margin="dense" required fullWidth id="id" label="ID" name="id" value={userInfo?.userid || ''} disabled />
        <TextField margin="dense" required fullWidth name="username" label="이름" id="username" value={userInfo?.username || ''} disabled />
        <TextField margin="dense" required fullWidth name="companyName" label="소속" id="companyName" value={userInfo?.companyName || ''} disabled />
        <TextField
          margin="dense"
          fullWidth
          name="password"
          label="비밀번호"
          type="password"
          id="password"
          error={!!errors.userInfo?.password}
          helperText={errors.userInfo?.password && errors.userInfo?.password.message}
          {...register('userInfo.password', {
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
              message: '비밀번호는 8자 이상, 최소 하나 이상의 문자, 숫자, 특수문자를 입력해주세요.'
            }
          })}
        />
        <TextField
          margin="dense"
          fullWidth
          name="passwordCheck"
          label="비밀번호 확인"
          type="password"
          id="passwordCheck"
          error={!!errors.userInfo?.passwordCheck}
          helperText={errors.userInfo?.passwordCheck && errors.userInfo?.passwordCheck.message}
          {...register('userInfo.passwordCheck', {
            validate: (value) => {
              if (value !== password.current && !!password.current) {
                return '비밀번호가 일치하지 않습니다.';
              }
            }
          })}
        />
        <TextField
          margin="dense"
          required
          fullWidth
          name="eMail"
          label="E-mail"
          type="text"
          id="eMail"
          value={userInfo?.eMail || ''}
          error={!!errors.userInfo?.eMail}
          helperText={errors.userInfo?.eMail && errors.userInfo?.eMail.message}
          {...register('userInfo.eMail', {
            required: '이메일을 입력해주세요.',
            pattern: { value: /^\S+@\S+$/i, message: '이메일 형식을 확인해주세요' }
          })}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          required
          fullWidth
          name="phoneNumber"
          label="전화번호"
          id="phoneNumber"
          value={userInfo?.phoneNumber || ''}
          error={!!errors.userInfo?.phoneNumber}
          helperText={errors.userInfo?.phoneNumber && errors.userInfo?.phoneNumber.message}
          {...register('userInfo.phoneNumber', {
            required: '전화번호를 입력해주세요.',
            pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
          })}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          required
          fullWidth
          name="cellPhoneNumber"
          label="핸드폰 번호"
          id="cellPhoneNumber"
          value={userInfo?.cellPhoneNumber || ''}
          error={!!errors.userInfo?.cellPhoneNumber}
          helperText={errors.userInfo?.cellPhoneNumber && errors.userInfo?.cellPhoneNumber.message}
          {...register('userInfo.cellPhoneNumber', {
            required: '핸드폰번호를 입력해주세요.',
            pattern: { value: /\d/, message: '숫자만 입력해주세요.' }
          })}
          onChange={handleChange}
        />
        <Button variant="contained" type="submit" form="userInfoForm" fullWidth>
          수정
        </Button>
      </Box>
    </Paper>
  );
}
