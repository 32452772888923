export const validator = (values, fieldName) => {
  let errors = {};
  switch (fieldName) {
    case 'email':
      validateEmail(values.email, errors);
      break;
    case 'password':
      validatePassword(values.password, errors);
      break;
    default:
  }
  return errors;
};

export const validatorType = (values, type) => {
  let params = { stat: false };
  switch (type) {
    case 'number':
      params = { ...params, ...validateNumber(values) };
      break;
    case 'numEng': //숫자영문만
      params = { ...params, ...validateNumberAndEnglish(values) };
      break;
    case 'kor':
      // errors = validateKorean(values, params);
      break;
    default:
  }
  return params;
};

/**
 * 입력필드 유효범위 - 숫자만 입력
 * @param {*} values
 * @param {*} errors
 * @returns
 */
function validateNumber(values, errors) {
  const regex = /^[0-9.]+$/;
  let result = regex.test(String(values));
  let params = {};
  if (!result) {
    params.errors = {
      msg: '0 이상 숫자만 입력하세요.',
      text: values?.replace(/[^.0-9]/g, '')
    };
    params.stat = true;
  } else {
    params.stat = false;
  }
  return params;
}

/**
 * 입력필드 유효범위 - 숫자, 영어만 입력
 * @param {*} values
 * @param {*} errors
 * @returns
 */
function validateNumberAndEnglish(values) {
  const regex = /^[a-zA-Z0-9]*$/;
  let result = regex.test(String(values));
  let params = {};
  if (!result) {
    params.errors = {
      msg: '숫자, 영어만 입력하세요.',
      text: values?.replace(/[^a-zA-Z0-9]/g, '')
    };
    params.stat = true;
  } else {
    params.stat = false;
  }

  return params;
}

// ******************************
function validateEmail(email, errors) {
  let result = true;

  if (!email) {
    errors.email = 'Email is Required';
    result = false;
  } else {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = 'Invalid Email address';
  }
  return result;
}
// ******************************
function validatePassword(pass, errors) {
  let result = true;

  if (!pass) {
    errors.password = 'Password is Required';
    result = false;
  } else {
    var lower = /(?=.*[a-z])/;
    result = lower.test(pass);

    if (!result) {
      errors.password = 'Password must contain at least one lower case letter.';
      result = false;
    } else if (pass.length < 8) {
      errors.password = 'Your password has less than 8 characters.';
      result = false;
    }
  }

  return result;
}
