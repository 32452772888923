import React, { useEffect, useState } from 'react';
import { BarChart, PieChart } from 'components/Chart';
import Grid from '@mui/material/Grid';
import axios from 'utils/api.js';
import Box from '@mui/material/Box';

export default function StatSafe(props) {
  const { pData } = props;
  const labels = ['세천', '소교량', '보 및 낙차공', '농로', '마을진입로'];
  const [series, setSeries] = useState([]);

  const categories = ['전체', '평가', '양호', '보통', '불량'];
  const [dataA, setDataA] = useState([0, 0, 0, 0, 0]);
  const [dataB, setDataB] = useState([0, 0, 0, 0, 0]);
  const [dataC, setDataC] = useState([0, 0, 0, 0, 0]);
  const [dataE, setDataE] = useState([0, 0, 0, 0, 0]);
  const [dataF, setDataF] = useState([0, 0, 0, 0, 0]);

  const getData = async () => {
    const response = await axios.get(`/api/stat/safe/${pData.id}`);
    if (response) {
      setSeries(response.data.series);
      const statA = response.data.dataA[0];
      const statB = response.data.dataB[0];
      const statC = response.data.dataC[0];
      const statE = response.data.dataE[0];
      const statF = response.data.dataF[0];
      setDataA([statA?.tot_cnt || 0, statA?.evl_cnt || 0, statA?.good_cnt || 0, statA?.normal_cnt || 0, statA?.bad_cnt || 0]);
      setDataB([statB?.tot_cnt || 0, statB?.evl_cnt || 0, statB?.good_cnt || 0, statB?.normal_cnt || 0, statB?.bad_cnt || 0]);
      setDataC([statC?.tot_cnt || 0, statC?.evl_cnt || 0, statC?.good_cnt || 0, statC?.normal_cnt || 0, statC?.bad_cnt || 0]);
      setDataE([statE?.tot_cnt || 0, statE?.evl_cnt || 0, statE?.good_cnt || 0, statE?.normal_cnt || 0, statE?.bad_cnt || 0]);
      setDataF([statF?.tot_cnt || 0, statF?.evl_cnt || 0, statF?.good_cnt || 0, statF?.normal_cnt || 0, statF?.bad_cnt || 0]);
    }
  };

  useEffect(() => {
    if (pData?.id) getData();
  }, [pData]);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center">
      <Grid item xs={4}>
        <PieChart name="소규모 공공시설 분포" width="100%" type="pie" labels={labels} series={series} />
      </Grid>
      <Grid item xs={4}>
        <BarChart name="세천 안전점검 통계" width="100%" height={300} type="bar" categories={categories} data={dataA} />
      </Grid>
      <Grid item xs={4}>
        <BarChart name="소교량 안전점검 통계" width="100%" height={300} type="bar" categories={categories} data={dataB} />
      </Grid>
      <Grid item xs={4}>
        <BarChart name="보 및 낙차공 안전점검 통계" width="100%" height={300} type="bar" categories={categories} data={dataC} />
      </Grid>
      <Grid item xs={4}>
        <BarChart name="농로 안전점검 통계" width="100%" height={300} type="bar" categories={categories} data={dataE} />
      </Grid>
      <Grid item xs={4}>
        <BarChart name="마을진입로 안전점검 통계" width="100%" height={300} type="bar" categories={categories} data={dataF} />
      </Grid>
    </Grid>
  );
}
