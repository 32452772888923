import * as React from 'react';
import Box from '@mui/system/Box';

const style = {
  minWidth: 200,
  m: 2,
  p: 2,
  backgroundColor: '#EAEAEA',
  borderTop: `1px solid #BDBDBD`,
  borderBottom: `1px solid #BDBDBD`,
  borderRadius: `5px`,
  '& .MuiTextField-root': {
    mx: 1
  },
  '& .MuiNativeSelect-root': {
    mx: 1
  },
  '& .MuiButton-root': {
    m: 1
  }
};

export default function CustomBox(props) {
  const { children, sx, ...rest } = props;

  return (
    <Box {...rest} sx={sx ? { ...style, ...sx } : style} spacing={1}>
      {children}
    </Box>
  );
}
