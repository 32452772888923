import React, { useState, useEffect } from 'react';
import MapContext from './MapContext';
import 'ol/ol.css';
import { Map as OlMap, View } from 'ol';
import { fromLonLat, get as getProjection } from 'ol/proj';
import { Tile as TileLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import { OverviewMap, defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteraction } from 'ol/interaction';

const vworldConfig = {
  vworldUrl: 'http://api.vworld.kr/req/data',
  key: '90C9EE28-4A66-3345-8D7D-57D57C2179D4',
  sigKorNm: '예천',
  crs: 'EPSG:3857'
};

const Map = ({ children }) => {
  const [mapObj, setMapObj] = useState({});

  useEffect(() => {
    // Map 객체 생성 및 Vworld 배경지도 추가
    const source = new XYZ({
      // url: `http://xdworld.vworld.kr:8080/2d/Base/202002/{z}/{x}/{y}.png`,
      url: `http://api.vworld.kr/req/wmts/1.0.0/${vworldConfig.key}/Base/{z}/{y}/{x}.png`,
      tileSize: 256
    });

    const map = new OlMap({
      layers: [
        new TileLayer({
          id: 'baseMap',
          source: source
        })
      ],
      target: 'map', // 하위 요소 중 id 가 map 인 element가 있어야함.
      view: new View({
        projection: getProjection('EPSG:3857'),
        center: fromLonLat([128.42914, 36.656263], getProjection('EPSG:3857')),
        zoom: 12,
        minZoom: 7,
        maxZoom: 22,
        constrainResolution: true
        // resolutions: [983.002, 484.311, 242.157, 120.626, 60.313, 30.128, 15.057, 7.527, 3.763, 1.881, 0.948]
        // extent: [14226344.40179568, 4358816.236315736, 14366988.533840405, 4423787.710358136]
        // minResolution: 0.000583,
        // maxResolution: 156543.0339
      }),
      interactions: defaultInteraction({ doubleClickZoom: false, altShiftDragRotate: false, pinchRotate: false })
    });

    setMapObj({ map });
    return () => map.setTarget(undefined);
  }, []);

  // MapContext.Provider 에 객체 저장
  return <MapContext.Provider value={mapObj}>{children}</MapContext.Provider>;
};

export default Map;
