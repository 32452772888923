import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const navMenuItems = [
  {
    name: '프로젝트 관리',
    uri: 'prj'
  },
  {
    name: '사용자 관리',
    uri: 'user',
    items: [
      {
        name: '관리자 관리',
        uri: 'admin'
      },
      {
        name: '점검원 관리',
        uri: 'insp'
      }
    ]
  },
  {
    name: '안전점검 관리',
    uri: 'safe'
  },
  {
    name: '공공시설 통계',
    uri: 'stat'
  },
  {
    name: '내 정보 수정',
    uri: 'info'
  }
];

export default function Navigation(props) {
  const navigator = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [navPath, setNavPath] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  const handleClickLink = (uri) => (e) => {
    navigator(uri, { state: location.pathname });
  };

  const handleChange = (e, value) => {
    setValue(value);
  };

  const getMenuInfo = (pathname, menus) => {
    const splitPath = pathname.substr(0, 1) === '/' ? pathname.substr(1, pathname.length).split('/') : pathname.split('/');
    const info = menus.find((t) => t.uri === splitPath[0]);
    if (info?.items) {
      splitPath.shift();
      let uri = splitPath.join('/');
      const result = getMenuInfo(uri, info?.items);
      const resultName = [...result.name];
      resultName.unshift(info.name);
      const resultUri = [...result.uri];
      resultUri.unshift(info.uri);
      const resultPath = [...result.path];
      resultPath.unshift(info.uri);
      return { menus: result.menus, name: resultName, uri: resultUri, path: resultPath };
    }
    return { menus: menus, name: [info?.name], uri: [info?.uri], path: [] };
  };

  useEffect(async () => {
    const result = getMenuInfo(location.pathname, navMenuItems);
    const length = result?.name.length - 1;
    await setMenuItems(result?.name.length > 3 ? result?.menus : [{ name: result.name[length], uri: result.uri[length] }]);
    await setNavPath(result.path.join('/'));
    await setBreadCrumbs(result?.name || []);
  }, [location.pathname]);

  useEffect(() => {
    if (location.state == null && location.pathname.indexOf('detail') === -1) {
      setValue(0);
    }
  }, [location.state]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mr: 2,
        ml: 2
      }}
    >
      <Tabs value={value} onChange={handleChange}>
        {menuItems.map((item, idx) => (
          <Tab key={`tab-${idx}`} label={item.name} wrapped onClick={handleClickLink(`/${navPath}/${item.uri}`)} />
        ))}
      </Tabs>
      <Box sx={{ flex: '1 1 auto' }} />
      <Stack sx={{ mb: 1 }}>
        <Breadcrumbs sx={{ mt: 2 }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadCrumbs.map((text, idx) => (
            <Typography key={`ty-${idx}`}>{text}</Typography>
          ))}
        </Breadcrumbs>
      </Stack>

      {/* {checkDetail ? (
        <>
          <Box sx={{ flex: '1 1 auto' }} />
          <Stack sx={{ mb: 1 }}>
            <Breadcrumbs sx={{ mt: 2 }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {checkDetail.items.map((text) => {
                return (
                  <Typography key={0} color="text.primary">
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          </Stack>
        </>
      ) : (
        <></>
      )} */}
    </Box>
  );
}
