import React, { useRef, useState, useContext } from "react";
import { useForm } from "react-hook-form";

import AuthContext from "router/AuthContext";

import Button from "components/Inputs/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "assets/img/dohwa.png";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.dohwa.co.kr/">
        DOHWA ENGINEERING
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function LogIn() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      id: "",
      password: "",
    },
  });

  let auth = useContext(AuthContext);

  let userPwInput = useRef(null);

  const signIn = (data) => {
    const userid = data.id;
    const userpw = data.password;
    auth.signin({ userid, userpw }, (result) => {
      if (result?.status === 401) {
        userPwInput.current.focus();
      }
    });
  };

  const onSubmit = async (data) => {
    await signIn(data);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="img" src={logo} sx={{ m: 2, p: 2, height: 80 }} />
          <Typography component="h1" variant="h5">
            소규모공공시설관리시스템 V1.0
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="id"
              label="ID"
              name="id"
              autoFocus
              error={!!errors.id}
              helperText={errors.id && errors.id.message}
              {...register("id", {
                required: "ID를 입력해주세요.",
                minLength: { value: 5, message: "ID는 5자 이상입니다." },
                maxLength: { value: 20, message: "ID는 20자 이하입니다." },
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="비밀번호"
              type="password"
              id="password"
              onKeyPress={handleKeyPress}
              inputRef={userPwInput}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...register("password", {
                required: "비밀번호를 입력해주세요.",
                maxLength: { value: 20, message: "패스워드는 20자 이하입니다." },
              })}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              로그인
            </Button>

            {/* <Button fullWidth variant="contained" color={'success'} onClick={handleJoin}>
              회원가입
            </Button> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
