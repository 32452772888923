import React, { useEffect, useState, useContext } from 'react';
import axios from 'utils/api.js';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { Paper, Grid, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Button from 'components/Inputs/Button';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';

import { ResponsiveGrid, ResponsiveGridRow, ResponsiveGridColumn, MobileSubTitle, ControlledAccordion, ControlledAccordionsContainer } from 'components/mobiles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const evlRsltItems = [
  { checkIdx: 1, text: '1.도로폭 협소(교행불가 등)' },
  { checkIdx: 2, text: '2.선형불량(심한 곡선, 시야 미확보 등)' },
  { checkIdx: 3, text: '3.비탈면의 안전시설(난간 등) 유∙무' },
  { checkIdx: 4, text: '4.지반침하 여부' },
  { checkIdx: 5, text: '5.포장상태 불량 및 파손 여부' },
  { checkIdx: 6, text: '6.비포장으로 인한 피해여부' },
  { checkIdx: 7, text: '7.노면의 요철 여부' },
  { checkIdx: 8, text: '8.구조물 파손으로 인한 피해여부' },
  { checkIdx: 9, text: '9.배수로 통수능 부족 여부' },
  { checkIdx: 10, text: '10.도로의 입지에 따른 위험성' }
];

export default function SafeEvlResult(props) {
  const { open, title, facInfo, onClose } = props;

  const [datas, setDatas] = useState({});
  const [rows, setRows] = useState([]);

  const handleClose = () => {
    onClose();
  };

  const getSafeEvl = async () => {
    try {
      const { fcType, prjId, fcCode } = facInfo;
      const response = await axios.get(`/api/safe/evl`, { params: { fcType, prjId, fcCode } });
      if (response.data) {
        setRows([...response.data]);
      }
    } catch (e) {
      alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  const getSafeEvlRslt = async () => {
    const { fcType, prjId, fcCode, evlRsltId } = facInfo;
    if (!evlRsltId) return;
    try {
      const response = await axios.get(`/api/safe/evl-rslt/${evlRsltId}`, { params: { fcType, prjId, fcCode } });
      if (response.data) {
        setDatas(response.data);
      }
    } catch (e) {
      alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  /**
   * 시설물의 후보지 선정하기
   * @param {*} e
   */
  const handleClickCandidate = async (e) => {
    const { prjId, fcCode, id } = facInfo;
    try {
      const flagCand = e.target.value;
      const params = { prjId, fcCode, flagCand, id };
      const response = await axios.patch(`/api/safe/evl-rslt-cand`, params);
      if (response.data) {
        getSafeEvlRslt();
      }
    } catch (e) {
      console.error(e);
      alert('후보지를 선정할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  /**
   * 안전점검평가 최종 완료
   * @param {*} end
   */
  const handleClickCompleteSafe = async (e) => {
    try {
      if (!facInfo?.evlRsltId) {
        alert('안전점검 평가를 진행하세요.');
        return;
      }
      if (window.confirm('안전점검 평가를 완료하면 이후 점검 내용 수정이 불가능합니다. 완료하시겠습니까?')) {
        const str = localStorage.getItem('user');
        const json = JSON.parse(str);
        const response = await axios.patch(`/api/safe/evl-rslt/${datas.id}`, { flagEvl: '1', cmpltId: json.userid });
        if (response.data) {
          setDatas(response.data);
          onClose({ type: 'evlResult', datas: response.data });
        }
      }
    } catch (e) {
      console.error(e);
      alert('평가를 완료할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  useEffect(() => {
    getSafeEvl();
    getSafeEvlRslt();
  }, [facInfo]);

  const isCompleteEvlRslt = () => {
    if (!datas?.id) return false;
    const uninput = evlRsltItems.filter((row, idx) => {
      const grade = datas?.[`grade${row.checkIdx}`];
      const score = datas?.[`score${row.checkIdx}`];
      if (grade && score) return true;
      else return false;
    });
    return uninput.length === evlRsltItems.length;
  };

  const selectedRow = (part) => rows.find((t) => t.part === part);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} scroll={'paper'}>
      <DialogTitle sx={{ backgroundColor: '#CCA63D' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: 400, color: 'white' }} variant="h6">
            {title}
            <span style={{ fontSize: '0.9rem' }}> {facInfo?.flagEvl === '0' ? '(평가완료)' : '(미리보기)'} </span>
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
          <Stack direction="row" spacing={1}>
            <Button autoFocus color="primary" onClick={handleClose}>
              닫기
            </Button>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 0 }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <MobileSubTitle title="시설물 상세정보" />
          <ResponsiveGridRow
            data={[
              { id: 'name', label: `${facInfo.fcTypeNm}명`, component: facInfo.name }
              // { id: 'addr', label: '시점부', component: facInfo.addr },
              // { id: 'addrMid', label: '중점부', component: facInfo.addrMid },
              // { id: 'addrEnd', label: '종점부', component: facInfo.addrEnd }
            ]}
          />
          <Box sx={{ m: 2 }}></Box>
          <MobileSubTitle title="도로구조 결과" />
          <TableContainer>
            <Table>
              {/* <colgroup>
                <col width="15%" />
                <col width="15%" />
                <col width="25%" />
                <col width="15%" />
                <col width="25%" />
              </colgroup> */}
              <TableBody>
                <TableRow>
                  <TableCell th rowSpan={5}>
                    도로폭(m)
                  </TableCell>
                  <TableCell>시점부</TableCell>
                  <TableCell> {selectedRow('start')?.roadWidth || '-'}</TableCell>
                  <TableCell align="center" rowSpan="5">
                    {datas?.widthAvg || '-'}
                  </TableCell>
                  <TableCell align="center" rowSpan="5">
                    {datas?.widthAvg > 1 ? '만족' : '불만족'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>시점-중앙</TableCell>
                  <TableCell> {selectedRow('start-mid')?.roadWidth || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>중앙부</TableCell>
                  <TableCell> {selectedRow('mid')?.roadWidth || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>중앙-종점</TableCell>
                  <TableCell> {selectedRow('mid-end')?.roadWidth || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>종점부</TableCell>
                  <TableCell> {selectedRow('end')?.roadWidth || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="caption" align="left">
            * 마을진입로는 자연·인위적으로 생긴 마을을 연결하거나 마을안의 공공용 도로로 평균폭 3.0m 이상인 것을 말한다.
          </Typography>
          <Box sx={{ m: 2 }}></Box>

          <MobileSubTitle title="현장조사 결과" />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} align="center">
                    점검항목
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    상세평가
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    평가결과
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">시점부</TableCell>
                  <TableCell align="center">중앙부</TableCell>
                  <TableCell align="center">종점부</TableCell>
                  <TableCell align="center">상태등급</TableCell>
                  <TableCell align="center">점수</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evlRsltItems?.map((row, idx) => {
                  const checkIdx = row.checkIdx;
                  const start = selectedRow('start');
                  const mid = selectedRow('mid');
                  const end = selectedRow('end');
                  return (
                    <>
                      <TableRow key={`row-${idx}`}>
                        <TableCell rowSpan="2">{row.text}</TableCell>
                        <TableCell align="center">{start?.[`grade${checkIdx}`] || '-'}</TableCell>
                        <TableCell align="center">{mid?.[`grade${checkIdx}`] || '-'} </TableCell>
                        <TableCell align="center">{end?.[`grade${checkIdx}`] || '-'} </TableCell>
                        <TableCell align="center" rowSpan="2">
                          {datas?.[`grade${checkIdx}`] || '-'}
                        </TableCell>
                        <TableCell align="center" rowSpan="2">
                          {datas?.[`score${checkIdx}`] || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">{start?.[`score${checkIdx}`] || '-'}</TableCell>
                        <TableCell align="center">{mid?.[`score${checkIdx}`] || '-'}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid #e0e0e0' }}>
                          {end?.[`score${checkIdx}`] || '-'}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
                <TableRow>
                  <TableCell align="center" component="th">
                    평가점수
                  </TableCell>
                  <TableCell align="center" component="th" colSpan="3">
                    {datas?.totResult}
                  </TableCell>
                  <TableCell align="center" component="th">
                    합계
                  </TableCell>
                  <TableCell align="center" component="th">
                    {datas?.totScore}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="column" sx={{ width: '100%', ml: 1 }} spacing={1}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button fullWidth color={datas?.flagCand === 'N' ? 'error' : 'default'} startIcon={<ClearIcon />} value="N" onClick={handleClickCandidate}>
              후보지 제외
            </Button>
            <Button fullWidth color={datas?.flagCand === 'Y' ? 'success' : 'default'} startIcon={<CheckIcon />} value="Y" onClick={handleClickCandidate}>
              후보지 선정
            </Button>
          </Stack>
          {isCompleteEvlRslt() && datas?.flagEvl === '0' && (
            <Button fullWidth onClick={handleClickCompleteSafe}>
              안전점검평가 완료하기
            </Button>
          )}
          {isCompleteEvlRslt() && datas?.flagEvl === '0' && (
            <Tooltip
              title={
                <div>
                  현장조사(안전점검평가) 항목을 모두 입력해야 완료할 수 있습니다. <br />
                  평가 항목을 확인하세요.
                </div>
              }
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: '100%',
                    fontSize: '0.9rem'
                  }
                }
              }}
            >
              <ErrorIcon size="small" color="action" sx={{ position: 'absolute', bottom: 13, right: 13 }} />
            </Tooltip>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
