import React, { useEffect, useState } from 'react';
import axios from 'utils/api.js';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, MenuItem, DialogTitle, DialogContent } from '@mui/material';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import Button from 'components/Inputs/Button';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import { ResponsiveGrid, ResponsiveGridRow, ResponsiveGridColumn, MobileSubTitle, ControlledAccordion, ControlledAccordionsContainer } from 'components/mobiles';
import { MbRadioGroup, MbRadio, MbDropzoneImage } from 'components/mobiles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SafeEvlPicture(props) {
  const { open, title, facInfo, onClose } = props;

  const [files, setFiles] = useState({});

  const handleClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const handleDropImg = ({ file, id }) => {
    setFiles({ ...files, [id]: file });
  };

  const handleSave = async (e) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      let params = new FormData();
      params.append('fcCode', facInfo?.fcCode);
      params.append('fcType', facInfo?.fcType);
      params.append('prjId', facInfo?.prjId);
      Object.keys(files).forEach((key) => {
        if (!files[key]?.file) params.append('deleteFileIds', key);
      });
      if (files?.startPanorama) params.append(`startPanorama`, files?.startPanorama?.file);
      if (files?.startPoint) params.append(`startPoint`, files?.startPoint?.file);
      if (files?.midPanorama) params.append(`midPanorama`, files?.midPanorama?.file);
      if (files?.midPoint) params.append(`midPoint`, files?.midPoint?.file);
      if (files?.endPanorama) params.append(`endPanorama`, files?.endPanorama?.file);
      if (files?.endPoint) params.append(`endPoint`, files?.endPoint?.file);

      const response = await axios.post(`/api/safe/evl-m-picture`, params, config);
      if (response.data) {
        setFiles({});
        alert('저장이 완료되었습니다.');
      }
    } catch (e) {
      alert('데이터를 저장할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} scroll={'paper'}>
      <DialogTitle sx={{ backgroundColor: '#CCA63D' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1, fontWeight: 400, color: 'white' }} variant="h6">
            {title}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button autoFocus color="primary" onClick={handleClose}>
              닫기
            </Button>
            <Button color="primary" onClick={handleSave}>
              저장
            </Button>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 0 }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <MobileSubTitle title="시설물 상세정보" />
          <ResponsiveGridRow
            data={[
              { id: 'name', label: `${facInfo.fcTypeNm}명`, component: facInfo.name },
              { id: 'addr', label: '시점부', component: facInfo.addr },
              { id: 'addrMid', label: '중점부', component: facInfo.addrMid },
              { id: 'addrEnd', label: '종점부', component: facInfo.addrEnd }
            ]}
          />
          <Box sx={{ m: 2 }}></Box>
          <MobileSubTitle title="시점부" />
          <ResponsiveGrid direction="column">
            <ResponsiveGridColumn
              id="startPanorama"
              label="전경사진"
              component={<MbDropzoneImage id="startPanorama" isUpdate={files?.startPanorama} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/startPanorama`} onAcceptedImage={handleDropImg} />}
            />
            <ResponsiveGridColumn
              id="startPoint"
              label="현황"
              component={<MbDropzoneImage id="startPoint" isUpdate={files?.startPoint} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/startPoint`} onAcceptedImage={handleDropImg} />}
            />
          </ResponsiveGrid>
          <Box sx={{ m: 2 }}></Box>
          <MobileSubTitle title="중점부" />
          <ResponsiveGrid direction="column">
            <ResponsiveGridColumn
              id="midPanorama"
              label="전경사진"
              component={<MbDropzoneImage id="midPanorama" isUpdate={files?.midPanorama} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/midPanorama`} onAcceptedImage={handleDropImg} />}
            />
            <ResponsiveGridColumn
              id="midPoint"
              label="현황"
              component={<MbDropzoneImage id="midPoint" isUpdate={files?.midPoint} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/midPoint`} onAcceptedImage={handleDropImg} />}
            />
          </ResponsiveGrid>
          <Box sx={{ m: 2 }}></Box>
          <MobileSubTitle title="종점부" />
          <ResponsiveGrid direction="column">
            <ResponsiveGridColumn
              id="endPanorama"
              label="전경사진"
              component={<MbDropzoneImage id="endPanorama" isUpdate={files?.endPanorama} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/endPanorama`} onAcceptedImage={handleDropImg} />}
            />
            <ResponsiveGridColumn
              id="endPoint"
              label="현황"
              component={<MbDropzoneImage id="endPoint" isUpdate={files?.endPoint} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/endPoint`} onAcceptedImage={handleDropImg} />}
            />
          </ResponsiveGrid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
