import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Button from 'components/Inputs/Button';
import Paper from '@mui/material/Paper';

import { button } from 'assets/jss/spf-react';

const ScoreItem = styled(Paper, { shouldForwardProp: (prop) => prop !== 'score' })(({ theme, score }) => ({
  backgroundColor: score ? button.colors.secondary : button.colors.default,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#FFFFFF',
  width: '40px'
}));

export default function PopoverSpfRadio(props) {
  const { anchorEl, onClose, selected, onChangeScore, ...rest } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'spf-score-popover' : undefined;
  const valId = selected?.valId;
  const valValue = selected?.valValue;
  const scoreId = selected?.scoreId;
  const value0 = ['0', '1', '2', '3'];
  const value1 = ['4', '5', '6'];
  const value2 = ['7', '8', '9', '10'];

  const [score, setScore] = useState('');

  const handleClickScore = (val) => (e) => {
    if (onChangeScore) {
      onChangeScore({ valId, valValue, scoreId, scoreValue: val });
    }
    setScore(val);
  };

  useEffect(() => {
    if (selected?.scoreValue) {
      setScore(selected.scoreValue);
    }
  }, []);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      {...rest}
    >
      {valValue === undefined && '입력데이터 없음'}
      {valValue === '0' && (
        <Stack direction="row" spacing={1} sx={{ p: 1 }}>
          {value0.map((val, idx) => (
            <ScoreItem score={score === val} onClick={handleClickScore(val)}>
              {val}
            </ScoreItem>
          ))}
        </Stack>
      )}
      {valValue === '1' && (
        <Stack direction="row" spacing={1} sx={{ p: 1 }}>
          {value1.map((val, idx) => (
            <ScoreItem score={score === val} onClick={handleClickScore(val)}>
              {val}
            </ScoreItem>
          ))}
        </Stack>
      )}
      {valValue === '2' && (
        <Stack direction="row" spacing={1} sx={{ p: 1 }}>
          {value2.map((val, idx) => (
            <ScoreItem score={score === val} onClick={handleClickScore(val)}>
              {val}
            </ScoreItem>
          ))}
        </Stack>
      )}
    </Popover>
  );
}
