import React from "react";
import { Paper, Typography, Divider, Box } from "@mui/material";

const MobileTitle = (props) => {
  const { title, children, ...rest } = props;

  return (
    <Paper elevation={0} square>
      {children}
      <Box align="center" color="#004442" fontFamily="Roboto" lineHeight={2.2} fontWeight="bold" textTransform="uppercase">
        {title}
      </Box>
      <Divider />
    </Paper>
  );
};

export default MobileTitle;
