import React, { useEffect, useState } from 'react';
import axios from 'utils/api.js';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, MenuItem, DialogTitle, DialogContent } from '@mui/material';
import Button from 'components/Inputs/Button';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import { ResponsiveGrid, ResponsiveGridRow, ResponsiveGridColumn, MobileSubTitle, ControlledAccordion, ControlledAccordionsContainer } from 'components/mobiles';
import { MbRadioGroup, MbRadio, MbDropzoneImage } from 'components/mobiles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const opinionEtc = `· 위험시설 지정 여부 : 지정 (OOOO년)/ 지정해제 (OOOO년) / 미지정
· 시점부 : 법정도로/ 마을진입로/ 농로
· 종점부 : 법정도로/ 마을진입로/ 농로/ 주거지/ 사유지 담장
· 시설물 제원 : 시점 2.5m, 중앙부 2.5m, 종점부 2.5m, 연장 200m
· 도로형식 : 콘크리트 포장/ 아스팔트 / 비포장
· 선형 : 양호/ 보통/ 불량
· 포장 : 양호/ 보통/ 불량
· 특이사항 : 위험요인인 인접한 하천이 (있으며,없으며), 주택 및 마을과는(인접/이격)되어 있음
· 주민의견 : 없음/ 있으면 상세내용
· 공사시공성 : 양호 / 보통 / 시급`;

export default function SafeEvl(props) {
  const { open, title, facInfo, onClose, getFacilityInfo } = props;

  const [datas, setDatas] = useState({ ...facInfo, opinionEtc });
  const [files, setFiles] = useState({});
  const [partOptions, setPartOptions] = useState('start');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});

  const handleClickpartOptions = (event) => {
    setPartOptions(event.target.value);
  };

  const handleChange = (e) => {
    setDatas({ ...datas, [e.target.id]: e.target.value });
  };

  const handleChangeRadio = (e) => {
    setDatas({ ...datas, [e.target.name]: e.target.value });
  };

  const handleClickRadioScore = (e) => {
    const valId = e.target.name;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const handleChangeScore = (data) => {
    const scoreId = data.scoreId.indexOf('score9') > -1 ? 'score9' : data.scoreId;
    setDatas({ ...datas, [data.valId]: data.valValue, [scoreId]: data.scoreValue });
  };

  const handleClose = (e) => {
    if (onClose) {
      onClose();
    }
  };

  /**
   * 시설물 안전점검 평가화면 - 시설정보 + 안전점검 평가 항목
   * @param {*} data
   */
  const getSafeEvlInfo = async () => {
    try {
      const { fcType, prjId, fcCode } = facInfo;
      const params = { fcType, prjId, fcCode, part: partOptions };
      const response = await axios.get(`/api/safe/evl/${params?.part}`, { params });
      if (response.data) {
        setDatas(response.data);
      } else {
        setDatas({});
      }
    } catch (e) {
      alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  const handleDropImg = ({ file, id }) => {
    setFiles({ ...files, [id]: file });
  };

  const handleSave = async (e) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      let params = new FormData();
      params.append('fcCode', facInfo?.fcCode);
      params.append('fcType', facInfo?.fcType);
      params.append('prjId', facInfo?.prjId);
      params.append('part', partOptions);
      params.append('datas', JSON.stringify(datas));

      Object.keys(files).forEach((key) => {
        if (!files[key]?.file) params.append('deleteFileIds', key);
      });
      if (files?.panorama) params.append(`panorama`, files?.panorama?.file);
      if (files?.point) params.append(`point`, files?.point?.file);
      if (files?.side) params.append(`side`, files?.side?.file);
      if (datas?.evlRsltId) params.append('evlRsltId', datas.evlRsltId);

      const response = await axios.post(`/api/safe/evl-m`, params, config);
      if (response.data) {
        getFacilityInfo();
        setFiles({});
        alert('저장이 완료되었습니다.');
      }
    } catch (e) {
      alert('데이터를 저장할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  useEffect(() => {
    if (partOptions) getSafeEvlInfo();
  }, [partOptions]);

  const scoreButtonOpen = Boolean(anchorEl);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} scroll={'paper'}>
      <DialogTitle sx={{ backgroundColor: '#CCA63D' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1, fontWeight: 400, color: 'white' }} variant="h6">
            {title}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button autoFocus color="primary" onClick={handleClose}>
              닫기
            </Button>
            <Button color="primary" onClick={handleSave}>
              저장
            </Button>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 0 }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <MobileSubTitle title="시설물 상세정보" />
          <ResponsiveGridRow
            data={[
              { id: 'name', label: `${facInfo.fcTypeNm}명`, component: facInfo.name },
              { id: 'addr', label: '시점부', component: facInfo.addr },
              { id: 'addrMid', label: '중점부', component: facInfo.addrMid },
              { id: 'addrEnd', label: '종점부', component: facInfo.addrEnd }
            ]}
          />
          <Box sx={{ m: 2 }}></Box>
          <MobileSubTitle title="현장조사 정보" />
          <Select value={partOptions} onChange={handleClickpartOptions} fullWidth size="small">
            <MenuItem value="start">시점부</MenuItem>
            <MenuItem value="start-mid">시점-중앙</MenuItem>
            <MenuItem value="mid">중앙부</MenuItem>
            <MenuItem value="mid-end">중앙-종점</MenuItem>
            <MenuItem value="end">종점부</MenuItem>
          </Select>
          <Box sx={{ m: 2 }}></Box>
          {(partOptions === 'start' || partOptions === 'mid' || partOptions === 'end') && <FormComponentCmm datas={datas} handleChange={handleChange} handleChangeRadio={handleChangeRadio} handleClickRadioScore={handleClickRadioScore} />}
          {(partOptions === 'start-mid' || partOptions === 'mid-end') && <FormComponentSpfE datas={datas} handleChange={handleChange} />}
          <Box sx={{ m: 2 }}></Box>
          {(partOptions === 'start' || partOptions === 'mid' || partOptions === 'end') && (
            <>
              <MobileSubTitle title="사진 첨부" />
              <ResponsiveGrid direction="column">
                <ResponsiveGridColumn
                  id="panorama"
                  label="전경사진"
                  component={<MbDropzoneImage id="panorama" isUpdate={files?.panorama} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/${partOptions}Panorama`} onAcceptedImage={handleDropImg} />}
                />
                <ResponsiveGridColumn
                  id="point"
                  label="시점부현황"
                  component={<MbDropzoneImage id="point" isUpdate={files?.point} imageUrl={`/api/safe/img/${facInfo?.prjId}/${facInfo?.fcCode}/${partOptions}Point`} onAcceptedImage={handleDropImg} />}
                />
              </ResponsiveGrid>
            </>
          )}
        </Paper>

        {scoreButtonOpen && (
          <PopoverSpfRadio
            open={scoreButtonOpen}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            selected={selectedScore}
            onChangeScore={handleChangeScore}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

const FormComponentSpfE = (props) => {
  const { datas, handleChange } = props;
  return (
    <ControlledAccordionsContainer>
      <ControlledAccordion title="도로구조문제" explain="도로폭·포장종류">
        <ResponsiveGridRow
          data={[
            {
              id: 'roadWidth',
              label: `① 도로폭·포장종류`,
              components: [
                {
                  id: 'roadWidth',
                  label: `도로폭`,
                  component: (
                    <TextField
                      id="roadWidth"
                      value={datas?.roadWidth || ''}
                      placeholder={'입력해주세요'}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>
                      }}
                      type="number"
                      // required
                      // error
                      inputProps={{ maxLength: 4 }}
                    />
                  )
                }
              ]
            }
          ]}
        />
      </ControlledAccordion>
    </ControlledAccordionsContainer>
  );
};

const FormComponentCmm = (props) => {
  const { datas, handleChange, handleChangeRadio, handleClickRadioScore } = props;
  return (
    <ControlledAccordionsContainer>
      <ControlledAccordion title="도로구조문제" explain="도로폭·포장종류, 선형불량, 안전시설(난간), 지반침하">
        <ResponsiveGridRow
          data={[
            {
              id: 'roadWidth',
              label: `① 도로폭·포장종류`,
              components: [
                {
                  id: 'roadWidth',
                  label: `도로폭`,
                  component: (
                    <TextField
                      id="roadWidth"
                      value={datas?.roadWidth || ''}
                      placeholder={'입력해주세요'}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>
                      }}
                      type="number"
                      // required
                      // error
                      inputProps={{ maxLength: 4 }}
                    />
                  )
                },
                {
                  id: 'val1_1',
                  label: '교행',
                  component: (
                    <MbRadioGroup row name="val1_1" value={datas?.val1_1 || ''} onChange={handleChangeRadio}>
                      <MbRadio value="0" label="가능" />
                      <MbRadio value="2" label="불가" />
                    </MbRadioGroup>
                  )
                },
                {
                  id: 'val1_2',
                  label: `포장종류 `,
                  component: (
                    <MbRadioGroup row name="val1_2" value={datas?.val1_2 || ''} onChange={handleChangeRadio}>
                      <MbRadio value="0" label="콘크리트" />
                      <MbRadio value="1" label="아스콘" />
                      <MbRadio value="2" label="비포장" />
                    </MbRadioGroup>
                  )
                }
              ]
            },
            {
              id: 'val2',
              label: `② 선형불량 `,
              isScore: true,
              score: datas?.score2,
              component: (
                <MbRadioGroup row name="val2" value={datas?.val2 || ''} onChange={handleClickRadioScore}>
                  <MbRadio value="0" label="선형양호" />
                  <MbRadio value="1" label="선형불량·안전시설 대처가능" />
                  <MbRadio value="2" label="선형불량·사고위험" />
                </MbRadioGroup>
              )
            },
            {
              id: 'val3',
              label: `③ 안전시설(난간)`,
              isScore: true,
              score: datas?.score3,
              component: (
                <MbRadioGroup row name="val3" value={datas?.val3 || ''} onChange={handleClickRadioScore}>
                  <MbRadio value="0" label="적합설치" />
                  <MbRadio value="1" label="노후화·부분파손" />
                  <MbRadio value="2" label="없음 또는 심한파손" />
                </MbRadioGroup>
              )
            },
            {
              id: 'val4',
              label: `④ 지반침하`,
              isScore: true,
              score: datas?.score4,
              component: (
                <MbRadioGroup row name="val4" value={datas?.val4 || ''} onChange={handleClickRadioScore}>
                  <MbRadio value="0" label="없음" />
                  <MbRadio value="1" label="일부진행" />
                  <MbRadio value="2" label="있음" />
                </MbRadioGroup>
              )
            }
          ]}
        />
      </ControlledAccordion>
      <ControlledAccordion title="기능성" explain="포장상태, 비포장 구간">
        <ResponsiveGridRow
          data={[
            {
              id: 'val5',
              label: `⑤ 포장상태`,
              isScore: true,
              score: datas?.score5,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val5" value={datas?.val5 || ''} color="secondary">
                  <MbRadio value="0" label="양호" />
                  <MbRadio value="1" label="균열·포트홀·통행양호" />
                  <MbRadio value="2" label="파손·통행지장" />
                </MbRadioGroup>
              )
            },
            {
              id: 'val6',
              label: '⑥ 비포장 구간',
              isScore: true,
              score: datas?.score6,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val6" value={datas?.val6 || ''} color="secondary">
                  <MbRadio value="0" label="없음·양호·통행적음" />
                  <MbRadio value="1" label="차량통행 많음" />
                  <MbRadio value="2" label="차량통행 많음·민원 많음" />
                </MbRadioGroup>
              )
            },
            {
              id: 'val7',
              label: '⑦ 노면요철',
              isScore: true,
              score: datas?.score7,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val7" value={datas?.val7 || ''} color="secondary">
                  <MbRadio value="0" label="없음" />
                  <MbRadio value="1" label="소성변형·통행지장 없음" />
                  <MbRadio value="2" label="소성변형·통행지장" />
                </MbRadioGroup>
              )
            }
          ]}
        />
      </ControlledAccordion>
      <ControlledAccordion title="시설물 파손" explain="도로·부대시설, 배수로">
        <ResponsiveGridRow
          data={[
            {
              id: 'val8',
              label: `⑧ 도로·부대시설`,
              isScore: true,
              score: datas?.score8,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val8" value={datas?.val8 || ''} color="secondary">
                  <MbRadio value="0" label="양호" />
                  <MbRadio value="1" label="일부파손·안전지장 없음" />
                  <MbRadio value="2" label="심한파손" />
                </MbRadioGroup>
              )
            },
            {
              id: 'val9',
              label: '⑨ 배수로',
              isScore: true,
              score: datas?.score9,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val9" value={datas?.val9 || ''} color="secondary">
                  <MbRadio value="0" label="양호" />
                  <MbRadio value="1" label="일부파손·막힘" />
                  <MbRadio value="2" label="없음 또는 심한파손" />{' '}
                </MbRadioGroup>
              )
            }
          ]}
        />
      </ControlledAccordion>
      <ControlledAccordion title="기타">
        <ResponsiveGridRow
          data={[
            {
              id: 'val10',
              label: `⑩ 마을진입로입지`,
              isScore: true,
              score: datas?.score10,
              component: (
                <MbRadioGroup onChange={handleClickRadioScore} name="val10" value={datas?.val10 || ''} color="secondary">
                  <MbRadio value="0" label="안전지역 입지" />
                  <MbRadio value="1" label="절벽·민가입지·통행량 적음" />
                  <MbRadio value="2" label="위험지역 입지·통행량 많음" />
                </MbRadioGroup>
              )
            }
          ]}
        />
        <TextField id="opinionEtc" multiline rows={10} fullWidth value={datas?.opinionEtc || opinionEtc} onChange={handleChange} inputProps={{ maxLength: 3000 }} sx={{ mt: 1 }} />
      </ControlledAccordion>
    </ControlledAccordionsContainer>
  );
};
