import React, { useCallback, useEffect, useState } from 'react';
import axios from 'utils/api.js';

import { Box, ContentsBox, SearchBox, ToolbarArea } from 'components/Layout';
import { DataGrid, DataGridToolbar, GridCellModes, GridRowModes, useGridApiContext } from 'components/DataDisplay/DataGrid';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import TextField from 'components/Inputs/TextField';
import Button from 'components/Inputs/Button';
import Stack from '@mui/material/Stack';
import Checkbox from 'components/Inputs/Checkbox';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import StatSafe from './StatSafe';
import StatResult from './StatResult';
import StatPerformance from './StatPerformance';

const TextFieldEditInput = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.updateRows([{ id, [field]: event.target.value }]);
  };

  useEffect(() => {
    apiRef.current.updateRows([{ id, [field]: value }]);
  }, []);

  return <TextField value={value || ''} onChange={handleChange} fullWidth />;
};
const renderTextFieldEditInput = (params) => {
  return <TextFieldEditInput {...params} />;
};
const CheckboxEditInput = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
    apiRef.current.updateRows([{ id, [field]: event.target.checked }]);
  };

  useEffect(() => {
    apiRef.current.updateRows([{ id, [field]: value }]);
  }, []);

  return <Checkbox checked={value} onChange={handleChange} fullWidth />;
};
const renderCheckboxEditInput = (params) => {
  return <CheckboxEditInput {...params} />;
};

export default function StatMng() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState({});
  const [anchor, setAnchor] = useState(false);
  const [srchDatas, setSrchDatas] = useState({ status: '' });
  const [datas, setDatas] = useState({});

  const getDataList = async () => {
    const response = await axios.get(`/api/prj`, { params: { ...srchDatas } });
    if (response.data) {
      setRows([...response.data]);
    }
  };

  const handleRowFocus = useCallback((event) => {
    const id = event.currentTarget.dataset.id;
    setSelectedRowId(id);
  }, []);

  const handleClickSrchBtn = (e) => {
    if (srchDatas?.projName?.length >= 100) {
      alert('프로젝트명은 100자까지 가능합니다.');
      return;
    }
    getDataList();
  };

  const handleSrchChange = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  const handleSrchChangeStatus = (e) => {
    setSrchDatas({ ...srchDatas, status: e.target.value });
  };

  const handleClickMovePage = (params) => (e) => {
    // setSelectedRow({ ...params.row });
    // navigate(`/stat/detail`, { state: { data: params.row } });
    setDatas(params.row);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: false, headerAlign: 'center', align: 'center', hide: true },
    { field: 'rowNumber', headerName: 'No', width: 100, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: 'select',
      headerName: '통계보기',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => (
        <button style={{ color: 'blue' }} onClick={handleClickMovePage(params)}>
          통계보기
        </button>
      )
    },
    {
      field: 'projName',
      headerName: '프로젝트명',
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true
    },
    {
      field: 'startDate',
      headerName: '시작일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value
    },
    {
      field: 'endDate',
      headerName: '종료일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      valueGetter: (params) => params.value
    },
    {
      field: 'registerId',
      headerName: '등록자',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value
    },
    {
      field: 'createdAt',
      headerName: '등록일',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => moment(params.value).format('LLL')
    }
  ];

  useEffect(() => {
    getDataList();
  }, []);

  console.log(selectedRowId);

  return (
    <Box sx={{ display: 'block' }}>
      <SearchBox component="form" noValidate autoComplete="off">
        <TextField margin="dense" name="projName" label="프로젝트명" id="projName" onChange={handleSrchChange} />
        <ToggleButtonGroup color={'primary'} value={srchDatas.status} exclusive onChange={handleSrchChangeStatus} size="small">
          <ToggleButton value="">전체</ToggleButton>
          <ToggleButton value="ready">준비</ToggleButton>
          <ToggleButton value="ing">진행중</ToggleButton>
          <ToggleButton value="end">마감</ToggleButton>
        </ToggleButtonGroup>
        <Button align="right" variant="contained" color="primary" onClick={handleClickSrchBtn}>
          검색
        </Button>
      </SearchBox>
      <Box sx={{ mt: 4 }}></Box>
      <ContentsBox>
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={40}
          height={300}
          editMode="row"
          rowModesModel={rowModesModel}
          componentsProps={{
            toolbar: {
              rows,
              setRows,
              rowModesModel,
              setRowModesModel,
              selectedRowId,
              setSelectedRowId,
              getDataList,
              selectionModel
            },
            row: {
              onFocus: handleRowFocus
            }
          }}
          experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
        />
      </ContentsBox>
      {datas?.id && <StatDetail pData={datas} />}
    </Box>
  );
}

const StatDetail = (props) => {
  const { pData } = props;
  const [tab, setTab] = useState('safe');
  const handleChange = (e, type) => {
    setTab(type);
  };
  console.log(pData);

  return (
    <Box sx={{ display: 'block' }}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab key={`tab-safe`} label={`시설물별 안전점검`} wrapped value={'safe'} />
        <Tab key={`tab-result`} label={`안전점검 결과`} wrapped value={'result'} />
        <Tab key={`tab-performance`} label={`안전점검 추진실적`} wrapped value={'performance'} />
      </Tabs>
      <ContentsBox>
        {tab === 'safe' && <StatSafe pData={pData} />}
        {tab === 'result' && <StatResult pData={pData} />}
        {tab === 'performance' && <StatPerformance pData={pData} />}
      </ContentsBox>
    </Box>
  );
};
