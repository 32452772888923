import React from 'react';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Checkbox from 'components/Inputs/Checkbox';
import Stack from '@mui/material/Stack';

const itemsVal1 = [
  { label: '양호', value: '0' },
  { label: '노후·일부파손', value: '1' },
  { label: '파손', value: '2' }
];
const itemsVal2 = [
  { label: '양호', value: '0' },
  { label: '노후', value: '1' },
  { label: '없음·파손', value: '2' }
];
const itemsVal3 = [
  { label: '양호', value: '0' },
  { label: '없음·일부파손', value: '1' },
  { label: '파손·세굴', value: '2' }
];
const itemsVal4 = [
  { label: '양호', value: '0' },
  { label: '옹벽 없으나 양호', value: '1' },
  { label: '파손·세굴', value: '2' }
];
const itemsVal5 = [
  { label: '양호·과거 월류피해 없음', value: '0' },
  { label: '과거 월류피해·보 높음·제방 낮음', value: '2' }
];
const itemsVal6C = [
  { label: '양호', value: '0' },
  { label: '일부퇴적·노후·취수가능', value: '1' },
  { label: '심한퇴적·취수문 파손·취수불가', value: '2' }
];
const itemsVal6D = [
  { label: '양호', value: '0' },
  { label: '비교적 양호', value: '1' },
  { label: '본체파괴·퇴적·기능상실', value: '2' }
];
const itemsVal7 = [
  { label: '양호', value: '0' },
  { label: '비교적 양호', value: '1' },
  { label: '본체파괴·퇴적·기능상실', value: '2' }
];
const itemsVal8 = [
  { label: '양호', value: '0' },
  { label: '비교적 양호', value: '1' },
  { label: '본체파괴·퇴적·기능상실', value: '2' }
];
const itemsVal9 = [
  { label: '양호', value: '0' },
  { label: '작동가능·일부노출', value: '1' },
  { label: '작동불가', value: '2' }
];
const itemsVal10 = [
  { label: '거의 없음', value: '0' },
  { label: '일부 침식·퇴적', value: '1' },
  { label: '침식·퇴적·취수영향', value: '2' }
];

export default function SafeEvlReportA(props) {
  const { pData, pRows, evlRslt } = props;

  const point = pRows.find((t) => t.part === 'point');
  console.log(point);
  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box sx={{ m: 2, display: 'block' }}>
        <ToolbarArea title="현장조사"></ToolbarArea>
        <TableContainer component={'div'} sx={{ width: '1200px' }}>
          <Table>
            <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">조사항목</TableCell>
                <TableCell align="center" colSPan={4}>
                  항목별 평가
                </TableCell>
                <TableCell align="center">점수</TableCell>
              </TableRow>
            </TableHead>
            {/* 제원측정 */}
            <TableBody>
              <TableRow>
                <TableCell component="th">제원측정</TableCell>
                <TableCell rowSpan={3} component="th">
                  보 낙차공 제원
                </TableCell>
                <TableCell align="center" component="th">
                  연장
                </TableCell>
                <TableCell align="center">{point?.cLength}</TableCell>
                <TableCell align="center" component="th">
                  높이
                </TableCell>
                <TableCell align="center">{point?.cHeight}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
            </TableBody>

            {/* 구조적안전성 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={4} component="th">
                  구조적안전성
                </TableCell>
                <TableCell component="th">①본체 노후·파손</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal1} value={point?.val1} />
                </TableCell>
                <TableCell align="center">{point?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">②제방연결 옹벽 파손</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal2} value={point?.val2} />
                </TableCell>
                <TableCell align="center">{point?.score2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">③바닥보호공 파손</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal3} value={point?.val3} />
                </TableCell>
                <TableCell align="center">{point?.score3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">④제방 접합부 세굴</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal4} value={point?.val4} />
                </TableCell>
                <TableCell align="center">{point?.score4}</TableCell>
              </TableRow>
            </TableBody>
            {/* 3.기능성 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={4} component="th">
                  기능성
                </TableCell>
                <TableCell component="th">{point?.cFcType === 'C' ? `⑥ 취수기능상실` : `⑥ 낙차기능상실`}</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal5} value={point?.val6} />
                </TableCell>
                <TableCell align="center">{point?.score5}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑥여유고 부족·월류</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal6C} value={point?.val6} />
                </TableCell>
                <TableCell align="center">{point?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑦ 기계장치파손</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal7} value={point?.val7} />
                </TableCell>
                <TableCell align="center">{point?.score7}</TableCell>
              </TableRow>
            </TableBody>
            {/* 4.침식·퇴적 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={2} component="th">
                  침식·퇴적
                </TableCell>
                <TableCell component="th">⑧ 상하류부 침식·퇴적</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal8} value={point?.val8} />
                </TableCell>
                <TableCell align="center">{point?.score8}</TableCell>
              </TableRow>
            </TableBody>
            {/* 4.기타 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={2} component="th">
                  기타
                </TableCell>
                <TableCell component="th"> ⑨ 철거민원·사회,환경영향</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal9} value={point?.val9} />
                </TableCell>
                <TableCell align="center">{point?.score9}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th"> ⑩ 어도 유무·파손</TableCell>
                <TableCell colSPan={4}>
                  <CheckItems items={itemsVal10} value={point?.val10} />
                </TableCell>
                <TableCell align="center">{point?.score10}</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell align="center" component="th" colSPan={2}>
                  계
                </TableCell>
                <TableCell align="center" colSPan={4}>
                  <span style={evlRslt?.totResult === '양호' ? totResultStyle : {}}>양호</span> / <span style={evlRslt?.totResult === '보통' ? totResultStyle : {}}>보통</span> /{' '}
                  <span style={evlRslt?.totResult === '불량' ? totResultStyle : {}}>나쁨</span>
                </TableCell>
                <TableCell align="center">{evlRslt?.totScore}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
const totResultStyle = { width: '100px', height: '85px', borderRadius: '50%', border: '1px solid' };

const CheckItems = (props) => {
  const { value, items } = props;

  return (
    <Stack direction="row" sx={{ mx: 2 }} spacing={1}>
      {items.map((item) => {
        return <Checkbox label={item?.label} checked={item.value === value} disabled />;
      })}
    </Stack>
  );
};
