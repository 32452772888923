import React, { useContext, useEffect, useState } from 'react';
import axios from 'utils/api.js';
import { authUtils } from 'utils/authUtils.js';

import Select from 'components/Inputs/Select';
import Button from 'components/Inputs/Button';
import TextField from 'components/Inputs/TextField';
import { Box, ContentsBox, SearchBox, ToolbarArea } from 'components/Layout';
import { DataGrid, DataGridToolbar, GridRowModes, GridCellModes, useGridApiContext, useGridApiRef } from 'components/DataDisplay/DataGrid';
import Stack from '@mui/material/Stack';
import { mapUtil } from 'map/MapUtils';

const fcTypeItems = [
  { label: '--전체--', value: '' },
  { label: '세천', value: 'A' },
  { label: '소교량', value: 'B' },
  { label: '보 및 낙차공', value: 'C' },
  { label: '농로', value: 'E' },
  { label: '마을진입로', value: 'F' }
];

const safeResultItems = [
  { label: '--전체--', value: '' },
  { label: '불량', value: '불량' },
  { label: '보통', value: '보통' },
  { label: '양호', value: '양호' }
];

export default function MapFacilitySearch(props) {
  const { pData, map, setSelectedFeature } = props;

  const [rows, setRows] = useState([]);
  const [srchDatas, setSrchDatas] = useState({});

  const handleChange = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  const handleClickSrchBtn = (e) => {
    if (validationName() || validationFcCode() || validationInspNm()) {
      return;
    }
    getDataList();
  };

  const handleChangeSelect = (e) => {
    setSrchDatas({ ...srchDatas, [e.target.id]: e.target.value });
  };

  const getDataList = async () => {
    const params = { ...srchDatas, prjId: pData.id };
    const response = await axios.get(`/api/safe/insp`, { params });
    if (response.data) {
      setRows(response.data);
    }
  };

  const handleClickRow = (params) => {
    const row = params.row;
    map.getView().setCenter([row.geomX, row.geomY]);
    map.getView().setZoom(16);

    const source = mapUtil.getLayerSourceId(map, `fc_${row.fcType.toLowerCase()}`);
    source.getFeatures().forEach((ft) => {
      if (ft.get('id') === row.id) {
        const editSource = mapUtil.getLayerSourceId(map, `editLayer`);
        editSource.clear();
        ft.setProperties({ ...ft.getProperties(), length: mapUtil.getFeatureLength(ft) || 0 });
        setSelectedFeature(ft);
      }
    });
  };

  const validationFcCode = () => {
    let check = /^[A-Za-z0-9]{0,200}$/;
    return !check.test(srchDatas?.fcCode);
  };

  const validationName = () => {
    let check = /^[ㄱ-ㅎ가-힣A-Za-z0-9d-]{0,200}$/;
    return !check.test(srchDatas?.name);
  };

  const validationInspNm = () => {
    let check = /^[ㄱ-ㅎ가-힣a-zA-Z|]{0,20}$/;
    return !check.test(srchDatas?.inspNm);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: false, headerAlign: 'center', align: 'center', hide: true },
    // { field: 'rowNumber', headerName: 'No', width: 100, sortable: false,headerAlign: 'center', align: 'center' },
    {
      field: 'fcType',
      headerName: '시설타입',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      hide: true
    },
    {
      field: 'fcTypeNm',
      headerName: '시설타입',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'fcCode',
      headerName: '관리번호',
      width: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'name',
      headerName: '명칭',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'totRslt',
      headerName: '위험도평가',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center'
    }
    // {
    //   field: 'name',
    //   headerName: '안전점검일',
    //   width: 120,
    //   sortable: false,
    //   align: 'center'
    // },
    // {
    //   field: 'name',
    //   headerName: '점검원',
    //   width: 120,
    //   sortable: false,
    //   align: 'center'
    // }
  ];

  useEffect(() => {
    getDataList();
  }, [pData]);

  return (
    <Box sx={{ display: 'block', height: '100%' }}>
      <ContentsBox sx={{ my: 0 }}>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <Select id="fcType" value={srchDatas?.fcType} onChange={handleChangeSelect}>
            {fcTypeItems.map((item, idx) => (
              <option key={`fc-type-${idx}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
          <Select id="safeResult" value={srchDatas?.safeResult} onChange={handleChangeSelect}>
            {safeResultItems.map((item, idx) => (
              <option key={`fc-safe-result-${idx}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <TextField label="시설관리번호" id="fcCode" onChange={handleChange} value={srchDatas?.fcCode} error={validationFcCode()} helperText={validationFcCode() ? '시설관리번호는 영문, 숫자만 200자까지 가능합니다.' : ''} />
          <TextField label="시설명칭" id="name" onChange={handleChange} value={srchDatas?.name} error={validationName()} helperText={validationName() ? '시설명칭은 한글, 영문, 숫자, 특수문자(-)만 200자까지 가능합니다.' : ''} />
          {authUtils.useIsManager() && (
            <TextField label="점검원 이름" id="inspNm" onChange={handleChange} value={srchDatas?.inspNm} error={validationInspNm()} helperText={validationInspNm() ? '점검원 이름은 한글 또는 영문만 20자까지 가능합니다.' : ''} />
          )}
        </Stack>
        <Button variant="contained" color="primary" onClick={handleClickSrchBtn} fullWidth>
          검색
        </Button>
      </ContentsBox>
      <ContentsBox>
        <DataGrid height={600} rowHeight={30} rows={rows} columns={columns} onRowClick={handleClickRow} />
      </ContentsBox>
    </Box>
  );
}
