import React, { useEffect, useState } from 'react';
import { BarChart, PieChart } from 'components/Chart';
import Grid from '@mui/material/Grid';
import axios from 'utils/api.js';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';

export default function StatSafe(props) {
  const { pData } = props;
  const labels = ['세천', '소교량', '보 및 낙차공', '농로', '마을진입로'];
  const [series, setSeries] = useState([44, 55, 13, 43, 22]);

  const categories = ['세천', '소교량', '보및낙차공', '농로', '마을진입로'];
  const [rows, setRows] = useState([]);

  const getData = async () => {
    const response = await axios.get(`/api/stat/perf/${pData.id}`);
    if (response) {
      setRows(response.data);
    }
  };

  const chartSeries = [
    {
      name: '미점검',
      data: rows.map((t) => t.no_evl_cnt) || [] || []
    },
    {
      name: '점검완료',
      data: rows.map((t) => t.evl_cnt) || [] || []
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: true,
      // enabledOnSeries: [1]
      // style: {
      //   colors: '#000000'
      // }
      background: {
        enabled: true,
        foreColor: '#000000',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#000000',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      }
    },
    xaxis: {
      categories: categories
    },
    yaxis: {
      title: {
        text: '안전점검관리 현황'
      }
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  };

  useEffect(() => {
    if (pData?.id) getData();
  }, [pData]);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center">
      {/* <Grid item xs={4}>
        <PieChart name="안전점검 결과 통계" width="100%" type="pie" labels={labels} series={series} />
      </Grid> */}
      <Grid item xs={12}>
        <Chart options={options} series={chartSeries} type="bar" width="100%" height="400" />
        {/* <BarChart name="소규모 공공시설별 안전점검 결과 차트" width={1050} type="bar" categories={categories} data={dataA} /> */}
      </Grid>
    </Grid>
  );
}
