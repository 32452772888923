import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import axios from 'utils/api.js';
import Button from 'components/Inputs/Button';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Feedback/Dialog';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Paper from '@mui/material/Paper';

import SafeEvlA from './SafeEvlA';
import SafeEvlB from './SafeEvlB';
import SafeEvlC from './SafeEvlC';
import SafeEvlE from './SafeEvlE';
import SafeEvlF from './SafeEvlF';
import SafeEvlRslt from './SafeEvlRslt';
import SafeEvlReportA from './SafeEvlReportA';
import SafeEvlReportB from './SafeEvlReportB';
import SafeEvlReportC from './SafeEvlReportC';
import SafeEvlReportE from './SafeEvlReportE';
import SafeEvlReportF from './SafeEvlReportF';

export default function SafeEvlSpf(props) {
  const { selectedMenu, open, setOpenSafeEvl, geoFeature } = props;

  const [visibleAction, setVisibleAction] = useState(true);
  const [datas, setDatas] = useState({});
  const [rows, setRows] = useState([]);
  const [insertRows, setInsertRows] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [evlRslt, setEvlRslt] = useState({});

  const handleClose = (e) => {
    if (isEdit) {
      if (window.confirm('변경 데이터가 있습니다. 저장하지 않고 진행하시겠습니까?')) {
        setOpenSafeEvl((prev) => !prev);
        setIsEdit(false);
      }
    } else {
      setOpenSafeEvl((prev) => !prev);
    }
  };

  const handleSave = async (e) => {
    try {
      if (insertRows.length > 0) {
        let params = new FormData();
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        };
        params.append('id', datas?.id);
        params.append('fcCode', datas?.fcCode);
        params.append('fcType', datas?.fcType);
        params.append('prjId', datas?.prjId);

        insertRows.map((item, idx) => {
          if (!!item.panorama) {
            params.append(`${item.part}Panorama`, item.panorama);
            item.panorama = null;
            item.panoramaPre = null;
            insertRows.slice(0, idx - 1).concat(item, insertRows.slice(idx + 1));
          }
          if (!!item.point) {
            params.append(`${item.part}Point`, item.point);
            item.point = null;
            item.pointPre = null;
            insertRows.slice(0, idx - 1).concat(item, insertRows.slice(idx + 1));
          }
          if (!!item.side) {
            params.append(`${item.part}Side`, item.side);
            item.side = null;
            item.sidePre = null;
            insertRows.slice(0, idx - 1).concat(item, insertRows.slice(idx + 1));
          }
        });

        params.append('dataList', JSON.stringify(insertRows));

        if (datas?.evlRsltId) params.append('evlRsltId', datas.evlRsltId);

        const response = await axios.post(`/api/safe/evl`, params, config);
        if (response.data) {
          getSafeEvlInfo();
          setIsEdit(false);
          alert('저장이 완료되었습니다.');
        }
      }
    } catch (e) {
      alert('데이터를 저장할 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  /**
   * 시설물 안전점검 평가화면 - 시설정보 + 안전점검 평가 항목
   * @param {*} data
   */
  const getSafeEvlInfo = async () => {
    try {
      const params = {
        id: geoFeature.get('id'),
        fcCode: geoFeature.get('fc_code'),
        fcType: geoFeature.get('fc_type').toLowerCase(),
        prjId: geoFeature.get('prj_id')
      };

      const response = await axios.get(`/api/safe/insp/${params.id}`, { params: params });
      if (response.data) {
        setDatas(response.data);
      }

      const responseRows = await axios.get(`/api/safe/evl`, { params: params });
      if (responseRows.data) {
        setRows([...responseRows.data]);
        setInsertRows(responseRows.data);
      }
    } catch (e) {
      alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  /**
   * 안전점검결과 결과보기 상세조회
   * @returns
   */
  const getSafeEvlRsltInfo = async () => {
    try {
      const { evlRsltId, prjId, fcCode, fcType } = datas;
      const params = { id: evlRsltId, prjId, fcCode, fcType };
      const response = await axios.get(`/api/safe/evl-rslt/${evlRsltId}`, { params });
      if (response.data) {
        setEvlRslt(response.data);
      }
    } catch (e) {
      // alert('데이터를 불러올 수 없습니다. 관리자에게 문의하세요.');
    }
  };

  useEffect(() => {
    if (geoFeature.get('id')) getSafeEvlInfo();
  }, [geoFeature]);

  useEffect(() => {
    if (selectedMenu === 'safetyReport') {
      getSafeEvlRsltInfo();
    }
  }, [selectedMenu]);

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" aria-labelledby="draggable-dialog-title" maxWidth="xl">
      <DialogTitle id="draggable-dialog-title">안전점검 평가 [{datas.fcTypeNm}]</DialogTitle>
      <DialogContent dividers sx={{ py: 0 }}>
        <Paper elevation={3}>
          <ContentsBox sx={{ m: 2, p: 2 }}>
            {/* <ToolbarArea title="시설 정보"></ToolbarArea> */}
            <TableContainer component={'div'}>
              <Table>
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" component="th">
                      {datas.fcTypeNm}명
                    </TableCell>
                    <TableCell align="center">{datas.name}</TableCell>
                    <TableCell align="center" component="th">
                      시점부주소
                    </TableCell>
                    <TableCell align="center">{datas.addr}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" component="th">
                      중점부주소
                    </TableCell>
                    <TableCell align="center">{datas.addrMid}</TableCell>
                    <TableCell align="center" component="th">
                      종점부주소
                    </TableCell>
                    <TableCell align="center">{datas.addrEnd}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ContentsBox>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          {selectedMenu === 'safetyEvl' &&
            (datas?.fcType === 'A' ? (
              <SafeEvlA pData={datas} pRows={rows} insertRows={insertRows} setInsertRows={setInsertRows} setVisibleAction={setVisibleAction} isEdit={isEdit} setIsEdit={setIsEdit} />
            ) : datas?.fcType === 'B' ? (
              <SafeEvlB pData={datas} pRows={rows} insertRows={insertRows} setInsertRows={setInsertRows} setVisibleAction={setVisibleAction} isEdit={isEdit} setIsEdit={setIsEdit} />
            ) : datas?.fcType === 'C' ? (
              <SafeEvlC pData={datas} pRows={rows} insertRows={insertRows} setInsertRows={setInsertRows} setVisibleAction={setVisibleAction} isEdit={isEdit} setIsEdit={setIsEdit} />
            ) : datas?.fcType === 'E' ? (
              <SafeEvlE pData={datas} pRows={rows} insertRows={insertRows} setInsertRows={setInsertRows} setVisibleAction={setVisibleAction} isEdit={isEdit} setIsEdit={setIsEdit} />
            ) : datas?.fcType === 'F' ? (
              <SafeEvlF pData={datas} pRows={rows} insertRows={insertRows} setInsertRows={setInsertRows} setVisibleAction={setVisibleAction} isEdit={isEdit} setIsEdit={setIsEdit} />
            ) : (
              '없는 시설물'
            ))}
          {selectedMenu === 'safetyRslt' && <SafeEvlRslt pData={datas} pRows={rows} selectedMenu={selectedMenu} />}
          {selectedMenu === 'safetyReport' &&
            (datas?.fcType === 'A' ? (
              <SafeEvlReportA pData={datas} pRows={rows} evlRslt={evlRslt} />
            ) : datas?.fcType === 'B' ? (
              <SafeEvlReportB pData={datas} pRows={rows} evlRslt={evlRslt} />
            ) : datas?.fcType === 'C' ? (
              <SafeEvlReportC pData={datas} pRows={rows} evlRslt={evlRslt} />
            ) : datas?.fcType === 'E' ? (
              <SafeEvlReportE pData={datas} pRows={rows} evlRslt={evlRslt} />
            ) : datas?.fcType === 'F' ? (
              <SafeEvlReportF pData={datas} pRows={rows} evlRslt={evlRslt} />
            ) : (
              '없는 시설물'
            ))}
        </Paper>
      </DialogContent>
      <DialogActions>
        {selectedMenu === 'safetyEvl' && visibleAction && (
          <Button onClick={handleSave} fullWidth disabled={insertRows.length === 0}>
            저장
          </Button>
        )}
        <Button onClick={handleClose} color="default" fullWidth>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
