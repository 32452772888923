import React, { cloneElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { colors } from 'assets/jss/spf-react';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(even)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  '& th': {
    backgroundColor: theme.palette.grey[200]
  }
  // hide last border
  // '&:last-child td, &:last-child th': {
  //     border: 0,
  // },
}));

const style = {
  '&:hover': {
    backgroundColor: '#EBF7FF'
  }
};

export default function CustomTableRow(props) {
  const { children, hover, sx, ...rest } = props;

  return (
    <StyledTableRow sx={hover ? { ...style, ...sx } : sx} {...rest}>
      {/* {children} */}
      {children.length > 0
        ? children.map((item, idx) => {
            return item;
          })
        : children}
    </StyledTableRow>
  );
}
