import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default function StyledDropzone(props) {
  const { accept, onAcceptedFiles, id, maxFiles = 0, maxSize = 5242880 } = props;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    // isDragActive,
    isDragAccept,
    isDragReject,
    inputRef
  } = useDropzone({ accept: accept, maxSize: maxSize, maxFiles: maxFiles });
  const [isDragActive, setIsDragActive] = useState(false);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path} style={{ color: 'red' }}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => {
            let message = null;
            if (e.code === 'file-invalid-type') {
              message = `[${e.message.replace('File type must be ', '')}] 파일만 업로드 가능합니다.`;
            } else if (e.code === 'file-too-large') {
              message = '5M 이하 파일만 업로드 가능합니다.';
            } else if (e.code === 'too-many-files') {
              message = '이미지는 하나씩만 올려주세요.';
            }
            return <li key={e.code}>{message || e.message}</li>;
          })}
        </ul>
        <Divider />
      </li>
    );
  });

  const handleMouseHover = (e) => {
    setIsDragActive(!isDragActive);
  };

  useEffect(() => {
    onAcceptedFiles(acceptedFiles, id);
  }, [acceptedFiles]);

  return (
    <div>
      <div></div>
      <Container onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>여기에 파일을 드래그 앤 드롭하거나 클릭하여 파일을 선택하십시오.</p>
      </Container>
      <aside>
        <ul>{files}</ul>
      </aside>
      <aside>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </div>
  );
}
