import React, { cloneElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

const StyledDialog = styled(Dialog)(({ theme }) => ({}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomDialog(props) {
  const { children, ...rest } = props;

  return (
    <StyledDialog maxWidth="md" PaperComponent={PaperComponent} {...rest}>
      {children}
    </StyledDialog>
  );
}
