import React, { useContext, useEffect, useState } from 'react';

import axios from 'utils/api.js';

import { useGridApiContext } from 'components/DataDisplay/DataGrid';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import Button from 'components/Inputs/Button';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MapIcon from '@mui/icons-material/Map';
import ExploreIcon from '@mui/icons-material/Explore';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import TextField from 'components/Inputs/TextField';
import Checkbox from 'components/Inputs/Checkbox';
import Dropzone from 'components/Inputs/Dropzone';

import MapContext from 'map/MapContext';
import Map from 'map';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { initMap, mapUtil, mapEdit } from 'map/MapUtils.js';
import TableHead from '@mui/material/TableHead';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(204,166,61) 0%,rgb(204,166,61) 50%,rgb(204,166,61) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(204,166,61) 0%,rgb(204,166,61) 50%,rgb(204,166,61) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(204,166,61) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(204,166,61) 0%, rgb(204,166,61) 50%, rgb(204,166,61) 100%)'
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <ExploreIcon />,
    2: <SettingsIcon />,
    3: <MapIcon />,
    4: <GroupAddIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const TextFieldEditInput = (props) => {
  const { id, value, field, row, onChange } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    apiRef.current.updateRows([{ id, [field]: event.target.value }]);
    if (onChange) {
      onChange({ id, [field]: event.target.value });
    }
  };

  useEffect(() => {
    apiRef.current.updateRows([{ id, [field]: value }]);
  }, []);

  return <TextField value={row?.useYn ? value : ''} disabled={!row?.useYn} onChange={handleChange} fullWidth />;
};
const renderTextFieldEditInput = (params) => {
  return <TextFieldEditInput {...params} />;
};

const CheckboxEditInput = (props) => {
  const { id, value, field, onChange } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    apiRef.current.updateRows([{ id, [field]: event.target.checked }]);
    if (onChange) {
      onChange({ id, [field]: event.target.checked });
    }
  };

  useEffect(() => {
    apiRef.current.updateRows([{ id, [field]: value }]);
  }, []);

  return <Checkbox checked={value} onChange={handleChange} fullWidth />;
};
const renderCheckboxEditInput = (params) => {
  return <CheckboxEditInput {...params} />;
};

const steps = ['행정구역 SHP', '소규모공공시설 SHP', '배경지도 SHP', '점검원 지정'];

export default function ProjectMngDetail(props) {
  const { onClose, pData } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [projectInfo, setProjectInfo] = useState({});
  const [shpFiles, setShpFiles] = useState({});
  const [geoDatas, setGeoDatas] = useState({});
  const [inspectors, setInspectors] = useState([]);
  const [posibleNextStep, setPosibleNextStep] = useState(false);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      completeAnalys();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleClickSave = (e) => {};

  /**
   * 프로젝트관리 - 신규 등록
   * @param {*} e
   */
  const completeAnalys = async () => {
    try {
      if (window.confirm(`프로젝트를 준비 완료로 처리 하시겠습니까?`)) {
        const response = await axios.patch(`/api/prj/ing/${pData.id}`);
        if (response.data) {
          onClose();
        }
      }
    } catch (e) {
      alert('LoS 분석 실패했습니다. 관리자에게 문의하세요.');
    }
  };

  return (
    <Box sx={{ display: 'block', width: 1200, mt: 12, mx: 4 }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mx: 14 }}>
        <Button color="default" disabled={activeStep === 0} onClick={handleBack}>
          뒤로
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button color="default" onClick={onClose} sx={{ mr: 2 }}>
          목록으로
        </Button>
        <Button color="default" disabled={!((activeStep !== 0 || activeStep !== 1) && posibleNextStep)} onClick={handleNext}>
          {activeStep === steps.length - 1 ? '준비 완료' : '다음'}
        </Button>
      </Box>
      <Box sx={{ display: 'block', mx: 8 }}>
        {activeStep === 0 && (
          <Map>
            <ProjectShpUpload1 key={1} pData={pData} shpFiles={shpFiles} setShpFiles={setShpFiles} geoDatas={geoDatas} setGeoDatas={setGeoDatas} setPosibleNextStep={setPosibleNextStep} />
          </Map>
        )}
        {activeStep === 1 && (
          <Map>
            <ProjectShpUpload2 key={2} pData={pData} shpFiles={shpFiles} setShpFiles={setShpFiles} geoDatas={geoDatas} setGeoDatas={setGeoDatas} setPosibleNextStep={setPosibleNextStep} />
          </Map>
        )}
        {activeStep === 2 && (
          <Map>
            <ProjectShpUpload3 key={3} pData={pData} shpFiles={shpFiles} setShpFiles={setShpFiles} geoDatas={geoDatas} setGeoDatas={setGeoDatas} />
          </Map>
        )}
        {activeStep === 3 && <ProjectInspector pData={pData} />}
      </Box>
    </Box>
  );
}

const filesUpload = async (acceptedFiles, id, layer, type) => {
  let formData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  acceptedFiles.forEach((file) => {
    formData.append(`files`, file);
  });

  if (!!type) {
    formData.append('type', type);
  }

  return await axios.post(`/api/prj/file/${layer}/${id}`, formData, config);
};

const getFiles = (id, layer, type) => {
  return axios.get(`/api/prj/file/${layer}${!!type ? '/' + type : ''}/${id}`);
};

const deleteFile = (file) => {
  if (window.confirm('이 파일로 등록 된 Shape도 함께 삭제 됩니다. 삭제하시겠습니까?')) {
    return axios.delete(`/api/prj/file/${file.id}`, { data: file });
  }
};

const fileDown = async (file) => {
  await axios
    .get(`/api/prj/file/down/${file.prjId}`, { params: file, responseType: 'blob' })
    .then((response) => {
      let blob = new Blob([response.data], {
        type: <response className="headers"></response>
      });
      let name = getFileName(decodeURIComponent(response.headers['content-disposition']));
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, name);
      } else {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_self';
        if (name) link.download = name;
        link.click();
        window.URL.revokeObjectURL(link);
      }
    })
    .catch((error) => {
      alert('다운로드 실패!');
    });
};

const getFileName = (contentDisposition) => {
  let fileName = contentDisposition
    .split(';')
    .filter((el) => el.indexOf('filename') > -1)
    .map((ele) => ele.replace(/"/g, '').split('=')[1]);
  return fileName[0];
};

const getGeoCount = (id, layer) => {
  return axios.get(`/api/prj/shp2geojson/${layer}/${id}`);
};

const ProjectShpUpload1 = (props) => {
  const { map } = useContext(MapContext);
  const { pData, geoDatas, setGeoDatas, setPosibleNextStep } = props;
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [layerType, setLayerType] = useState('sgg');

  const shp2geojson = async (tmpShpFiles, uploadType, groupId) => {
    const formData = new FormData();
    formData.append('prjId', pData.id);
    formData.append('groupId', groupId);
    tmpShpFiles.forEach((file) => {
      formData.append(`files`, file);
    });
    await axios
      .post(`/api/prj/shp2geojson/${uploadType}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (!!res.data) {
          setGeoDatas({ ...geoDatas, [uploadType]: res.data });
          setOpen(false);
          getFitLayerExtent();
        } else {
          alert('등록할 데이터가 없습니다. SHP 파일을 확인해주세요.');
        }
      })
      .catch((err) => {
        console.log('failed: ', err);
      });
  };

  const handleDropShpfile = (acceptedFiles, id) => {
    if (acceptedFiles.length > 0) {
      setLayerType(id);
    }
  };

  const getAreaFile = async () => {
    await getFiles(pData.id, 'area').then((result) => {
      setFiles(result.data);
      setPosibleNextStep(result.data.length > 0);
    });
  };

  const handleUploadFiles = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      try {
        setOpen(true);
        await filesUpload(acceptedFiles, pData.id, 'area').then(async (response) => {
          await getAreaFile();
          if (response) {
            shp2geojson(acceptedFiles, layerType, response.data.groupId);
          }
          return response;
        });
      } catch (err) {
        setOpen(false);
        alert('파일 업로드를 실패했습니다.');
      }
    }
  };

  const handleDeleteFile = (file) => {
    deleteFile(file)
      .then(async (response) => {
        alert(response.data.message);
        await getAreaFile();
        await getGeoCount();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const getFitLayerExtent = async () => {
    try {
      const response = await axios.get(`/api/prj/layer-fit`, { params: { prjId: pData.id, layerNm: 'sgg' } });
      if (response) {
        const { xMin, yMin, xMax, yMax } = response.data;
        mapUtil.fitExtent(map, 'sgg', [xMin, yMin, xMax, yMax]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (map && pData?.id) {
      initMap(map, {
        prjId: pData.id
      });
      mapUtil.isVisibleLayerType(map, 'spf', false);
      mapUtil.isVisibleLayerType(map, 'background', false);
      getFitLayerExtent();
    }
    return function cleanup() {
      if (map) {
      }
    };
  }, [map]);

  useEffect(async () => {
    await getAreaFile();
  }, []);

  return (
    <Box sx={{ display: 'block' }}>
      <ContentsBox>
        {pData.status === 'ing' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 진행 중인 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        {pData.status === 'end' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 마감 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        <ToolbarArea title="행정구역 SHP [ .shp, .shx, .dbf ]"></ToolbarArea>
        {pData.status !== 'ing' && pData.status !== 'end' && <Dropzone accept={{ 'geo/shp': ['.shp', '.shx', '.dbf'] }} onAcceptedFiles={handleDropShpfile} onUploadFiles={handleUploadFiles} id="sgg" maxFiles={3} />}
      </ContentsBox>
      {files.length > 0 && (
        <ContentsBox>
          <aside>
            <TableContainer component={'div'}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">파일명</TableCell>
                    <TableCell align="center">다운로드</TableCell>
                    <TableCell align="center">삭제</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, idx) => (
                    <TableRow key={file.id}>
                      <TableCell align="center">{file.fileName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={`다운로드`}>
                          <IconButton aria-label="down" onClick={() => fileDown(file)} sx={{ p: 0, ml: 1 }}>
                            <DownloadIcon fontSize="small" sx={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {pData.status !== 'ing' && pData.status !== 'end' && idx % 3 == 0 && (
                        <TableCell align="center" rowSpan="3">
                          <Tooltip title={`삭제`}>
                            <IconButton onClick={() => handleDeleteFile(file)} sx={{ p: 0 }}>
                              <DeleteForeverIcon fontSize="small" sx={{ color: 'black' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </aside>
        </ContentsBox>
      )}
      <ContentsBox>
        <div id="map" style={{ width: `100%`, height: 500 }}></div>
      </ContentsBox>
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const ProjectShpUpload2 = (props) => {
  const { map } = useContext(MapContext);
  const { pData, geoDatas, setGeoDatas, setPosibleNextStep } = props;
  const [files, setFiles] = useState([]);
  const [type, setType] = useState('A');
  const [layerType, setLayerType] = useState('spf');
  const [open, setOpen] = useState(false);
  const [factCount, setFactCount] = useState({ A: 0, B: 0, C: 0, E: 0, F: 0 });

  const refreshByFcType = () => {
    let layerId = '';
    if (type === 'A' || type === 'a') layerId = 'fc_a';
    if (type === 'B' || type === 'b') layerId = 'fc_b';
    if (type === 'C' || type === 'c') layerId = 'fc_c';
    if (type === 'E' || type === 'e') layerId = 'fc_e';
    if (type === 'F' || type === 'f') layerId = 'fc_f';

    mapUtil.refreshByLayerId(map, layerId);
  };

  const shp2geojson = async (tmpShpFiles, uploadType, groupId) => {
    const formData = new FormData();
    formData.append('prjId', pData.id);
    formData.append('type', type);
    formData.append('groupId', groupId);
    tmpShpFiles.forEach((file) => {
      formData.append(`files`, file);
    });
    await axios
      .post(`/api/prj/shp2geojson/${uploadType}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (!!res.data) {
          setGeoDatas({ ...geoDatas, [uploadType]: res.data });
          getGeoCount();
          getFitLayerExtent();
        } else {
          alert('등록할 데이터가 없습니다. SHP 파일을 확인해주세요.');
        }
      })
      .catch((err) => {
        console.log('failed: ', err);
      });
  };
  const handleDropShpfile = async (acceptedFiles, id) => {
    if (acceptedFiles.length > 0) {
      setLayerType(id);
    }
  };

  const getFactFile = async () => {
    await getFiles(pData.id, 'fact', type).then((result) => {
      setFiles(result.data);
    });
  };

  const handleUploadFiles = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      try {
        setOpen(true);
        await filesUpload(acceptedFiles, pData.id, 'fact', type).then(async (response) => {
          await getFactFile();
          await checkNextStep();
          if (response) {
            const result = shp2geojson(acceptedFiles, layerType, response.data.groupId);
            setOpen(false);
          }
          return response;
        });
      } catch (err) {
        setOpen(false);
        alert('소규모공공시설 shp 파일 업로드를 실패했습니다.');
      }
    }
  };

  const handleDeleteFile = (file) => {
    deleteFile(file)
      .then(async (response) => {
        alert(response.data.message);
        await getFactFile();
        await getGeoCount();
        await checkNextStep();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const handleChange = (e, type) => {
    mapUtil.isVisibleLayerType(map, 'spf', false);
    switch (type) {
      case 'A':
        mapUtil.isVisibleLayerId(map, 'fc_a', true);
        break;
      case 'B':
        mapUtil.isVisibleLayerId(map, 'fc_b', true);
        break;
      case 'C':
        mapUtil.isVisibleLayerId(map, 'fc_c', true);
        break;
      case 'E':
        mapUtil.isVisibleLayerId(map, 'fc_e', true);
        break;
      case 'F':
        mapUtil.isVisibleLayerId(map, 'fc_f', true);
        break;
    }
    getFitLayerExtent();
    setType(type);
  };

  const checkNextStep = () => {
    getFiles(pData.id, 'fact')
      .then((res) => {
        if (res.data.length > 0) {
          setPosibleNextStep(true);
        } else {
          setPosibleNextStep(false);
        }
      })
      .catch((err) => {
        setPosibleNextStep(false);
      });
  };

  const getGeoCount = async () => {
    const layer = 'fact';
    const response = await axios.get(`/api/prj/shp2geojson/${layer}/${pData.id}`);
    if (response) {
      let tempFactCount = { A: 0, B: 0, C: 0, E: 0, F: 0 };
      response.data.forEach((itemList, idx) => {
        itemList.map(async (item, idx) => {
          tempFactCount = { ...tempFactCount, [item.fcType]: Number(item.count) };
        });
      });
      refreshByFcType();
      setFactCount(tempFactCount);
    }
  };

  const getFitLayerExtent = async () => {
    try {
      const response = await axios.get(`/api/prj/layer-fit`, { params: { prjId: pData.id, layerNm: 'sgg' } });
      if (response) {
        const { xMin, yMin, xMax, yMax } = response.data;
        mapUtil.fitExtent(map, 'sgg', [xMin, yMin, xMax, yMax]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (map && pData?.id) {
      initMap(map, {
        prjId: pData.id
      });
      mapUtil.isVisibleLayerType(map, 'spf', false);
      mapUtil.isVisibleLayerType(map, 'background', false);
      mapUtil.isVisibleLayerId(map, 'fc_a', true);
      getFitLayerExtent();
    }
    return function cleanup() {
      if (map) {
      }
    };
  }, [map]);

  useEffect(async () => {
    await getFactFile();
  }, [type]);

  useEffect(async () => {
    if (pData?.id) {
      getGeoCount();
      checkNextStep();
    }
  }, [pData]);

  return (
    <Box sx={{ display: 'block' }}>
      <ContentsBox>
        {pData.status === 'ing' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 진행 중인 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        {pData.status === 'end' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 마감 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        <ToolbarArea title="소규모공공시설 SHP [ .shp, .shx, .dbf ]"></ToolbarArea>
        <Tabs value={type} onChange={handleChange}>
          <Tab key={`tab-A`} label={`세천 (${factCount.A})`} wrapped value={'A'} />
          <Tab key={`tab-B`} label={`소교량 (${factCount.B})`} wrapped value={'B'} />
          <Tab key={`tab-C`} label={`보 및 낙차공 (${factCount.C})`} wrapped value={'C'} />
          <Tab key={`tab-E`} label={`농로 (${factCount.E})`} wrapped value={'E'} />
          <Tab key={`tab-F`} label={`마을진입로 (${factCount.F})`} wrapped value={'F'} />
        </Tabs>
        {pData.status !== 'ing' && pData.status !== 'end' && <Dropzone accept={{ 'geo/shp': ['.shp', '.shx', '.dbf'] }} onAcceptedFiles={handleDropShpfile} onUploadFiles={handleUploadFiles} id="spf" maxFiles={3} />}
      </ContentsBox>
      {files.length > 0 && (
        <ContentsBox>
          <aside>
            <TableContainer component={'div'}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">파일명</TableCell>
                    <TableCell align="center">다운로드</TableCell>
                    <TableCell align="center">삭제</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, idx) => (
                    <TableRow key={file.id}>
                      <TableCell align="center">{file.fileName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={`다운로드`}>
                          <IconButton aria-label="down" onClick={() => fileDown(file)} sx={{ p: 0, ml: 1 }}>
                            <DownloadIcon fontSize="small" sx={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {pData.status !== 'ing' && pData.status !== 'end' && idx % 3 == 0 && (
                        <TableCell align="center" rowSpan="3">
                          <Tooltip title={`삭제`}>
                            <IconButton onClick={() => handleDeleteFile(file)} sx={{ p: 0 }}>
                              <DeleteForeverIcon fontSize="small" sx={{ color: 'black' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </aside>
        </ContentsBox>
      )}
      <ContentsBox>
        <div id="map" style={{ width: `100%`, height: 500 }}></div>
      </ContentsBox>
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const ProjectShpUpload3 = (props) => {
  const { map } = useContext(MapContext);
  const { pData, geoDatas, setGeoDatas } = props;
  const [files, setFiles] = useState([]);
  const [type, setType] = useState('river');
  const [layerType, setLayerType] = useState('back');
  const [open, setOpen] = useState(false);
  const [mapCount, setMapCount] = useState({ river: 0, road: 0, land: 0, continue: 0, rain: 0 });

  const shp2geojson = async (tmpShpFiles, uploadType, groupId) => {
    const formData = new FormData();
    formData.append('prjId', pData.id);
    formData.append('type', type);
    formData.append('groupId', groupId);
    tmpShpFiles.forEach((file) => {
      formData.append(`files`, file);
    });

    await axios
      .post(`/api/prj/shp2geojson/${uploadType}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (!!res.data) {
          setGeoDatas({ ...geoDatas, [uploadType]: res.data });
          getGeoCount();
          getFitLayerExtent();
          setOpen(false);
        } else {
          alert('등록할 데이터가 없습니다. SHP 파일을 확인해주세요.');
        }
      })
      .catch((err) => {
        console.log('failed: ', err);
      });
  };

  const handleDropShpfile = (acceptedFiles, id) => {
    if (acceptedFiles.length > 0) {
      setLayerType(id);
    }
  };

  const getMapFile = async () => {
    await getFiles(pData.id, 'map', type).then((result) => {
      setFiles(result.data);
    });
  };

  const handleUploadFiles = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      try {
        setOpen(true);
        await filesUpload(acceptedFiles, pData.id, 'map', type).then(async (response) => {
          await getMapFile();
          if (response) {
            shp2geojson(acceptedFiles, layerType, response.data.groupId);
          }
          return response;
        });
      } catch (err) {
        setOpen(false);
        alert('배경지도 shp 파일 업로드를 실패했습니다.');
      }
    }
  };

  const handleDeleteFile = (file) => {
    deleteFile(file)
      .then(async (response) => {
        alert(response.data.message);
        await getMapFile();
        await getGeoCount();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const handleChange = (e, type) => {
    mapUtil.isVisibleLayerType(map, 'background', false);
    mapUtil.isVisibleLayerId(map, type, true);
    getFitLayerExtent();
    setType(type);
  };

  const getGeoCount = async () => {
    const layer = 'map';
    const response = await axios.get(`/api/prj/shp2geojson/${layer}/${pData.id}`);
    if (response) {
      let tempMapCount = { river: 0, road: 0, land: 0, continue: 0, rain: 0 };
      response.data.forEach((itemList, idx) => {
        itemList.map(async (item, idx) => {
          tempMapCount = { ...tempMapCount, [item.type]: Number(item.count) };
        });
      });
      setMapCount(tempMapCount);
    }
  };

  const getFitLayerExtent = async () => {
    try {
      const response = await axios.get(`/api/prj/layer-fit`, { params: { prjId: pData.id, layerNm: 'sgg' } });
      if (response) {
        const { xMin, yMin, xMax, yMax } = response.data;
        mapUtil.fitExtent(map, 'sgg', [xMin, yMin, xMax, yMax]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (map && pData?.id) {
      initMap(map, {
        prjId: pData.id
      });
      mapUtil.isVisibleLayerType(map, 'spf', false);
      mapUtil.isVisibleLayerType(map, 'background', false);
      mapUtil.isVisibleLayerId(map, 'river', true);
      getFitLayerExtent();
    }
    return function cleanup() {
      if (map) {
      }
    };
  }, [map]);

  useEffect(async () => {
    await getMapFile();
  }, [type]);

  useEffect(async () => {
    if (pData?.id) {
      getGeoCount();
    }
  }, [pData]);

  return (
    <Box sx={{ display: 'block' }}>
      <ContentsBox>
        {pData.status === 'ing' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 진행 중인 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        {pData.status === 'end' && (
          <Typography variant="h6" color="red">
            [안내] 프로젝트가 마감 상태이므로 점검원 지정만 설정 가능합니다.
          </Typography>
        )}
        <ToolbarArea title="배경지도 SHP [ .shp, .shx, .dbf ]"></ToolbarArea>
        <Tabs value={type} onChange={handleChange}>
          <Tab key={`tab-river`} label={`하천망도 (${mapCount.river})`} wrapped value={'river'} />
          <Tab key={`tab-road`} label={`도로망도 (${mapCount.road})`} wrapped value={'road'} />
          <Tab key={`tab-continue`} label={`연속주제도 (${mapCount.continue})`} wrapped value={'continue'} />
          <Tab key={`tab-rain`} label={`우수망도 (${mapCount.rain})`} wrapped value={'rain'} />
        </Tabs>
        {pData.status !== 'ing' && pData.status !== 'end' && <Dropzone accept={{ 'geo/shp': ['.shp', '.shx', '.dbf'] }} onAcceptedFiles={handleDropShpfile} onUploadFiles={handleUploadFiles} id="back" maxFiles={3} />}
      </ContentsBox>
      {files.length > 0 && (
        <ContentsBox>
          <aside>
            <TableContainer component={'div'}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">파일명</TableCell>
                    <TableCell align="center">다운로드</TableCell>
                    <TableCell align="center">삭제</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, idx) => (
                    <TableRow key={file.id}>
                      <TableCell align="center">{file.fileName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={`다운로드`}>
                          <IconButton aria-label="down" onClick={() => fileDown(file)} sx={{ p: 0, ml: 1 }}>
                            <DownloadIcon fontSize="small" sx={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {pData.status !== 'ing' && pData.status !== 'end' && idx % 3 == 0 && (
                        <TableCell align="center" rowSpan="3">
                          <Tooltip title={`삭제`}>
                            <IconButton onClick={() => handleDeleteFile(file)} sx={{ p: 0 }}>
                              <DeleteForeverIcon fontSize="small" sx={{ color: 'black' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </aside>
        </ContentsBox>
      )}
      <ContentsBox>
        <div id="map" style={{ width: `100%`, height: 500 }}></div>
      </ContentsBox>
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const SelectCharge = (props) => {
  const { open, handleClose, region, getRegionList } = props;
  const [charge, setCharge] = useState('');
  const [userList, setUserList] = useState([]);

  const getUserList = async () => {
    axios.get(`/api/user/charge`).then((response) => {
      if (response.data) {
        let tempList = [];
        response.data.map((data, rowIdx) => {
          let temp = {};
          temp.label = `${data.username} (${data.userid})`;
          temp.value = data.id;
          tempList.push(temp);
        });
        setUserList(tempList);
      }
    });
  };

  const chargeCreate = async () => {
    let params = {};
    params.prjId = region.prjId;
    params.regionId = region.id;
    params.userId = charge;

    return await axios.post(`/api/prj/charge`, params);
  };

  const onSubmit = () => {
    if (!!charge) {
      chargeCreate()
        .then((response) => {
          alert(response.data.message);
          getRegionList();
          handleClose();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  useEffect(async () => {
    if (open) {
      await getUserList();
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="draggable-dialog-title">{region?.regionName} 점검원 지정</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 1 }}>
            <Autocomplete
              id="requestId"
              size="small"
              label="점검원"
              sx={{ width: 500 }}
              options={userList}
              onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'input' && !!newInputValue) {
                  setCharge(newInputValue.split('(')[1].replaceAll(')', ''));
                } else {
                  setCharge('');
                }
              }}
              renderInput={(params) => <TextField {...params} label="점검원" />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" onClick={onSubmit}>
            지정
          </Button>
          <Button variant="outlined" color="success" onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ChargeList = (props) => {
  const { pData, region } = props;

  const [chargeList, setChargeList] = useState([]);

  const getChargeList = async () => {
    axios.get(`/api/prj/charge/${pData.id}/${region.id}`).then((response) => {
      if (response.data) {
        setChargeList(response.data);
      }
    });
  };

  const deleteCharge = (charge) => {
    axios
      .delete(`/api/prj/charge/${charge.id}`)
      .then(async (response) => {
        alert(response.data.message);
        await getChargeList();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(async () => {
    await getChargeList();
  }, []);

  return (
    <>
      {chargeList.map((charge, idx) => {
        return (
          <Button variant="outlined" onClick={() => deleteCharge(charge)}>
            {charge.username} ({charge.userid})
            <HighlightOffIcon sx={{ ml: 0.5 }} />
          </Button>
        );
      })}
    </>
  );
};

const ProjectInspector = (props) => {
  const { pData } = props;
  const [regionList, setRegionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState();

  const handleClickOpen = async (region) => {
    await setRegion(region);
    await setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRegionList = async () => {
    await setRegionList([]);
    axios.get(`/api/prj/region/${pData.id}`).then(async (response) => {
      await setRegionList(response.data);
    });
  };
  useEffect(async () => {
    await getRegionList();
  }, []);

  return (
    <Box sx={{ display: 'block', m: 4 }}>
      <ToolbarArea title={`점검원지정`} />
      <TableContainer component={'div'} sx={{ mb: 8 }}>
        <Table aria-label="customized table">
          <colgroup>
            <col width="10%" />
            <col width="*" />
            <col width="13%" />
          </colgroup>
          <TableBody>
            {regionList.map((region, idx) => {
              return (
                <TableRow key={region.id}>
                  <TableCell component="th" align="center">
                    {region.regionName}
                  </TableCell>
                  <TableCell align="left">
                    <ChargeList pData={pData} region={region} />
                  </TableCell>
                  <TableCell align="center">
                    <Button fullWidth variant="contained" onClick={() => handleClickOpen(region)}>
                      <PersonPinIcon sx={{ mr: 0.5 }} />
                      지정하기
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <SelectCharge open={open} handleClose={handleClose} pData={pData} region={region} getRegionList={getRegionList} />
    </Box>
  );
};
