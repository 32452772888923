import React, { useState } from 'react';
import { Box, ToolbarArea } from 'components/Layout';
import { Tab, TabPanel, Tabs } from 'components/Navigation/Tabs';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import TextField from 'components/Inputs/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Radio, RadioGroup } from 'components/Inputs/RadioButton';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import SafeEvlRslt from './SafeEvlRslt';
import DropzoneImage from '../../components/Inputs/DropzoneImage';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

let opinionEtc = `· 위험시설 지정 여부 : 지정 (OOOO년)/ 지정해제 (OOOO년) / 미지정
· 시점부 : 산지초입/ 저수지 하류/ 보호시설 직하류/ 하폭 1m 이하 / 기타 중 택1
· 종점부 : 국가하천/ 지방하천/ 소하천/ 농수로/ 우수관로 / 세천 / 저수지
· 피해이력 여부: 있음/ 없음
· 세천 제원: 연장 598m, 평균 하폭 1.3m, 제방고 0.8m
· 호안 및 옹벽 (설치/미설치) 이며, (정비구간 없음/정비 필요)
· 하폭협소 및 급축소로 인한 정비 필요 여부 : 있음 / 없음
· 특이사항 : 주택 및 마을과는 (인접/이격)되어 있으며 피해시 영향범위는 (많은/작은) 편임
· 주민의견 : 없음/ 있으면 상세내용
· 공사시급성 : 양호 / 보통 / 시급`;

const validationColumns = [
  {
    part: 'point',
    partName: '평가하기',
    columns: [
      { id: 'bLength', name: '교량제원 - 연장' },
      { id: 'bWidth', name: '교량제원 - 폭' },
      { id: 'bHeight', name: '교량제원 - 높이' },
      { id: 'riverWidthTop', name: '상하류 하폭 - 상류하폭' },
      { id: 'riverWidthPos', name: '상하류 하폭 - 교량위치하폭' },
      { id: 'riverWidthBottom', name: '상하류 하폭 - 하류하폭' },
      {
        id: 'pierType',
        name: '교량형식별 통수단면 제원 - 교량유형',
        subColumns: [
          {
            type: '0',
            columns: [
              { id: 'pierCnt', name: '교각수' },
              { id: 'pierLength', name: '경간장' }
            ]
          },
          {
            type: '1',
            columns: [
              { id: 'pierCnt', name: '교각수' },
              { id: 'pierLength', name: '경간장' }
            ]
          },
          {
            type: '2',
            columns: [
              { id: 'pierCnt', name: '암거련수' },
              { id: 'pierLength', name: '암거직경' }
            ]
          },
          {
            type: '3',
            columns: [
              { id: 'pierCnt', name: '흄관련수' },
              { id: 'pierLength', name: '흄관직경' }
            ]
          },
          {
            type: '4',
            columns: [
              {
                id: 'pierForm',
                name: '교량형식',
                subColumns: [
                  {
                    type: '0'
                  },
                  {
                    type: '1',
                    columns: [
                      { id: 'pierFormW', name: '폭' },
                      { id: 'pierFormH', name: '높이' },
                      { id: 'pierFormCnt', name: '련수' }
                    ]
                  },
                  {
                    type: '2',
                    columns: [
                      { id: 'pierFormL', name: '직경' },
                      { id: 'pierFormCnt', name: '련수' }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      { id: 'val1', name: '구조적 안정성 - 슬라브·교대·교각·파손·균열' },
      { id: 'val2', name: '구조적 안정성 - 철근노출·부식' },
      { id: 'val3', name: '구조적 안정성 - 교대·교각 세굴' },
      { id: 'val4', name: '구조적 안정성 - 노후도·구조물 안전성' },
      { id: 'val5', name: '홍수위험성 - 제방 접합부 침식' },
      { id: 'val6', name: '홍수위험성 - 여유고 부족·월류' },
      { id: 'val9', name: '기타 - 입지여건·통행량' },
      { id: 'val10', name: '기타 - 통행위험성·세월교' }
    ]
  }
];

export default function SafeEvlA(props) {
  const { pData, pRows, insertRows, setInsertRows, setVisibleAction, setIsEdit } = props;

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
    setVisibleAction(newValue === 5 ? false : true);
  };

  const handleChange = (part, data) => {
    const isPart = insertRows.find((t) => t.part === part);
    let result = [...insertRows];

    if (isPart) {
      result = insertRows.map((row) => (row.part === part ? { ...row, ...data } : row));
    } else {
      const { fcCode, fcType, prjId } = pData;
      const params = { ...data, part, fcCode, fcType, prjId };
      result.push(params);
    }
    setInsertRows(result);
    setIsEdit(true);
  };

  const point = insertRows.find((t) => t.part === 'point');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box style={{ m: 0, borderBottom: '1px solid #CCA63D' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
          <Tab label="평가하기" index={0} />
          <Tab label="평가결과 미리보기" index={1} />
        </Tabs>
      </Box>
      <Box sx={{ display: 'block', height: '550px', overflow: 'auto', overflowX: 'hidden' }}>
        <TabPanel value={valueTab} index={0}>
          <EvlInfo label="평가하기" part="point" datas={point} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <SafeEvlRslt label="평가결과 미리보기" pData={pData} pRows={pRows} validationColumns={validationColumns} selectedMenu="safetyEvl" />
        </TabPanel>
      </Box>
    </Box>
  );
}

const EvlInfo = (props) => {
  const { datas, prjId, fcCode, part, label, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleChangePier = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeRadio = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeScore = (data) => {
    if (!onChange) return;
    onChange(part, { [data.valId]: data.valValue, [data.scoreId]: data.scoreValue });
  };

  const handleClickRadioScore = (e) => {
    const valId = e.target.name;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.id]: e.target.value });
  };

  const handleDropImg = (acceptedFiles, imageType) => {
    if (!!acceptedFiles && acceptedFiles.length > 0) {
      setBackdropOpen(true);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      return new Promise((resolve) => {
        reader.onload = () => {
          eval(`onChange(part, { ${imageType + 'Pre'}: reader.result, ${imageType}: acceptedFiles[0] })`);
          resolve();
          setBackdropOpen(false);
        };
      });
    }
  };

  const open = Boolean(anchorEl);
  const checkVaild = (id) => (!datas?.[id] ? { required: true } : {});

  return (
    <Box sx={{ m: 2, display: 'block' }}>
      <ToolbarArea title="제원측정"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="16%" />
            <col width="13%" />
            <col width="15%" />
            <col width="13%" />
            <col width="15%" />
            <col width="13%" />
            <col width="15%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                교량제원
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.bLength && { required: true })}>
                연장
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="bLength"
                  value={datas?.bLength || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.bWidth && { required: true })}>
                폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="bWidth"
                  value={datas?.bWidth || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th">
                높이
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="bHeight"
                  value={datas?.bHeight || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                상하류 하폭
              </TableCell>
              <TableCell align="center" component="th" {...checkVaild('riverWidthTop')}>
                상류하폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="riverWidthTop"
                  value={datas?.riverWidthTop || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th">
                교량위치하폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="riverWidthPos"
                  value={datas?.riverWidthPos || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell align="center" component="th">
                하류하폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="riverWidthBottom"
                  value={datas?.riverWidthBottom || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" rowSpan={3}>
                교량형식별 <br />
                통수단면 제원
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.pierType && { required: true })}>
                교량유형
              </TableCell>
              <TableCell align="center" colSpan={5}>
                <RadioGroup name="pierType" value={datas?.pierType || ''} onClick={handleChangePier}>
                  <Radio value="0" label="슬래브교" />
                  <Radio value="1" label="라멘교" />
                  <Radio value="2" label="암거교" />
                  <Radio value="3" label="흄관교" />
                  <Radio value="4" label="세월교" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            {(datas?.pierType === '0' || datas?.pierType === '1') && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierCnt')}>
                  교각수
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierCnt"
                    value={datas?.pierCnt || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">개</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 20 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierLength')}>
                  경간장
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierLength"
                    value={datas?.pierLength || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {datas?.pierType === '2' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierCnt')}>
                  암거련수
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierCnt"
                    value={datas?.pierCnt || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">개</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 20 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierLength')}>
                  암거직경
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierLength"
                    value={datas?.pierLength || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {datas?.pierType === '3' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierCnt')}>
                  흄관련수
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierCnt"
                    value={datas?.pierCnt || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">개</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 20 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierLength')}>
                  흄관직경
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierLength"
                    value={datas?.pierLength || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
            {datas?.pierType === '4' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierForm')}>
                  교량형식
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  <RadioGroup name="pierForm" value={datas?.pierForm || ''} onClick={handleChangeRadio}>
                    <Radio value="0" label="하상바닥" />
                    <Radio value="1" label="BOX형" />
                    <Radio value="2" label="흄관형" />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            )}
            {datas?.pierType === '4' && datas?.pierForm === '1' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierFormW')}>
                  폭
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierFormW"
                    value={datas?.pierFormW || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierFormH')}>
                  높이
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierFormH"
                    value={datas?.pierFormH || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierFormCnt')}>
                  련수
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierFormCnt"
                    value={datas?.pierFormCnt || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">개</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 20 }}
                  />
                </TableCell>
              </TableRow>
            )}
            {datas?.pierType === '4' && datas?.pierForm === '2' && (
              <TableRow>
                <TableCell align="center" component="th" {...checkVaild('pierFormL')}>
                  직경
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierFormL"
                    value={datas?.pierFormL || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell align="center" component="th" {...checkVaild('pierFormCnt')}>
                  련수
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="pierFormCnt"
                    value={datas?.pierFormCnt || ''}
                    placeholder={'입력해주세요'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">개</InputAdornment>
                    }}
                    type="number"
                    required
                    error
                    inputProps={{ maxLength: 4 }}
                  />
                </TableCell>
                <TableCell align="center" colSpan={2}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="구조적 안정성"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ① 슬라브·교대·교각·파손·균열
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val1" value={datas?.val1 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="파손없음·소규모 균열" />
                  <Radio value="1" label="파손·균열·안전상 지장 없음" />
                  <Radio value="2" label="심각한 파손·균열" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ② 철근노출·부식
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val2" value={datas?.val2 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="안전상 지장없음" />
                  <Radio value="1" label="균열부위 일부 부식" />
                  <Radio value="2" label="철근노출·심각한 부식" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ③ 교대·교각 세굴
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val3" value={datas?.val3 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="세굴없음·세굴방지공 설치" />
                  <Radio value="1" label="세굴 일부 진행" />
                  <Radio value="2" label="세굴로 기초 노출" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ④노후도·구조물 안전성
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val4" value={datas?.val4 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="30년이하·배수구,난간 없음" />
                  <Radio value="2" label="30년이상·구조적 결함" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="홍수위험성"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ⑤ 제방 접합부 침식
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val5" value={datas?.val5 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="날개벽 있음·안전" />
                  <Radio value="1" label="날개벽 노후·균열" />
                  <Radio value="2" label="날개벽 없음·이동·침식" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑥여유고 부족·월류
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val6" value={datas?.val6 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="높음·설계기준 적합" />
                  <Radio value="1" label="일부 설계기준 부적합" />
                  <Radio value="2" label="과거 월류 피해발생" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑦하폭에 비해 작은 규모의 교량
              </TableCell>
              <TableCell align="center" colSpan={3}>
                하폭 입력값과 교량 제원의 자동 비교를 통한 점수 산정
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑧경간장 부족에 의한 피해 여부
              </TableCell>
              <TableCell align="center" colSpan={3}>
                하폭 입력값과 경간장 입력값의 자동 비교를 통한 점수 산정
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="기타"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ⑨ 입지여건·통행량
              </TableCell>
              <TableCell align="center">
                <RadioGroup name="val9" value={datas?.val9 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="거의 없음" />
                  <Radio value="1" label="작음" />
                  <Radio value="2" label="많음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑩ 통행위험성·세월교
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <RadioGroup name="val10" value={datas?.val10 || ''} onClick={handleClickRadioScore}>
                  <Radio value="0" label="계획홍수위보다 높음" />
                  <Radio value="1" label="세월교·통행자 거의없음" />
                  <Radio value="2" label="세월교·통행자 많음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToolbarArea title="기타의견"></ToolbarArea>
      <TextField id="opinionEtc" multiline rows={10} fullWidth value={datas?.opinionEtc || opinionEtc} onChange={handleChange} inputProps={{ maxLength: 3000 }} />

      <ToolbarArea title="사진 첨부"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="50%" />
            <col width="50%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                전경 사진
              </TableCell>
              <TableCell align="center" component="th">
                주변부 사진
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="panorama" maxFiles={1} maxFilesize={5242880} />
                {datas?.panoramaPre && <img src={datas?.panoramaPre} alt="panoramaPre" width="80%" height="200px" />}
                {!datas?.panoramaPre && (
                  <img
                    src={!!datas?.panoramaPre ? datas?.panoramaPre : `/api/safe/img/${prjId}/${fcCode}/${part}Panorama`}
                    alt={`${part}Panorama`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="side" maxFiles={1} maxFilesize={5242880} />
                {datas?.sidePre && <img src={datas?.sidePre} alt="sidePre" width="80%" height="200px" />}
                {!datas?.sidePre && (
                  <img
                    src={!!datas?.sidePre ? datas?.sidePre : `/api/safe/img/${prjId}/${fcCode}/${part}Side`}
                    alt={`${part}Side`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <PopoverSpfRadio
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          selected={selectedScore}
          onChangeScore={handleChangeScore}
        />
      )}
      <Backdrop open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
