import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'utils/api.js';

import Button from 'components/Inputs/Button';
import { Box } from 'components/Layout';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import MapContext from 'map/MapContext';
import { mapEdit, mapUtil } from 'map/MapUtils.js';

const fcTypeItems = [
  { label: '세천', fcType: 'A' },
  { label: '소교량(단독)', fcType: 'B' },
  { label: '보 및 낙차공(단독)', fcType: 'C' },
  { label: '농로', fcType: 'E' },
  { label: '마을진입로', fcType: 'F' }
];

let spfDraw = null;
let splitDraw = null; //라인 자르기
let splitOverlay; //라인 split 할 때 시점부/종점부 Overlay
let mergeOverlay; //라인 merge 할 때 기준점 선택 Overlay
let addFeaturePoint = null; //점추가 Draw 객체
let editSource = null;
let mergeSource = null;
let mergeClickEvt = null;

export default function MapFacilityEdit(props) {
  const { map } = useContext(MapContext);
  const { pData, editFeature, visibleSetting, onCloseEdit } = props;

  const [selectedSpf, setSelectedSpf] = useState('');
  const [selectedTool, setSelectedTool] = useState('');
  const [deleteFeatures, setDeleteFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const mapClickEvtMergeFeature = async (evt) => {
    const fcLayer = [`fc_${selectedSpf.toLowerCase()}`];
    map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        let values = feature.getProperties();
        if (values) {
          let ftMearge1 = editSource.getFeatures()[0].clone();
          ftMearge1.setProperties({ ...ftMearge1.getProperties(), target: 1 });
          let ftMearge2 = feature.clone();
          ftMearge2.setProperties({ ...ftMearge2.getProperties(), target: 2 });
          mergeSource.clear();
          mergeSource.addFeatures([ftMearge1, ftMearge2]);
          const midPoint = ftMearge1.getGeometry().getFlatMidpoint();
          mergeOverlay.setPosition(midPoint);
        }
      },
      {
        layerFilter: function (layer) {
          return fcLayer.find((t) => t === layer.get('id'));
        }
      }
    );
  };
  /**
   * 시설물 편집 > 시종점부 전환 (LineString)
   */
  const getReverseFeaturePoint = () => {
    if (editSource.getFeatures().length > 0) {
      let feature = editSource.getFeatures()[0];
      if (feature) mapUtil.reverseFeaturePoint(feature);
    } else {
      alert('소규모 공공 시설 공간데이터를 입력하세요.');
    }
  };

  /**
   * 시설물 편집 > LineString Type 자르기 시작
   */
  const splitLineGeometryStart = () => {
    if (editSource.getFeatures().length === 0) return;
    splitDraw = mapEdit.addInteractionToLayer(map, 'draw', editSource, { type: 'LineString', maxPoints: 2 });
    splitDraw.once('drawend', (e) => {
      map.removeInteraction(splitDraw);
      const feature = editSource.getFeatures()[0];
      const splitLine = e.feature;
      const splitData = mapEdit.addInteractionSplitFeature(feature, splitLine);
      if (splitData.length === 0) {
        editSource.removeFeature(splitLine);
        splitLineGeometryStart();
        alert('기준선이 교차하지 않습니다. 다시 입력하세요.');
      } else {
        const midPoint = e.feature.getGeometry().getFlatMidpoint();
        splitOverlay.setPosition(midPoint);
      }
    });
  };

  const setDeleteFeatureId = (ftId) => {
    setDeleteFeatures([...deleteFeatures, ftId]);
  };

  /**
   * 시설물 편집 > LineString Type 병합 시작
   */
  const mergeLineGeometryStart = () => {
    const mergeLayer = mapUtil.getLayerId(map, 'mergeLayer');
    mergeLayer.setVisible(true);

    let ftMearge = editSource.getFeatures()[0].clone();
    ftMearge.setProperties({ ...ftMearge.getProperties(), target: 1 });
    mergeSource.clear();
    mergeSource.addFeature(ftMearge);

    mergeClickEvt = mapClickEvtMergeFeature;
    map.on('singleclick', mergeClickEvt);
  };

  const handleClickBtnTool = (e) => {
    const toolType = e.target.id;
    if (toolType === 'reverse' && (selectedSpf === 'A' || selectedSpf === 'E' || selectedSpf === 'F')) {
      getReverseFeaturePoint();
    }
  };

  const handleClickBtnDraw = (event) => {
    setSelectedTool(event.target.id);
  };

  const activeDrawTools = () => {
    removeMapInteractions();
    if (selectedTool === 'modify') {
      initEditInteraction(selectedSpf);
    } else if (selectedTool === 'lineSplit' && (selectedSpf === 'A' || selectedSpf === 'E' || selectedSpf === 'F')) {
      splitLineGeometryStart();
    } else if (selectedTool === 'merge') {
      mergeLineGeometryStart();
    } else if (selectedTool === 'addPoint' && (selectedSpf === 'A' || selectedSpf === 'E' || selectedSpf === 'F')) {
      addFeaturePoint = mapEdit.addInteractionFeaturePoint(map);
    }
  };

  /**
   * 시설물 편집 저장 후 선택된 시설물 활성화
   * @param {*} e
   */
  const handleClickBtnSave = async (e) => {
    const feature = editSource.getFeatures()[0].clone(); //편집한 시설물
    const geojson = mapUtil.writeFeatureToGeoJSON(feature);
    const len = mapUtil.getFeatureLength(feature) || 0; //시설물 연장

    try {
      if (deleteFeatures.length > 0) {
        const response = await axios.delete(`/api/safe/spf-geom`, { data: { fcType: selectedSpf, dataList: deleteFeatures } });
        mergeOverlay.setPosition(undefined);
        map.un('singleclick', mergeClickEvt);
      }
      const spfPoint = mapUtil.getPointAddress(feature);
      if (visibleSetting === 'addFeature') {
        let params = { prjId: pData.id, fcType: selectedSpf, geom: geojson, len, spfPoint };
        const response = await axios.post(`/api/safe/spf-geom`, params);
        if (response) {
          const source = mapUtil.getLayerSourceId(map, `fc_${params.fcType.toLowerCase()}`);
          source.refresh();
          editSource.clear();
          onCloseEdit({ editedFeature: null });
        }
      } else if (visibleSetting === 'editFeature') {
        let params = { ...editFeature.getProperties(), fcType: selectedSpf, geom: geojson, len, spfPoint };
        const response = await axios.patch(`/api/safe/spf-geom`, params);
        if (response) {
          const source = mapUtil.getLayerSourceId(map, `fc_${params.fcType.toLowerCase()}`);
          let updateFeature = null;
          source.getFeatures().forEach((ft) => {
            if (ft.get('id') === feature.get('id')) {
              ft.setGeometry(feature.getGeometry());
              updateFeature = ft.clone();
            }
          });
          onCloseEdit({ editedFeature: updateFeature });
        }
      }
      removeMapInteractions();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickBtnCancel = (e) => {
    removeMapInteractions();
    editSource.clear();
    onCloseEdit({ editedFeature: editFeature });
  };

  /**
   * 시설물 편집 모든 이벤트 제거
   */
  const removeMapInteractions = () => {
    //interaction evt 제거
    if (spfDraw) map.removeInteraction(spfDraw);
    if (splitDraw) map.removeInteraction(splitDraw);
    if (addFeaturePoint) map.removeInteraction(addFeaturePoint);
    mapEdit.removeInteraction(map);

    //overlay 제거
    if (splitOverlay) splitOverlay.setPosition(undefined);
    if (mergeOverlay) mergeOverlay.setPosition(undefined);

    //merge layer
    if (mergeSource) {
      const mergeLayer = mapUtil.getLayerId(map, 'mergeLayer');
      mergeLayer.setVisible(false);
      mergeSource.clear();
    }
  };

  /**
   * 시설물 종류 선택시 시설물 편집모드 실행
   * @param {*} event
   */
  const handleClickBtnSpf = (event) => {
    const fcType = event.target.value;
    removeMapInteractions();
    if (visibleSetting === 'addFeature') {
      if (fcType === 'A' || fcType === 'E' || fcType === 'F') {
        spfDraw = mapEdit.addInteractionToLayer(map, 'draw', editSource, { type: 'LineString' });
      } else if (fcType === 'B' || fcType === 'C') {
        spfDraw = mapEdit.addInteractionToLayer(map, 'draw', editSource, { type: 'Polygon' });
      }
      if (spfDraw) {
        const drawEvt = (e) => {
          if (!mapUtil.isWithin(map, 'insp', e.feature)) {
            alert(`시설물을 점검구역 내에 입력하세요.`);
            editSource.once('addfeature', (evt) => {
              editSource.clear();
            });
          } else {
            editSource.once('addfeature', (evt) => {
              let feature = evt.feature;
              feature.setProperties({ fc_type: fcType, edit: true });
              map.getView().fit(evt.feature.getGeometry());
            });

            spfDraw.un('drawend', drawEvt);
            setSelectedTool('modify');
            map.removeInteraction(spfDraw);
          }
        };
        spfDraw.on('drawend', drawEvt);
      }
    }
    setSelectedSpf(fcType);
  };

  /**
   * 시설물 feature에 Draw / Modify / Snap Interaction 추가
   * @param {*} fcType
   */
  const initEditInteraction = (fcType) => {
    if (visibleSetting === 'addFeature' || visibleSetting === 'editFeature') {
      mapEdit.addInteractionToLayer(map, 'modify', editSource);
      mapEdit.addInteractionToLayer(map, 'snap', editSource);
    }
  };

  useEffect(() => {
    removeMapInteractions();
    splitOverlay = mapUtil.initOverlay(map, 'ol-popup-custom-split');
    mergeOverlay = mapUtil.initOverlay(map, 'ol-popup-custom-merge');
    editSource = mapUtil.getLayerSourceId(map, 'editLayer');
    mergeSource = mapUtil.getLayerSourceId(map, 'mergeLayer');
    return () => {
      removeMapInteractions();
      if (splitOverlay) splitOverlay.setMap(map);
      if (mergeOverlay) mergeOverlay.setMap(map);
    };
  }, []);

  useEffect(() => {
    //선택된 시설물을 편집하기 위한 신규 벡터 생성
    if (editFeature && map) {
      let newEditFeature = editFeature.clone();
      newEditFeature.setProperties({ ...newEditFeature.getProperties(), edit: true });
      editSource.clear();
      editSource.addFeature(newEditFeature);
      const mergeLayer = mapUtil.getLayerId(map, 'mergeLayer');
      mergeLayer.setVisible(false);
      mergeSource.clear();
      initEditInteraction(editFeature.get('fc_type'));
      setSelectedSpf(editFeature.get('fc_type'));
      setSelectedTool('modify');
    }
  }, [editFeature]);

  useEffect(() => {
    activeDrawTools();
  }, [selectedTool]);

  return (
    <div id="layerSearch" className="layer layerSearch">
      <Box sx={{ display: 'block', width: 170, height: '100%' }}>
        {visibleSetting === 'addFeature' && (
          <Stack spacing={1} sx={{ my: 2 }}>
            {fcTypeItems.map((item, idx) => (
              <Button key={`menu-btn-${idx}`} variant={selectedSpf === item.fcType ? 'contained' : 'text'} color="success" value={item.fcType} onClick={handleClickBtnSpf}>
                {item.label}
              </Button>
            ))}
          </Stack>
        )}
        {selectedTool && (selectedSpf === 'A' || selectedSpf === 'E' || selectedSpf === 'F') && (
          <Stack spacing={1} sx={{ my: 4 }}>
            <Button variant={selectedTool === 'modify' ? 'contained' : 'text'} color="secondary" id="modify" onClick={handleClickBtnDraw}>
              수정
            </Button>
            <Button variant={selectedTool === 'lineSplit' ? 'contained' : 'text'} color="secondary" id="lineSplit" onClick={handleClickBtnDraw}>
              자르기
            </Button>
            <Button variant={selectedTool === 'merge' ? 'contained' : 'text'} color="secondary" id="merge" onClick={handleClickBtnDraw}>
              병합
            </Button>
            <Button variant={selectedTool === 'addPoint' ? 'contained' : 'text'} color="secondary" id="addPoint" onClick={handleClickBtnDraw}>
              시/종점부 점추가
            </Button>
            <Button color="secondary" variant="text" id="reverse" onClick={handleClickBtnTool}>
              시/종점부 방향전환
            </Button>
          </Stack>
        )}

        {selectedTool && (selectedSpf === 'B' || selectedSpf === 'C') && (
          <Stack spacing={1} sx={{ my: 4 }}>
            <Button variant={selectedTool === 'modify' ? 'contained' : 'text'} color="secondary" id="modify" onClick={handleClickBtnDraw}>
              수정
            </Button>
          </Stack>
        )}

        {selectedTool && (
          <Stack spacing={1} sx={{ my: 2 }}>
            <Button variant="contained" id="save" onClick={handleClickBtnSave}>
              저장
            </Button>
            <Button variant="contained" id="delete" onClick={handleClickBtnCancel}>
              취소
            </Button>
          </Stack>
        )}
      </Box>
      <MapSplitOverlay splitLineGeometryStart={splitLineGeometryStart} />
      <MapMergeOverlay setDeleteFeatureId={setDeleteFeatureId} />
    </div>
  );
}

const MapSplitOverlay = (props) => {
  const { splitLineGeometryStart } = props;

  /**
   * 시설물 편집 > LineString Type Split > 시점부/종점부 버튼 클릭 시 방향에 따라 LineString 자르기
   * @param {*} e
   */
  const handleClickSplitStandardPoint = (e) => {
    const targetPoint = e.target.id;
    const features = editSource.getFeatures();

    if (features.length == 2) {
      const splitLine = mapEdit.addInteractionSplitFeature(features[0], features[1]);
      if (splitLine.length > 0) {
        if (targetPoint === 'startPoint') {
          features[0].setGeometry(splitLine[0].getGeometry());
        } else if (targetPoint === 'endPoint') {
          features[0].setGeometry(splitLine[1].getGeometry());
        }
        editSource.removeFeature(features[1]);
        splitOverlay.setPosition(undefined);
        splitLineGeometryStart();
      } else {
        alert('기준선이 교차하지 않습니다. 다시 입력하세요.');
      }
    }
  };

  /**
   * 시설물 편집 > LineString Type 자르기 > 취소 클릭시 선택화면 hide
   * @param {*} e
   */
  const handleCloseSplitOverlay = (e) => {
    editSource.removeFeature(editSource.getFeatures()[1]);
    splitOverlay.setPosition(undefined);
    splitLineGeometryStart();
  };

  return (
    <Box id="ol-popup-custom-split" sx={{ zIndex: 1, backgroundColor: 'white' }}>
      <Stack spacing={1} sx={{ m: 2 }}>
        <Button variant="contained" id="startPoint" onClick={handleClickSplitStandardPoint}>
          시점부
        </Button>
        <Button variant="contained" id="endPoint" onClick={handleClickSplitStandardPoint}>
          종점부
        </Button>
        <Button variant="contained" id="cancel" onClick={handleCloseSplitOverlay}>
          취소
        </Button>
      </Stack>
    </Box>
  );
};

const MapMergeOverlay = (props) => {
  const { setDeleteFeatureId } = props;

  const [mergePoint, setMergePoint] = useState('');

  const handleClickRadioBtn = (e) => {
    const value = e.target.value;
    connectLineGeometry(value);
    setMergePoint(value);
  };

  /**
   * Merge를 위한 기준 선택
   * @param {*} e
   */
  const connectLineGeometry = (value) => {
    if (mergeSource.getFeatures().length > 1) {
      let connectionPoint = [];
      const ftGeom1 = mergeSource.getFeatures()[0].getGeometry();
      const ftGeom2 = mergeSource.getFeatures()[1].getGeometry();
      connectionPoint.push(ftGeom1.getFirstCoordinate());
      connectionPoint.push(ftGeom1.getLastCoordinate());
      connectionPoint.push(ftGeom2.getFirstCoordinate());
      connectionPoint.push(ftGeom2.getLastCoordinate());

      let tempLineGeom = null;
      if (value === 's1s2') {
        tempLineGeom = mapUtil.createGeometry('LineString', [connectionPoint[0], connectionPoint[2]]);
      } else if (value === 's1e2') {
        tempLineGeom = mapUtil.createGeometry('LineString', [connectionPoint[0], connectionPoint[3]]);
      } else if (value === 'e1s2') {
        tempLineGeom = mapUtil.createGeometry('LineString', [connectionPoint[1], connectionPoint[2]]);
      } else if (value === 'e1e2') {
        tempLineGeom = mapUtil.createGeometry('LineString', [connectionPoint[1], connectionPoint[3]]);
      }
      let lineFeature = mapUtil.createFeature(tempLineGeom, { properties: { id: 'merge-temp' } });
      if (lineFeature) {
        mergeSource.removeFeature(mergeSource.getFeatures()[2]);
        mergeSource.addFeature(lineFeature);
      }
    } else {
      alert('병합하실 시설물을 선택해주세요.');
    }
  };

  /**
   * Merge 적용
   * @param {*} e
   */
  const handleClickMergeSave = (e) => {
    let coords = [];
    const ft1 = mergeSource.getFeatures()[0].clone();
    const ft2 = mergeSource.getFeatures()[1].clone();
    const ftCoords1 = ft1.getGeometry().getCoordinates();
    const ftCoords2 = ft2.getGeometry().getCoordinates();

    if (mergePoint === 's1s2') {
      coords = ftCoords2.reverse().concat(ftCoords1);
    } else if (mergePoint === 's1e2') {
      coords = ftCoords2.concat(ftCoords1);
    } else if (mergePoint === 'e1s2') {
      coords = ftCoords1.concat(ftCoords2);
    } else if (mergePoint === 'e1e2') {
      coords = ftCoords1.concat(ftCoords2.reverse());
    }
    let newGeometry = mapUtil.createGeometry('LineString', coords);
    let newEditFeature = mapUtil.createFeature(newGeometry, { properties: ft1.getProperties() });
    editSource.clear();
    editSource.addFeature(newEditFeature);
    let newMergeFeature = mapUtil.createFeature(newGeometry, { properties: ft1.getProperties(), target: 1 });
    mergeSource.clear();
    mergeSource.addFeature(newMergeFeature);

    mergeOverlay.setPosition(undefined);
    setDeleteFeatureId(ft2.get('id'));
  };

  const handleClose = (e) => {
    // let ftMearge = editFeature.clone();
    // ftMearge.setProperties({ ...ftMearge.getProperties(), target: 1 });
    // mergeSource.clear();
    // mergeSource.addFeature(ftMearge);
    // mergeOverlay.setPosition(undefined);
    // map.un('singleclick', mapClickEvtMergeFeature);
    // mergeLineGeometryStart();
  };

  return (
    <Box id="ol-popup-custom-merge" sx={{ zIndex: 1, backgroundColor: 'white' }}>
      <Stack spacing={1} sx={{ m: 2 }}>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label"  >Gender</FormLabel> */}
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" onClick={handleClickRadioBtn}>
            <FormControlLabel value="s1s2" control={<Radio />} label="s1 - s2" />
            <FormControlLabel value="s1e2" control={<Radio />} label="s1 - e2" />
            <FormControlLabel value="e1s2" control={<Radio />} label="e1 - s2" />
            <FormControlLabel value="e1e2" control={<Radio />} label="e1 - e2" />
          </RadioGroup>
        </FormControl>
        <Button variant="contained" id="save" onClick={handleClickMergeSave}>
          완료
        </Button>
        {/* <Button variant="contained" id="cancel" onClick={handleClose}>
          취소
        </Button> */}
      </Stack>
    </Box>
  );
};
