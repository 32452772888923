import React from 'react';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Checkbox from 'components/Inputs/Checkbox';
import Stack from '@mui/material/Stack';

const itemsVal1 = [
  { label: '없음', value: '0' },
  { label: '없음', value: '2' }
];
const itemsVal2 = [
  { label: '없음', value: '0' },
  { label: '장비 후 추가피해 없음', value: '1' },
  { label: '있음', value: '2' }
];
const itemsVal3 = [
  { label: '파손없음', value: '0' },
  { label: '파손', value: '2' }
];
const itemsVal4 = [
  { label: '양호', value: '0' },
  { label: '노후화', value: '1' },
  { label: '호안없음·파손', value: '2' }
];
const itemsVal5 = [
  { label: '시설물양호', value: '0' },
  { label: '노후화 심함', value: '1' },
  { label: '시설물 없거나 파손', value: '2' }
];
const itemsVal6 = [
  { label: '급축소 없음', value: '0' },
  { label: '하폭 매우 협소', value: '1' },
  { label: '급축소', value: '2' }
];
const itemsVal7 = [
  { label: '양호', value: '0' },
  { label: '일부퇴적', value: '1' },
  { label: '심한퇴적', value: '2' }
];
const itemsVal8 = [
  { label: '양호', value: '0' },
  { label: '하도내식생', value: '1' },
  { label: '교량내 유송잡물', value: '2' }
];
const itemsVal9 = [
  { label: '산지·나대지', value: '0' },
  { label: '일부 농경지·취락지', value: '1' },
  { label: '다수 취락지', value: '2' }
];
const itemsVal10 = [
  { label: '없음', value: '0' },
  { label: '일부', value: '1' },
  { label: '심함', value: '2' }
];

export default function SafeEvlReportA(props) {
  const { pData, pRows, evlRslt } = props;

  const start = pRows.find((t) => t.part === 'start');
  const startMid = pRows.find((t) => t.part === 'start-mid');
  const mid = pRows.find((t) => t.part === 'mid');
  const midEnd = pRows.find((t) => t.part === 'mid-end');
  const end = pRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box sx={{ m: 2, display: 'block' }}>
        <ToolbarArea title="현장조사"></ToolbarArea>
        <TableContainer component={'div'} sx={{ width: '1200px' }}>
          <Table>
            <colgroup>
              <col width="8%" />
              <col width="10%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="6%" />
              <col width="6%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2}>
                  구분
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  조사항목
                </TableCell>
                <TableCell align="center" rowSpan={2} colSpan={9}>
                  항목별 평가
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  점수
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">개별</TableCell>
                <TableCell align="center">평균</TableCell>
              </TableRow>
            </TableHead>
            {/* 제원측정 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={8} component="th">
                  제원측정
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  하천단면
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  하폭
                </TableCell>
                <TableCell align="center">{start?.riverWidth || '-'}</TableCell>
                <TableCell rowSpan={5} component="th">
                  평균하폭
                </TableCell>
                <TableCell align="center" rowSpan={5}>
                  {evlRslt?.widthAvg}
                </TableCell>
                <TableCell rowSpan={5} component="th">
                  제방고
                </TableCell>
                <TableCell align="center">{start?.leveeHeight || '-'}</TableCell>
                <TableCell rowSpan={5} component="th">
                  평균
                  <br />
                  제방고
                </TableCell>
                <TableCell align="center" rowSpan={5}>
                  {evlRslt?.heightAvg}
                </TableCell>
                <TableCell align="center" rowSpan={5}>
                  -
                </TableCell>
                <TableCell align="center" rowSpan={5}>
                  -
                </TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  시점중앙
                </TableCell>
                <TableCell align="center">{startMid?.riverWidth || '-'}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  중앙부
                </TableCell>
                <TableCell align="center">{start?.riverWidth || '-'}</TableCell>
                <TableCell align="center">{start?.leveeHeight || '-'}</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  중앙종점
                </TableCell>
                <TableCell align="center">{midEnd?.riverWidth || '-'}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center">{end?.riverWidth || '-'}</TableCell>
                <TableCell align="center">{end?.leveeHeight || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" rowSpan={3}>
                  둑마루폭
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell component="th" rowSpan={3}>
                  좌안
                </TableCell>
                <TableCell align="center">{start?.leveeLeftLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {start?.leveeLeftLen ? '미설치' : '설치'}
                </TableCell>
                <TableCell component="th" rowSpan={3}>
                  우안
                </TableCell>
                <TableCell align="center">{start?.leveeRightLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {start?.leveeRightLen ? '미설치' : '설치'}
                </TableCell>
                <TableCell align="center" rowSpan={3}>
                  -
                </TableCell>
                <TableCell align="center" rowSpan={3}>
                  -
                </TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  중앙부
                </TableCell>
                <TableCell align="center">{mid?.leveeLeftLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {mid?.leveeLeftLen ? '미설치' : '설치'}
                </TableCell>
                <TableCell align="center">{mid?.leveeRightLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {mid?.leveeRightLen ? '미설치' : '설치'}
                </TableCell>
              </TableRow>
              <TableRow component="th">
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center">{end?.leveeLeftLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {end?.leveeLeftLen ? '미설치' : '설치'}
                </TableCell>
                <TableCell align="center">{mid?.leveeRightLen || '-'}</TableCell>
                <TableCell align="center" colSpan={2}>
                  {end?.leveeRightLen ? '미설치' : '설치'}
                </TableCell>
              </TableRow>
            </TableBody>

            {/* 피해이력 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={6} component="th">
                  피해이력
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ① 인명·재산피해
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  인명피해
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={start?.val1_1} />
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  재산피해
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={start?.val1_2} />
                </TableCell>
                <TableCell align="center">{start?.score1}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score1}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={mid?.val1_1} />
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={mid?.val1_2} />
                </TableCell>
                <TableCell align="center">{mid?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={end?.val1_2} />
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal1} value={end?.val1_2} />
                </TableCell>
                <TableCell align="center">{mid?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ② 상습침수피해
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal2} value={start?.val2} />
                </TableCell>
                <TableCell align="center">{start?.score2}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score2}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal2} value={mid?.val2} />
                </TableCell>
                <TableCell align="center">{mid?.score2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal2} value={end?.val2} />
                </TableCell>
                <TableCell align="center">{end?.score2}</TableCell>
              </TableRow>
            </TableBody>

            {/* 시설물현황 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={12} component="th">
                  시설물현황
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ③ 제 방
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  좌안
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={start?.val3} />
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  우안
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={mid?.val3} />
                </TableCell>
                <TableCell align="center">{start?.score3}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score3}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={mid?.val3} />
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={mid?.val3} />
                </TableCell>
                <TableCell align="center">{mid?.score3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={end?.val3} />
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <CheckItems items={itemsVal3} value={end?.val3} />
                </TableCell>
                <TableCell align="center">{mid?.score3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={6} component="th">
                  ④ 호 안
                </TableCell>
                <TableCell align="center" rowSpan={2} component="th">
                  시점부
                </TableCell>
                <TableCell component="th">좌안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={start?.val4_1} />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  {start?.score4}
                </TableCell>
                <TableCell align="center" rowSpan={6}>
                  {evlRslt?.score4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">우안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={start?.val4_2} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" rowSpan={2} component="th">
                  중점부
                </TableCell>
                <TableCell component="th">좌안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={mid?.val4_1} />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  {mid?.score4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">우안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={mid?.val4_2} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" rowSpan={2} component="th">
                  종점부
                </TableCell>
                <TableCell component="th">좌안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={end?.val4_1} />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  {end?.score4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">우안</TableCell>
                <TableCell align="center" colSpan={7}>
                  {/* 미설치 석축 돌쌓기 옹벽 기타 */}
                  <CheckItems items={itemsVal4} value={end?.val4_2} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑤ 통관·문비
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal5} value={start?.val5} />
                </TableCell>
                <TableCell align="center">{start?.score5}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score5}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal5} value={mid?.val5} />
                </TableCell>
                <TableCell align="center">{mid?.score5}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal5} value={end?.val5} />
                </TableCell>
                <TableCell align="center">{end?.score5}</TableCell>
              </TableRow>
            </TableBody>

            {/* 하도현황 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={15} component="th">
                  하도 현황
                </TableCell>
                <TableCell rowSpan={3} component="th">
                  ⑥ 하폭 변화
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal6} value={start?.val6} />
                </TableCell>
                <TableCell align="center">{start?.score6}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score6}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal6} value={mid?.val6} />
                </TableCell>
                <TableCell align="center">{mid?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal6} value={end?.val6} />
                </TableCell>
                <TableCell align="center">{end?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑦ 하상침식·퇴적
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal7} value={start?.val7} />
                </TableCell>
                <TableCell align="center">{start?.score7}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score7}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal7} value={mid?.val7} />
                </TableCell>
                <TableCell align="center">{mid?.score7}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal7} value={end?.val7} />
                </TableCell>
                <TableCell align="center">{end?.score7}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑧ 식생·유송잡물
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal8} value={start?.val8} />
                </TableCell>
                <TableCell align="center">{start?.score8}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score8}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal8} value={mid?.val8} />
                </TableCell>
                <TableCell align="center">{mid?.score8}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal8} value={end?.val8} />
                </TableCell>
                <TableCell align="center">{end?.score8}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑨ 입지여건
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal9} value={start?.val9} />
                </TableCell>
                <TableCell align="center">{start?.score9}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score9}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal9} value={mid?.val9} />
                </TableCell>
                <TableCell align="center">{mid?.score9}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal9} value={end?.val9} />
                </TableCell>
                <TableCell align="center">{end?.score9}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  ⑩ 훼손·불법점용
                </TableCell>
                <TableCell align="center" component="th">
                  시점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal10} value={start?.val10} />
                </TableCell>
                <TableCell align="center">{start?.score10}</TableCell>
                <TableCell align="center" rowSpan={3}>
                  {evlRslt?.score10}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  중점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal10} value={mid?.val10} />
                </TableCell>
                <TableCell align="center">{mid?.score10}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component="th">
                  종점부
                </TableCell>
                <TableCell colSPan={8}>
                  <CheckItems items={itemsVal10} value={end?.val10} />
                </TableCell>
                <TableCell align="center">{end?.score10}</TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow>
                <TableCell align="center" component="th" colSPan={3}>
                  계
                </TableCell>
                <TableCell align="center" colSPan={8}>
                  <span style={evlRslt?.totResult === '양호' ? totResultStyle : {}}>양호</span> / <span style={evlRslt?.totResult === '보통' ? totResultStyle : {}}>보통</span> /{' '}
                  <span style={evlRslt?.totResult === '불량' ? totResultStyle : {}}>나쁨</span>
                </TableCell>
                <TableCell align="center" colSPan={2}>
                  {evlRslt?.totScore}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
const totResultStyle = { width: '100px', height: '85px', borderRadius: '50%', border: '1px solid' };

const CheckItems = (props) => {
  const { value, items } = props;

  return (
    <Stack direction="row" sx={{ my: 0 }} spacing={1} justifyContent="center">
      {items.map((item) => {
        return <Checkbox label={item?.label} checked={item.value === value} disabled />;
      })}
    </Stack>
  );
};
