import React from "react";
import {authUtils} from 'utils/authUtils.js';
import {Navigate, useLocation} from "react-router-dom";

const AdminRoute = ({children}) => {
    const location = useLocation();

    if (authUtils.useIsAdmin()) {
        return children;
    }

    alert('권한이 없는 메뉴입니다.')
    return <Navigate to="/" state={{from: location}} replace/>;
};

export default AdminRoute;