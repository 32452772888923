import { atom } from 'recoil';

const authAtom = atom({
    key: 'auth',
    default: IsJsonString(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : localStorage.getItem('user')
});

function IsJsonString(str) {
    try {
        const json = JSON.parse(str);
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}

export { authAtom };