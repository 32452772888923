import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MbSnackbar(props) {
  const { messageInfo, severity, vertical, horizontal, isOpen } = props;
  const { key, message } = messageInfo || {};

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (key || isOpen) {
      setOpen(true);
    }
  }, [key, isOpen]);

  return (
    <Snackbar
      key={key || 'snackbar-id'}
      open={open}
      anchorOrigin={{ vertical: vertical || 'bottom', horizontal: horizontal || 'center' }}
      autoHideDuration={6000}
      onClose={handleClose}
      action={
        <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Alert onClose={handleClose} severity={severity || 'info'} sx={{ width: '100%' }}>
        {message || 'This is a success message!'}
      </Alert>
    </Snackbar>
  );
}
