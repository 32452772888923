import React from "react";
import { authUtils } from "utils/authUtils.js";
import { Navigate, useLocation } from "react-router-dom";

const IndexRoute = ({ children, isMobile }) => {
  const location = useLocation();

  if (isMobile) {
    return <Navigate to="/m/safe" state={{ from: location }} replace />;
  } else {
    if (authUtils.useIsAdmin()) {
      return <Navigate to="/prj" state={{ from: location }} replace />;
    } else {
      return <Navigate to="/safe" state={{ from: location }} replace />;
    }
  }
};

export default IndexRoute;
