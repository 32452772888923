import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ThemeProvider, useTheme, useMediaQuery } from '@mui/material';

const Item = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' ? '#262B32' : '#fff',
  textAlign: 'center',
  borderRadius: 2,
  flexGrow: 1
}));

const ResponsiveGridColumn = (props) => {
  const { id, label, component } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      {/* sx={{ ...(matches ? { flex: '0 0 100%' } : { flex: '0 0 auto' }) }} */}
      <Item width={{ xs: '100%', sm: '48%' }}>
        {label && <Box sx={{ backgroundColor: (theme) => theme.palette.grey[200], display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{label}</Box>}
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{component}</Box>
      </Item>
    </ThemeProvider>
  );
};

export default ResponsiveGridColumn;
