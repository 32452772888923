import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { button } from 'assets/jss/spf-react';

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '0.8rem'
          // margin: 8,
          // fontWeight: 'bold'
        }
      }
    }
  },
  palette: {
    primary: {
      main: button.colors.primary
    },
    secondary: {
      main: button.colors.secondary,
      contrastText: '#FFFFFF'
    },
    success: {
      main: button.colors.success,
      contrastText: '#FFFFFF'
    },
    error: {
      main: button.colors.error,
      contrastText: '#FFFFFF'
    },
    default: {
      main: button.colors.default,
      contrastText: '#FFFFFF'
    }
  }
});

export default function CustomButton(props) {
  const { children, ...rest } = props;

  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" {...rest}>
        {children}
      </Button>
    </ThemeProvider>
  );
}
