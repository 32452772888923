import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import MbSnackbar from './../MbSnackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import NoImage from './../../../assets/img/noimage.png';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    border-color: #2196f3;
  }
`;

export default function MbDropzoneImage(props) {
  const { id, imageUrl, alt, onAcceptedImage, isUpdate } = props;

  const [editMode, setEditMode] = useState(false);
  const [originImage, setOriginImage] = useState(undefined);
  const [editImage, setEditImage] = useState(undefined);

  const handleClickEdit = (e) => {
    setEditMode(true);
  };

  const handleEditClose = (image) => {
    setEditMode(false);
    if (image) {
      setEditImage(originImage);
      if (onAcceptedImage) {
        onAcceptedImage({ file: undefined, id });
      }
    }
  };

  const handleEditSave = (image) => {
    setEditMode(false);
    setEditImage(image || { src: NoImage });
    if (onAcceptedImage) {
      onAcceptedImage({ file: image || NoImage, id });
    }
  };

  const getBase64FromImageUrl = (url, callbackOnload, callbackError) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      callbackOnload(dataURL);
    };
    img.onerror = function () {
      callbackError(NoImage);
    };
    img.src = url;
  };

  useEffect(() => {
    if (imageUrl && !isUpdate) {
      getBase64FromImageUrl(
        imageUrl,
        function (base64Data) {
          setOriginImage({ src: base64Data });
          setEditImage({ src: base64Data });
        },
        function (error) {
          setOriginImage({ src: NoImage });
          setEditImage({ src: undefined });
        }
      );
    }
  }, [imageUrl, isUpdate]);

  return (
    <div style={{ width: '100%', minHeight: '180px' }}>
      {!editMode && (
        <>
          <div style={{ position: 'relative' }}>
            <Stack sx={{ position: 'absolute', right: 0 }}>
              <IconButton aria-label="edit" color={isUpdate ? 'primary' : 'gray'} onClick={handleClickEdit}>
                <EditIcon />
              </IconButton>
            </Stack>
          </div>
          <div style={{ width: '100%', minHeight: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={isUpdate ? editImage?.src : originImage?.src} alt={alt || 'no-image'} style={{ maxHeight: '180px', maxWidth: '100%' }} />
          </div>
        </>
      )}
      {editMode && <ImageEditComponent originImage={originImage} editImage={editImage} onClose={handleEditClose} onSave={handleEditSave} />}
    </div>
  );
}

const ImageEditComponent = ({ originImage, editImage, onSave, onClose }) => {
  const maxFiles = 1;
  const maxSize = 5242880;
  const accept = { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject, inputRef } = useDropzone({ accept, maxSize, maxFiles });

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(editImage || undefined);
  const [messageInfo, setMessageInfo] = useState(undefined);

  const handleClickInit = (e) => {
    setPreviewImage(originImage);
  };

  const handleClickDelete = (e) => {
    setPreviewImage(undefined);
  };

  const handleClickCancel = (e) => {
    onClose();
  };

  const handleClickSave = (e) => {
    try {
      // if (originImage?.src && !previewImage) {
      //   alert('사진을 첨부해주세요');
      //   return;
      // }
      if (previewImage?.src === originImage?.src) {
        if (window.confirm('변경사항이 없습니다. 저장하시겠습니까?')) {
          onClose(originImage);
          return;
        }
      }
      onSave(previewImage);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * 첫번째 이미지 정보 가져오기
   * @param {*} acceptedFiles
   * @param {*} imageType
   * @returns
   */
  const getDropImg = (acceptedFiles) => {
    try {
      setBackdropOpen(true);
      if (!!acceptedFiles && acceptedFiles.length > 0) {
        const acceptedFile = acceptedFiles[0];
        const fileSize = acceptedFile.size;
        const filePath = acceptedFile.path;
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFile);
        return new Promise((resolve) => {
          reader.onload = () => {
            setPreviewImage({ ...previewImage, src: reader.result, file: acceptedFile, path: filePath, size: fileSize });
            resolve();
          };
        });
      }
    } catch (e) {
      alert('파일을 읽어올 수 없습니다. 다시 시도해주세요.');
      console.log(e);
    } finally {
      setBackdropOpen(false);
    }
  };

  const getRejectionMessage = (rejections) => {
    let errorItems = [];
    rejections.forEach(({ file, errors }) => {
      return (
        <li key={file.path} style={{ color: 'red' }}>
          {file.path} - {file.size} bytes
          <ul>
            {errors.map((e) => {
              let message = null;
              if (e.code === 'file-invalid-type') {
                message = `[${e.message.replace('File type must be ', '')}] 파일만 업로드 가능합니다.`;
              } else if (e.code === 'file-too-large') {
                message = '5M 이하 파일만 업로드 가능합니다.';
              } else if (e.code === 'too-many-files') {
                message = '이미지는 하나씩만 올려주세요.';
              }
              if (message !== null) {
                const dupError = errorItems.find((t) => t.code === e.code);
                if (!dupError) errorItems.push({ code: e.code, message: message });
              }
              return <li key={e.code}>{message || e.message}</li>;
            })}
          </ul>
        </li>
      );
    });
    const errorMessage = errorItems.map((t) => t.message).join('\n');
    return errorMessage;
  };

  useEffect(() => {
    getDropImg(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      const message = getRejectionMessage(fileRejections);
      setMessageInfo({ key: new Date().getTime(), message });
    }
  }, [fileRejections]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Stack direction="row-reverse" sx={{ position: 'absolute', right: 0, zIndex: 100 }}>
          <IconButton aria-label="save" label="저장" color="gray" onClick={handleClickSave}>
            <UploadIcon />
          </IconButton>
          <IconButton aria-label="cancel" label="취소" color="gray" onClick={handleClickCancel}>
            <ClearIcon />
          </IconButton>
          <IconButton aria-label="refresh" label="새로고침" color="gray" onClick={handleClickInit}>
            <RefreshIcon />
          </IconButton>
          {previewImage?.src && (
            <IconButton aria-label="delete" label="삭제" color="gray" onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </div>
      <div style={{ width: '100%', minHeight: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
          <input {...getInputProps()} capture="camera" />
          {previewImage?.src ? (
            <div style={{ position: 'relative' }}>
              <img src={previewImage?.src} style={{ maxHeight: '200px', maxWidth: '100%' }} />
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', minWidth: '150px' }}>
                <span style={{ fontSize: '1rem', color: 'red', fontWeight: 'bold' }}>미리보기</span>
                {previewImage?.path && <p style={{ fontSize: '0.8rem' }}>{previewImage?.path}</p>}
                {previewImage?.size && <span style={{ fontSize: '0.8rem' }}>{previewImage?.size} bytes</span>}
              </div>
            </div>
          ) : (
            <p style={{ fontSize: '0.8rem' }}>여기에 파일을 드래그 앤 드롭하거나 클릭하여 파일을 선택하십시오.</p>
          )}
        </Container>
      </div>
      <MbSnackbar messageInfo={messageInfo} severity="error" />
      <Backdrop open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};
