import React, { useState } from 'react';
import { Box, ToolbarArea } from 'components/Layout';
import { Tab, TabPanel, Tabs } from 'components/Navigation/Tabs';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';
import TextField from 'components/Inputs/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Radio, RadioGroup } from 'components/Inputs/RadioButton';
import PopoverSpfRadio from 'components/Utils/PopoverSpfRadio';

import SafeEvlRslt from './SafeEvlRslt';
import DropzoneImage from '../../components/Inputs/DropzoneImage';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

let opinionEtc = `· 위험시설 지정 여부 : 지정 (OOOO년)/ 지정해제 (OOOO년) / 미지정
· 시점부 : 법정도로/ 마을진입로/ 농로
· 종점부 : 법정도로/ 마을진입로/ 농로/ 농경지/ 사유지 담장
· 시설물 제원 : 시점 2.5m, 중앙부 2.5m, 종점부 2.5m, 연장 255m
· 도로형식 : 콘크리트 포장/ 아스팔트 / 비포장
· 선형 : 양호/ 보통/ 불량
· 포장 : 양호/ 보통/ 불량
· 특이사항 : 위험요인인 인접한 하천이 (있으며, 없으며), 주택 및 마을과는(인접/이격)되어 있음
· 주민의견 : 없음/ 있으면 상세내용`;

const validationColumns = [
  {
    part: 'start',
    partName: '시점부',
    columns: [
      { id: 'roadWidth', name: '도로구조문제 - 도로폭' },
      { id: 'val1_1', name: '도로구조문제 - 교행' },
      { id: 'val1_2', name: '도로구조문제 - 포장종류' },
      { id: 'val2', name: '도로구조문제 - 선형불량' },
      { id: 'val3', name: '도로구조문제 - 안전시설(난간)' },
      { id: 'val4', name: '도로구조문제 - 지반침하' },
      { id: 'val5', name: '기능성 - 포장상태' },
      { id: 'val6', name: '기능성 - 비포장 구간' },
      { id: 'val7', name: '기능성 - 노면요철' },
      { id: 'val8', name: '시설물 파손 - 도로·부대시설' },
      { id: 'val9', name: '시설물 파손 - 배수로' },
      { id: 'val10', name: '기타 - 농로입지' }
    ]
  },
  {
    part: 'start-mid',
    partName: '시점-중앙',
    columns: [{ id: 'roadWidth', name: '도로구조문제 - 도로폭·포장종류' }]
  },
  {
    part: 'mid',
    partName: '중앙부',
    columns: [
      { id: 'roadWidth', name: '도로구조문제 - 도로폭' },
      { id: 'val1_1', name: '도로구조문제 - 교행' },
      { id: 'val1_2', name: '도로구조문제 - 포장종류' },
      { id: 'val2', name: '도로구조문제 - 선형불량' },
      { id: 'val3', name: '도로구조문제 - 안전시설(난간)' },
      { id: 'val4', name: '도로구조문제 - 지반침하' },
      { id: 'val5', name: '기능성 - 포장상태' },
      { id: 'val6', name: '기능성 - 비포장 구간' },
      { id: 'val7', name: '기능성 - 노면요철' },
      { id: 'val8', name: '시설물 파손 - 도로·부대시설' },
      { id: 'val9', name: '시설물 파손 - 배수로' },
      { id: 'val10', name: '기타 - 농로입지' }
    ]
  },
  {
    part: 'mid-end',
    partName: '중앙-종점',
    columns: [{ id: 'roadWidth', name: '도로구조문제 - 도로폭·포장종류' }]
  },
  {
    part: 'end',
    partName: '종점부',
    columns: [
      { id: 'roadWidth', name: '도로구조문제 - 도로폭' },
      { id: 'val1_1', name: '도로구조문제 - 교행' },
      { id: 'val1_2', name: '도로구조문제 - 포장종류' },
      { id: 'val2', name: '도로구조문제 - 선형불량' },
      { id: 'val3', name: '도로구조문제 - 안전시설(난간)' },
      { id: 'val4', name: '도로구조문제 - 지반침하' },
      { id: 'val5', name: '기능성 - 포장상태' },
      { id: 'val6', name: '기능성 - 비포장 구간' },
      { id: 'val7', name: '기능성 - 노면요철' },
      { id: 'val8', name: '시설물 파손 - 도로·부대시설' },
      { id: 'val9', name: '시설물 파손 - 배수로' },
      { id: 'val10', name: '기타 - 농로입지' }
    ]
  }
];

export default function SafeEvlE(props) {
  const { pData, pRows, insertRows, setInsertRows, setVisibleAction, setIsEdit } = props;

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
    setVisibleAction(newValue === 5 ? false : true);
  };

  const handleChange = (part, data) => {
    const isPart = insertRows.find((t) => t.part === part);
    let result = [...insertRows];

    if (isPart) {
      result = insertRows.map((row) => (row.part === part ? { ...row, ...data } : row));
    } else {
      const { fcCode, fcType, prjId } = pData;
      const params = { ...data, part, fcCode, fcType, prjId };
      result.push(params);
    }
    setInsertRows(result);
    setIsEdit(true);
  };

  const start = insertRows.find((t) => t.part === 'start');
  const startMid = insertRows.find((t) => t.part === 'start-mid');
  const mid = insertRows.find((t) => t.part === 'mid');
  const midEnd = insertRows.find((t) => t.part === 'mid-end');
  const end = insertRows.find((t) => t.part === 'end');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box style={{ m: 0, borderBottom: '1px solid #CCA63D' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
          <Tab label="시점부" index={0} />
          <Tab label="시점-중앙" index={1} />
          <Tab label="중앙부" index={2} />
          <Tab label="중앙-종점" index={3} />
          <Tab label="종점부" index={4} />
          <Tab label="평가결과 미리보기" index={5} />
        </Tabs>
      </Box>
      <Box sx={{ display: 'block', height: '550px', overflow: 'auto', overflowX: 'hidden' }}>
        <TabPanel value={valueTab} index={0}>
          <LinePointInfo label="시점부" part="start" datas={start} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <LineConnectInfo label="시점-중앙" part="start-mid" datas={startMid} onChange={handleChange} />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <LinePointInfo label="중점부" part="mid" datas={mid} onChange={handleChange} prjId={pData.prjId} />
        </TabPanel>
        <TabPanel value={valueTab} index={3}>
          <LineConnectInfo label="중앙-종점" part="mid-end" datas={midEnd} onChange={handleChange} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={4}>
          <LinePointInfo label="종점부" part="end" datas={end} onChange={handleChange} prjId={pData.prjId} fcCode={pData.fcCode} />
        </TabPanel>
        <TabPanel value={valueTab} index={5}>
          <SafeEvlRslt label="평가결과 미리보기" pData={pData} pRows={pRows} validationColumns={validationColumns} selectedMenu="safetyEvl" />
        </TabPanel>
      </Box>
    </Box>
  );
}

const LinePointInfo = (props) => {
  const { datas, prjId, fcCode, part, label, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleChangeRadio = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.name]: e.target.value });
  };

  const handleChangeScore = (data) => {
    if (!onChange) return;
    if (data.scoreId.indexOf('score9') > -1) {
      onChange(part, { [data.valId]: data.valValue, score9: data.scoreValue });
    } else {
      onChange(part, { [data.valId]: data.valValue, [data.scoreId]: data.scoreValue });
    }
  };

  const handleClickRadioScore = (e) => {
    const valId = e.target.name;
    const valValue = e.target.value;
    if (valId) {
      let scoreId = valId.replace('val', 'score');
      let scoreValue = datas?.[scoreId];
      const params = { valId, valValue, scoreId, scoreValue };
      setSelectedScore(params);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (!onChange) return;
    onChange(part, { [e.target.id]: e.target.value });
  };

  const handleDropImg = (acceptedFiles, imageType) => {
    if (!!acceptedFiles && acceptedFiles.length > 0) {
      setBackdropOpen(true);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      return new Promise((resolve) => {
        reader.onload = () => {
          eval(`onChange(part, { ${imageType + 'Pre'}: reader.result, ${imageType}: acceptedFiles[0] })`);
          resolve();
          setBackdropOpen(false);
        };
      });
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ m: 2, display: 'block' }}>
      <ToolbarArea title="도로구조문제"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" rowSpan={2}>
                ① 도로폭·포장종류
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.roadWidth && { required: true })}>
                도로폭
              </TableCell>
              <TableCell align="center" colSpan={3}>
                <TextField
                  id="roadWidth"
                  value={datas?.roadWidth || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                교행
              </TableCell>
              <TableCell align="center">
                <RadioGroup onChange={handleChangeRadio} name="val1_1" value={datas?.val1_1 || ''}>
                  <Radio value="0" label="가능" />
                  <Radio value="1" label="불가" />
                </RadioGroup>
              </TableCell>
              <TableCell align="center" component="th">
                포장종류
              </TableCell>
              <TableCell align="center">
                <RadioGroup onChange={handleChangeRadio} name="val1_2" value={datas?.val1_2 || ''}>
                  <Radio value="0" label="콘크리트" />
                  <Radio value="1" label="아스콘" />
                  <Radio value="2" label="비포장" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ② 선형불량
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val2" value={datas?.val2 || ''} color="secondary">
                  <Radio value="0" label="선형양호" />
                  <Radio value="1" label="선형불량·안전시설 대처가능" />
                  <Radio value="2" label="선형불량·사고위험" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ③ 안전시설(난간)
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val3" value={datas?.val3 || ''} color="secondary">
                  <Radio value="0" label="적합설치" />
                  <Radio value="1" label="노후화·부분파손" />
                  <Radio value="2" label="없음 또는 심한파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ④ 지반침하
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val4" value={datas?.val4 || ''} color="secondary">
                  <Radio value="0" label="없음" />
                  <Radio value="1" label="일부진행" />
                  <Radio value="2" label="있음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ToolbarArea title="기능성"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ⑤ 포장상태
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val5" value={datas?.val5 || ''} color="secondary">
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="균열·포트홀·통행양호" />
                  <Radio value="2" label="파손·통행지장" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑥ 비포장 구간
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val6" value={datas?.val6 || ''} color="secondary">
                  <Radio value="0" label="없음·양호·통행적음" />
                  <Radio value="1" label="차량통행 많음" />
                  <Radio value="2" label="차량통행 많음·민원 많음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑦ 노면요철
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val7" value={datas?.val7 || ''} color="secondary">
                  <Radio value="0" label="없음" />
                  <Radio value="1" label="소성변형·통행지장 없음" />
                  <Radio value="2" label="소성변형·통행지장" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ToolbarArea title="시설물 파손"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ⑧ 도로·부대시설
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val8" value={datas?.val8 || ''} color="secondary">
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="일부파손·안전지장 없음" />
                  <Radio value="2" label="심한파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th">
                ⑨ 배수로
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val9" value={datas?.val9 || ''} color="secondary">
                  <Radio value="0" label="양호" />
                  <Radio value="1" label="일부파손·막힘" />
                  <Radio value="2" label="없음 또는 심한파손" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ToolbarArea title="기타"></ToolbarArea>
      <TableContainer component={'div'} sx={{ width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="15%" />
            <col width="12%" />
            <col width="25%" />
            <col width="12%" />
            <col width="25%" />
          </colgroup>
          {/* <TableHead></TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ⑩ 농로입지
              </TableCell>
              <TableCell align="center" colSpan="4">
                <RadioGroup onChange={handleClickRadioScore} name="val10" value={datas?.val10 || ''} color="secondary">
                  <Radio value="0" label="안전지역 입지" />
                  <Radio value="1" label="절벽·민가입지·통행량 적음" />
                  <Radio value="2" label="위험지역 입지·통행량 많음" />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ToolbarArea title="기타의견"></ToolbarArea>
      <TextField id="opinionEtc" multiline rows={10} fullWidth value={datas?.opinionEtc || opinionEtc} onChange={handleChange} inputProps={{ maxLength: 3000 }} />

      <ToolbarArea title="사진 첨부"></ToolbarArea>
      <TableContainer component={'div'}>
        <Table>
          <colgroup>
            <col width="50%" />
            <col width="50%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                전경 사진
              </TableCell>
              <TableCell align="center" component="th">
                {`${label} 현황`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="panorama" maxFiles={1} maxFilesize={5242880} />
                {datas?.panoramaPre && <img src={datas?.panoramaPre} alt="panoramaPre" width="80%" height="200px" />}
                {!datas?.panoramaPre && (
                  <img
                    src={!!datas?.panoramaPre ? datas?.panoramaPre : `/api/safe/img/${prjId}/${fcCode}/${part}Panorama`}
                    alt={`${part}Panorama`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <DropzoneImage accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }} onAcceptedFiles={handleDropImg} id="point" maxFiles={1} maxFilesize={5242880} />
                {datas?.pointPre && <img src={datas?.pointPre} alt="pointPre" width="80%" height="200px" />}
                {!datas?.pointPre && (
                  <img
                    src={!!datas?.pointPre ? datas?.pointPre : `/api/safe/img/${prjId}/${fcCode}/${part}Point`}
                    alt={`${part}Point`}
                    width="80%"
                    height="200px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.display = 'none';
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <PopoverSpfRadio
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          selected={selectedScore}
          onChangeScore={handleChangeScore}
        />
      )}
      <Backdrop open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const LineConnectInfo = (props) => {
  const { datas, part, label, onChange } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(part, { [e.target.id]: e.target.value });
    }
  };
  return (
    <Box sx={{ display: 'block' }}>
      <ToolbarArea title="도로구조문제"></ToolbarArea>
      <TableContainer component={'div'} sx={{ height: '510px', width: '1100px' }}>
        <Table>
          <colgroup>
            <col width="17%" />
            <col width="13%" />
            <col width="70%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th">
                ①도로폭·포장종류
              </TableCell>
              <TableCell align="center" component="th" {...(!datas?.roadWidth && { required: true })}>
                도로폭
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="roadWidth"
                  value={datas?.roadWidth || ''}
                  placeholder={'입력해주세요'}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                  type="number"
                  required
                  error
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
