import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

export default function CustomDataGrid(props) {
  const { children, height, ...rest } = props;

  return (
    <Box
      sx={{
        height: height || 500,
        width: '100%',
        mt: 1,
        // '& .MuiDataGrid-cell--textCenter': {
        //   bgcolor: 'rgb(234 234 234)'
        // },
        '& .MuiDataGrid-row--editing': {
          backgroundColor: 'rgb(234 234 234)'
        }
        // '& .MuiDataGrid-cell--editable': {
        //   bgcolor: 'rgb(255 255 255)'
        // }
      }}
    >
      <DataGrid
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableExtendRowFullWidth={true}
        rowHeight={40}
        // experimentalFeatures={{ newEditingApi: true }}
        // checkboxSelection
        // disableSelectionOnClick
        // hideFooter
        // hideFooterPagination={true}
        {...rest}
      />
    </Box>
  );
}
