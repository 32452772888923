import React from 'react';
import { Box, ContentsBox, ToolbarArea } from 'components/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components/DataDisplay/Table';
import Checkbox from 'components/Inputs/Checkbox';
import Stack from '@mui/material/Stack';

const itemsVal1 = [
  { label: '파손없음·소규모 균열', value: '0' },
  { label: '심각한 파손·균열', value: '1' },
  { label: '파손·균열·안전상 지장 없음', value: '2' }
];
const itemsVal2 = [
  { label: '안전상 지장없음', value: '0' },
  { label: '균열부위 일부 부식', value: '1' },
  { label: '철근노출·심각한 부식', value: '2' }
];
const itemsVal3 = [
  { label: '세굴없음·세굴방지공 설치', value: '0' },
  { label: '세굴 일부 진행', value: '1' },
  { label: '세굴로 기초 노출', value: '2' }
];
const itemsVal4 = [
  { label: '양호', value: '0' },
  { label: '30년이하·배수구,난간 없음', value: '1' },
  { label: '30년이상·구조적 결함', value: '2' }
];
const itemsVal5 = [
  { label: '날개벽 있음·안전', value: '0' },
  { label: '날개벽 노후·균열', value: '1' },
  { label: '날개벽 없음·이동·침식', value: '2' }
];
const itemsVal6 = [
  { label: '높음·설계기준 적합', value: '0' },
  { label: '일부 설계기준 부적합', value: '1' },
  { label: '과거 월류 피해발생', value: '2' }
];
const itemsVal9 = [
  { label: '거의 없음', value: '0' },
  { label: '작음', value: '1' },
  { label: '많음', value: '2' }
];
const itemsVal10 = [
  { label: '계획홍수위보다 높음', value: '0' },
  { label: '세월교·통행자 거의없음', value: '1' },
  { label: '세월교·통행자 많음', value: '2' }
];

export default function SafeEvlReportA(props) {
  const { pData, pRows, evlRslt } = props;

  const point = pRows.find((t) => t.part === 'point');

  return (
    <Box sx={{ display: 'block', m: 0 }}>
      <Box sx={{ m: 2, display: 'block' }}>
        <ToolbarArea title="현장조사"></ToolbarArea>
        <TableContainer component={'div'} sx={{ width: '1200px' }}>
          <Table>
            <colgroup>
              <col width="8%" />
              <col width="15%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
              <col width="7%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">조사항목</TableCell>
                <TableCell align="center" colSpan={6}>
                  항목별 평가
                </TableCell>
                <TableCell align="center">점수</TableCell>
              </TableRow>
            </TableHead>
            {/* 제원측정 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={3} component="th">
                  소교량제원
                </TableCell>
                <TableCell component="th">교량제원</TableCell>
                <TableCell align="center" component="th">
                  연장
                </TableCell>
                <TableCell align="center">{point?.bLength}</TableCell>
                <TableCell align="center" component="th">
                  폭
                </TableCell>
                <TableCell align="center">{point?.bWidth}</TableCell>
                <TableCell align="center" component="th">
                  높이
                </TableCell>
                <TableCell align="center">{point?.bHeight}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">상하류 하폭</TableCell>
                <TableCell align="center" component="th">
                  상류하폭
                </TableCell>
                <TableCell align="center">{point?.riverWidthTop}</TableCell>
                <TableCell align="center" component="th">
                  교량위치하폭
                </TableCell>
                <TableCell align="center">{point?.riverWidthPos}</TableCell>
                <TableCell align="center" component="th">
                  하류하폭
                </TableCell>
                <TableCell align="center">{point?.riverWidthBottom}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">교각</TableCell>
                <TableCell align="center" component="th">
                  교각수
                </TableCell>
                <TableCell align="center">{point?.pierCnt}</TableCell>
                <TableCell align="center" component="th">
                  경간장
                </TableCell>
                <TableCell align="center">{point?.pierLength}</TableCell>
                <TableCell colSpan={2}></TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
            </TableBody>

            {/* 구조적안전성 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={4} component="th">
                  구조적안전성
                </TableCell>
                <TableCell component="th">① 슬라브·교대·교각·파손·균열</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal1} value={point?.val1} />
                </TableCell>
                <TableCell align="center">{point?.score1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">② 철근노출·부식</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal2} value={point?.val2} />
                </TableCell>
                <TableCell align="center">{point?.score2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">③ 교대·교각 세굴</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal3} value={point?.val3} />
                </TableCell>
                <TableCell align="center">{point?.score3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">④노후도·구조물 안전성</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal4} value={point?.val4} />
                </TableCell>
                <TableCell align="center">{point?.score4}</TableCell>
              </TableRow>
            </TableBody>
            {/* 3.홍수위험성 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={4} component="th">
                  홍수위험성
                </TableCell>
                <TableCell component="th">⑤ 제방 접합부 침식</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal5} value={point?.val5} />
                </TableCell>
                <TableCell align="center">{point?.score5}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑥여유고 부족·월류</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal6} value={point?.val6} />
                </TableCell>
                <TableCell align="center">{point?.score6}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑦하폭에 비해 작은 규모의 교량</TableCell>
                <TableCell colSPan={6}>하폭 입력값과 교량 제원의 자동 비교를 통한 점수 산정</TableCell>
                <TableCell align="center">{point?.score7}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑧경간장 부족에 의한 피해 여부</TableCell>
                <TableCell colSPan={6}>하폭 입력값과 경간장 입력값의 자동 비교를 통한 점수 산정</TableCell>
                <TableCell align="center">{point?.score7}</TableCell>
              </TableRow>
            </TableBody>
            {/* 4.기타 */}
            <TableBody>
              <TableRow>
                <TableCell rowSpan={2} component="th">
                  기타
                </TableCell>
                <TableCell component="th">⑨ 입지여건·통행량</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal9} value={point?.val9} />
                </TableCell>
                <TableCell align="center">{point?.score9}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">⑩ 통행위험성·세월교</TableCell>
                <TableCell colSPan={6}>
                  <CheckItems items={itemsVal10} value={point?.val10} />
                </TableCell>
                <TableCell align="center">{point?.score10}</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell align="center" component="th" colSPan={2}>
                  계
                </TableCell>
                <TableCell align="center" colSPan={6}>
                  <span style={evlRslt?.totResult === '양호' ? totResultStyle : {}}>양호</span> / <span style={evlRslt?.totResult === '보통' ? totResultStyle : {}}>보통</span> /{' '}
                  <span style={evlRslt?.totResult === '불량' ? totResultStyle : {}}>나쁨</span>
                </TableCell>
                <TableCell align="center">{evlRslt?.totScore}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
const totResultStyle = { width: '100px', height: '85px', borderRadius: '50%', border: '1px solid' };

const CheckItems = (props) => {
  const { value, items } = props;

  return (
    <Stack direction="row" sx={{ mx: 2 }} spacing={1}>
      {items.map((item) => {
        return <Checkbox label={item?.label} checked={item.value === value} disabled />;
      })}
    </Stack>
  );
};
