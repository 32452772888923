import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    // 'label + &': {
    // marginTop: theme.spacing(3),
    // },
    '& .MuiNativeSelect-root': {
        backgroundColor: theme.palette.background.paper,
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 13,
        padding: '5px 15px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default function CustomizedSelects(props) {

    const { children, onChange, width, value, ...rest } = props;

    const [val, setVal] = useState('');

    const handleChange = (event) => {
        setVal(event.target.value);
    };

    useEffect(() => {
        setVal(value ? value : '');
    }, [value]);

    return (
        <NativeSelect
            // id="demo-customized-select-native"
            // value={value}
            onChange={onChange ? onChange : handleChange}
            value={val ? val : ''}
            input={
                width
                    ?
                    <BootstrapInput sx={{ width }} />
                    :
                    <BootstrapInput fullWidth />
            }
            {...rest}
        >
            {children}
        </NativeSelect>
    );
}