import React, { useEffect, useState } from 'react';
import Button from 'components/Inputs/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Feedback/Dialog';
import { Table, TableBody, TableCell, TableContainer, TableRow } from 'components/DataDisplay/Table';

export default function SafeEvlPicture(props) {
  const { open, setOpenSafeEvl, geoFeature } = props;
  const [fcCode, setFcCode] = useState(null);
  const [prjId, setPrjId] = useState(null);
  const [fcType, setFcType] = useState(null);

  const handleClose = (e) => {
    setOpenSafeEvl((prev) => !prev);
  };

  const setSafeEvlPicture = () => {
    setFcCode(geoFeature.get('fc_code'));
    setPrjId(geoFeature.get('prj_id'));
    setFcType(geoFeature.get('fc_type'));
  };

  useEffect(() => {
    if (geoFeature.get('id')) setSafeEvlPicture();
  }, [geoFeature]);

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" aria-labelledby="draggable-dialog-title" minWidth="1200">
      <DialogTitle id="draggable-dialog-title">사진관리</DialogTitle>
      <DialogContent dividers sx={{ py: 0 }}>
        <TableContainer component={'div'}>
          <Table>
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <TableBody>
              {fcType !== 'B' && fcType !== 'C' && (
                <>
                  <TableRow>
                    <TableCell align="center" component="th">
                      시점부 전경 사진
                    </TableCell>
                    <TableCell align="center" component="th">
                      시점부 현황
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/startPanorama`}
                        alt="panoramaPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/startPoint`}
                        alt="pointPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" component="th">
                      중점부 전경 사진
                    </TableCell>
                    <TableCell align="center" component="th">
                      중점부 현황
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/midPanorama`}
                        alt="panoramaPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/midPoint`}
                        alt="pointPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" component="th">
                      종점부 전경 사진
                    </TableCell>
                    <TableCell align="center" component="th">
                      종점부 현황
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/endPanorama`}
                        alt="panoramaPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/endPoint`}
                        alt="pointPre"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {(fcType === 'B' || fcType === 'C') && (
                <>
                  <TableRow>
                    <TableCell align="center" component="th">
                      전경 사진
                    </TableCell>
                    <TableCell align="center" component="th">
                      주변현황사진
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/pointPanorama`}
                        alt="panorama"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={`/api/safe/img/${prjId}/${fcCode}/pointSide`}
                        alt="side"
                        width="400px"
                        height="250px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = 'none';
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default" fullWidth>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
