import React from 'react';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
}));

export default function CustomTableHead(props) {

    const { children, ...rest } = props;

    return (
        <StyledTableHead
            {...rest}
        >
            {children}
        </StyledTableHead>
    );
}
