import { useRecoilValue } from 'recoil';
import { authAtom } from '../_state';
import { JSEncrypt } from 'jsencrypt';

const rsaPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0JHr/mQnO8Ngf5KtO0k4
FAtB9qcZvElapYYTlGtrBX+m3pllekiqw1lJJBo9ZRoRfRgEGfExwUYV3FX9dSFd
tfrRphY6kcNxIHotD/UeVuFRqJeyUCiOHkeSxqcuc2DxC1xCo6j0Dv494Uhw3Ppy
vmtqrVfcG3h0kPO1mJeKPM60GPXwVAaA4au1oIZunbXVpXH+zKJQJey+62wM7Wcn
L0AnczsbvuM4DBo2WqO9EG0tiAOWPsGqv9Gh3IAeIyQgl7eKVnnsRyf53Sn3QliD
68PuNOvFoC/XrRneP7vKuuso71GcIW8OMP+SKQDLnc6AlVhw5CWtHkVoWANXdLQy
EQIDAQAB
-----END PUBLIC KEY-----`;
const useAuth = () => {
  const auth = useRecoilValue(authAtom);
  return auth;
};

const authUtils = {
  useIsLogin: () => {
    const auth = useAuth();
    return !!auth;
  },
  useIsManager: () => {
    const auth = useAuth();
    if (auth?.scale === 'N') {
      return false;
    }
    return auth?.role === 'ROLE_MANAGER' || auth?.role === 'ROLE_ADMIN';
  },
  useIsAdmin: () => {
    const auth = useAuth();
    return auth?.role === 'ROLE_ADMIN';
  },
  useGetUserid: () => {
    const auth = useAuth();
    return auth?.userid;
  },
  useEncryptByPublicKey: (data) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(rsaPublicKey);
    return encrypt.encrypt(JSON.stringify(data));
  }
};

export { authUtils };
