import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';

export default function ToolbarArea(props) {
  const { children, title, count, sx, flex, size, ...rest } = props;

  return (
    <Grid container>
      {children ? (
        <>
          <Grid item xs={5} sx={{ mt: 1 }}>
            <Box sx={{ flex: '1 1 auto' }}>
              <HdrWeakIcon sx={{ mr: 1, pt: 1 }} color="success" />
              <span style={{ fontSize: 'medium', fontWeight: 'bold' }}>
                {title} {!!count && <span style={{ fontSize: 'small' }}> (전체 {count} 건)</span>}
              </span>
            </Box>
          </Grid>
          <Grid item xs={7}>
            {children}
          </Grid>
        </>
      ) : size === 'small' ? (
        <Grid container>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <span style={{ marginLeft: '8px' }}>
              {title} {!!count && <span style={{ fontSize: 'small' }}> (전체 {count} 건)</span>}
            </span>
          </Grid>
          <Grid item xs={8}>
            {children}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box sx={{ flex: '1 1 auto' }}>
            <HdrWeakIcon sx={{ mr: 1, pt: 1 }} color="success" />
            <span style={{ fontSize: 'medium', fontWeight: 'bold' }}>
              {title} {!!count && <span style={{ fontSize: 'small' }}> (전체 {count} 건)</span>}
            </span>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
