import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import SourceIcon from "@mui/icons-material/Source";
import CodeIcon from "@mui/icons-material/Code";
import CategoryIcon from "@mui/icons-material/Category";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import HistoryIcon from "@mui/icons-material/History";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InfoIcon from "@mui/icons-material/Info";
import PageviewIcon from "@mui/icons-material/Pageview";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import KeyIcon from "@mui/icons-material/Key";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TuneIcon from "@mui/icons-material/Tune";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalculateIcon from "@mui/icons-material/Calculate";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { authUtils } from "utils/authUtils.js";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Sidebar(props) {
  const { pathname } = props;

  const menuItemList = [
    {
      divider: true,
    },
    authUtils.useIsAdmin() && {
      name: "프로젝트 관리",
      uri: "/prj",
      Icon: SourceIcon,
    },
    {
      divider: true,
    },
    authUtils.useIsManager() && {
      name: "사용자 관리",
      Icon: SourceIcon,
      items: [
        authUtils.useIsAdmin() && {
          name: "관리자 관리",
          uri: "/user/admin",
          Icon: AddBusinessIcon,
          level: 2,
        },
        {
          name: "점검원 관리",
          uri: "/user/insp",
          Icon: CategoryIcon,
          level: 2,
        },
      ],
    },
    {
      divider: true,
    },
    authUtils.useIsLogin() && {
      name: "안전점검 관리",
      uri: "safe",
      Icon: SourceIcon,
    },
    {
      divider: true,
    },
    authUtils.useIsLogin() && {
      name: "공공시설 통계",
      uri: "stat",
      Icon: SourceIcon,
    },
  ];

  return (
    <Drawer variant="permanent" open={true}>
      {/* <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1]
        }}
      >
        <IconButton>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar> */}
      <List component="nav" disablePadding>
        {menuItemList.map((item, index) => item && <MenuItem {...item} key={index} />)}
      </List>
    </Drawer>
  );
}

const customTextTheme = (level) => {
  return createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: `${level ? level * 0.4 : 1}rem`,
          },
        },
      },
    },
  });
};

const MenuItem = (props) => {
  const { name, uri, Icon, level, divider, items = [] } = props;
  const isExpandable = items && items.length > 0;
  const isDevider = divider;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <MenuItemComponent uri={uri} onClick={handleClick} level={level}>
      {!!Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      {level ? (
        <ThemeProvider theme={customTextTheme(level)}>
          <ListItemText primary={name} inset={!Icon} />
        </ThemeProvider>
      ) : (
        <ListItemText primary={name} inset={!Icon} />
      )}
      {isExpandable && !open && <ExpandMore />}
      {isExpandable && open && <ExpandLess />}
    </MenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => item && <MenuItem {...item} key={index} />)}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {!isDevider && MenuItemRoot}
      {!isDevider && MenuItemChildren}
      {isDevider && <Divider />}
    </>
  );
};

const MenuItemComponent = (props) => {
  let navigate = useNavigate();
  const { onClick, uri, children, level } = props;

  const handleClick = (uri) => (e) => {
    navigate(`${uri}`);
  };

  if (!uri || typeof uri !== "string") {
    return <ListItem button children={children} onClick={onClick} />;
  }

  return <ListItem button children={children} sx={{ pl: level * 2 }} onClick={handleClick(uri)} />;
};
