import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/Inputs/Button";
import logo from "assets/img/dohwa_icon3.png";

import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import SourceIcon from "@mui/icons-material/Source";
import PersonIcon from "@mui/icons-material/Person";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { useRecoilState } from "recoil";
import { authAtom } from "../_state";

export default function Default() {
  const navigate = useNavigate();
  const location = useLocation();

  const [auth, setAuth] = useRecoilState(authAtom);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handlePageMove = (uri) => (e) => {
    if (uri === "sys") {
      navigate(`/sys/code`);
    } else if (uri === "mng") {
      navigate(`/mng/reg/asstmng`);
    } else if (uri === "gis") {
      navigate(`/${uri}`);
    }
  };

  const handleLogout = (e) => {
    if (window.confirm(`로그아웃 하시겠습니까?`)) {
      Cookies.remove("access_token");
      localStorage.clear();
      setAuth(null);
      alert("로그아웃이 완료 되었습니다.");
      navigate("/login");
    }
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ background: "#004442", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <Box component="img" src={logo} sx={{ mr: 1, width: 30, height: 30 }} />
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          소규모공공시설관리시스템 V1.0
        </Typography>
        {location.pathname.indexOf("/mng") > -1 && (
          <Button variant="text" sx={{ color: "white" }} onClick={handlePageMove("sys")}>
            <SettingsIcon sx={{ mr: 0.5 }} />
            시스템관리
          </Button>
        )}
        {location.pathname.indexOf("/sys") > -1 && (
          <>
            <Button variant="text" sx={{ color: "white" }} onClick={handlePageMove("gis")}>
              <MapIcon sx={{ mr: 0.5 }} />
              GIS현황
            </Button>
            <Button variant="text" sx={{ color: "white" }} onClick={handlePageMove("mng")}>
              <SourceIcon sx={{ mr: 0.5 }} />
              자산관리
            </Button>
          </>
        )}

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="마이 페이지">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <PersonIcon fontSize="large" sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                navigate("/info");
                handleCloseUserMenu();
              }}
            >
              <Typography textAlign="center">내정보</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">로그아웃</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
